import React from 'react';
import FigureCard from '../../../components/FigureCard/index.jsx';

import {BusinessSlickWrap,
} from '../styled';


const BusinessItems = (props) => {
    return (
        <BusinessSlickWrap count={props.members ? props.members.length : ''}>
            <div>
            {props.members ? props.members.map((member, key) => 
                <FigureCard active={props.active} member={{id: key, member_type:props.member_type, ...member}} />) : ''}
            {/* <FigureCard member={{id: 1, member_type:props.member_type, member_name:"이름",member_position:"직책",member_text:"텍스트",member_image:"https://kds.easypress.me/wp-content/uploads/2020/10/스크린샷-2020-10-22-오후-11.51.31.png", member_imageSharp:"1"}} /> */}
            </div>
        </BusinessSlickWrap>
    );
};

export default BusinessItems;