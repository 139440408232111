import React from 'react';
import Popup from '../../../components/Popup';
import styled from 'styled-components';

const VisionVideoPopupStyle = styled.div``;
const VisionVideoPopup1 = (props) => {
    return (
        <VisionVideoPopupStyle>
            <Popup type="video" show={props.show} onClose={props.onClose} youtube_id="BGBIu3WL0jE" ></Popup>
        </VisionVideoPopupStyle>
    );
};

export default VisionVideoPopup1;