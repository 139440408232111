import styled from "styled-components";

export const PopupWrapper = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
  ${(props) => (props.zIndex ? `z-index: ${props.zIndex}` : `z-index:1000000`)};
  ${(props) => props.show && `display:block`};
  ${(props) => props.width && `width:` + props.width + `;`}
  ${(props) => props.top && `top:` + props.top + `;`}
    @media ${(props) => props.theme.tablet} {
    position: fixed;
    padding: 20px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  @media ${(props) => props.theme.mobile} {
    position: fixed;
    /* padding: 20px; */
    padding: 116px 0 20px 0;
    overflow-x: hidden;
    overflow-y: scroll;
  }
`;

export const PopupInner = styled.div`
  max-width: 700px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  transform: none;
  padding-bottom: 40px;
  margin-bottom: 150px;
  z-index: 100;
  left: 50%;
  background: #ffffff;
  ${(props) =>
    props.border ? `border: ${props.border};` : `border: 1px solid #bbbbbb;`}
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(190, 190, 190, 0.25);
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  ${(props) => {
    if (props.padding) return `padding: ${props.padding};`;
    else if (props.padding == "none") return `padding: 0px !important;`;
    else return `padding: 40px 50px;`;
  }}
  ${(props) => {
    if (props.show) {
      return `
                top: 60px;
            `;
    } else {
      return `
                top:-500vh;
            `;
    }
  }}
    @media ${(props) => props.theme.tablet} {
    width: 100%;
    left: 14%;
    margin-bottom: 0px;
    box-sizing: border-box;
    position: relative;
    transform: none;
    ${(props) => {
      if (props.show) {
        return `
                top: 60px;
            `;
      } else {
        return `
                top:-500vh;
            `;
      }
    }}
    ${(props) => {
      if (props.padding == "none") return `padding: 0px !important;`;
      else return `padding: 40px 50px;`;
    }}
  }
  @media ${(props) => props.theme.mobile} {
    width: 100%;
    padding: 30px 20px 0px 20px;
    box-sizing: border-box;
    left: 0px;
    top: 0px;
    position: relative;
    transform: none;
    ${(props) => {
      if (props.size == "Full") {
        return `padding-right: 0px; padding-left: 0px;`;
      }
    }}
  }
`;

export const PopupHead = styled.div`
  position: relative;
  margin-bottom: 40px;
  ${(props) => props.Headpadding && `padding:` + props.Headpadding + `;`}
  strong {
    display: block;
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 32px;
    color: #000000;
    padding-top: 20px;
    margin-bottom: 10px;
  }
  a {
    position: absolute;
    top: 0px;
    right: 0px;
    line-height: auto;
    cursor: pointer;
    ${(props) => (props.is_padding ? `top: 40px; right: 40px` : "")}
  }
  p {
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    color: #666666;
  }
  @media ${(props) => props.theme.mobile} {
    margin-bottom: 10px;
    p {
      font-size: 13px;
    }
    strong {
      padding-top: 40px;
      font-size: 20px;
      line-height: 28px;
      ${(props) => (props.is_padding ? `padding-top: 20px;` : "")}
    }
    a {
      ${(props) => (props.is_padding ? `top: 0px; right: 20px` : "")}
    }
    ${(props) => {
      if (props.size == "Full") {
        return `padding: 20px 20px 0px 20px`;
      }
    }}
  }
`;

export const PopupBody = styled.div``;

export const Overlay = styled.div`
  width: 100%;
  max-width: 100%;
  height: 130%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99;
  position: absolute;
  top: 0px;
  left: 0px;
  ${(props) => {
    if (props.show) {
      return `
                display:block;
            `;
    } else {
      return `
                display:none;
            `;
    }
  }}
  @media ${(props) => props.theme.talbet} {
    height: 160%;
  }
  @media ${(props) => props.theme.mobile} {
    height: 300%;
  }
`;

export const PopupContent = styled.div``;
export const PopupItem = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
  ${(props) => (props.zIndex ? `z-index: ${props.zIndex}` : `z-index:1000000`)};
  ${(props) => props.show && `display:block`};
  ${(props) => props.width && `width:` + props.width + `;`}
  ${(props) => props.top && `top:` + props.top + `;`}
@media ${(props) => props.theme.tablet} {
    position: fixed;
    padding: 20px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  @media ${(props) => props.theme.mobile} {
    position: fixed;
    padding: 20px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
`;
