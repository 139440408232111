import React from 'react';
import { SidemenuWrapper, Overlay, SidemenuBar, PopupHead } from './styled';

const SidePopup = props => {
  return (
    <SidemenuWrapper isopen={props.option.open}>
      <Overlay isopen={props.option.open} onClick={props.option.onClose} />
      <SidemenuBar isopen={props.option.open}>
        <PopupHead>
          <strong>{props.option.title}</strong>
          <a onClick={props.option.onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                id="ic_close_24px"
                d="M23,6.813,21.187,5,14,12.187,6.813,5,5,6.813,12.187,14,5,21.187,6.813,23,14,15.813,21.187,23,23,21.187,15.813,14Z"
                transform="translate(-5 -5)"
              />
            </svg>
          </a>
          <p>{props.option.summary}</p>
        </PopupHead>
        {props.option.children}
      </SidemenuBar>
    </SidemenuWrapper>
  );
};

export default SidePopup;
