import React, { useRef, useState } from 'react';
import Popup from '../../../components/Popup';
import Badge from '../../../components/Badge';
import SectionDevelopmentCard from './SectionDevelopmentCard';
import { Row, Col, mobileQuery, MediaQuery } from '../../../components/Grid';
import YouTube from 'react-youtube';
import {MainContent,
        MainContentBadge,
        MainVideoWrapper,
        CardContainer,
        } from '../styled';
import { motion } from 'framer-motion';

const SectionDevelopment = (props) => {
    const video_el = useRef(null);
    const isMobile = MediaQuery(mobileQuery);
    const [VideoPopup_isshow,show_VideoPopup] = useState(0);
    const opts = {
      height: '100%',
      width: '100%',
      playerVars: {
        autoplay: 1,
        mute: 1,
        loop: 1,
        playlist: 'cAK3r7kPje0',
        controls: 0
      },
    };
    const showVideoPopup = (e) => {
        e.preventDefault();
        if(VideoPopup_isshow) {
            show_VideoPopup(0);
        } else {
            show_VideoPopup(1);
        }
    }

    return (
        <motion.div
            initial={{ opacity: 0, y: 140 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, ease: 'easeOut', staggerChildren: 0.5 }}>
            <MainContent>
                <Row>
                    <Col flex="2"><h4>시스템 개발</h4></Col>
                    <Col flex="8">
                        <p>
                            KB데이타시스템은 KB금융그룹이 세상을 바꾸는 금융이 될 수 있도록 최고의 인재와 담대한 
                            혁신으로 가장 신뢰받는 금융IT파트너가 되겠습니다. 
                        </p>
                        <MainContentBadge>
                            <Badge type="second" size="medium">#스타뱅킹</Badge>
                            <Badge type="second" size="medium">#KB시니어플랫폼</Badge>
                        </MainContentBadge>
                        <MainVideoWrapper bg='Visionitem6.png' onClick={showVideoPopup}>
                            {!isMobile ? (
                            <YouTube
                                videoId={"cAK3r7kPje0"}
                                opts={opts}
                            />
                            // <video autoPlay={true} muted loop ref={video_el}>
                            //     <source src="https://kds.easypress.me/wp-content/plugins/ep-manager/assets/images/common/vision_si.mp4" type="video/mp4"/>
                            // </video> 
                            ) : (<div></div>)}
                        </MainVideoWrapper>
                        <CardContainer>
                            <SectionDevelopmentCard show_popup={props.show_popup}/>
                        </CardContainer>
                    </Col>
                </Row>
                <Popup type="video" youtube_id="cAK3r7kPje0" show={VideoPopup_isshow} onClose={showVideoPopup}/>
                {/* <Popup type="video" src="https://kds.easypress.me/wp-content/plugins/ep-manager/assets/images/common/vision_si.mp4" show={VideoPopup_isshow} onClose={showVideoPopup}/> */}
            </MainContent>
        </motion.div>
    )
};

export default SectionDevelopment;