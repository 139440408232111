import React from 'react';
import TagCloud from 'react-tag-cloud';
import randomColor from 'randomcolor';
import {TagBox} from '../styled';

const Tag = () => { 
    return (
    <TagBox>
      <TagCloud 
        style={{
          fontFamily: 'KBFG Display',
          fontSize: 30,
          fontWeight: '600',
          fontStyle: 'normal',
          color: () => randomColor(),
          padding: 20,
          width: '100%',
          height: '100%'
        }}>
        <div style={{fontSize: 48, lineheight:34, color: 'rgba(255, 172, 0, 0.6)'}}>#Blind-Recruitment</div>
        <div style={{fontSize: 38, lineheight:34, color: 'rgba(255, 172, 0, 0.6)'}}>#DevOps</div>
        <div style={{fontSize: 28, lineheight:34, color: 'rgba(255, 172, 0, 0.6)'}}>#DevOps</div>
        <div style={{fontSize: 28, lineheight:34, color: 'rgba(144, 136, 125, 0.6)'}}>#Consulting</div>
        <div style={{fontSize: 28, lineheight:34, color: 'rgba(144, 136, 125, 0.6)'}}>#Agile</div>
        <div style={{fontSize: 28, lineheight:34, color: 'rgba(144, 136, 125, 0.6)'}}>#Consulting</div>
        <div style={{fontSize: 18, lineheight:34, color: 'rgba(144, 136, 125, 0.6)'}}>#Consulting</div>
        <div style={{fontSize: 18, lineheight:34, color: 'rgba(144, 136, 125, 0.6)'}}>#Cloud</div>
        <div style={{fontSize: 18, lineheight:34, color: 'rgba(144, 136, 125, 0.6)'}}>#Agile</div>
        <div style={{fontSize: 18, lineheight:34, color: 'rgba(144, 136, 125, 0.6)'}}>#Cloud</div>
        <div style={{fontSize: 18, lineheight:34, color: 'rgba(144, 136, 125, 0.6)'}}>#Agile</div>
        <div style={{fontSize: 18, lineheight:34, color: 'rgba(144, 136, 125, 0.6)'}}>#Cloud</div>
      </TagCloud>
    </TagBox>
    );
};

export default Tag;