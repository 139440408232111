import React from "react";
import { Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import Home from "./views/Home";
import Qrdemo from "./views/Qrdemo";
import About from "./views/About";
import Vision from "./views/Vision";
import Story from "./views/Story";
import PrivacyPolicy from "./views/Pages/PrivacyPolicy";
import CustomerPolicy from "./views/Pages/CustomerPolicy";
import Operation from "./views/Pages/Operation";
import Custom from "./views/Pages/Custom";
import Custom2 from "./views/Pages/Custom2";
import SearchPage from "./views/Story/Search";
import Single from "./views/Story/Single";
import TagPage from "./views/Story/Tag/index";
import User from "./views/User";
import Bidding from "./views/Bidding";
import BiddingView from "./views/Bidding/components/View";
import People from "./views/People";
import Global from "./views/Global";

function App() {
  return (
    <>
      <Helmet>
        <title>KB데이타시스템</title>
      </Helmet>
      <Switch>
        <Route exact={true} path="/" component={Home} />
        <Route exact={true} path="/about" component={About} />
        <Route exact={true} path="/qrdemo" component={Qrdemo} />
        <Route exact={true} path="/vision" component={Vision} />
        <Route exact={true} path="/story" component={Story} />
        <Route exact={true} path="/people" component={People} />
        <Route exact={true} path="/global" component={Global} />
        <Route exact={true} path="/privacy_policy" component={PrivacyPolicy} />
        <Route exact={true} path="/operational_example" component={Operation} />

        <Route
          exact={true}
          path="/privacy_policy/:version"
          component={PrivacyPolicy}
        />
        <Route
          exact={true}
          path="/customer_policy"
          component={CustomerPolicy}
        />
        <Route exact={true} path="/consignment_company" component={Custom} />
        <Route
          exact={true}
          path="/consignment_company_02"
          component={Custom2}
        />
        <Route exact={true} path="/story/:id" component={Single} />
        <Route exact={true} path="/search/:keyword" component={SearchPage} />
        <Route exact={true} path="/tag/:tag_slug" component={TagPage} />
        <Route exact={true} path="/user/:id" component={User} />
        <Route exact={true} path="/board/bidding" component={Bidding} />
        <Route exact={true} path="/board/bidding/:id" component={BiddingView} />
      </Switch>
    </>
  );
}

export default App;
