import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import './Reset.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from "react-router-dom";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { ThemeProvider } from 'styled-components';
import theme from './components/theme';
import { ParallaxProvider } from 'react-scroll-parallax';

ReactDOM.render(
  // <React>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
          <ParallaxProvider>
            <App />
          </ParallaxProvider>
      </BrowserRouter>
    </ThemeProvider>,
//  </React>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
