import React,{useEffect, useState} from 'react';
import { useParams } from 'react-router';
import MainContainer from '../../../components/MainContainer';
import {CommonContainer} from '../../../components/Grid';
import Card from '../../../components/Card'
import LoadInView from '../../../components/LoadInView'
import Pagination from '../../../components/Pagination'
import {CardSection} from '../components/styled'
import {SearchWrapper,
        SearchCount,
        Path,
        Title,
        Blank
} from './styled';
import {SearchGetPosts} from '../../../api/story';

const SearchPage = (props) => {
    const [post_list, update_post_list] = useState(0);
    const [current_paged, set_current_paged] = useState(0);
    const [total_page, set_total_page] = useState(0);
    const [list_tags, set_list_tags] = useState(0);
    const [paged, set_paged] = useState(1);
    const params = useParams();
    useEffect(() => {
        if(current_paged != paged) {
            SearchGetPosts({
                keyword: params.keyword,
                posts_per_page: '12',
                paged: paged
            })
            .then((response) => {
                console.log(response);
                if(response.data.body.paged) {
                    set_total_page(response.data.body.total);
                    set_current_paged(response.data.body.paged);
                    update_post_list(response.data.body.posts);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    });
    const onPaging = (page) => {
        console.log(page);
        set_paged(page);
        window.scrollTo({top:document.querySelector('#post_archive').offsetTop});
    }
    return (
        <MainContainer>
            <SearchWrapper>
                <CommonContainer>
                    <Path>
                        <span>홈</span>
                        <span>Story</span>
                        Search
                    </Path>
                    <Title id="post_archive">
                        Search <span>{params.keyword}</span>
                    </Title>
                    <SearchCount>
                        총 <span>{total_page ? total_page : 0}</span>개의 포스트가 있습니다.
                    </SearchCount>
                    <Blank />
                    <CardSection> 
                        {post_list ? post_list.map((post, key) => <Card item={post} width="33.3333%"/>) : '로딩'}
                    </CardSection>
                    <LoadInView>
                        <Pagination onPaging={onPaging} current={current_paged} max={(total_page >= 10) ? (parseInt(total_page / 10) + 1) : 1}/>
                    </LoadInView>
                </CommonContainer>
            </SearchWrapper>
        </MainContainer>
    );
};

export default SearchPage;