import React from "react";
import { CommonContainer } from "../../../components/Grid";
import { AboutKeyWord, AboutWebSite, FigureIcon } from "../styled";
import Tag from "./Tag";

const AboutSubContent = (props) => {
  return (
    <CommonContainer>
      <AboutKeyWord>
        <h2>키워드</h2>
        <Tag tags={props.user_tags} />
      </AboutKeyWord>
      <AboutWebSite>
        <h2>웹사이트</h2>
        <FigureIcon>
          <ul>
            <li>
              <a href="/#">
                <img src={process.env.PUBLIC_URL + "/Figureitem1.svg"} alt="" />
              </a>
            </li>
            <li>
              <a href="/#">
                <img src={process.env.PUBLIC_URL + "/Figureitem2.svg"} alt="" />
              </a>
            </li>
            <li>
              <a href="/#">
                <img src={process.env.PUBLIC_URL + "/Figureitem3.svg"} alt="" />
              </a>
            </li>
          </ul>
        </FigureIcon>
      </AboutWebSite>
    </CommonContainer>
  );
};

export default AboutSubContent;
