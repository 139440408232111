import axios from './defaultClient';

const nameSpace = '/ep/v1/board';
export const BiddingGetPosts = async (args) => {
    return await axios.get( nameSpace + '/bidding/',{ 
        params:{
            order: args.order,
            orderby: args.orderby,
            posts_per_page: args.posts_per_page,
            paged: args.paged
        }
    })
}

export const BiddingGetPost = async (args) => {
    return await axios.get( nameSpace + '/bidding/' + args.post_id, {})
}