import { motion } from "framer-motion";
import React, { useState } from "react";
import { Br, CommonContainer } from "../../../components/Grid";
import {
  SectionTitle,
  WorkContent,
  WorkContentWrapper,
  WorkVideo,
  WorkWrapper,
} from "../styled";
import VisionVideoPopup1 from "./VisionVideoPopup1";
import VisionVideoPopup2 from "./VisionVideoPopup2";
import VisionVideoPopup3 from "./VisionVideoPopup3";
import VisionVideoPopup4 from "./VisionVideoPopup4";
import VisionVideoPopup5 from "./VisionVideoPopup5";
import VisionVideoPopup6 from "./VisionVideoPopup6";
import VisionVideoPopup7 from "./VisionVideoPopup7";

const SectionWayworking = (props) => {
  // const [active_popup, set_active_popup] = useState(null);
  // const show_popup = (e) => {
  //     const item = e.currentTarget.dataset.id;
  //     e.preventDefault();
  //     set_active_popup(item);
  // }
  // const close_popup = (e) => {
  //     e.preventDefault();
  //     set_active_popup(null);
  // }

  const [Video1_isshow, show_Video1] = useState(0);
  const showVideo1 = (e) => {
    e.preventDefault();
    if (Video1_isshow) {
      show_Video1(0);
    } else {
      show_Video1(1);
    }
  };

  const [Video2_isshow, show_Video2] = useState(0);
  const showVideo2 = (e) => {
    e.preventDefault();
    if (Video2_isshow) {
      show_Video2(0);
    } else {
      show_Video2(1);
    }
  };

  const [Video3_isshow, show_Video3] = useState(0);
  const showVideo3 = (e) => {
    e.preventDefault();
    if (Video3_isshow) {
      show_Video3(0);
    } else {
      show_Video3(1);
    }
  };

  const [Video4_isshow, show_Video4] = useState(0);
  const showVideo4 = (e) => {
    e.preventDefault();
    if (Video4_isshow) {
      show_Video4(0);
    } else {
      show_Video4(1);
    }
  };

  const [Video5_isshow, show_Video5] = useState(0);
  const showVideo5 = (e) => {
    e.preventDefault();
    if (Video5_isshow) {
      show_Video5(0);
    } else {
      show_Video5(1);
    }
  };

  const [Video6_isshow, show_Video6] = useState(0);
  const showVideo6 = (e) => {
    e.preventDefault();
    if (Video6_isshow) {
      show_Video6(0);
    } else {
      show_Video6(1);
    }
  };

  const [Video7_isshow, show_Video7] = useState(0);
  const showVideo7 = (e) => {
    e.preventDefault();
    if (Video7_isshow) {
      show_Video7(0);
    } else {
      show_Video7(1);
    }
  };

  return (
    <WorkWrapper>
      <CommonContainer>
        <motion.div
          initial={{ opacity: 0, y: 140 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: "easeOut", staggerChildren: 0.5 }}
        >
          <SectionTitle>
            <small>일하는 방식</small>
            일하는 방식이 달라지면 <Br />
            우리의 미래도 달라집니다.
          </SectionTitle>
        </motion.div>
      </CommonContainer>
      <CommonContainer>
        <WorkContent>
          <WorkContentWrapper>
            <WorkVideo
              wide="true"
              url="/work_item1.png"
              data-id="1"
              onClick={showVideo1}
            >
              <div>
                <div />
              </div>
              <p>
                <strong>기본에 충실하자</strong>
                {/* <small>전문가라면 보이지 않는 코드 한 줄에도 정성을 쏟는 디테일이 필요합니다. 
                                    작은 것까지 섬세하게 마무리하는 것이 진정한 전문가의 자세입니다.</small> */}
              </p>
            </WorkVideo>
            <WorkVideo url="/work_item2.png" data-id="2" onClick={showVideo2}>
              <div>
                <div />
              </div>
              <p>
                <strong>우리의 1시간은 고객의 1년이다.</strong>
                {/* <small>우리가 고객사에서 1년간 구축한 시스템은 향후 10년 동안 고객이 사용하게 될 
                                    업무환경이라는 책임감과 자부심으로 프로젝트에 임합니다.</small> */}
              </p>
            </WorkVideo>
            <WorkVideo url="/Visionitem12.png" data-id="3" onClick={showVideo3}>
              <div>
                <div />
              </div>
              <p>
                <strong>매뉴얼보다 더 좋은 방법이 있다면 그것이 매뉴얼</strong>
                {/* <small>효율적이고 효과적인 방식이 있다면 이것이 표준! 일이 중심이고 그에 따라 형식과 
                                    절차는 개선합니다.</small> */}
              </p>
            </WorkVideo>
            <WorkVideo url="/Visionitem13.png" data-id="4" onClick={showVideo4}>
              <div>
                <div />
              </div>
              <p>
                <strong>My Name is Deep Learning Man</strong>
                {/* <small>디지털 융합 시대, 꾸준한 자기 주도학습으로 변화에 유연하게 적응할 수 
                                    있는 능력을 키워야 합니다.</small> */}
              </p>
            </WorkVideo>
            <WorkVideo url="/Visionitem14.png" data-id="5" onClick={showVideo5}>
              <div>
                <div />
              </div>
              <p>
                <strong>깨끗하고 진실되게. 그래서 당당하게</strong>
                {/* <small>고객과 동료를 대함에 있어서 깔끔하고 진실된 사람으로서 일해야 하며, 
                                    그렇게 일하는 사람이 당당할 수 있습니다.</small> */}
              </p>
            </WorkVideo>
            <WorkVideo url="/work_item6.png" data-id="6" onClick={showVideo6}>
              <div>
                <div />
              </div>
              <p>
                <strong>다름은 틀림이 아니다.</strong>
                {/* <small>회사의 건강한 기업문화는 소통에서 비롯합니다. 누구나 각자의 일하는 방식이 있으며, 
                                    나와 다를 수 있다는 것을 인정하고 존중해야 합니다.</small> */}
              </p>
            </WorkVideo>
            <WorkVideo url="/Visionitem16.png" data-id="7" onClick={showVideo7}>
              <div>
                <div />
              </div>
              <p>
                <strong>일은 스마트하게 퇴근할 때는 가볍게</strong>
                {/* <small>효율적으로 집중해서 빈틈없이 일하고 가벼운 마음으로 퇴근합니다.</small> */}
              </p>
            </WorkVideo>
          </WorkContentWrapper>
        </WorkContent>
      </CommonContainer>
      <VisionVideoPopup1 show={Video1_isshow} onClose={showVideo1} />
      <VisionVideoPopup2 show={Video2_isshow} onClose={showVideo2} />
      <VisionVideoPopup3 show={Video3_isshow} onClose={showVideo3} />
      <VisionVideoPopup4 show={Video4_isshow} onClose={showVideo4} />
      <VisionVideoPopup5 show={Video5_isshow} onClose={showVideo5} />
      <VisionVideoPopup6 show={Video6_isshow} onClose={showVideo6} />
      <VisionVideoPopup7 show={Video7_isshow} onClose={showVideo7} />
    </WorkWrapper>
  );
};

export default SectionWayworking;
