import styled from 'styled-components';

export const PopupWrapper = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
  z-index: 999998;
  ${props => props.show && `display:block`};
  ${props => props.width && `width:` + props.width + `;`}
  ${props => props.top && `top:` + props.top + `;`}
`;

export const PopupHead = styled.div`
  position: relative;
  margin-bottom: 40px;
  strong {
    display: block;
    font-family: 'KBFG Display';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    color: #000000;
    padding-top: 20px;
    margin-bottom: 10px;
  }
  a {
    position: absolute;
    top: 0px;
    right: 0px;
    line-height: auto;
    cursor: pointer;
  }
  p {
    font-family: 'KBFG Display';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    color: #666666;
  }
`;

export const PopupBody = styled.div``;

export const Overlay = styled.div`
  width: 100%;
  max-width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 100;
  position: fixed;
  top: 0px;
  left: 0px;
  ${props => {
    if (props.show) {
      return `
                display:block;
            `;
    } else {
      return `
                display:none;
            `;
    }
  }}
`;

export const PopupContainer = styled.div`
  width: 70%;
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  margin: 0px auto;

  @media ${props => props.theme.tablet} {
    width: 85%;
  }
  @media ${props => props.theme.mobile} {
    width: 95%;
  }
`;

export const PopupInner = styled.div`
  /* max-width: 854px; */
  width: 100%;
  height: 0px;
  padding-bottom: 56.25%;
  position: relative;
  z-index: 999999;
  left: 50%;
  background: #000;
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(190, 190, 190, 0.25);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  .video-react {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
  }
  iframe {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
  }

  ${props => {
    if (props.show) {
      return `
                top:45%;
            `;
    } else {
      return `
                top:-500vh;
            `;
    }
  }}
  a {
    position: absolute;
    top: -5px;
    right: -55px;
    display: block;
    width: 43px;
    height: 43px;
    div {
      position: absolute;
      text-align: center;
      width: 2px;
      height: 42px;
      vertical-align: top;
      margin-left: -3px;
      background: #fff;
      transition: 0.2s;
      margin-left: 19px;
    }
    div:nth-of-type(1) {
      transform: rotate(45deg);
    }
    div:nth-of-type(2) {
      transform: rotate(-45deg);
    }
    :hover {
      div:nth-of-type(1) {
        transform: rotate(90deg);
      }
      div:nth-of-type(2) {
        transform: rotate(-90deg);
      }
    }
  }
  @media ${props => props.theme.mobile} {
  }
`;

export const PopupContent = styled.div``;
