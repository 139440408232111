import React, { useEffect, useRef, useState } from "react";
import {
  CommonContainer,
  Row,
  Col,
  MediaQuery,
  mobileQuery,
} from "../../../components/Grid";
import BusinessItems from "./BusinessItems";
import { Br } from "../../../components/Grid";
import {
  BusinessWrapper,
  SectionTitle,
  BusinessBoxSummaryWrap,
  BusinessBoxWrapper,
  BusinessSummary,
  ButtonGroup,
  BusinessButton,
  SliderWrapper,
} from "../styled";
import BusinessPopup from "../BusinessPopup";

const SectionBusiness = () => {
  const [active_section, set_active_section] = useState("digital");
  const [active_popup, set_active_popup] = useState(null);

  const is_mobile = MediaQuery(mobileQuery);
  let digitalBoxEl = useRef(null);
  let digitalWrapEl = useRef(null);

  let siBoxEl = useRef(null);
  let siWrapEl = useRef(null);

  let smBoxEl = useRef(null);
  let smWrapEl = useRef(null);

  let businessWrapperEl = useRef(null);

  useEffect(() => {
    window.addEventListener("scroll", scrollEvent);
    return () => window.removeEventListener("scroll", scrollEvent);
  });
  const show_popup = (e) => {
    const item = e.currentTarget.dataset.id;
    e.preventDefault();
    set_active_popup(item);
  };
  const close_popup = (e) => {
    e.preventDefault();
    set_active_popup(null);
  };
  const scrollEvent = () => {
    const Wrapper = businessWrapperEl.current;
    const digital_el = digitalBoxEl.current;
    const digital_wr = digitalWrapEl.current;
    const si_el = siBoxEl.current;
    const si_wr = siWrapEl.current;
    const sm_el = smBoxEl.current;
    const sm_wr = smWrapEl.current;
    const section_el = document.getElementById("business").offsetTop;
    var scroll_range = 300;
    // const scrollTop = document.documentElement.scrollTop;
    var _scrollTop = window.scrollY || document.documentElement.scrollTop;

    if (is_mobile) {
      scroll_range = 600;
    }

    _scrollTop = _scrollTop - section_el;
    if (_scrollTop) {
      const digital_top = digital_wr.offsetTop;
      const si_top = si_wr.offsetTop;
      const sm_top = sm_wr.offsetTop;
      console.log(digital_wr.offsetTop);
      console.log(si_top);
      console.log(sm_top);

      if (_scrollTop < digital_top + digital_wr.offsetHeight) {
        set_active_section("digital");
        sm_el.classList.remove("active");
        sm_wr.classList.remove("active");
        si_el.classList.remove("active");
        si_wr.classList.remove("active");

        digital_el.classList.add("active");
        digital_wr.classList.add("active");
        Wrapper.classList.remove("si", "sm");
        Wrapper.classList.add("digital");
      }
      if (
        _scrollTop > si_top - scroll_range &&
        _scrollTop < si_top + si_wr.offsetHeight
      ) {
        set_active_section("si");
        digital_el.classList.remove("active");
        digital_wr.classList.remove("active");
        sm_el.classList.remove("active");
        sm_wr.classList.remove("active");

        si_el.classList.add("active");
        si_wr.classList.add("active");
        Wrapper.classList.remove("digital", "sm");
        Wrapper.classList.add("si");
      }
      if (
        _scrollTop > sm_top - scroll_range &&
        _scrollTop < sm_top + sm_wr.offsetHeight
      ) {
        set_active_section("sm");
        digital_el.classList.remove("active");
        digital_wr.classList.remove("active");
        si_el.classList.remove("active");
        si_wr.classList.remove("active");

        sm_el.classList.add("active");
        sm_wr.classList.add("active");
        Wrapper.classList.remove("digital", "si");
        Wrapper.classList.add("sm");
      }
    }
  };
  const digitalMembers = [
    // {title:'DevOps', name:'김태준 과장', image:'https://wp.daitso.kds.co.kr/wp-content/plugins/ep-manager/assets/images/about/about_di_6.jpg', content: `KB데이타시스템에서 자체 개발한 플랫폼인 크로노스와 Back-end 개발 및 애자일 문화 확산을 지원하고 있습니다.`},
    {
      title: "Cloud 사업 관리 ",
      // name: "김하님 사원",
      // image: "/nul-mg.png",
      image: "/avatar-01.png",
      content: `그룹의 서비스들이 Cloud 기반으로 인프라를 구축, 운영할 수 있도록 사업 계약, 관리, 비용 최적화 등 업무를 진행하고 있습니다.`,
    },
    {
      title: "Cloud 운영 관리",
      // name: "차진권 대리",
      // image: "/nul-mg.png",
      image: "/avatar-02.png",
      content: `Cloud에서 운영되는 서비스들의 자원 생성, 관리, 보안, 모니터링 등 365일 안정적으로 서비스될 수 있도록 관리하고 있습니다.`,
    },
    // {title:'UI/UX', name:'권창환 대리', image:'https://wp.daitso.kds.co.kr/wp-content/plugins/ep-manager/assets/images/about/about_di_2.jpg', content: `DevOps 플랫폼인 크로노스에서 UI개발을 담당하여 고객과 상호작용을 통해 더 나은 사용자 경험을 제공하고 서비스 품질 향상에 기여하고 있습니다.`},
    {
      title: "AI 연구& 개발",
      // name: "최재혁 사원",
      // image: "/nul-mg.png",
      image: "/avatar-03.png",
      content: `생성형AI를 비롯한 AI 기술의 트렌드를 분석하고, 최신 기술 연구, PoC 진행, RAG 시스템 개발 및 관련 사업에 참여하여 실질적인 개발 구현을 하고 있습니다.`,
    },
    // {title:'DevOps', name:'김상우 사원', image:'https://wp.daitso.kds.co.kr/wp-content/plugins/ep-manager/assets/images/about/about_di_4.jpg', content: `KB데이타시스템 자체 DevOps 플랫폼을 개발하여 KB금융그룹의 클라우드 네이티브 트랜스퍼를 지원 및 MSP 사업 확장을 위한 교두보의 역할을 담당하고 있습니다.`},
    {
      title: "서비스 기획",
      // name: "신채연 사원",
      // image: "/nul-mg.png",
      image: "/avatar-04.png",
      content: `Cloud 운영 플랫폼부터 신기술을 활용한 서비스까지,   gma 등 협업툴을 활용하여 사용자 중심의 프로세스를 설계하고 UI/UX 기획을 하고 있습니다.`,
    },
    // {title:'전략기획', name:'권은선 과장', image:'https://wp.daitso.kds.co.kr/wp-content/plugins/ep-manager/assets/images/about/about_di_1.jpg', content: `KB금융그룹 내 ICT/Digital 영역의 신기술 도입/정착을 위한 체계적인 디지털컨설팅 역량 기반의 그룹 디지털 전략수립 및 방향성을 제시하고 있습니다.`},
    {
      title: "디지털 플랫폼 개발",
      // name: "윤진욱 수석",
      // image: "/nul-mg.png",
      image: "/avatar-05.png",
      content: `KB SaaS Platform, 테스트통합관리 솔루션 ‘이슈리포터‘, MSP 포털 서비스 등 오픈 소스 기반으로 KB금융그룹에 필요한 서비스를 개발하고 있습니다.`,
    },
    {
      title: "전략 기획",
      // name: "안은정 사원",
      // image: "/nul-mg.png",
      image: "/avatar-05.png",
      content: `신성장 동력을 확보하고자 디지털 영역에서 신기술 기반의 사업 전략을 수립하고 KB금융그룹 내 도입/ 확산을 위해 실행 추진하고 있습니다.`,
    },
  ];
  const siMembers = [
    {
      title: "웹서버 개발",
      // name: "전가을 사원",
      // image: "/nul-mg.png",
      image: "/avatar-01.png",
      content: `2020년 공채 신입사원으로, 현재 국민은행 "KB시니어플랫폼 구축" 프로젝트에 참여하여 PC 및 모바일 Front-End 개발을 담당하고 있습니다.`,
    },
    {
      title: "웹서버 개발",
      // name: "김규현 차장",
      // image: "/nul-mg.png",
      image: "/avatar-02.png",
      content: `웹/서버 개발자로 KB모바일인증서, SSO 계열사 연동 등 인증업무 시스템 개발을 담당하고 있습니다.`,
    },
    {
      title: "웹서버 개발",
      // name: "최현수 과장",
      // image: "/nul-mg.png",
      image: "/avatar-03.png",
      content: `웹/서버 개발자로 KB저축은행 대고객 서비스인 Project 3S 프로젝트에 참여하였고 현재, 그룹 공용 클라우드 포탈 구축 사업을 준비하고 있습니다.`,
    },
    {
      title: "아키텍처",
      // name: "박기태 차장",
      // image: "/nul-mg.png",
      image: "/avatar-04.png",
      content: `전략사업본부 아키텍처 팀원으로 KB그룹의 아키텍쳐 표준, 설계와 클라우드를 포함하는 인프라 구축을 담당하며 SI 사업진행시 인프라 구축 가이드 및 지원 업무를 맡고 있습니다.`,
    },
    {
      title: "웹서버 개발",
      // name: "송경빈 차장",
      // image: "/nul-mg.png",
      image: "/avatar-05.png",
      content: `웹/서버 개발자로 KB국민은행 The-K 콜센터 재구축 프로젝트에 참여하여 어플리케이션 개발 업무를 수행하고 있습니다.`,
    },
  ];
  const smMembers = [
    {
      title: "시스템 운영",
      // name: "정광철 사원",
      // image: "/nul-mg.png",
      image: "/avatar-01.png",
      content: `준법상시모니터링 시스템의 안정성과 지속적인 운영을 위해 고객과 소통하고 자료를 제공하여 고객의 의사결정을 지원 하고, 그 중 점검항목 도출과 시스템 반영 업무를 수행하고 있습니다.`,
    },
    // {title:'웹서버 개발', name:'장서연 사원', image:'https://wp.daitso.kds.co.kr/wp-content/plugins/ep-manager/assets/images/about/about_m_5.jpg', content:`2020년 공채 신입사원으로, 현재 "KB손해보험 일반보험 운영업무"에 참여하여 모바일 Front-End 개발을 담당하고 있습니다.`},
    {
      title: "품질업무",
      // name: "정수림 사원",
      // image: "/nul-mg.png",
      image: "/avatar-02.png",
      content: `안정적인 IT서비스 제공을 위해 시스템 개발/운영 관련 프로세스 수립/개선/시행하고, 품질관련 이슈 분석 및 개선안을 도출/실행하는 업무를 담당하고 있습니다.`,
    },
    {
      title: "정보보호",
      // name: "김성재 차장",
      // image: "/nul-mg.png",
      image: "/avatar-03.png",
      content: `KB금융그룹 그룹사 IT 자산의 해킹사고에 대한 사전예방을 위해 모의해킹 및 취약점 진단 업무를 수행하여 보안대책 수립을 지원하고 있습니다.`,
    },
    {
      title: "시스템 운영",
      // name: "조성욱 대리",
      // image: "/nul-mg.png",
      image: "/avatar-04.png",
      content: `KB국민카드의 카드 신청 및 발급 업무관련 채널(홈페이지, 모바일)의 서비스 개발 및 운영을 담당하고 있습니다.`,
    },
    {
      title: "iOS 개발",
      // name: "신유나 사원",
      // image: "/nul-mg.png",
      image: "/avatar-05.png",
      content: `KB국민은행의 비대면 금융서비스의 디지털화를 지원하고 있습니다. 개인/기업뱅킹, 비대면 대출업무, 퇴직연금 등 비대면 금융서비스를 개발 및 운영하고 있습니다.`,
    },
    {
      title: "Android 개발",
      // name: "황선영 과장",
      // image: "/nul-mg.png",
      image: "/avatar-06.png",
      content: `KB국민은행에서 가장 최신 디지털 신기술로 개발하여 비대면 금융서비스를 제공하고 있습니다. 스타뱅킹, 리브 등의 Android Native App. 개발 및 운영 업무를 담당하고 있습니다.`,
    },
    // {title:'그룹사IT운영부', name:'강현진 대리', image:'https://wp.daitso.kds.co.kr/wp-content/plugins/ep-manager/assets/images/about/about_m_1.jpg', content: `KB증권 내 그룹웨어 시스템(전자결재,ECM,동보메일 등)을 운영하고 있으며  안정적이고 효율적인 IT업무환경을 제공, 지원하고 있습니다.`},
    // {title:'보험IT운영부', name:'황정아 과장', image:'https://wp.daitso.kds.co.kr/wp-content/plugins/ep-manager/assets/images/about/about_m_9.jpg', content: `KB손해보험 장기보험 변경시스템의 요구사항 분석 및 설계 역할을 하고 있습니다. 장기보험 변경시스템 전반에 걸쳐 사용성, 효율성을 높이기 위한 프로세스 개선을 진행하고 있습니다.`},
    // {title:'카드IT운영부', name:'김혁 사원', image:'https://wp.daitso.kds.co.kr/wp-content/plugins/ep-manager/assets/images/about/about_m_3.jpg', content: `KB국민카드의 IFRS(국제회계기준) 재무제표를 작성하고 공시하는 일련의 과정을 지원하는 시스템을 운영하고 있습니다.`},
  ];
  return (
    <>
      <BusinessWrapper ref={businessWrapperEl}>
        <CommonContainer>
          <SectionTitle>
            <small>사업영역</small>
            금융IT 혁신을 위한
            <br />
            다양한 사업을 진행하고 있습니다.
          </SectionTitle>
        </CommonContainer>
        <CommonContainer type="full">
          <BusinessBoxWrapper className="section">
            <CommonContainer>
              <Row>
                <Col flex="2">
                  <BusinessBoxSummaryWrap>
                    <BusinessSummary ref={digitalBoxEl} className="active">
                      <div>
                        <h4>
                          <small>Digital</small> 디지털
                        </h4>
                        <p>
                          KB데이타시스템은 KB금융그룹의 Digital Hub로서 Cloud,
                          데이터, AI 등 신기술 기반의 전략 사업 및 융합 서비스와
                          최적화된 개발 환경을 제공하고 있습니다.
                        </p>
                        <ButtonGroup>
                          <BusinessButton
                            href="#"
                            data-id="1"
                            onClick={show_popup}
                          >
                            KB금융그룹의 Cloud 를 관리하는 통합 MSP
                            <span></span>
                          </BusinessButton>
                          <BusinessButton
                            href="#"
                            data-id="2"
                            onClick={show_popup}
                          >
                            생성형AI를 비롯한 AI기술 기반 서비스
                            <span></span>
                          </BusinessButton>
                          <BusinessButton
                            href="#"
                            data-id="3"
                            onClick={show_popup}
                          >
                            KB 디지털 자산의 발굴과 확대
                            <span></span>
                          </BusinessButton>
                        </ButtonGroup>
                        {/* <Badge type="Black" size="small">#DevOps</Badge>
                                            <Badge type="Black" size="small">#Agile</Badge> */}
                      </div>
                    </BusinessSummary>
                  </BusinessBoxSummaryWrap>
                </Col>
                <Col flex="2">
                  <SliderWrapper ref={digitalWrapEl} className="active">
                    <BusinessItems
                      member_type="digital"
                      active={active_section == "digital" ? "true" : ""}
                      members={digitalMembers}
                    />
                  </SliderWrapper>
                </Col>
              </Row>
              <Row>
                <Col flex="2">
                  <BusinessBoxSummaryWrap>
                    <BusinessSummary ref={siBoxEl}>
                      <div>
                        <h4>
                          <small>SI</small> 시스템 개발
                        </h4>
                        <p>
                          KB데이타시스템은 KB금융그룹의 IT전문가로서 그룹내
                          다양한 금융 솔루션이 고객과 함께 할 수 있는 시스템을
                          구축하는데 최선을 다하고 있습니다.
                        </p>
                        <ButtonGroup>
                          <BusinessButton
                            href="#"
                            data-id="4"
                            onClick={show_popup}
                          >
                            계열사 신규 IT 사업 참여 및 고도화
                            <span></span>
                          </BusinessButton>
                          <BusinessButton
                            href="#"
                            data-id="5"
                            onClick={show_popup}
                          >
                            그룹 공동사업에 대한 역할 수행
                            <span></span>
                          </BusinessButton>
                          <BusinessButton
                            href="#"
                            data-id="6"
                            onClick={show_popup}
                          >
                            그룹내 사업 신속 개발 대응
                            <span></span>
                          </BusinessButton>
                        </ButtonGroup>
                        {/* <Badge type="Black" size="small">#프로젝트 3S</Badge>
                                        <Badge type="Black" size="small">#KB Pay</Badge> */}
                      </div>
                    </BusinessSummary>
                  </BusinessBoxSummaryWrap>
                </Col>
                <Col flex="2">
                  <SliderWrapper ref={siWrapEl}>
                    <BusinessItems
                      member_type="si"
                      active={active_section == "si" ? "true" : ""}
                      members={siMembers}
                    />
                  </SliderWrapper>
                </Col>
              </Row>
              <Row>
                <Col flex="2">
                  <BusinessBoxSummaryWrap>
                    <BusinessSummary ref={smBoxEl}>
                      <div>
                        <h4>
                          <small>SM</small> 시스템 운영
                        </h4>
                        <p>
                          KB데이타시스템은 KB금융그룹의 IT전문가로서 다양한 금융
                          업무를 지원하기 위해 고객사와 함께하며 안정적인
                          서비스를 제공하기 위해 최선을 다하고 있습니다.
                        </p>
                        <ButtonGroup>
                          <BusinessButton
                            href="#"
                            data-id="7"
                            onClick={show_popup}
                          >
                            그룹 공동 시스템 운영/유지보수
                            <span></span>
                          </BusinessButton>
                          <BusinessButton
                            href="#"
                            data-id="8"
                            onClick={show_popup}
                          >
                            은행IT시스템 운영서비스
                            <span></span>
                          </BusinessButton>
                          <BusinessButton
                            href="#"
                            data-id="9"
                            onClick={show_popup}
                          >
                            그룹사/대외기관 IT시스템 운영
                            <span></span>
                          </BusinessButton>
                        </ButtonGroup>
                        {/* <Badge type="Black" size="small">#The K 프로젝트</Badge>
                                        <Badge type="Black" size="small">#디지털 콜센터 구축</Badge> */}
                      </div>
                    </BusinessSummary>
                  </BusinessBoxSummaryWrap>
                </Col>
                <Col flex="2">
                  <SliderWrapper ref={smWrapEl}>
                    <BusinessItems
                      member_type="sm"
                      active={active_section == "sm" ? "true" : ""}
                      members={smMembers}
                    />
                  </SliderWrapper>
                </Col>
              </Row>
            </CommonContainer>
          </BusinessBoxWrapper>
        </CommonContainer>
      </BusinessWrapper>
      <BusinessPopup active={active_popup} onClose={close_popup} />
    </>
  );
};

export default SectionBusiness;
