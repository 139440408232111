import React from 'react';
import Popup from '../../../components/Popup';
import styled from 'styled-components';

const VisionVideoPopupStyle = styled.div``;
const VisionVideoPopup3 = (props) => {

    return (
        <VisionVideoPopupStyle>
            {/* <Popup type="video" show={props.active} onClose={props.onClose} src={contents[props.active-1] ? contents[props.active-1].src : ''} > */}
            <Popup type="video" youtube_id="cKMrwsnfKfU" show={props.show} onClose={props.onClose}></Popup>
        </VisionVideoPopupStyle>
    );
};

export default VisionVideoPopup3;