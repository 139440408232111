import styled from "styled-components";

export const TitleWrapper = styled.div`
  margin-top: 119px;
  @media ${(props) => props.theme.tablet} {
    margin-top: 149px;
  }
  @media ${(props) => props.theme.moblie} {
    margin-top: 82px;
  }
`;
export const TitleImage = styled.div`
    position: relative;
    margin-top: 35px;
    height: 500px;
    background-image: url('${(props) => props.bgImage}');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    &:after{
        background:rgba(0,0,0,0.18);
        position: absolute;
        display: block;
        content:"";
        top:0;
        left:0;
        width: 100%;
        height: 100%;
    }
    @media ${(props) => props.theme.laptop}{
        background-attachment: scroll;
    }
    @media ${(props) => props.theme.mobile}{
        height: 250px;
    }
`;

export const PostBadge = styled.div`
  label {
    margin-right: 10px;
  }
  @media ${(props) => props.theme.laptop} {
    padding-left: 20px;
  }
  @media ${(props) => props.theme.tablet} {
    padding-left: 0px;
  }
  @media ${(props) => props.theme.mobile} {
    label {
      padding: 0px 11px;
      font-size: 16px;
      line-height: 34px;
      margin-bottom: 10px;
    }
  }
`;

export const PostTitle = styled.div`
  margin-top: 30px;
  font-family: "KBFG Display";
  font-style: normal;
  h2 {
    font-size: 38px;
    line-height: 64px;
    color: #000000;
  }
  @media ${(props) => props.theme.laptop} {
    padding-left: 20px;
  }
  @media ${(props) => props.theme.tablet} {
    padding-left: 0px;
    h2 {
      font-size: 42px;
      line-height: 62px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    margin-top: 10px;
    h2 {
      font-size: 24px;
      line-height: 38px;
    }
  }
`;

export const PostSubTitle = styled.div`
  margin-top: 43px;
  font-family: "KBFG Text";
  font-style: normal;
  font-weight: bold;
  transition: 0.3s;
  /* cursor:pointer;
    :hover{
        opacity: .6;
    } */
  .name {
    padding: 10px 22px;
    background: #ffffff;
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    border-radius: 40px;
    font-size: 17px;
    line-height: 22px;
    color: #444444;
    display: inline-block;
    span {
      padding-left: 16px;
    }
  }
  p {
    margin-top: 15px;
    display: block;
    float: right;
    font-size: 15px;
    line-height: 19px;
    color: #bfbfbf;
  }
  @media ${(props) => props.theme.laptop} {
    padding: 0px 20px;
  }
  @media ${(props) => props.theme.tablet} {
    padding: 0px 0px;
    margin-top: 20px;
  }
  @media ${(props) => props.theme.moblie} {
    margin-top: 20px;
    .name {
      padding: 10px 15px;
      font-size: 18px;
      line-height: 23px;
      span {
        font-size: 15px;
      }
    }
    p {
      margin-top: 7px;
      font-size: 14px;
      line-height: 23px;
    }
  }
`;
export const Avatar = styled.div`
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  @media ${(props) => props.theme.laptop} {
    padding-left: 20px;
  }
  @media ${(props) => props.theme.tablet} {
    padding-left: 0px;
  }
  @media ${(props) => props.theme.mobile} {
    width: 20px;
    margin-top: 0px;
    height: 20px;
  }
`;

export const FigureIcon = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  ul {
    position: sticky;
    top: 50px;
  }
  li {
    display: block;
    position: relative;
    padding-bottom: 80px;
    a {
      width: 52px;
      height: 52px;
      position: absolute;
      box-sizing: border-box;
      border: 1px solid #eeeeee;
      border-radius: 50%;
      text-align: center;
      line-height: 52px;
    }
    img {
      display: inline-block;
    }
  }
  @media ${(props) => props.theme.laptop} {
    right: 100px;
  }
  @media ${(props) => props.theme.tablet} {
    right: 80px;
  }
  @media ${(props) => props.theme.mobile} {
    position: static;
    margin-top: 50px;
    margin-bottom: 0px;
    margin-left: 20px;
    margin-right: 0px;
    li {
      padding-right: 60px;
      display: inline-block;
      position: relative;
      a {
        width: 52px;
        height: 52px;
        box-sizing: border-box;
        border: 1px solid #eeeeee;
        border-radius: 50%;
        display: block;
        text-align: center;
        line-height: 52px;
        img {
          display: inline-block;
          margin-top: 0px;
          margin-left: 0px;
        }
      }
    }
  }
`;

//SectionTitle End

export const ContentWrapper = styled.div`
  margin-bottom: 81px;
  padding-top: 50px;
  .container-post_content {
    position: relative;
  }
  @media ${(props) => props.theme.mobile} {
    .container-post_content {
      padding: 0px 0px;
    }
  }
`;

export const PostContent = styled.div`
  font-family: "KBFG Text";
  font-style: normal;
  position: relative;
  font-weight: normal;
  font-size: 17px;
  line-height: 32px;
  color: #444444;
  p {
    font-weight: normal;
    font-size: 17px;
    line-height: 32px;
    color: #444444;
    padding-bottom: 30px;
  }
  h3 {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 32px;
    color: #000000;
  }
  a {
    margin-top: 9px;
    font-weight: normal;
    font-size: 17px;
    line-height: 32px;
    text-decoration-line: underline;
    color: #ffac00;
  }
  .wp-block-code {
    background: #f7f7f7;
    padding: 30px;
    line-height: 20px;
    overflow-x: scroll;
  }
  .contentimg {
    position: relative;
    margin-bottom: 30.45px;
    height: 638.55px;
    background-image: url(${(props) => props.theme.image_url}/common/Singitem3.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 25px auto;
  }
  p img {
  }
  iframe {
    max-width: 100%;
    height: auto;
    width: 100%;
    min-height: 500px;
  }
  @media ${(props) => props.theme.mobile} {
    iframe {
      min-height: 260px;
    }
  }
`;

export const Blockquote = styled.div`
  position: relative;
  font-family: "KBFG Text";
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 32px;
  color: #000000;
  text-align: center;
  height: 70px;
  margin-top: 30px;
  margin-bottom: 40px;
  padding: 19px 36px;
  background-color: #f7f7f7;
  border-left: 5px solid #ffdc00;
`;

//SectionContent End

export const CommentWrapper = styled.div`
  margin-top: 29px;
`;

export const PostComment = styled.div`
  font-family: "KBFG Text";
  font-style: normal;
  h3 {
    /* padding-top:29px */
    border-top: 1px solid #eeeeee;
    font-size: 20px;
    line-height: 32px;
    color: #222222;
  }
  img {
    padding-top: 38px;
    display: inline-block;
  }
  strong {
    position: relative;
    display: block;
    margin-top: 45px;
    padding-left: 19px;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    color: #000000;
    small {
      padding-left: 6px;
      font-size: 13px;
      font-weight: normal;
      line-height: 17px;
      color: #888888;
    }
  }
  span {
    position: relative;
    display: block;
    font-weight: normal;
    padding-left: 19px;
    font-size: 13px;
    line-height: 20px;
    color: #888888;
  }
  p {
    border-bottom: 1px solid #eeeeee;
    margin-top: 23px;
    padding-bottom: 27px;
    font-weight: normal;
    font-size: 15px;
    line-height: 26px;
    color: #222222;
  }
`;

export const PostCommnetBox = styled.div`
  margin-top: 61px;
  /* margin-bottom: 100px; */
  font-family: "KBFG Text";
  font-style: normal;
  position: relative;
  img {
    margin-right: 35px;
  }
  textarea {
    float: right;
    width: 100%;
    height: 67px;
    padding: 20px 31px;
    resize: none;
    font-weight: 300;
    font-size: 17px;
    line-height: 25px;
    color: #888888;
    background-color: #ffffff;
    border: 1px solid #e1e1e1;
    box-sizing: border-box;
    border-radius: 60px;
  }
  button {
    position: absolute;
    top: 8.5px;
    right: 14px;
    width: 100px;
    height: 50px;
    background: #ffac00;
    border: 1px solid white;
    box-sizing: border-box;
    border-radius: 60ex;
    font-weight: 500;
    font-size: 15px;
    line-height: 19xp;
    text-align: center;
    color: #ffffff;
    float: right;
  }
`;

//SectionCommnet End

export const UserWrapper = styled.div`
  margin-top: 100px;
`;

export const UserItemBackground = styled.div`
  position: relative;
  cursor: pointer;
  &:after {
    background: #f8f8f8;
    position: absolute;
    display: block;
    content: "";
    top: 100px;
    left: 0;
    width: 100%;
    height: 100%;
  }
  @media ${(props) => props.theme.mobile} {
    &:after {
      content: "";
      top: 50px;
    }
  }
`;

export const UserItemUser = styled.div`
  font-family: "KBFG Text";
  font-style: normal;
  text-align: center;
  position: relative;
  z-index: 1;
  .userimg {
    background-image: url(${(props) => props.image});
    width: 185px;
    height: 185px;
    margin: 0 auto;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 100px;
    position: relative;
  }
  strong {
    padding-top: 40px;
    font-size: 28px;
    line-height: 36px;
    color: #444444;
    display: block;
    small {
      margin-top: 5px;
      font-weight: bold;
      font-size: 18px;
      line-height: 23px;
      color: #ffac00;
      display: block;
    }
    span {
      margin-top: 5px;
      font-weight: bold;
      font-size: 18px;
      line-height: 23px;
      color: #6c6c6c;
    }
    img {
      width: 3px;
      height: 3px;
      margin: 0px 5px;
      margin-bottom: 5px;
    }
  }
  p {
    margin-top: 13px;
    font-weight: normal;
    font-size: 17px;
    line-height: 30px;
    color: #888888;
    padding-bottom: 36px;
  }
  @media ${(props) => props.theme.tablet} {
    p {
      padding-bottom: 25px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    strong {
      padding-top: 20px;
    }
    .userimg {
      width: 100px;
      height: 100px;
    }
  }
`;

export const UserItemBadge = styled.div`
  margin-top: 20px;
  text-align: center;
  position: relative;
  z-index: 1;
  label {
    margin-right: 10px;
  }
`;

export const UserItem = styled.div`
  z-index: 1;
  font-family: "KBFG Dispaly";
  font-style: normal;
  position: relative;
  text-align: center;
  font-weight: bold;
  a {
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: bold;
    margin-top: 35px;
    font-size: 15px;
    line-height: 19px;
    color: #6c6c6c;
  }
  @media ${(props) => props.theme.laptop} {
    p {
      font-size: 18px;
    }
  }
`;

export const UserSliderStyle = styled.div`
  position: relative;
  .slick-arrow {
    z-index: 3;
  }
  .slick-prev,
  .slick-next {
    font-size: 0;
    position: absolute;
    bottom: 20px;
    color: red;
    border: 0;
    background: none;
    z-index: 1;
    cursor: pointer;
    background: #fff;
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  .slick-prev {
    /* left: 50%; */
    margin-left: -60px;
    /* top: 506px; */
  }
  .slick-prev:before {
    content: "";
    background-image: url(${(props) => props.theme.image_url}/common/arrow-before.svg);
    width: 20px;
    height: 20px;
    display: inline-block;
    background-size: 100%;
  }
  .slick-next {
    margin-right: -60px;
    /* left: 50%; */
    /* margin-left:50px; */
    /* top: 506px; */
    @media ${(props) => props.theme.tablet} {
      display: none !important;
    }
    @media ${(props) => props.theme.mobile} {
      display: none !important;
    }
  }
  .slick-next:before {
    content: "";
    background-image: url(${(props) => props.theme.image_url}/common/arrow-next.svg);
    width: 20px;
    height: 20px;
    display: inline-block;
    background-size: 100%;
  }
  .slick-prev:hover:after,
  .slick-next:hover:after {
    color: red;
  }
  @media ${(props) => props.theme.laptop} {
    padding: 0px 20px;
    .slick-prev,
    .slick-next {
      border-radius: 0%;
      width: 0px;
      height: 0px;
    }
    .slick-prev:before {
      display: none;
    }
    .slick-next:before {
      display: none;
    }
  }
  @media ${(props) => props.theme.tablet} {
    padding: 0px 0px;
  }
`;
export const UserSlideItem = styled.div`
  margin-top: 20px;
  padding: 20px;
  background: rgba(0, 0, 0, 0.4);
  height: 180px;
  text-align: center;
  font-size: 19px;
  line-height: 32px;
  color: #ffffff;
  position: relative;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 180px;
    background: rgba(0, 0, 0, 0.5);
  }
  span {
    display: flex;
    position: relative;
    z-index: 1;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    font-weight: 600;
  }
`;
export const UserSliderButtons = styled.div`
  z-index: 1;
  a {
    position: absolute;
    display: block;
    top: 50%;
    margin-top: -17px;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    background: #fff;
    border-radius: 50%;
    text-decoration: none;
  }
  a:first-child {
    left: -60px;
  }
  a:last-child {
    right: -60px;
  }
`;

//SectionUser End

export const PostCardWrapper = styled.div`
  margin-top: 180px;
  h3 {
    font-family: "KBFG Display";
    font-style: normal;
    font-size: 28px;
    line-height: 35px;
    color: #000000;
    text-align: center;
  }
  @media ${(props) => props.theme.laptop} {
    margin-top: 160px;
  }
  @media ${(props) => props.theme.tablet} {
    margin-top: 145px;
  }
  @media ${(props) => props.theme.mobile} {
    margin-top: 95px;
    h3 {
      font-size: 24px;
      line-height: 30px;
    }
  }
`;
