import React from "react";
import {
  CommonContainer,
  MediaQuery,
  mobileQuery,
  Br,
} from "../../../components/Grid";
import Button from "../../../components/Button";
import {
  DXWrapper,
  DXBoxWrapper,
  DXBackground,
  DXContentWrapper,
  DXContent,
  DXContentbox,
  TextArea,
  SectionTitle,
  SectionSummary,
  SeemoreContainer,
} from "../styled";

const SectionDX = () => {
  const isMobile = MediaQuery(mobileQuery);
  return (
    <DXWrapper>
      <TextArea>
        <SectionTitle>
          <small>DX</small>
          KB금융그룹의 <Br />
          Digital Transformation을 지원합니다.
        </SectionTitle>
        <SectionSummary>
          KB데이타시스템은 그룹 디지털/IT Hub역할을 위해 디지털 혁신에 걸맞는
          회사로 변모하고 있습니다.
        </SectionSummary>
        <Button type="primary" to={"/vision"}>
          자세히보기
        </Button>
      </TextArea>
      {!isMobile ? (
        <CommonContainer type="wide">
          <DXBoxWrapper>
            <video autoPlay muted loop={true}>
              <source
                src="https://wp.daitso.kds.co.kr/wp-content/uploads/2020/10/main_dx.mp4"
                type="video/mp4"
              />
            </video>
          </DXBoxWrapper>
        </CommonContainer>
      ) : (
        ""
      )}
      {isMobile ? (
        <CommonContainer type="wide">
          <DXBackground />
        </CommonContainer>
      ) : (
        ""
      )}
      <CommonContainer>
        <DXContentWrapper>
          <DXContent>
            <DXContentbox>
              <h4>Value creation</h4>
              <p>
                전문적인 IT서비스로 그룹IT <Br />
                혁신 및 가치 창출
              </p>
            </DXContentbox>
            <DXContentbox>
              <h4>Improving</h4>
              <p>
                IT전문인력 집중 양성으로 그룹IT <Br />
                역량 강화에 기여
              </p>
            </DXContentbox>
            <DXContentbox>
              <h4>Partnership</h4>
              <p>
                선진 IT서비스 업체들과의 전략적 <Br />
                파트너십등을 통한 핵심 기술력 확보
              </p>
            </DXContentbox>
          </DXContent>
          <SeemoreContainer>
            <Button type="primary" to={"/vision/#dx"}>
              자세히보기
            </Button>
          </SeemoreContainer>
        </DXContentWrapper>
      </CommonContainer>
    </DXWrapper>
  );
};

export default SectionDX;
