import React, {useRef} from 'react';
import {CommonContainer, MediaQuery, mobileQuery} from '../../../components/Grid';
import Button from '../../../components/Button';
import {ESGWrapper,
        TextArea,
        SectionTitle,
        SectionSummary,
        ESGFigure,
        ESGFigures,
        SeemoreContainer
} from '../styled'
import { Parallax } from 'react-scroll-parallax';
import ESGSlider from './ESGSlider';

const SectionWayWorking = () => {
    let topBoxEl = useRef(null);
    const isMobile = MediaQuery(mobileQuery);
    
    return (
        <ESGWrapper>
            <ESGFigures>
                {isMobile ? '' : (
                    <>
                    <ESGFigure>
                        <Parallax y={[-20, 20]} x={[20, -20]} tagOuter="figure">
                        </Parallax>
                    </ESGFigure>
                    </>
                )}
            </ESGFigures>
            <TextArea>
                <SectionTitle>
                    <small>지속가능경영활동</small>
                    지속가능한 가치를 만들기 위한<br/>ESG경영활동
                </SectionTitle>
                <SectionSummary>
                    KB금융그룹의 ESG전략에 발맞추어 환경과<br/>사회 상생을 위한 가치 내재화를 위해 노력하고 있습니다.
                </SectionSummary>
            </TextArea>
            <ESGSlider/>
            <CommonContainer>
                <SeemoreContainer>
                    <Button type='primary' to={'/about/#esg'}>자세히보기</Button>
                </SeemoreContainer>
            </CommonContainer>
        </ESGWrapper>
    );
};

export default SectionWayWorking;