import styled from "styled-components";

//BusinessCard//

export const CardWrapper = styled.div`
  width: 33.33%;
  height: 100%;
  display: inline-block;
  padding: 0 15px;
`;

export const CardStyle = styled.div`
  box-sizing: border-box;
  height: 100%;
  background: rgba(255, 255, 255, 0.96);
  padding-top: 43px;
  padding-left: 49px;
  h4 {
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 162.5%;
    color: #000000;
    margin-bottom: 10px;
  }
  h5 {
    font-family: "KBFG Text";
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 32px;
    color: #666666;
    white-space: pre;
  }
`;

//BusinessCard//

//

export const StorySliderWraaper = styled.div`
  ${(props) => (props.type ? ` margin-bottom: 170px;` : ``)}
  margin-bottom: 205px;
  ${(props) =>
    props.style && props.style.marginBottom
      ? `margin-bottom: ${props.style.marginBottom} !important;`
      : ``}
  transform: translateX(-100px);
  .slick-slider {
    padding: 0px 80px;
  }
  .slick-dots li.slick-active button:before {
    ${(props) =>
      props.style && props.style.barColor
        ? `background: ${props.style.barColor} !important;`
        : ``}
  }
  @media ${(props) => props.theme.mobile} {
    margin-bottom: 0 !important;
  }
`;

export const StorySliderStyle = styled.div`
  margin-top: 28px;
  text-align: center;
  margin-bottom: 0;
  @media ${(props) => props.theme.mobile} {
    margin-top: 0;
    /* margin-top: 0px;
    & > div {
      overflow-y: hidden;
      overflow-x: scroll;
    }
    & > div > div {
      width: 3920px;
      text-align: left;
    }
    & > div > div > div {
      width: 325px;
    }
    & section > div {
      width: 300px;
      height: 300px;
      padding-bottom: 0px;
    } */
  }
`;

export const StoryCardWrapper = styled.div`
  padding: 36px 14px;
  padding-bottom: 0px;
`;

export const Storytext = styled.div`
  position: relative;
  span {
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 37px;
    color: #515151;
  }
  span:last-child {
    right: 0;
    position: absolute;
  }
`;

export const Storyimg = styled.div`
  width: 388px;
  height: 388px;
  background-image: url("../Group102.png");
  background-size: cover;
  background-position: center center;
  /* @media ${(props) => props.theme.laptop} {
    height: 0;
    weight: 100%;
    padding: 50px;

    position: relative;
  }
  @media ${(props) => props.theme.tablet} {
  }
  @media ${(props) => props.theme.mobile} {
    width: 100%;
  } */
`;

//

//slick//

export const settings = {
  infinite: true,
  autoplay: 0,
  speed: 500,
  slidesToShow: 1,
  dots: true,
  arrows: false,
  rows: 1,
  slidesToScroll: 1,
  autoplaySpeed: 3000, // 자동 스크롤 시 다음으로 넘어가는데 걸리는 시간 (ms)
  pauseOnHover: true,
  centerPadding: "0px",
  variableWidth: true,

  // responsive: [
  //   {
  //     breakpoint: 1800,
  //     settings: {
  //       slidesToShow: 2,
  //       slidesToScroll: 1,
  //     },
  //   },
  //   {
  //     breakpoint: 1480,
  //     settings: {
  //       slidesToShow: 2,
  //       slidesToScroll: 1,
  //       infinite: true,
  //     },
  //   },
  //   {
  //     breakpoint: 1024,
  //     settings: {
  //       slidesToShow: 2,
  //       slidesToScroll: 1,
  //     },
  //   },
  //   {
  //     breakpoint: 770,
  //     settings: 'unslick',
  //   },
  // ],
};

export const SlickWrap = styled.div`
  .slick-arrow {
    z-index: 3;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-list {
    overflow: visible;
    padding: 0px !important;
  }
  .slick-slide:not(.slick-active) {
  }
  .slick-arrow {
  }
  .slick-center {
    position: relative;
    /* div{
            :after{
                display: block;
                position: absolute;
                content: "";
                background: rgba(255, 25, 255, 0);
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: 0.4s; 
            }
        } */
  }

  .slick-track div:nth-child(7),
  .slick-track div:nth-child(8),
  .slick-track div:nth-child(9) {
    display: none;
  }
  .slick-prev,
  .slick-next {
    font-size: 0;
    position: absolute;
    bottom: 20px;
    color: red;
    border: 0;
    background: none;
    z-index: 1;
    cursor: pointer;
    background: #fff;
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  .slick-prev {
    left: calc(50% - (50vw - 728px));
    transform: translateX(-150%);
    top: 506px;
    @media ${(props) => props.theme.tablet} {
      /* top: 400px; */
    }
  }
  .slick-slide {
    /* width: 1252px; */
  }
  .slick-prev:before {
    content: url("/arrow-before.svg");
  }
  .slick-next {
    right: auto;
    left: calc(50% - (50vw - 728px));
    transform: translateX(50%);
    top: 506px;
    @media ${(props) => props.theme.tablet} {
      /* top: 400px; */
    }
  }
  .slick-next:before {
    content: url("/arrow-next.svg");
  }
  .slick-prev:hover:after,
  .slick-next:hover:after {
    color: red;
  }
  .slick-dots {
    bottom: -65px;
    left: calc(0% - (-728px + 50vw));
  }
  .slick-dots li {
    width: 76px;
    margin: 0 15px;
  }
  .slick-dots li button {
    width: 76px;
    height: 42px;
  }
  .slick-track {
    display: flex;
  }
  .slick-dots li button:before {
    display: block;
    width: 76px;
    height: 2px;
    font-size: 0;
    margin-top: 45px;
    margin-bottom: 45px;
    background-color: #000;
    transform: translateY(-25px);
  }
  @media (max-width: 1332px) {
    .slick-dots {
      bottom: -40px;
      /* bottom: -65px; */
      /* left: calc(0% - (-728px + 50vw)); */
      left: 70px;
    }
    .slick-dots li {
      width: 45px;
    }
    .slick-dots li button {
      width: 45px;
    }
    .slick-dots li button:before {
      width: 45px;
    }
    .slick-dots li {
      margin: 0 6px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    .slick-dots {
      bottom: -20px;
    }
    .slick-list {
      margin-bottom: 40px;
    }
  }
`;

//slick//

//ESGslick//

//ESGslick//

export const Card = styled.div`
  padding: 0 20px;
  width: 1292px !important;
  > div {
    position: relative;
    background-color: #eee;
    padding-bottom: 40%;
    border-radius: 0 30px 0 30px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    padding: 70px 66px calc(40% - 70px) 66px;
    cursor: pointer;
    :after {
      position: absolute;
      display: block;
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.26);
    }
    .btn {
      position: absolute;
      z-index: 1;
      width: 100px;
      height: 100px;
      top: 70px;
      background-image: url("https://wp.daitso.kds.co.kr/wp-content/plugins/ep-manager/assets/images/common/icon-play.svg");
      background-size: 167%;
      background-repeat: no-repeat;
      background-position: center;
    }
    .content {
      /* display: flex;
      flex-wrap: wrap;
      align-items: space-between; */
      position: absolute;
      z-index: 1;
      word-break: keep-all;
      text-align: left;
      bottom: 30px;
      left: 0px;
      padding: 0 30px;
      div {
        font-size: 42px;
        font-weight: bold;
        line-height: 160%;
        color: #ffffff;
      }
      .youtube-desc > p {
        font-size: 16px;
        font-weight: normal;
        line-height: 160%;
        color: #ffffff;
      }
    }
  }
  @media (max-width: 1332px) {
    width: calc(100vw - 80px) !important;
    > div {
      .btn {
        width: 80px;
        height: 80px;
        top: 15%;
      }
      .content {
        bottom: 5%;
        transform: scale(0.8);
        transform-origin: left;
      }
    }
  }
  @media (max-width: 960px) {
    width: calc(100vw - 80px) !important;
    > div {
      .btn {
        width: 70px;
        height: 70px;
      }
      .content {
        bottom: 0%;
        transform: scale(0.7);
        transform-origin: left;
      }
    }
  }
  @media (max-width: 770px) {
    width: calc(100vw - 80px) !important;
    > div {
      padding: 70px 30px calc(40% - 70px) 30px;
      .btn {
        width: 50px;
        height: 50px;
      }
      .content {
        bottom: 0%;
        transform: scale(0.7);
        transform-origin: left bottom;
      }
    }
  }
  /* @media (max-width: 500px) {
    width: calc(100vw - 80px) !important;
    > div {
      padding: 70px 30px calc(40% - 70px) 30px;
      .btn {
        width: 50px;
        height: 50px;
        top: 20%;
      }
      .content {
        bottom: 0%;
        transform: scale(1);
        transform-origin: left bottom;
      }
    }
  } */
  @media (max-width: 680px) {
    padding: 0 10px;
    width: calc(100vw - 40px) !important;
    > div {
      padding-bottom: 66.6%;
      padding: 0 40px 66.6% 30px;

      .btn {
        position: absolute;
        z-index: 1;
        top: 10%;
        left: 28px;
        width: 70px;
        height: 70px;
      }

      .content {
        /* display: flex;
      flex-wrap: wrap;
      align-items: space-between; */
        position: absolute;
        z-index: 1;
        text-align: left;
        bottom: 10%;
        padding-right: 20px;
        transform: scale(1);
        div {
          font-size: 30px;
          line-height: 160%;
          margin-bottom: 10px;
        }
        .youtube-desc {
          display: flex;
          overflow: hidden;
        }
        .youtube-desc > p {
          font-size: 18px;
          line-height: 160%;
          margin-bottom: 0;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
          height: 320%;
        }
      }
    }
  }
  @media (max-width: 450px) {
    padding: 0 10px;
    width: calc(100vw - 40px) !important;
    > div {
      padding-bottom: 66.6%;
      padding: 0 40px 66.6% 30px;

      .btn {
        position: absolute;
        z-index: 1;
        top: 20px;
        left: 28px;
        width: 32px;
        height: 32px;
      }

      .content {
        /* display: flex;
      flex-wrap: wrap;
      align-items: space-between; */
        position: absolute;
        z-index: 1;
        text-align: left;
        bottom: 10%;
        padding-right: 20px;
        transform: scale(1);
        div {
          font-size: 20px;
          line-height: 160%;
          margin-bottom: 10px;
        }
        .youtube-desc > p {
          font-size: 12px;
          line-height: 160%;
          margin-bottom: 0;
        }
      }
    }
  }
`;
