import React from "react";
import MainContainer from "../../../components/MainContainer";
import SectionMain from "./components/SectionMain";
import SectionContent from "./components/SectionContent";
import LoadInView from "../../../components/LoadInView";
import { PrivacyWrapper } from "./styled";

const Operation = () => {
  return (
    <MainContainer>
      <PrivacyWrapper>
        <SectionMain />
        <LoadInView>
          <SectionContent />
        </LoadInView>
      </PrivacyWrapper>
    </MainContainer>
  );
};

export default Operation;
