import React from "react";
import Popup from "../../../components/Popup";
import styled from "styled-components";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const PopupCloseButton = styled.div`
  text-align: center;
  margin-top: 40px;
  position: relative;
  a {
    background: rgba(96, 88, 76, 0.88);
    color: #ffffff;
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    padding: 14px 85px;
    display: inline-block;
    text-decoration: none;
  }
  @media ${(props) => props.theme.mobile} {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;

const VisionPopupStyle = styled.div`
  // 공통 Span

  span {
    word-break: keep-all;
    display: block;
    margin-top: -30px;
    margin-bottom: 20px;
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    color: #666666;
  }

  b {
    display: block;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    color: #444444;
    /* margin-top: 12px; */
  }

  .btn {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin: 16px 0 0 0;
    a {
      flex: 0 1 33.3%;
      display: block;
      height: 50px;
      text-align: center;
      text-decoration: none;
      line-height: 50px;
      border-radius: 16px;
      background-color: #ffbc00;
      color: #fff;
      transition: 0.4s;
      :hover {
        opacity: 0.7;
      }
    }
  }

  // 공통 Span End

  // 공통 popup

  .popup {
  }

  .listpopup {
    background: #f8f8f8;
    padding: 30px 30px;
    margin-bottom: 20px;
    font-family: "KBFG Display";
    font-style: normal;
    h5 {
      font-size: 18px;
      line-height: 24px;
      color: #000000;
    }
    ul {
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      color: #444444;
      padding: 10px 20px 0px 20px;
    }
  }
  .normalpopup {
    background: #f8f8f8;
    padding: 30px 30px;
    margin-bottom: 20px;
    font-family: "KBFG Display";
    font-style: normal;
    ul {
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      padding: 10px 15px 0px 15px;
    }
    li {
      word-break: keep-all;
      line-height: 26px;
    }
    h5 {
      font-size: 18px;
      line-height: 24px;
      color: #000000;
      padding-bottom: 10px;
    }
    p {
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      color: #444444;
    }
  }
  @media ${(props) => props.theme.tablet} {
    span {
      margin-top: 0px;
    }
    .btn {
      gap: 20px;
      a {
        height: 40px;
        line-height: 40px;
        font-size: 13px;
        border-radius: 12px;
      }
    }
  }
`;
const VisionPopup = (props) => {
  // id
  const contents = [
    {
      title: "그룹 Cloud 의 통합 운영으로 DT 지원의 효율화 추구",
      content: (
        <>
          {/* <span>
            Cloud 도입 컨설팅부터 마이그레이션과 운영을 포함한 KB금융그룹 차원의
            MSP 총괄 관리 역할 수행
          </span> */}
          <div className="digitalpopup">
            <div className="listpopup">
              <b>
                KB금융그룹이 Digital Transformation 을 추진하는데 그룹 Cloud의
                안정적인 운영과 신속한 대응은 중요합니다. 이를 위해, 지금까지
                그룹의 MSP로 Multi CSP에서 다양한 사업을 통합 운영 해온 경험과
                전문적인 역량을 기반으로, Cloud 자원, 조직, 거버넌스 등을 하나로
                통합하여 One-stop 서비스 제공하고 운영 시스템의 자동화를 통해
                효율화를 추구합니다.
              </b>
              {/* <h5>그룹의 "Cloud First" 전략 기반 MSP 역량 확보</h5>
              <ul>
                <li type="disc">그룹내 MSP 역할 주도 및 역량 강화</li>
                <li type="disc">
                  MSP를 통해 각 산업별 국내 기업의 특성을 반영한 Cloud 서비스
                  제공
                </li>
              </ul> */}
            </div>
            {/* <div className="listpopup">
              <h5>Cloud 서비스 역량 확보 및 그룹 내 사업 참여</h5>
              <ul>
                <li type="disc">
                  Hybrid-Multi Cloud 자체 구축을 통한 역량 확보
                </li>
                <li type="disc">
                  계열사 Cloud 사업 제안 및 발굴을 통한 프로젝트 참여
                </li>
              </ul>
            </div>
            <div className="listpopup">
              <h5>Hybrid-Multi Cloud 운영 및 관리</h5>
              <ul>
                <li type="disc">
                  Cloud 도입 후 지속 가능한 운영 및 관리 체계 확보
                </li>
                <li type="disc">업권별 Cloud 보안 및 관제</li>
              </ul>
            </div>
            <div className="btn">
              <a target="_blank" href="/operational_example">
                그룹마이데이터 +
              </a>
              <a target="_blank" href="/operational_example">
                KB윌렛 +
              </a>
              <a target="_blank" href="/operational_example">
                KB부동산 +
              </a>
            </div> */}
          </div>
          <PopupCloseButton>
            <a href="/#" onClick={props.onClose}>
              닫기
            </a>
          </PopupCloseButton>
        </>
      ),
    },
    {
      title: "AI기술 컨설팅부터 사업화까지 그룹의 AI MSP 지향",
      content: (
        <>
          {/* <span>
            KB금융그룹 체계적인 신기술 도입-구축 지원을 위한 Digital Consulting
            기반의 현업과 IT 간 Gap 최소화
          </span> */}
          <div className="digitalpopup">
            <div className="normalpopup">
              <b>
                KB데이타시스템은 생성형AI의 최신 기술 및 기업에서 활용할 수 있는
                방안을 연구하고 데모 서비스를 구축하며 계열사의 비즈니스에
                적용해오고 있습니다. 빠르게 발전하는 AI 기술을 KB 금융 그룹에
                적용하기 위해 AI 도입부터 구축(SI, Cloud), 운영(SM), 컨설팅에
                이르기까지 AI 비즈니스를 실현하는데 필요한 모든 영역을 지원하는
                AI MSP(Modeling & Managed Service Provider) 로서 역할을
                수행하고자 합니다.
              </b>
            </div>

            {/* <div className="normalpopup">
              <h5>대내/외 IT 규제 대응전략</h5>
              <ul>
                <li type="disc">
                  금융그룹 특화 IT 전문 계열사로 금융산업 신규 IT 규제 및 법규
                  대응을 위한 의무 준수사항 구축 방향성 정립 (예. 금융 클라우드
                  도입 및 데이터3법 대응 등)
                </li>
              </ul>
            </div>
            <div className="normalpopup">
              <h5>ICT/Digital 기반 사업기회 발굴</h5>
              <ul>
                <li type="disc">
                  Digital 기반 핵심 과제 도출 및 신기술 (클라우드, Open API,
                  MSP, Data 등) 기반IT/Digital 중심의 수익화 모델 발굴
                </li>
              </ul>
            </div>
            <div className="normalpopup">
              <h5>KB금융 특화 Gap 분석</h5>
              <ul>
                <li type="disc">
                  Digital 생태계 구축을 위한 KB계열사 ITO 운영 경험 기반의
                  Digital 진단 및 전환 방향 제시
                </li>
              </ul>
            </div>
            <div className="normalpopup">
              <h5>IT/Digital 아키텍처 설계</h5>
              <ul>
                <li type="disc">
                  기존 시스템과 연계 및 서비스 영역별/계열사별 특화 환경을
                  고려한 Digital 서비스 아키텍처 설계 (예. 클라우드 아키텍처,
                  신기술 인프라 등)
                </li>
              </ul>
            </div>
            <div className="normalpopup">
              <h5>새로운 Digital 개발 문화 도입</h5>
              <ul>
                <li type="disc">
                  기존IT 자원 도입부터 구축까지 복잡한 업무 프로세스를 DevOps
                  기반의 즉시 개발 환경 제공으로 체계적인 개발 운영 관리
                </li>
              </ul>
            </div>
            <div className="normalpopup">
              <h5>Digital Platform 구축 지원</h5>
              <ul>
                <li type="disc">
                  그룹 모바일 APP 기반 운영 서비스 지원을 위한 플랫폼 및 인프라
                  구축/그 외 외부 솔루션 및 협업 활용 지원
                </li>
              </ul>
            </div> */}
          </div>
          <PopupCloseButton>
            <a href="/#" onClick={props.onClose}>
              닫기
            </a>
          </PopupCloseButton>
        </>
      ),
    },
    {
      title: "자체 기술력으로 제품개발, 역량 기반 디지털 사업 확대",
      content: (
        <>
          {/* <span>
            내/외부 데이터 수집부터 분석 및 적용까지 계열사별/영역별 데이터 분석
            파이프라인을 통한 분석·모델링 서비스 제공
          </span> */}
          <div className="popup">
            <div className="listpopup">
              <b>
                KB데이타시스템은 MSA, DevOps, 오픈 소스 활용 등, 신기술 기반으로
                자체적으로 소프트웨어 및 플랫폼을 개발해왔으며 그룹 계열사에
                판매, 제공하여 제품 중심의 디지털 사업 영역을 개척하고 있습니다.
                지속적으로 제품 발굴 및 개발에 힘쓰면서 다각도로 그룹 대내외로
                활용도를 확장하여 디지털 사업 확대 및 KB 디지털 자산의 가치를
                높이고자 합니다.
              </b>
              {/* <h5>Data Engineering</h5>
              <ul>
                <li type="disc">과제별 데이터 수집·저장·접근권한 기준 정립</li>
                <li type="disc">
                  내/외부 테이터 수집을 위한 툴 및 인프라 제공
                </li>
              </ul> */}
            </div>
            {/* <div className="listpopup">
              <h5>Data Analytics / Science</h5>
              <ul>
                <li type="disc">
                  그룹 내 데이터 유관 사업 참여 및 분석을 통한 인사이트 도출
                </li>
                <li type="disc">
                  ML/DL 기반 데이터 모델링과 모델 학습 및 최적화
                </li>
              </ul>
            </div> */}
          </div>
          <PopupCloseButton>
            <a href="/#" onClick={props.onClose}>
              닫기
            </a>
          </PopupCloseButton>
        </>
      ),
    },
    {
      title: "그룹 IT 사업 수행 및 비즈니스 Value Up",
      content: (
        <>
          <div className="normalpopup">
            <p>
              KB데이타시스템은 KB금융그룹 지주 및 계열사에서 수행하는 IT
              프로젝트에 참여하여 그룹 내 인프라 및 시스템 구축, 업무 프로세스
              개선 및 고도화를 위해 주도적 역할을 수행하고 있으며 금융IT
              전문회사로서 계열사의 비즈니스 개선 및 연속성을 유지하고 신뢰할 수
              있는 금융IT 서비스 구축에 기여하고 있습니다.
            </p>
          </div>
          <PopupCloseButton>
            <a href="/#" onClick={props.onClose}>
              닫기
            </a>
          </PopupCloseButton>
        </>
      ),
    },
    {
      title: "그룹 내 디지털/IT Hub 역할 수행",
      content: (
        <>
          <div className="normalpopup">
            <p>
              KB데이타시스템은 그룹 내 필요한 디지털 영역 사업에 지속적으로
              참여하여 모바일, 빅데이타, 클라우드 등의 신기술 역량을 강화하고
              디지털/IT Hub 역할로서 계열사 Digitalization 파트너로서 미래
              성장동력인 디지털뱅크, 클라우드 플랫폼 구축 등 디지털 중심의
              금융IT를 선도하는 역할을 지향하고 있습니다.
            </p>
          </div>
          <PopupCloseButton>
            <a href="/#" onClick={props.onClose}>
              닫기
            </a>
          </PopupCloseButton>
        </>
      ),
    },
    {
      title: "전략적 금융 IT서비스 제공",
      content: (
        <>
          <div className="normalpopup">
            <p>
              KB금융그룹에서 추진하는 그룹 공동사업 및 계열사 전략사업의 안정적
              수행 및 운영을 위해 계열사 비즈니스와 연계된 디지털 Value를
              발굴하고 신기술 역량 강화 및 안정적인 IT 서비스를 제공함으로써
              KB금융그룹만의 특화된 금융서비스 창출에 기여하고 있습니다.
            </p>
          </div>
          <PopupCloseButton>
            <a href="/#" onClick={props.onClose}>
              닫기
            </a>
          </PopupCloseButton>
        </>
      ),
    },
    {
      title: "SM업무 재정의",
      content: (
        <>
          <div className="normalpopup">
            <p>
              KB데이타시스템은 핵심업무영역에 대한 주도적 업무확보,
              Value-Up관점의 SM Re-Define 및 은행의 Bionic 플랫폼 조직변화,
              보험부문의 SSC 추진전략에 따라 공통업무 및 인프라영역의 Shared
              Service를 추진하고 있습니다. 계열사의 비본질적 IT서비스 범위를
              점진적으로 축소하고, 디지털/비대면, 계열사 본질업무, 그룹 공동업무
              그리고 전산센터 인프라운영같은 핵심업무중심으로 SM업무의 중심을
              이동하여 계열사의 핵심사업을 지원하고 동반성장하는 IT파트너로
              성장하겠습니다.
            </p>
          </div>
          <PopupCloseButton>
            <a href="/#" onClick={props.onClose}>
              닫기
            </a>
          </PopupCloseButton>
        </>
      ),
    },
    {
      title: "차별적 가치제공",
      content: (
        <>
          <div className="normalpopup">
            <p>
              KB데이타시스템은 핵심업무영역의 금융 도메인 전문지식을 기반으로
              핵심 업무영역에 신기술 적용 및 프로세스 혁신을 통해 서비스를
              개선하고 지속적 혁신을 추구하고 있습니다. 또한, 가속화되는 Digital
              환경변화에 적응하고, Digital 역할 확대요구에 대응하기 위해 내부
              인력의 디지털 Talent화를 통해 디지털 역량 내재화를 추진하고
              있습니다. 이런 조직적 활동을 통해 KB금융그룹 사업에 꼭 필요한
              IT파트너로서 계열사의 IT 혁신을 리딩하고,고객 관점에서의 차별적
              가치 제공을 하는 전문가조직을 지향하고 있습니다.
            </p>
          </div>
          <PopupCloseButton>
            <a href="/#" onClick={props.onClose}>
              닫기
            </a>
          </PopupCloseButton>
        </>
      ),
    },
    {
      title: "안정적인 IT 서비스 제공",
      content: (
        <>
          <div className="normalpopup">
            <p>
              KB데이타시스템은 KB국민은행, 증권, 손해보험, 국민카드, 생명 등 각
              계열사 운영업무를 수행하면서 안정적인 IT 서비스 제공을 위한
              품질지표를 기획하여 품질목표를 제시할 것입니다.IT서비스 품질지표는
              시스템가동율, 인시던트 발생율, 개발품질지표 그리고 고객만족도 등
              안정적인 IT 서비스 제공을 위해 꼭 필요한 인프라 및 S/W품질관리
              항목들을 포함하고, 매월 목표관리를 통해 최고의 IT서비스 품질을
              달성할 것입니다.
            </p>
          </div>
          <PopupCloseButton>
            <a href="/#" onClick={props.onClose}>
              닫기
            </a>
          </PopupCloseButton>
        </>
      ),
    },
  ];
  return (
    <VisionPopupStyle>
      <Popup
        type="default"
        show={props.active}
        onClose={props.onClose}
        title={
          contents[props.active - 1] ? contents[props.active - 1].title : ""
        }
      >
        {contents[props.active - 1] ? contents[props.active - 1].content : ""}
      </Popup>
    </VisionPopupStyle>
  );
};

export default VisionPopup;
