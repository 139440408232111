import React, { useRef, useState } from "react";
import {
  CommonContainer,
  Row,
  Col,
  Br,
  MediaQuery,
  mobileQuery,
} from "../../../components/Grid";
import { motion } from "framer-motion";
import Popup from "../../../components/Popup/index";
import YouTube from "react-youtube";
import {
  DxWrapper,
  SectionTitle,
  DxFrame,
  DXVideoWrapper,
  DxContent,
} from "../styled";

const SectionDx = () => {
  const isMobile = MediaQuery(mobileQuery);
  const video_el = useRef(null);
  const [VideoPopup_isshow, show_VideoPopup] = useState(0);
  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: 1,
      mute: 1,
      loop: 1,
      playlist: "WSzO81oMyjo",
      controls: 0,
    },
  };
  const showVideoPopup = (e) => {
    e.preventDefault();
    if (VideoPopup_isshow) {
      show_VideoPopup(0);
    } else {
      show_VideoPopup(1);
    }
  };

  return (
    <DxWrapper id="dx">
      <CommonContainer>
        <motion.div
          initial={{ opacity: 0, y: 140 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: "easeOut", staggerChildren: 0.5 }}
        >
          <SectionTitle>
            <small>DX</small>
            DIGITAL TRANSFORMATION을 <Br />
            LEAD합니다.
          </SectionTitle>
        </motion.div>
      </CommonContainer>
      <CommonContainer type="wide">
        <motion.div
          initial={{ opacity: 0, y: 140 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: "easeOut", staggerChildren: 0.5 }}
        >
          <DxFrame>
            <DXVideoWrapper bg="Visionitem2.png" onClick={showVideoPopup}>
              {!isMobile ? (
                <YouTube videoId={"WSzO81oMyjo"} opts={opts} />
              ) : (
                // <video autoPlay={true} muted loop ref={video_el}>
                //     <source src="https://kds.easypress.me/wp-content/plugins/ep-manager/assets/images/common/dx_ceo.mp4" type="video/mp4"/>
                // </video>
                <div></div>
              )}
            </DXVideoWrapper>
            <DxContent>
              <CommonContainer>
                <Row>
                  <Col>
                    <strong>
                      KB금융그룹의 디지털 트랜스포메이션을 LEAD하는
                      <Br /> KB데이타시스템이 되겠습니다.
                    </strong>
                    <span>대표이사/사장 김명원</span>
                  </Col>
                  <Col>
                    <p>
                      KB데이타시스템은 미래 핵심역량 중심의 디지털 기술이 내재화
                      된 그룹 내 디지털/IT 혁신을 선도하는 전문가 집단으로
                      변모하고 있습니다. 디지털 환경변화에 신속한 대응이 가능한
                      유연한 조직문화를 바탕으로 구성원 각자의 위치에서 지속적
                      역량 개발에 최선을 다하고 있습니다.
                    </p>
                  </Col>
                </Row>
              </CommonContainer>
            </DxContent>
          </DxFrame>
        </motion.div>
      </CommonContainer>
      <Popup
        type="video"
        youtube_id="WSzO81oMyjo"
        show={VideoPopup_isshow}
        onClose={showVideoPopup}
      />
      {/* <Popup type="video" src="https://kds.easypress.me/wp-content/plugins/ep-manager/assets/images/common/dx_ceo.mp4" show={VideoPopup_isshow} onClose={showVideoPopup}/> */}
    </DxWrapper>
  );
};

export default SectionDx;
