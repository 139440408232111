import React, { useState } from "react";
import ESGCard from "./ESGCard";
import { ESGSliderWraaper, ESGSliderStyle } from "../styled";

const ESGSlider = () => {
  const [active_section, set_active_section] = useState("ESG");
  const [hasMouseOver, setMouseOver] = useState(false);
  const itemWidth = 600;
  const viewPortMargin = 14;
  const imageWrapperWidth = itemWidth * 10 + viewPortMargin - 270;

  // const [ESG_open, on_ESG_open] = useState(0);

  // const Toggle = () => {
  //     if(!ESG_open) {
  //         on_ESG_open(1);
  //     } else {
  //         on_ESG_open(0);
  //     }
  // }

  return (
    <ESGSliderWraaper>
      <ESGSliderStyle
        onMouseEnter={() => {
          setMouseOver(true);
          console.log("마우스오버");
        }}
        onMouseLeave={() => {
          setMouseOver(false);
          console.log("마우스아웃");
        }}
      >
        {/* <Slider {...ESGsettings}> */}
        <ESGCard
          data-id="1"
          wrapperWidth={imageWrapperWidth}
          x={(itemWidth + 16) * 0}
          shouldAnimate={!hasMouseOver}
          title="기후 변화 및 환경보호"
          // date="2020.04.12"
          Stitle="기후변화 및 환경보호"
          Mcontent="지속적인 탄소배출 절감 활동"
          Scontent="- 전자결재시스템, 경비지출관리시스템 도입으로 종이사용 절감"
          index={0}
          image={
            "https://wp.daitso.kds.co.kr/wp-content/plugins/ep-manager/assets/images/common/about_esg_1.jpg"
          }
        />
        <ESGCard
          data-id="2"
          wrapperWidth={imageWrapperWidth}
          x={(itemWidth + 16) * 1}
          shouldAnimate={!hasMouseOver}
          title="사회공헌 활동 확대"
          Stitle="사회공헌 활동 확대"
          Mcontent={
            <div>
              <span>취약 계층 및 미래세대 가치 창출을 위한 교육활동 추진</span>
              <p>
                - 노인 대상 IT교육
                <br />- 청소년 대상 소셜미디어 및 퍼스널브랜딩 교육
              </p>
              {/* <span>2)기업의 사회적 책임을 위한 사회공헌 활동</span>
                                <p>
                                    -동작노인종합복지관, 돈보스코자립생활관, 성모이주여성의집 봉사활동 및 기부금 지원
                                </p> */}
            </div>
          }
          // date="2020.04.12"
          index={1}
          image={
            "https://wp.daitso.kds.co.kr/wp-content/uploads/2020/10/esg_3-scaled.jpg"
          }
        />
        <ESGCard
          data-id="3"
          wrapperWidth={imageWrapperWidth}
          x={(itemWidth + 16) * 2}
          shouldAnimate={!hasMouseOver}
          title="동반성장 및 상생 가치실현"
          Stitle="동반성장 및 상생 가치실현"
          Mcontent="파트너사를 위한 책임활동"
          Scontent="- 공정거래원칙 및 투명한 계열절차 준수를 위해 전자계약 시스템 구축"
          // date="2020.04.12"
          index={2}
          image={
            "https://wp.daitso.kds.co.kr/wp-content/plugins/ep-manager/assets/images/common/about_esg_3.jpg"
          }
        />
        <ESGCard
          data-id="4"
          wrapperWidth={imageWrapperWidth}
          x={(itemWidth + 16) * 3}
          shouldAnimate={!hasMouseOver}
          title="인권 및 다양성 존중"
          Stitle="인권 및 다양성 존중"
          Mcontent="임직원을 위한 안전한 근무환경 조성"
          Scontent={
            <div>
              <p>
                - 자발적인 학습문화를 위한 cop활동지원, 직원자기개발 지원
                <br />- 모성보호 및 출산 육아지원
              </p>
            </div>
          }
          // date="2020.04.12"
          index={3}
          image={
            "https://wp.daitso.kds.co.kr/wp-content/uploads/2020/10/esg_4-scaled.jpg"
          }
        />
        <ESGCard
          data-id="5"
          wrapperWidth={imageWrapperWidth}
          x={(itemWidth + 16) * 4}
          shouldAnimate={!hasMouseOver}
          title="다양하고 지속적인 소통을 통한 조직문화 확립"
          Stitle="다양하고 지속적인 소통을 통한 조직문화 확립"
          Mcontent="경영전략, 비전 공유 및 직원 소통"
          Scontent={
            <div>
              <p>
                - CEO타운홀 미팅
                <br />
                - 본부별 직원간담회
                <br />- 사내커뮤니케이션 채널 운영
              </p>
            </div>
          }
          // date="2020.04.12"
          index={4}
          image={
            "https://wp.daitso.kds.co.kr/wp-content/uploads/2020/10/esg_2-scaled.jpg"
          }
        />
        <ESGCard
          data-id="6"
          wrapperWidth={imageWrapperWidth}
          x={(itemWidth + 16) * 5}
          shouldAnimate={!hasMouseOver}
          title="기후 변화 및 환경보호"
          Stitle="기후변화 및 환경보호"
          Mcontent="지속적인 탄소배출 절감 활동"
          Scontent="- 전자결재시스템, 경비지출관리시스템 도입으로 종이사용 절감"
          // date="2020.04.12"
          index={5}
          image={
            "https://wp.daitso.kds.co.kr/wp-content/plugins/ep-manager/assets/images/common/about_esg_1.jpg"
          }
        />
        <ESGCard
          data-id="7"
          wrapperWidth={imageWrapperWidth}
          x={(itemWidth + 16) * 6}
          shouldAnimate={!hasMouseOver}
          title="사회공헌 활동 확대"
          Stitle="사회공헌 활동 확대"
          Mcontent={
            <div>
              <span>취약 계층 및 미래세대 가치 창출을 위한 교육활동 추진</span>
              <p>
                - 노인 대상 IT교육
                <br />- 청소년 대상 소셜미디어 및 퍼스널브랜딩 교육
              </p>
              {/* <span>기업의 사회적 책임을 위한 사회공헌 활동</span>
                                <p>
                                    -동작노인종합복지관, 돈보스코자립생활관, 성모이주여성의집 봉사활동 및 기부금 지원
                                </p> */}
            </div>
          }
          // date="2020.04.12"
          index={6}
          image={
            "https://wp.daitso.kds.co.kr/wp-content/uploads/2020/10/esg_3-scaled.jpg"
          }
        />
        <ESGCard
          data-id="8"
          wrapperWidth={imageWrapperWidth}
          x={(itemWidth + 16) * 7}
          shouldAnimate={!hasMouseOver}
          title="동반성장 및 상생 가치실현"
          Stitle="동반성장 및 상생 가치실현"
          Mcontent="파트너사를 위한 책임활동"
          Scontent="- 공정거래원칙 및 투명한 계열절차 준수를 위해 전자계약 시스템 구축"
          // date="2020.04.12"
          index={7}
          image={
            "https://wp.daitso.kds.co.kr/wp-content/plugins/ep-manager/assets/images/common/about_esg_3.jpg"
          }
        />
        <ESGCard
          data-id="9"
          wrapperWidth={imageWrapperWidth}
          x={(itemWidth + 16) * 8}
          shouldAnimate={!hasMouseOver}
          title="인권 및 다양성 존중"
          Stitle="인권 및 다양성 존중"
          Mcontent="임직원을 위한 안전한 근무환경 조성"
          Scontent={
            <div>
              <p>
                - 자발적인 학습문화를 위한 cop활동지원, 직원자기개발 지원
                <br />- 모성보호 및 출산 육아지원
              </p>
            </div>
          }
          // date="2020.04.12"
          index={8}
          image={
            "https://wp.daitso.kds.co.kr/wp-content/uploads/2020/10/esg_4-scaled.jpg"
          }
        />
        <ESGCard
          data-id="10"
          wrapperWidth={imageWrapperWidth}
          x={(itemWidth + 16) * 9}
          shouldAnimate={!hasMouseOver}
          title="다양하고 지속적인 소통을 통한 조직문화 확립"
          Stitle="다양하고 지속적인 소통을 통한 조직문화 확립"
          Mcontent="경영전략, 비전 공유 및 직원 소통"
          Scontent={
            <div>
              <p>
                - CEO타운홀 미팅
                <br />
                - 본부별 직원간담회
                <br />- 사내커뮤니케이션 채널 운영
              </p>
            </div>
          }
          // date="2020.04.12"
          index={9}
          image={
            "https://wp.daitso.kds.co.kr/wp-content/uploads/2020/10/esg_2-scaled.jpg"
          }
        />
        {/* </Slider> */}
      </ESGSliderStyle>
      {/* <ESGPopup open={ESG_open} onClose={Toggle}/> */}
    </ESGSliderWraaper>
  );
};

export default ESGSlider;
