import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router';
import LoopBadge from './LoopBadge';
import LoopContent from './LoopContent';
import {LoopWrapper,
        } from '../styled';
import {GetUserPosts} from '../../../api/story';
import LoadInView from '../../../components/LoadInView';
import Pagination from '../../../components/Pagination';

const Loop = () => {
    const params = useParams();
    const [post_list, update_post_list] = useState(0);
    const [current_paged, set_current_paged] = useState(0);
    const [total_page, set_total_page] = useState(0);
    const [list_tags, set_list_tags] = useState(0);
    const [paged, set_paged] = useState(1);
    useEffect(() => {
        if(current_paged != paged) {
            GetUserPosts({
                order: 'DESC',
                orderby: 'ID',
                posts_per_page: '12',
                post_author: params.id,
                paged: paged
            })
            .then((response) => {
                console.log(response);
                if(response.data.body.paged) {
                    set_total_page(response.data.body.total);
                    set_current_paged(response.data.body.paged);
                    update_post_list(response.data.body.posts);
                    set_list_tags(response.data.body.tags);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    });
    const onPaging = (page) => {
        console.log(page);
        set_paged(page);
        window.scrollTo({top:document.querySelector('#post_archive').offsetTop});
    }
    return (
        <LoopWrapper id="post_archive">
            <LoopBadge list_tags={list_tags}/>
            <LoopContent post_list={post_list}/>
            <LoadInView>
                <Pagination onPaging={onPaging} current={current_paged} max={(total_page >= 10) ? (parseInt(total_page / 10) + 1) : 1}/>
            </LoadInView>
        </LoopWrapper>
    )
};

export default Loop;