import React from 'react';
import {CommonContainer} from '../../../components/Grid';
import ESGSlider from './ESGSlider';
import {ESGWrapper,
        SectionTitle,
        ESGSummary,
        ESGBox
} from '../styled'

const SectionESG = () => {
    return (
        <ESGWrapper>
            <CommonContainer>
                <SectionTitle>
                    <small>지속가능경영활동</small>
                    다양한 지속 가능경영활동을 추진합니다.
                </SectionTitle>
                <ESGSummary>
                    <p>
                        KB데이타시스템은 '세상을 바꾸는 금융'이라는 그룹공동의 미션 아래 환경, 사회적 책임이행, 인권, 다양성 등
                        지속가능한 가치를 지키며 KB금융 그룹의 ESG전략 목표를 달성할 수 있도록 함께 노력하고 있습니다.
                    </p>
                </ESGSummary>
            </CommonContainer>
            <CommonContainer type="full">
            <ESGBox>
                <ESGSlider/>
            </ESGBox>
            </CommonContainer>
        </ESGWrapper>
    );
};

export default SectionESG;