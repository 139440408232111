import React, { useEffect, useState } from 'react';
import { CommonContainer } from '../../../components/Grid';
import StorySlider from './StorySlider';
import Card from '../../../components/Card';
import { CardSection } from './styled';
import { GetPosts } from '../../../api/story';
import LoadInView from '../../../components/LoadInView';
import Pagination from '../../../components/Pagination';
// import {animteScrollTo} from '../../../lib/common';

const AllTab = () => {
  const [post_list, update_post_list] = useState(0);
  const [current_paged, set_current_paged] = useState(0);
  const [total_page, set_total_page] = useState(0);
  const [list_tags, set_list_tags] = useState(0);
  const [paged, set_paged] = useState(1);
  useEffect(() => {
    if (current_paged != paged) {
      GetPosts({
        order: 'DESC',
        orderby: 'ID',
        posts_per_page: '12',
        cate: '4500',
        paged: paged,
      })
        .then(response => {
          console.log(response);
          if (response.data.body.paged) {
            set_total_page(response.data.body.total);
            set_current_paged(response.data.body.paged);
            update_post_list(response.data.body.posts);
            set_list_tags(response.data.body.tags);
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  });
  const onPaging = page => {
    console.log(page);
    set_paged(page);
    window.scrollTo({ top: document.querySelector('#post_archive').offsetTop });
  };
  return (
    <>
      <CommonContainer type="full">
        <LoadInView>
          <StorySlider cate_slug={'kbds'} />
        </LoadInView>
      </CommonContainer>
      <CommonContainer id="post_archive">
        <LoadInView>
          <CardSection>
            {post_list
              ? post_list.map((post, key) => (
                  <Card item={post} key={key} width="33.3333%" />
                ))
              : '로딩'}
          </CardSection>
        </LoadInView>
        <LoadInView>
          <Pagination
            onPaging={onPaging}
            current={current_paged}
            max={total_page >= 10 ? parseInt(total_page / 10) + 1 : 1}
          />
        </LoadInView>
      </CommonContainer>
    </>
  );
};

export default AllTab;
