import React from "react";
import { CommonContainer } from "../../../../components/Grid";
import LoadInView from "../../../../components/LoadInView";
import { motion } from "framer-motion";
import { Path, MainTitle, MainPost } from "../styled";

const SectionMain = () => {
  return (
    <>
      <CommonContainer>
        <Path>
          <span>홈</span>개인정보처리방침
        </Path>
        <MainTitle>
          <LoadInView>
            <h2>개인정보처리방침</h2>
          </LoadInView>
        </MainTitle>
      </CommonContainer>
      <CommonContainer type="full">
        <motion.section
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 1,
            ease: "easeInOut",
            stiffness: 2000,
          }}
        >
          <MainPost />
        </motion.section>
      </CommonContainer>
    </>
  );
};

export default SectionMain;
