import styled from 'styled-components';

export const StoryWrapper = styled.div`
    margin-top: 105px;
    @media ${props => props.theme.mobile} {
        margin-top: 75px;
    }
`;

export const Path = styled.div`
    font-family: "KBFG Text";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    color: #FFAC00;
    margin-bottom: 32px;
    span{
        font-family: "KBFG Text";
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 17px;
        color: #999999;
        :after{
            content: ">";
            margin: 0 14px;
        }
    }
    @media ${props => props.theme.laptop} {
        padding-left: 20px;
    }

    @media ${props => props.theme.tablet} {
        padding-left: 0px;
        font-size: 15px;
        line-height: 19px;
        margin-bottom: 25px;
        span{
            font-size: 15px;
            line-height: 19px;
        }
    }
    @media ${props => props.theme.mobile} {
        font-size: 13px;
        line-height: 19px;
        margin-bottom: 15px;
        span{
            font-size: 13px;
            line-height: 19px;
        }
    }
`;

export const Title = styled.div`
    display: inline-block;
    font-family: "KBFG Display";
    font-style: normal;
    margin-bottom: 40px;
    margin-right: 100px;
    h2{
        font-family: "KBFG Display";
        font-style: normal;
        font-weight: 700;
        font-size: 50px;
        line-height: 62px;
        color: #1A1A1A;
    }
    @media ${props => props.theme.laptop} {
        padding-left: 20px;
    }
    @media ${props => props.theme.tablet} {
        padding-left: 0px;
        margin-bottom: 50px;
        h2{
            font-size: 50px;
            line-height: 72px;
        }
    }
    @media ${props => props.theme.mobile} {
        margin-bottom: 25px;
        h2{
            margin-bottom: 0px;
            font-size: 34px;
            line-height: 35px;
        }
    }
`;

