import styled, { keyframes } from "styled-components";
import { Container, ResponsiveBr } from "../../components/Grid/styled";

export const HomeWrapper = styled.div`
  height: 100%;
  position: relative;
  @media ${(props) => props.theme.laptop} {
  }
  @media ${(props) => props.theme.tablet} {
  }
  @media ${(props) => props.theme.mobile} {
  }
`;

export const TopBoxWrapper = styled.div`
  /* margin-bottom: 190px; */
  @media ${(props) => props.theme.laptop} {
    .section-wrap {
      height: auto;
      position: absolute;
      bottom: 150px;
    }
  }
  @media ${(props) => props.theme.tablet} {
    width: 100%;
    /* margin-bottom: 100px; */
    .section-wrap {
      height: auto;
      position: absolute;
      bottom: 190px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    margin-bottom: 0px;
    .section-wrap {
      height: auto;
      position: absolute;
      bottom: 80px;
      padding: 0px 20px;
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;
      left: 0px;
    }
    .slick-prev,
    .slick-next {
      display: none !important;
    }
  }
`;

export const TimeLineWrapper = styled.div`
  margin-bottom: 30px;
  display: inline-block;
  position: relative;
  z-index: 2;
  span {
    font-family: "KBFG Text";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #ffffff;
    text-shadow: 0px 4px 13px rgba(0, 0, 0, 0.12);
    margin-right: 10px;
  }
  div {
    display: inline-block;
    margin-bottom: 4px;
  }
  @media ${(props) => props.theme.laptop} {
  }
  @media ${(props) => props.theme.tablet} {
    margin-top: 110px;
    margin-bottom: 20px;
  }
  @media ${(props) => props.theme.mobile} {
    margin-top: 0px;
    margin-bottom: 42px;
    span {
      display: block;
    }
  }
`;

export const TimeLine = styled.div`
  display: inline-block;
  position: relative;
  background: #fff;
  width: 240px;
  height: 3px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  :after {
    display: block;
    position: absolute;
    content: "";
    background: rgba(255, 255, 255, 0.8);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .slick-active > div & {
      animation: timeline 7s;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }
    @keyframes timeline {
      0% {
        width: 0px;
      }
      100% {
        width: 240px;
      }
    }
    ${(props) => {
      if (props.isplay) {
        return `
                    {
                    animation-play-state: paused;
                }
            `;
      } else {
        return `
            `;
      }
    }}
  }
  @media ${(props) => props.theme.laptop} {
  }
  @media ${(props) => props.theme.tablet} {
    width: 170px;
    :after {
      @keyframes timeline {
        0% {
          width: 0px;
        }
        100% {
          width: 170px;
        }
      }
    }
  }
  @media ${(props) => props.theme.mobile} {
  }
`;

export const Playbutton = styled.div`
  display: block;
  position: absolute;
  top: 4px;
  right: -19px;

  div.s {
    display: inline-block;
    background: none;
    width: 3px;
    height: 10px;
  }
  div.l {
    display: inline-block;
    background: #fff;
    width: 3px;
    height: 10px;
  }
  ${(props) => {
    if (props.isplay) {
      return `
               
                & > div.s {
                    border-top: 5px solid transparent;
                    border-right: 0px solid transparent;
                    border-bottom: 5px solid transparent;
                    border-left: 9px solid #fff;
                    }

                & > div.l {
                    display: none;
                }

            `;
    } else {
      return `

            `;
    }
  }}
  @media ${(props) => props.theme.laptop} {
  }
  @media ${(props) => props.theme.tablet} {
  }
  @media ${(props) => props.theme.mobile} {
    right: 0;
  }
`;

export const TopBox = styled.div`
  position: relative;
  border-radius: 0px 50px;
  width: 100%;
  height: 80vh;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 0 0;

  & > div {
    height: 100%;
    display: flex;
    align-items: center;
    /* justify-content: center; */
  }
  :after {
    display: block;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 0px 50px;
    z-index: 1;
  }
  h2 {
    position: relative;
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 67px;
    color: #ffffff;
    text-shadow: 0px 4px 14px rgba(0, 0, 0, 0.29);
    margin-bottom: 20px;
    z-index: 2;
    /* transform: translate(0,100px); */
    /* opacity: 0; */

    /* transition: transform 1s, opacity 1s; */
  }
  &.on h2 {
    position: relative;
    transform: translate(0, 0);
    opacity: 1;
    z-index: 2;
  }
  p {
    position: relative;
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    color: #ffffff;
    text-shadow: 0px 2px 14px rgba(0, 0, 0, 0.3);
    margin-bottom: 45px;
    z-index: 2;
  }
  a {
    position: relative;
    margin-left: 20px;
    z-index: 2;
  }
  @media ${(props) => props.theme.laptop} {
    height: 600px;
    padding-top: 150px;
    padding-left: 60px;
    h2 {
      font-size: 52px;
    }
  }
  @media ${(props) => props.theme.tablet} {
    height: 90vh;
    padding-left: 0;
    border-radius: 0px;
    background-attachment: scroll;
    h2 {
      font-size: 50px;
      line-height: 72px;
      margin-bottom: 10px;
    }
    p {
      font-size: 22px;
      margin-bottom: 35px;
    }
    :after {
      border-radius: 0 0;
    }
  }
  @media ${(props) => props.theme.mobile} {
    height: 85vh;
    min-height: 560px;
    padding: 0 0;
    h2 {
      font-size: 28px;
      line-height: 36px;
      color: #f3f3f3;
      text-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24);
      margin-bottom: 20px;
    }
    p {
      color: #f3f3f3;
      text-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24);
      margin-bottom: 40px;
      line-height: 25px;
      font-size: 14px;
    }
    a {
      font-size: 16px;
      color: #f3f3f3;
    }
    :after {
      border-radius: 0 0;
    }
  }
  @media (max-width: 400px) {
    ${Container} {
      padding: 0 10px;
    }
  }
`;

export const SectionTitle = styled.h3`
  margin-bottom: 20px;
  display: block;
  font-family: "KBFG Display";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 160%;
  color: #000000;
  small {
    display: block;
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 25px;
    color: #ffbc00;
  }
  @media ${(props) => props.theme.laptop} {
  }
  @media ${(props) => props.theme.tablet} {
    small {
    }
  }
  @media ${(props) => props.theme.mobile} {
    font-size: 22px;
    line-height: 140%;
    margin-bottom: 0px;
    small {
      line-height: 24px;
      font-size: 15px;
    }
  }
`;

export const TextArea = styled(Container)`
  position: relative;
  &.on ${SectionTitle} {
    transform: translate(0, 0);
    opacity: 1;
  }
  @media ${(props) => props.theme.laptop} {
    ${SectionTitle} {
      padding-left: 20px;
    }
  }
  @media ${(props) => props.theme.tablet} {
    ${SectionTitle} {
      padding-left: 0px;
    }
  }
`;

export const SectionSummary = styled.h4`
  position: relative;
  display: block;
  font-family: "KBFG Display";
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 37px;
  color: #777777;
  @media ${(props) => props.theme.laptop} {
    padding-left: 20px;
  }
  @media ${(props) => props.theme.tablet} {
    padding-left: 0px;
  }
  @media ${(props) => props.theme.mobile} {
    font-size: 14px;
    line-height: 26px;
  }
`;

export const SectionSubSummary = styled.p`
  margin-top: 16px;
  font-family: "KBFG Display";
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 37px;
  color: #777777;
`;

export const Seemore = styled.div`
  display: block;
  line-height: 32px;
  text-align: center;
  margin-top: 20px;
  @media ${(props) => props.theme.laptop} {
  }

  @media ${(props) => props.theme.tablet} {
  }

  @media ${(props) => props.theme.mobile} {
    display: block;
    position: relative;
    font-size: 18px;
    line-height: 23px;
    margin-top: 40px;
  }
`;

export const OverviewFigure = styled.div`
  position: absolute;
  width: 400px;
  height: 400px;
  right: 80px;
  top: 0px;
  z-index: -1;
  transition: 3s;
  div {
    position: relative;
    width: 400px;
    height: 400px;
    border: 1px dashed #ffbc00;
    box-sizing: border-box;
    border-radius: 50%;
    transition: 3s;
  }
`;

export const OverviewWrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 150px;
  ${OverviewFigure}:nth-of-type(2) {
    top: 400px;
    left: -100px;
    width: 600px;
    height: 600px;
    div {
      width: 600px;
      height: 600px;
    }
  }
  @media ${(props) => props.theme.laptop} {
    padding-top: 140px;
  }
  @media ${(props) => props.theme.tablet} {
    padding-top: 100px;
  }
  @media ${(props) => props.theme.mobile} {
    margin-top: -5px;
    border-top: 15px solid #eee;
    padding-top: 90px;
    overflow: hidden;
    & > ${Container}:nth-of-type(2) {
      padding: 0 0;
    }
  }
`;

export const ESGFigure = styled.div``;
export const ESGFigures = styled.div`
  ${ESGFigure} {
    position: absolute;
    width: 600px;
    height: 600px;
    right: -80px;
    top: -80px;
    z-index: -1;
    transition: 3s;
    div {
      position: relative;
      width: 600px;
      height: 600px;
      border: 1px dashed #ffbc00;
      box-sizing: border-box;
      border-radius: 50%;
      transition: 3s;
    }
  }
  ${ESGFigure}:nth-of-type(2) {
    top: 400px;
    left: -100px;
    width: 600px;
    height: 600px;
    div {
      width: 600px;
      height: 600px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    ${ESGFigure}:nth-of-type(2) {
    }
  }
`;

/* 
NEW ESG
*/
export const ESGSliderWraaper = styled.div`
  margin-bottom: 0px;
  position: relative;
  height: 700px;
  overflow: hidden;
  &:after {
    content: "";
    display: block;
    height: 250px;
    width: 100%;
    position: absolute;
    background-image: url(${(props) =>
      props.theme.image_url}/common/esg_wave_line.png);
    z-index: -1;
    top: 45%;
    left: 0px;
    @media ${(props) => props.theme.mobile} {
      display: none;
    }
  }
  @media ${(props) => props.theme.mobile} {
    overflow-x: scroll;
    overflow-y: hidden;
    height: 400px;
  }
`;

export const ESGSliderStyle = styled.div`
  margin-top: 24px;
  width: 3400px;
  position: absolute;
  margin-left: -308px;
  top: 0px;
  @media ${(props) => props.theme.mobile} {
    margin-top: 40px;
    margin-left: 0px;
    width: 3220px;
  }
`;

const floatAnimation = keyframes`
  0% {
    transform: translateY(30px);
  }
  50% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(30px);
  }
`;
export const ESGCardWrapper = styled.div`
  padding: 0px;
  padding-bottom: 0px;
  width: 268px;
  height: 530px;
  display: inline-block;
  position: absolute;
  left: ${(props) => props.index * 334}px;
  top: 0;
  &:hover {
    span:first-child {
      color: #ffbc00;
    }
  }
  animation: 7s ${floatAnimation} infinite;
  animation-delay: ${(props) => (props.index || 0) * 250}ms;
  will-change: transform;
  animation-fill-mode: backwards;
  animation-timing-function: ease;
  @media ${(props) => props.theme.mobile} {
    position: relative;
    vertical-align: top;
    display: inline-block;
    width: 300px;
    height: 340px;
    padding-left: 20px;
    left: auto !important;
    animation: none;
    border-radius: 50%;
    top: 0px !important;
    margin-right: 20px;
  }
`;

export const ESGtext = styled.div`
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  font-family: "KBFG Display";
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  padding: 0px 20px;
  line-height: 36px;
  color: #fff;
  transition: 0.3s;
  @media ${(props) => props.theme.mobile} {
    top: 42%;
    span {
      line-height: 30px;
    }
    span:last-child {
      display: block;
      position: relative;
    }
  }
`;

export const ESGimg = styled.div`
  width: 100%;
  height: 530px;
  background-image: url(${(props) => props.theme.image_url}/common/${(props) =>
    props.image});
  background-size: cover;
  background-position: center center;
  border-radius: 55px;
  :after {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 55px;
  }
  @media ${(props) => props.theme.mobile} {
    margin-bottom: 11px;
    height: 300px;
    width: 300px;
    border-radius: 50%;
    position: relative;
    :after {
      border-radius: 50%;
    }
  }
`;

export const InterviewBoxWrapper = styled.div`
  padding: 60px 0px 150px 234px;
  @media ${(props) => props.theme.laptop} {
    padding: 40px 24px 140px 150px;
  }
  @media ${(props) => props.theme.tablet} {
    padding: 40px 0px 100px 150px;
  }
  @media ${(props) => props.theme.mobile} {
    padding: 30px 0px 90px 0px;
    border-bottom: 15px solid #eee;
  }
`;

export const InterviewBox = styled.div`
  width: 100%;
  overflow: hidden;
  height: 0px;
  padding-bottom: 56.25%;
  position: relative;
  iframe {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
  }
  .video-react {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
  }
  .video-react .video-react-big-play-button {
    z-index: -1;
  }

  @media ${(props) => props.theme.tablet} {
  }
  @media ${(props) => props.theme.mobile} {
  }
`;

export const InterviewText = styled.div`
  margin-top: 15px;
  margin-bottom: 0px;
  font-family: "KBFG Display";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #515151;
  span {
    float: right;
    font-family: "KBFG Display";
    color: #ffbc00;
  }
  ${(props) =>
    props.type == "mobile"
      ? `margin-top: 0px;
     margin-bottom: 15px;`
      : ``}
  @media ${(props) => props.theme.mobile} {
    padding: 0 20px;
  }
`;

export const BusinessSection = styled.div`
  margin: 0px 0px 0 0px;
  padding-top: 150px;
  padding-bottom: 150px;
  background: #f7f7f7;
  ${TextArea} {
    a {
      display: none;
    }
  }
  @media ${(props) => props.theme.laptop} {
    padding-top: 140px;
    padding-bottom: 140px;
  }
  @media ${(props) => props.theme.tablet} {
    padding-top: 100px;
    padding-bottom: 100px;
    ${SectionTitle} {
      margin-bottom: 10px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    padding-top: 90px;
    padding-bottom: 90px;
    border-bottom: 15px solid #eee;
    background: #fff;
    position: relative;
  }
`;

export const SeemoreContainer = styled.div``;

export const BusinessContentWrapper = styled.div`
  margin-top: 85px;
  position: relative;
  ${SeemoreContainer} {
    text-align: center;
    margin-top: 69px;
  }
  @media ${(props) => props.theme.laptop} {
    padding-left: 23px;
  }
  @media ${(props) => props.theme.tablet} {
    padding-left: 0px;
    ${SeemoreContainer} {
      text-align: center;
      margin-top: 0px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    margin-top: 60px;
    /* overflow-x: hidden; */
    ${SeemoreContainer} {
      display: block;
      margin-top: 40px;
      text-align: center;
    }
  }
`;

export const BusinessContent = styled.div`
  min-height: 240px;
  & > div {
    display: inline-block;
    vertical-align: top;
    transition: 0.5s;
    position: absolute;
    :hover {
      transform: translateY(-10px);
    }
  }
  div > div {
    width: 447px;
    /* height: 241px; */
    padding: 84px 0;
    display: inline-block;
    box-shadow: 4px 4px 20px rgba(205, 205, 205, 0.35);
    border-radius: 220px;
    font-family: "KBFG Display";
    font-weight: 600;
    font-style: normal;
    font-size: 20px;
    line-height: 36px;
    text-align: center;
    position: absolute;
    transition: 0.3s;
  }
  div:nth-of-type(1) > div {
    position: relative;
    background: rgba(255, 255, 255, 0.88);
    color: #1a1a1a;
  }
  div:nth-of-type(2) > div {
    left: 0px;
    background: rgba(136, 136, 136, 0.83);
    color: #fff;
    transition: 1s;
  }
  div:nth-of-type(3) > div {
    left: 0px;
    background: rgba(96, 88, 76, 0.88);
    color: #fff;
    transition: 1s;
  }
  div:nth-of-type(1) {
    z-index: 1;
  }
  div:nth-of-type(2) {
    z-index: 2;
  }
  div:nth-of-type(3) {
    z-index: 3;
  }
  @media ${(props) => props.theme.laptop} {
    div > div {
      width: 400px;
    }
  }
  @media ${(props) => props.theme.tablet} {
    div > div {
      width: 340px;
      padding: 60px 0px;
      font-size: 18px;
      line-height: 30px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    & > div {
      position: static;
      width: 100%;
      pointer-events: none;
    }
    div > div {
      position: relative;
      padding: 44px 0;
      width: 100%;
      font-size: 17px;
      line-height: 162.5%;
      margin-top: -30px;
    }
    div:nth-of-type(1) > div {
      background: rgba(255, 255, 255, 0.95);
      border: 1px solid #eee;
    }
    div:nth-of-type(2) > div {
      background: #888888;
      opacity: 0.95;
    }
    div:nth-of-type(3) > div {
      background: #70695f;
      opacity: 0.95;
    }
  }
`;

export const CardSection = styled.div`
  padding: 70px 268px 177px 268px;
`;

export const CenterSeemore = styled.div`
  text-align: center;
  margin-top: 52px;
  font-family: "KBFG Text";
  font-style: normal;
  font-size: 17px;
  line-height: 22px;
  position: relative;
  img {
    padding: 8px;
    background: #f7f7f7;
    border-radius: 50%;
    margin-left: 8px;
    position: absolute;
    top: -2px;
  }
`;

export const CardWrapper = styled.div`
  padding: 100px 66px 76px 66px;
  height: 387px;
  margin: 0px -15px;
  background-image: url(${(props) =>
    props.theme.image_url}/common/businessbg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`;

export const DXWrapper = styled.div`
  padding-top: 150px;
  padding-bottom: 150px;
  ${TextArea} {
    a {
      display: none;
    }
  }
  @media ${(props) => props.theme.laptop} {
    padding-top: 140px;
    padding-bottom: 140px;
  }
  @media ${(props) => props.theme.tablet} {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  @media ${(props) => props.theme.mobile} {
    padding-top: 90px;
    padding-bottom: 90px;
    border-bottom: 15px solid #eee;
    ${SectionSummary} {
      margin-bottom: 40px;
    }
  }
`;

export const DXBoxWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 60px 0px 42px 0px;
  height: 400px;
  position: relative;
  overflow: hidden;
  video {
    width: 100%;
    height: 320%;
    position: absolute;
    top: -450px;
    left: 0px;
    display: block;
  }
`;

export const DXBackground = styled.div`
  background-image: url(${(props) =>
    props.theme.image_url}/common/vision_dx.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 300px;
  margin-bottom: 20px;

  @media ${(props) => props.theme.mobile} {
    height: 250px;
  }
`;

export const DXContentWrapper = styled.div`
  ${SeemoreContainer} {
    text-align: center;
  }
  @media ${(props) => props.theme.mobile} {
    ${SeemoreContainer} {
      display: block;
      text-align: center;
    }
  }
`;

export const DXContent = styled.div`
  margin-bottom: 80px;
  padding: 0 0px;
  text-align: right;
  div:nth-of-type(-n + 2) {
    /* margin-right: 181px; */
  }
  div {
    :after {
      display: block;
      position: absolute;
      top: 6px;
      left: -5px;
      padding-left: 70px;
      font-family: "KBFG Display";
      font-style: normal;
      font-weight: 600;
      font-size: 84px;
      line-height: 104px;
      color: #eeeeee;
      z-index: -1;
    }
  }
  & > div:nth-of-type(1) {
    :after {
      content: "01";
    }
  }
  & > div:nth-of-type(2) {
    :after {
      content: "02";
    }
  }
  & > div:nth-of-type(3) {
    :after {
      content: "03";
    }
  }
  @media ${(props) => props.theme.tablet} {
    margin-bottom: 70px;
    div {
      :after {
        font-size: 86px;
        line-height: 107px;
        padding-left: 36px;
      }
    }
  }
  @media ${(props) => props.theme.mobile} {
    margin-bottom: 30px;
    div {
      :after {
        font-size: 86px;
        line-height: 107px;
        top: 0px;
      }
    }
  }
`;

export const DXContentbox = styled.div`
  display: inline-block;
  position: relative;
  width: 33.3%;
  height: 100%;
  padding-left: 70px;
  vertical-align: top;
  text-align: left;
  h4 {
    font-family: "KBFG Display";
    font-style: normal;
    font-size: 28px;
    line-height: 36px;
    color: #000000;
    margin-top: 59px;
  }
  p {
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 35px;
    color: #888888;
    margin-top: 13px;
  }
  @media ${(props) => props.theme.tablet} {
    padding-left: 36px;
    p {
      font-size: 18px;
      line-height: 34px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    width: 100%;
    padding-left: 0px;
    &:after {
      padding-left: 0px !important;
    }
    h4 {
      font-size: 22px;
      line-height: 30px;
    }
    p {
      font-size: 14px;
      line-height: 26px;
    }
  }
`;

export const VisionWrapper = styled.div`
  background-image: url(${(props) =>
    props.theme.image_url}/common/stratgybg.png);
  background-attachment: fixed;
  background-size: cover;
  @media ${(props) => props.theme.laptop} {
    background-attachment: scroll;
  }
  @media ${(props) => props.theme.tablet} {
    background-attachment: scroll;
  }
  @media ${(props) => props.theme.mobile} {
    border-bottom: 15px solid #eee;
  }
`;

export const VisionButton = styled.div`
  margin-top: 60px;
  text-align: center;
  display: block;
  position: relative;
  @media ${(props) => props.theme.talbet} {
  }
  @media ${(props) => props.theme.mobile} {
    margin-top: 20px;
  }
`;

export const VisionBox = styled.div`
  padding-top: 150px;
  padding-bottom: 150px;
  margin-bottom: 0px;
  ${TextArea} {
    margin-bottom: 60px;
  }
  ${SectionTitle} {
    position: relative;
    color: #fff;
  }
  @media ${(props) => props.theme.laptop} {
    padding-top: 140px;
    padding-bottom: 140px;
    ${SectionTitle} {
      padding-left: 0px;
    }
  }
  @media ${(props) => props.theme.tablet} {
    padding-top: 100px;
    padding-bottom: 100px;
    ${TextArea} {
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 40px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    padding-top: 90px;
    padding-bottom: 90px;
    ${TextArea} {
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 30px;
    }
  }
`;

export const VisionContent = styled.div`
  text-align: center;
  & > div {
    display: inline-block;
    width: 440px;
    height: 440px;
    padding: 150px 50px;
    margin-left: -20px;
    margin-right: -20px;
    text-align: center;
    transition: 0.3s;
    border-radius: 50%;
    position: relative;
  }
  .rot {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border: 1px dashed white;
    text-align: center;
    transition: 0.3s;
    border-radius: 50%;
  }
  & > div:hover .rot {
    animation: rotation 2s infinite linear;
  }

  @keyframes rotation {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
    }
  }
  div:hover {
    background: rgba(0, 0, 0, 0.4);
  }
  div:nth-child(2) > .rot {
    background: rgba(0, 0, 0, 0.4);
  }
  figure {
    position: relative;
    z-index: 1;
    h4,
    p {
      color: #fff;
    }
    h4 {
      margin-bottom: 13px;
      font-size: 33px;
      line-height: 40px;
    }
    p {
      font-size: 18px;
      line-height: 34px;
    }
  }
  @media ${(props) => props.theme.laptop} {
    & > div {
      width: 370px;
      height: 370px;
      padding: 125px 35px;
    }
    figure {
      p {
        font-size: 16px;
        line-height: 30px;
      }
    }
  }
  @media ${(props) => props.theme.tablet} {
    & > div {
      text-align: center;
      width: 270px;
      height: 270px;
      padding: 80px 32px;
      margin-left: -10px;
      margin-right: -10px;
    }
    figure {
      h4 {
        font-size: 28px;
        line-height: 37px;
      }
      p {
        font-size: 16px;
        line-height: 30px;
      }
    }
    ${ResponsiveBr} {
      display: inline-block;
    }
    & > div:nth-of-type(2) {
      p:after {
        display: block;
        content: "block";
        opacity: 0;
      }
    }
  }
  @media ${(props) => props.theme.mobile} {
    padding: 0px;
    & > div {
      border: 1px solid #fff;
      width: 100%;
      height: auto;
      padding: 30px 30px;
      margin-bottom: 20px;
      border-radius: 0px;
    }
    figure {
      h4 {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 6;
      }
      p {
        font-size: 16px;
        line-height: 26px;
      }
    }
    .rot {
      display: none;
    }
    div:nth-child(2) {
      background: transparent;
      :hover {
        background: rgba(0, 0, 0, 0.4);
      }
    }
    & > div:nth-of-type(2) {
      p:after {
        display: none;
        /* content: "block"; */
        opacity: 0;
      }
    }
    & > div:nth-of-type(1) {
      p:after {
        display: none;
      }
    }
  }
`;

export const RecruitmentWrapper = styled.div`
  position: relative;
  background-image: url(${(props) =>
    props.theme.image_url}/common/Recruite_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  padding-top: 260px;
  height: 700px;
  z-index: 10;
  :after {
    display: block;
    position: absolute;
    content: "";
    background: rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  ${TextArea} {
    z-index: 10;
  }
  ${SectionTitle} {
    color: #ffffff;
    small {
      color: #ffbc00;
    }
  }
  ${SeemoreContainer} {
    margin-top: 46px;
    margin-left: 20px;
  }
  @media ${(props) => props.theme.laptop} {
    background-attachment: scroll;
    height: 680px;
    padding-top: 240px;
  }

  @media ${(props) => props.theme.tablet} {
    height: 600px;
    padding-top: 200px;
    ${SeemoreContainer} {
      margin-top: 20px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    height: 480px;
    padding-top: 145px;
    border-bottom: 15px solid #eee;
    ${SeemoreContainer} {
      margin-top: 10px;
      text-align: center;
    }
    ${SectionTitle} {
      font-size: 23px;
      font-weight: 600;
      line-height: 37px;
      text-align: center;
      text-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
      small {
        text-align: center;
        text-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
      }
    }
  }
`;

export const WayWorkingWrapper = styled.div`
  padding-top: 150px;
  padding-bottom: 150px;
  position: relative;
  ${TextArea} {
    padding: 0 0;
    margin-bottom: 60px;
    a {
      display: none;
    }
  }
  :after {
    content: "";
    display: block;
    clear: both;
  }
  ${SectionSummary} {
    span {
      position: relative;
      font-family: "KBFG Display";
      font-style: normal;
      font-weight: 300;
      font-size: 17px;
      line-height: 37px;
      color: #777777;
    }
  }
  ${SeemoreContainer} {
    display: block;
    text-align: center;
    a {
      margin-top: 80px;
    }
  }
  @media ${(props) => props.theme.laptop} {
    padding-top: 140px;
    padding-bottom: 140px;
  }
  @media ${(props) => props.theme.tablet} {
    padding-top: 100px;
    padding-bottom: 100px;
    ${TextArea} {
      margin-bottom: 40px;
    }
    ${SeemoreContainer} {
      a {
        margin-top: 60px;
      }
    }
  }
  @media ${(props) => props.theme.mobile} {
    margin-bottom: 0;
    padding-top: 90px;
    padding-bottom: 90px;
    border-bottom: 15px solid #eee;
    ${SectionSummary} {
      font-size: 14px;
      line-height: 26px;
      span {
        font-size: 0;
      }
    }
    ${TextArea} {
      margin-bottom: 30px;
    }
    ${SeemoreContainer} {
      a {
        margin-top: 20px;
      }
    }
  }
`;

export const WayCardAreaItem = styled.div`
  position: relative;
  float: left;
  padding: 0 15px;
  height: auto;
  ${(props) => (props.wide ? `width:100%; margin-bottom: 30px;` : `width:50%;`)}
  p {
    position: absolute;
    bottom: 40px;
    left: 0px;
    width: 100%;
    padding: 0px 60px;
    pointer-events: none;
  }
  strong,
  small {
    display: block;
    font-style: normal;
    color: #ffffff;
    text-shadow: 0px 2px 14px rgba(0, 0, 0, 0.3);
    z-index: 20;
  }
  strong {
    font-family: "KBFG Display";
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
  }
  small {
    position: relative;
    margin-top: 10px;
    font-family: "KBFG Text";
    font-weight: 300;
    font-size: 17px;
    line-height: 32px;
  }
  div {
    position: relative;
    width: 100%;
    height: 320px;
    overflow: hidden;
    z-index: 0;
    border-radius: 0px 30px;

    div {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      height: 100%;
      -webkit-transition: transform 0.8s ease-out;
      -moz-transition: transform 0.8s ease-out;
      -ms-transition: transform 0.8s ease-out;
      -o-transition: transform 0.8s ease-out;
      transition: transform 0.8s ease-out;
      :hover {
        transform: scale(1.1);
      }
      :after {
        border-radius: 0px 30px;
      }
    }
  }
  @media ${(props) => props.theme.laptop} {
    padding: 0px 15px;
    strong {
      font-size: 25px;
    }
    small {
      line-height: 30px;
    }
  }
  @media ${(props) => props.theme.tablet} {
    & > div {
      height: 360px;
    }
    strong {
      font-size: 23px;
    }
    small {
      display: none;
    }
  }
  @media ${(props) => props.theme.mobile} {
    width: 100%;
    margin-bottom: 20px;
    padding: 0 0;
    & > div {
      height: 300px;
    }
    p {
      bottom: 40px;
      padding: 0px 40px;
    }
    strong {
      font-size: 18px;
      line-height: 26px;
      text-shadow: 0px 1px 14px rgba(0, 0, 0, 0.25);
    }
  }
`;

export const WayCardArea = styled.div`
  display: block;
  margin-bottom: 0px;
  margin: 0 -15px;
  ${WayCardAreaItem}:nth-of-type(1) {
    div {
      div {
        background-image: url(${(props) =>
          props.theme.image_url}/common/work_item1.png);
        position: relative;
        :after {
          display: block;
          position: absolute;
          content: "";
          background: rgba(0, 0, 0, 0.2);
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 0;
        }
      }
    }
  }
  ${WayCardAreaItem}:nth-of-type(2) {
    position: relative;
    div {
      div {
        background-image: url(${(props) =>
          props.theme.image_url}/common/work_item2.png);
        position: relative;
        :after {
          display: block;
          position: absolute;
          content: "";
          background: rgba(0, 0, 0, 0.2);
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 0;
        }
      }
    }
  }
  ${WayCardAreaItem}:nth-of-type(3) {
    position: relative;
    div {
      div {
        background-image: url(${(props) =>
          props.theme.image_url}/common/strategyitem3.png);
        position: relative;
        :after {
          display: block;
          position: absolute;
          content: "";
          background: rgba(0, 0, 0, 0.2);
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 0;
        }
      }
    }
  }
  @media ${(props) => props.theme.laptop} {
    margin: 0px 15px;
  }
  @media ${(props) => props.theme.tablet} {
    margin: 0px -15px;
  }
  @media ${(props) => props.theme.mobile} {
    margin: 0px;
  }
`;

export const ESGitem = styled.section`
  position: absolute;
  width: 380px;
  height: 380px;
  z-index: 12;
  transition: 1s;
  :hover {
    transform: scale(1.1);
  }
  & div {
    width: 380px;
    height: 380px;
    border-radius: 50%;
    text-align: center;
    background-size: cover;
    background-position: center center;
    position: relative;
    transition: 3s;
    p {
      font-family: "KBFG Display";
      font-style: normal;
      font-size: 30px;
      color: #ffffff;
      text-shadow: 0px 1px 14px rgba(0, 0, 0, 0.44);
      position: relative;
      z-index: 10;
      line-height: 380px;
    }
    :after {
      display: block;
      position: absolute;
      border-radius: 1020px;
      content: "";
      background: rgba(0, 0, 0, 0.3);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
  @media ${(props) => props.theme.laptop} {
    div {
      width: 320px;
      height: 320px;
      p {
        line-height: 320px;
      }
    }
  }
  @media ${(props) => props.theme.tablet} {
    div {
      width: 300px;
      height: 300px;
      p {
        line-height: 300px;
      }
    }
  }
`;
export const ESGItemWrapper = styled.div`
  height: 600px;
  @media ${(props) => props.theme.mobile} {
    height: auto;
    margin-top: 60px;
    margin-bottom: 60px;
  }
`;
export const ESGWrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 150px;
  padding-bottom: 150px;
  ${TextArea} {
    position: relative;
    z-index: 1;
    margin-bottom: 40px;
  }
  ${SeemoreContainer} {
    margin-top: 30px;
    text-align: center;
  }
  @media ${(props) => props.theme.laptop} {
    padding-top: 140px;
    padding-bottom: 140px;
    overflow-x: hidden;
    ${ESGitem}:nth-of-type(1) {
      top: 40%;
    }
    ${ESGitem}:nth-of-type(3) {
      top: 0%;
      left: 48%;
    }
    ${ESGitem}:nth-of-type(4) {
      left: 60%;
    }
    ${ESGitem}:nth-of-type(5) {
      right: -12%;
    }
  }

  @media ${(props) => props.theme.tablet} {
    padding-top: 100px;
    padding-bottom: 100px;
    ${ESGitem}:nth-of-type(1) {
      top: 45%;
      left: 5%;
    }
    ${ESGitem}:nth-of-type(2) {
      top: 30%;
      left: 40%;
    }
    ${ESGitem}:nth-of-type(3) {
      top: -2%;
      left: 60%;
    }
    ${ESGitem}:nth-of-type(4) {
      top: 70%;
      left: 45%;
    }
    ${ESGitem}:nth-of-type(5) {
      top: 40%;
      right: -15%;
    }
  }

  @media ${(props) => props.theme.mobile} {
    overflow: hidden;
    padding-top: 90px;
    padding-bottom: 90px;
    border-bottom: 15px solid #eee;
    ${TextArea} {
      margin-bottom: 0px;
    }
    ${ESGItemWrapper} {
      overflow-x: scroll;
      overflow-y: hidden;
      width: 100%;
    }
    ${ESGItemWrapper} > div {
      width: 2100px;
    }
    ${ESGItemWrapper} > div section {
      display: inline-block;
      width: 350px;
    }
    ${ESGitem} {
      vertical-align: middle;
      position: relative;
      top: auto !important;
      right: auto !important;
      left: auto !important;
    }

    ${SeemoreContainer} {
      margin-top: 0px;
    }
  }
`;

export const StoryWrapper = styled.div`
  padding: 0 65px;
  ${(props) => (props.type ? `padding-top: 0px;` : ` `)}
  ${(props) => (props.full ? `padding: 0 0;` : ` `)}

    @media ${(props) => props.theme.laptop} {
    padding: 0 20px;
    ${(props) => (props.type ? `padding-top:0px;` : ` `)}
    ${(props) => (props.full ? `padding: 0 0;` : ` `)}
  }

  @media ${(props) => props.theme.tablet} {
    ${(props) => (props.type ? `padding-top:0px;` : ` `)}
  }

  @media ${(props) => props.theme.mobile} {
    ${(props) => (props.type ? `padding-top: 0px;` : `padding-top:0px;`)}
    padding: 0px 0px;
    border-bottom: 15px solid #eee;
  }
`;

export const StoryPostWrapper = styled.div`
  /* padding-right: 300px;
    @media ${(props) => props.theme.laptop} {
        padding-right: 200px;
    }
    @media ${(props) => props.theme.tablet} {
        padding-right: 100px;
    }
    @media ${(props) => props.theme.mobile} {
        padding-right: 0px;
    } */
`;

export const StoryPost = styled.div`
  width: 100%;
  z-index: 2;
  position: relative;
  ${TextArea} {
  }
  ${SectionTitle} {
    margin-bottom: 25px;
    font-weight: 600;
    font-size: 30px;
    line-height: 53px;
    color: #fff;
    padding-left: 80px;
    small {
      font-size: 22px;
    }
  }
  ${SeemoreContainer} {
    padding-left: 80px;
  }
  @media ${(props) => props.theme.laptop} {
    ${SectionTitle} {
      padding-left: 160px;
    }
    ${SeemoreContainer} {
      padding-left: 160px;
    }
  }
  @media ${(props) => props.theme.tablet} {
    ${SectionTitle} {
      font-size: 30px;
      line-height: 45px;
      padding-left: 140px;
    }
    ${SeemoreContainer} {
      padding-left: 140px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    text-align: center;
    ${SectionTitle} {
      margin-bottom: 25px;
      font-weight: 600;
      font-size: 20px;
      line-height: 35px;
      padding-left: 0px;
      small {
        font-size: 17px;
      }
    }
    ${SeemoreContainer} {
      padding-left: 0px;
    }
  }
`;

export const StorySliderSection = styled.div`
  background: #fff;
  /* height: 868px; */
  overflow: hidden;
  z-index: 1;
  position: relative;
  ${(props) => (props.type ? `padding-top: 0px;` : ``)}
  @media ${(props) => props.theme.laptop} {
    ${(props) => (props.type ? `padding-top: 0px;` : ``)}
  }
  @media ${(props) => props.theme.tablet} {
    ${(props) => (props.type ? `padding-top: 0px;` : ``)}
  }
  @media ${(props) => props.theme.mobile} {
    background: #fff;
    ${(props) => (props.type ? `padding-top:0px;` : ``)}
  }
`;

export const StoryBackground = styled.div`
  position: relative;
  cursor: pointer;
  /* top:0px; */
  /* left:0px; */
  height: 450px;
  width: 100%;
  padding: 100px 0px;
  margin-bottom: 0px;
  background-image: url(${(props) => (props.imageUrl ? props.imageUrl : "")});
  background-size: cover;
  background-position: center 100%;
  background-repeat: no-repeat;
  transition: 0.3s;
  :after {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    top: 0px;
    left: 0px;
  }
  & > div {
    z-index: 2;
  }
  & > div:first-child {
    position: relative;
  }
  @media ${(props) => props.theme.tablet} {
    background-attachment: scroll;
  }
  @media ${(props) => props.theme.mobile} {
    height: 480px;
  }
`;

export const ProgressBar = styled.div`
  position: absolute;
  width: 100%;
  height: 10px;
  background: rgba(255, 255, 255, 0.3);
  bottom: 0px;
  left: 0px;
  div {
    width: ${(props) => props.percent}%;
    height: 10px;
    background: rgba(255, 255, 255, 0.7);
    .slick-active > div & {
      animation: timeline 7s;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      @keyframes timeline {
        0% {
          width: 0px;
        }
        100% {
          width: 100%;
        }
      }
    }
  }
`;

export const StoryBox = styled.div`
  height: 344px;
`;
export const StorySliderWrapper = styled.div`
  position: relative;
  height: 450px;
  margin-bottom: 0px;
  overflow: hidden;
  :after {
    content: "";
    display: block;
    clear: both;
  }
  @media ${(props) => props.theme.laptop} {
  }
  @media ${(props) => props.theme.tablet} {
    height: 360px;
  }
  @media ${(props) => props.theme.mobile} {
    height: 360px;
  }
`;
export const StoryBoxWrapper = styled.div`
  width: 650px;
  position: relative;
  box-sizing: border-box;
  padding: 54px 65px;
  padding-left: 0;
  /* background: rgba(255, 255, 255, 0.96); */
  cursor: pointer;
  transition: 0.3s;
  a {
    margin-left: 30px;
  }
  :hover {
    /* background: rgba(255, 255, 255, 0.8); */
  }
  label {
    margin-right: 10px;
  }
  @media ${(props) => props.theme.laptop} {
    padding: 25px 45px;
    padding-left: 0;
  }
  @media ${(props) => props.theme.tablet} {
  }
  @media ${(props) => props.theme.mobile} {
    width: 100%;
    padding: 0px 0px;
    a {
      margin-left: 0;
    }
    ${SeemoreContainer} {
      margin-left: 20px;
      text-align: center;
    }
  }
`;

export const BoxTitle = styled.div`
  margin-top: 24px;
  margin-bottom: 50px;
  font-family: "KBFG Display";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 52px;
  color: #000000;
`;
