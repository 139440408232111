import React from "react";
import { CommonContainer } from "../../../components/Grid";
import LoadInView from "../../../components/LoadInView";
import { motion } from "framer-motion";
import { TitleWrapper, Path, MainTitle, MainTitleImg } from "../styled";

const SectionTitle = () => {
  const titleimg =
    "https://wp.daitso.kds.co.kr/wp-content/plugins/ep-manager/assets/images/common/vision_bg.jpg";
  return (
    <TitleWrapper id="vision_dx">
      <CommonContainer>
        <Path>
          <span>홈</span>Vision
        </Path>
        <MainTitle>
          <LoadInView>
            <h2>Vision</h2>
          </LoadInView>
        </MainTitle>
      </CommonContainer>
      <CommonContainer type="full">
        <motion.section
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 1,
            ease: "easeInOut",
            stiffness: 2000,
          }}
        >
          <MainTitleImg
            style={{ backgroundImage: `url(${titleimg})` }}
          ></MainTitleImg>
        </motion.section>
      </CommonContainer>
    </TitleWrapper>
  );
};

export default SectionTitle;
