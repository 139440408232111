import React from 'react';
import styled from 'styled-components';
import Button from '../Button';
import {CgChevronRight,CgChevronLeft} from "react-icons/cg";

const StylePagination = styled.div`
    text-align:center;
    margin-bottom:70px;
    a {
        border:1px solid #eee;
        margin-top:12px;
        margin-right:15px;
    }
`;

const Pagination = (props) => {
    // const [currentPage, setCurrentPage] = useState(props.current);
    const max = props.max;
    // console.log(max);
    //props : total, current, type:<button, paging>, onPaging:function
    return (
        <StylePagination>
            {(parseInt(props.current) - 1) ? (<Button type="round" size="70px" onClick={(e) => props.onPaging((parseInt(props.current) - 1))}><CgChevronLeft/></Button>) : (<Button type="round" size="70px" disable='true'><CgChevronLeft/></Button>) }
            {((parseInt(props.current) + 1) <= max) ? (<Button type="round" size="70px" onClick={(e) => props.onPaging((parseInt(props.current) + 1))}><CgChevronRight/></Button>) : (<Button type="round" size="70px" disable='true'><CgChevronRight/></Button>)}
        </StylePagination>
    );
};

export default Pagination;