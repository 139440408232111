import React from 'react';
import {CommonContainer, MediaQuery, mobileQuery, Br} from '../../../components/Grid';
import Button from '../../../components/Button';
import LoadInView from '../../../components/LoadInView';
import { useInView } from 'react-hook-inview';
import { motion } from 'framer-motion';
import {BusinessSection,
        BusinessContentWrapper,
        BusinessContent,
        TextArea,
        SectionTitle,
        SectionSummary,
        SeemoreContainer
} from '../styled';

const SectionBusiness = () => {
    const isMobile = MediaQuery(mobileQuery);
    const [ref, inView] = useInView({ unobserveOnEnter: true, delay: 3000, rootMargin:'10px', threshold: 0 });
  
    return (
        <CommonContainer type="full">
            <LoadInView>
                <BusinessSection>
                    <LoadInView>
                        <TextArea>
                            <SectionTitle>
                                <small>사업영역</small>
                                KB데이타시스템의 <Br/>그룹내 역할을 소개합니다.
                            </SectionTitle>
                            <SectionSummary>
                                다양한 금융IT개발 경험을 통해 축적된 노하우와 기술력을 바탕으로 그룹 내 디지털/IT HUB 역할을 수행하고 있습니다.
                            </SectionSummary>
                            <Button type='primary' color='gray' to={'/about/#business'}>자세히보기</Button>
                        </TextArea>
                    </LoadInView>
                    <CommonContainer>
                        <BusinessContentWrapper>
                            <BusinessContent ref={ref}>
                                {inView ? (
                                    <>
                                        <div>
                                            <motion.div 
                                                initial={{ opacity: 0 }} 
                                                animate={{ opacity: 1 }} 
                                                transition={{ duration: .5, staggerChildren: 0.5 }}
                                            >전문적인 IT서비스<br/>그룹IT혁신 및 가치 창출</motion.div>
                                        </div>
                                        <div>
                                            <motion.div 
                                                initial={isMobile ? { opacity: 1, y: -140 } : { opacity: 1, x: 0 }} 
                                                animate={isMobile ? {opacity:1, y:0} : { opacity: 1, x: "86%" }} 
                                                transition={{ duration: .5, staggerChildren: 0.5 }
                                            }>IT전문인력 집중 양성으로<br/>그룹IT역량 강화에 기여</motion.div>
                                        </div>
                                        <div>
                                            <motion.div  
                                                initial={isMobile ? { opacity: 1, y: -280 } : { opacity: 1, x: 0 }} 
                                                animate={isMobile ? { opacity: 1, y: 0 } : { opacity: 1, x: "172%" }} 
                                                transition={{ duration: .5, staggerChildren: 0.5 }
                                            }>선진 IT 서비스 업체들과의 전략적<br/>파트너십을 통한 핵심 기술력 확보</motion.div>
                                        </div>
                                    </>
                                ) : ''}
                            </BusinessContent>
                            <SeemoreContainer>
                                <Button type='primary' color='bg-white' to={'/about/#business'}>자세히보기</Button>
                            </SeemoreContainer>
                        </BusinessContentWrapper>
                    </CommonContainer>
                </BusinessSection>
            </LoadInView>
        </CommonContainer>
    );
};

export default SectionBusiness;