import React, {useState} from 'react';
import MainContainer from '../../components/MainContainer';
import {CommonContainer} from '../../components/Grid/';
import Tab from './components/Tab';
import AllTab from './components/AllTab';
import FinanceTab from './components/FinanceTab';
import TechnicTab from './components/TechnicTab';
import CultureTab from './components/CultureTab';
import {StoryWrapper,
        Path,
        Title        
} from './styled';
import {useLocation, Link} from 'react-router-dom';


const Story = () => {
    let { search } = useLocation();

    const query = new URLSearchParams(search);
    const [active_tab, set_active_tab] = useState(query.get('tab') ? query.get('tab') : 1);
    const ChangeTab = (e) => {
        // e.preventDefault();
        set_active_tab(e.target.dataset.id)
        console.log(query.get('tab'));
    }
    return (
        <MainContainer>
            <StoryWrapper>
                <CommonContainer>
                    <Path>
                        <span>홈</span>Story 
                    </Path>
                    <Title>
                        <h2>Story</h2>
                    </Title>
                    <Tab>
                        <li className={(active_tab == 1) ? 'active' : ''}><Link to={"/story/"} data-id="1" onClick={ChangeTab}>All</Link></li>
                        <li className={(active_tab == 2) ? 'active' : ''}><Link to={"/story/?tab=2"} data-id="2" onClick={ChangeTab}>금융 IT 트렌드</Link></li>
                        <li className={(active_tab == 3) ? 'active' : ''}><Link to={"/story/?tab=3"} data-id="3" onClick={ChangeTab}>조직문화</Link></li>
                        <li className={(active_tab == 4) ? 'active' : ''}><Link to={"/story/?tab=4"} data-id="4" onClick={ChangeTab}>지속가능경영</Link></li>
                    </Tab>
                </CommonContainer>
                    {(active_tab == 1) ? (<AllTab/>) : ''}
                    {(active_tab == 2) ? (<TechnicTab/>) : ''}
                    {(active_tab == 3) ? (<FinanceTab/>) : ''}
                    {(active_tab == 4) ? (<CultureTab/>) : ''}
            </StoryWrapper>
        </MainContainer>
    );

};

export default Story;