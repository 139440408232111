import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom'
import {CommonContainer} from '../../components/Grid';
import MainContainer from '../../components/MainContainer';
import Pagination from '../../components/Pagination';
import {BiddingGetPosts} from '../../api/board';
import { 
    BoardTitle,
    BoardContainer,
    BoardTableWrapper
} from './styled';

const List = (props) => {
    return (
        <tr>
            <td>{props.post.ID}</td>
            <td>
                <Link to={`/board/bidding/${props.post.ID}`} style={{textDecoration:"none", color:"#424242"}}>
                    {props.post.post_title}
                    &nbsp;<span>{`(${props.post.comment_count})`}</span>
                </Link>
            </td>
            {/* <td>{props.post.post_author}</td> */}
            <td>{props.post.post_date}</td>
        </tr>
    );
};

const Board = () => {
    const [post_list, update_post_list] = useState(0);
    const [current_paged, set_current_paged] = useState(0);
    const [total_page, set_total_page] = useState(0);
    const [paged, set_paged] = useState(1);
    useEffect(() => {
        if(current_paged != paged) {
            BiddingGetPosts({
                order: 'DESC',
                orderby: 'ID',
                posts_per_page: '10',
                paged: paged
            })
            .then((response) => {
                console.log(response);
                if(response.data.body.paged) {
                    set_total_page(response.data.body.total);
                    set_current_paged(response.data.body.paged);
                    update_post_list(response.data.body.posts);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    });
    const onPaging = (page) => {
        console.log(page);
        set_paged(page);
    }
    return (
        <>
        <MainContainer>
            <CommonContainer>
                <BoardTitle>
                    입찰공고
                </BoardTitle>
                <BoardContainer>
                    <BoardTableWrapper>
                        <thead>
                            <tr>
                                <th>번호</th>
                                <th><label>제목</label></th>
                                <th>작성일</th>
                            </tr>
                        </thead>
                        <tbody>
                            {post_list ? post_list.map((post, key) => {
                                return (<List post={post}/>);
                            }) : (<tr><td colSpan="4">아직 게시글이 없습니다.</td></tr>) }
                        </tbody>
                    </BoardTableWrapper>
                    <Pagination onPaging={onPaging} current={current_paged} max={(total_page >= 10) ? (parseInt(total_page / 10) + 1) : 1}/>
                    
                </BoardContainer>
            </CommonContainer>
        </MainContainer>
        </>
    );
};

export default Board;