import axios from './defaultClient';

const nameSpace = '/ep/v1/story';
export const GetPosts = async (args) => {
    return await axios.get( nameSpace + '/',{ 
        params:{
            order: args.order,
            orderby: args.orderby,
            cate: args.cate,
            posts_per_page: args.posts_per_page,
            paged: args.paged
        }
    })
}
export const SearchGetPosts = async (args) => {
    return await axios.get( nameSpace + '/',{ 
        params:{
            s: args.keyword,
            posts_per_page: args.posts_per_page,
            paged: args.paged
        }
    })
}
export const TagGetPosts = async (args) => {
    return await axios.get( nameSpace + '/',{ 
        params:{
            tag: args.tag_slug,
            posts_per_page: args.posts_per_page,
            paged: args.paged
        }
    })
}
export const GetRelatedPosts = async (args) => {
    return await axios.get( nameSpace + '/related',{ 
        params:{
            post_id: args.post_id,
            posts_per_page: args.posts_per_page,
            paged: args.paged
        }
    })
}
export const GetUserRelatedPosts = async (args) => {
    return await axios.get( nameSpace + '/user/related',{ 
        params:{
            post_id: args.post_id,
            post_author: args.post_author,
            posts_per_page: args.posts_per_page,
            paged: args.paged
        }
    })
}
export const GetUserPosts = async (args) => {
    return await axios.get( nameSpace + '/user',{ 
        params:{
            post_author: args.post_author,
            posts_per_page: args.posts_per_page,
            paged: args.paged
        }
    })
}
export const GetPost = async (args) => {
    return await axios.get( nameSpace + '/' + args.post_id, {})
}