import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import Button from "../../../components/Button";
import { CommonContainer } from "../../../components/Grid";
import {
  StoryBoxWrapper,
  StorySliderWrapper,
  StoryBackground,
  SeemoreContainer,
  SectionTitle,
} from "../styled";
import { GetStorySlider } from "../../../api/slider";
const StoryBoxItem = (props) => {
  return (
    <StoryBackground
      onClick={() =>
        window.open("https://blog.kbds.co.kr/blog/" + props.item.post_ID)
      }
      imageUrl={props.item.post_image}
      active={props.count == props.active - 1 ? "active" : ""}
    >
      <CommonContainer>
        <StoryBoxWrapper>
          <SectionTitle>
            {/* <small>{props.item.cate_name}</small> */}
            <small>스토리</small>
            {props.item.post_title}
          </SectionTitle>
          <SeemoreContainer>
            <Button type="primary" color="white" icon="arrow">
              자세히보기
            </Button>
          </SeemoreContainer>
        </StoryBoxWrapper>
      </CommonContainer>

      {/* <ProgressBar percent={props.percent}><div/></ProgressBar> */}
    </StoryBackground>
  );
};

export const settings = {
  infinite: true,
  autoplay: 1,
  speed: 300,
  arrows: 1,
  dots: true,
  slidesToShow: 1,
  rows: 1,
  slidesToScroll: 1,
  autoplaySpeed: 7000, // 자동 스크롤 시 다음으로 넘어가는데 걸리는 시간 (ms)
  pauseOnHover: false,
  // afterChange: () => this.setState(state => ({ updateCount: state.updateCount + 1 })),
  // beforeChange: (current, next) => this.setState({ slideIndex: next }),
  responsive: [
    {
      breakpoint: 1800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const SlickWrap = styled.div`
  .slick-arrow {
    z-index: 3;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-list {
    overflow: visible;
    padding: 0px !important;
  }
  .slick-slide:not(.slick-active) {
  }
  .slick-prev,
  .slick-next {
    font-size: 0;
    position: absolute;
    bottom: auto;
    color: red;
    border: 0;
    background: none;
    z-index: 1;
    cursor: pointer;
    background: #fff;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    transition: 0.8s;
    opacity: 0;
  }
  .slick-slider:hover .slick-next,
  .slick-slider:hover .slick-prev {
    opacity: 0.8;
    :hover {
      opacity: 1;
    }
    @media ${(props) => props.theme.tablet} {
      opacity: 0;
    }
  }
  .slick-prev {
    left: 80px;
    top: 50%;
  }
  .slick-prev:before {
    content: url("arrow-before.svg");
  }
  .slick-next {
    right: 80px;
    top: 50%;
  }
  .slick-next:before {
    content: url("arrow-next.svg");
  }
  .slick-prev:hover:after,
  .slick-next:hover:after {
  }
  .slick-dots {
    position: relative;
    top: -30px;
  }
  .slick-dots li {
    margin: 0 25px;
  }
  .slick-dots li button {
    position: relative;
  }
  .slick-dots li.slick-active button:before {
    background: #fff;
    opacity: 1;
  }
  .slick-dots li button:before {
    position: absolute;
    width: 40px;
    height: 3px;
    background: rgba(255, 255, 255, 0.5);
    content: "";
    opacity: 1;
    left: 50%;
    transform: translateX(-50%);
    top: 10px;
  }
  @media ${(props) => props.theme.laptop} {
    .slick-prev,
    .slick-next {
      border-radius: 0%;
      width: 0px;
      height: 0px;
    }
    .slick-prev:before {
      display: none;
    }
    .slick-next:before {
      display: none;
    }
  }
`;

const StorySlider = (props) => {
  const [slide_list, update_slide_list] = useState(0);
  const [current_category, update_current_category] = useState(0);
  useEffect(() => {
    if (current_category != props.cate_slug) {
      GetStorySlider({
        cate_slug: props.cate_slug,
      })
        .then((response) => {
          if (response.data.code == "200") {
            update_slide_list(response.data.body);
            console.log(response.data);
            update_current_category(props.cate_slug);
          }
        })
        .catch((response) => {
          console.log(response);
        });
    }
  });

  return (
    <StorySliderWrapper>
      <SlickWrap>
        <Slider {...settings}>
          {slide_list
            ? slide_list.map((slide, key) => (
                <StoryBoxItem item={slide} key={key} count={key} />
              ))
            : ""}
        </Slider>
      </SlickWrap>
    </StorySliderWrapper>
  );
};

export default StorySlider;
