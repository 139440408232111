import React from "react";
import Button from "../../../components/Button";
import { Br, CommonContainer } from "../../../components/Grid";
import {
  RecruitmentWrapper,
  SectionTitle,
  SeemoreContainer,
  TextArea,
} from "../styled";

const SectionWayWorking = () => {
  return (
    <CommonContainer type="wide">
      <RecruitmentWrapper>
        <TextArea>
          <SectionTitle>
            <small>인재채용</small>
            창의적 사고와 행동으로 변화를 선도하며 고객만족을
            <Br />
            실현시키는 분야별 전문가
          </SectionTitle>
          <SeemoreContainer>
            <Button
              type="primary"
              to={"https://kbds.career.greetinghr.com/"}
              target="_blank"
              color="white"
              icon="goodbye"
            >
              채용정보
            </Button>
          </SeemoreContainer>
        </TextArea>
      </RecruitmentWrapper>
    </CommonContainer>
  );
};

export default SectionWayWorking;
