import React from 'react';
import Button from '../../../components/Button';
import LoadInView from '../../../components/LoadInView';
import {MainContentCardWrapper,
        MainContentCardContainer,
        MainContentCard,
        } from '../styled';

const SectionOperationCard = (props) => {
    return (
        <MainContentCardWrapper>
            <MainContentCardContainer data-id="7" onClick={props.show_popup}>
                <LoadInView>
                    <MainContentCard>
                        <strong>SM 업무 재정의</strong>
                        <p>KB금융그룹 IT 업무영역 변화에 따라 가치 업무 중심의 IT파트너로 성장</p>
                        <Button type="primary" icon="plus" iconSize="13px 13px">자세히보기</Button>
                    </MainContentCard>
                </LoadInView>
            </MainContentCardContainer>
            <MainContentCardContainer data-id="8" onClick={props.show_popup}>
                <LoadInView>
                    <MainContentCard>
                        <strong>차별적 가치제공</strong>
                        <p>KB금융그룹 IT혁신을 리딩하며, DT를 주도하는 IT파트너로 성장</p>
                        <Button type="primary" icon="plus" iconSize="13px 13px">자세히보기</Button>
                    </MainContentCard>
                </LoadInView>
            </MainContentCardContainer>
            <MainContentCardContainer data-id="9" onClick={props.show_popup}>
                <LoadInView>
                    <MainContentCard>
                        <strong>안정적인 IT 서비스 제공</strong>
                        <p>KB금융그룹 IT품질 향상을 지원하는 IT파트너로 성장</p>
                        <Button type="primary" icon="plus" iconSize="13px 13px">자세히보기</Button>
                    </MainContentCard>
                </LoadInView>
            </MainContentCardContainer>
        </MainContentCardWrapper>
    )
};

export default SectionOperationCard;