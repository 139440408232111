import React from "react";
import Popup from "../../components/Popup/index";
import { CIPopupContent, CIPopupStyle, PopupCloseButton } from "./styled";

const CIPopup = (props) => {
  return (
    <Popup
      type="default"
      show={props.show}
      title="CI"
      summary="KB금융그룹의 심볼마크는
            별의 의미를 내포하는 Star-b의 심볼은 KB금융그룹의 미래 지향적인 모습과World-Class로 도약하는 높은 의지를 나타낸다."
      top="0%"
      onClose={props.onClose}
    >
      <CIPopupContent>
        <CIPopupStyle>
          <div>
            <div>
              <p>그룹 코퍼레이트 마크</p>
              <div>
                <div />
              </div>
            </div>
            <div>
              <div>
                <p>
                  KB데이타시스템 국문
                  <a
                    href="https://wp.daitso.kds.co.kr/wp-content/plugins/ep-manager/assets/images/common/kbds-ci-ko.ai"
                    target="blank"
                  >
                    <img
                      src={
                        "https://wp.daitso.kds.co.kr/wp-content/plugins/ep-manager/assets/images/common/icon_download.png"
                      }
                    />
                  </a>
                </p>
                <div>
                  <div />
                </div>
              </div>
              <div>
                <p>
                  KB데이타시스템 영문
                  <a
                    href="https://wp.daitso.kds.co.kr/wp-content/plugins/ep-manager/assets/images/common/kbds-ci-en.ai"
                    target="blank"
                  >
                    <img
                      src={
                        "https://wp.daitso.kds.co.kr/wp-content/plugins/ep-manager/assets/images/common/icon_download.png"
                      }
                    />
                  </a>
                </p>
                <div>
                  <div />
                </div>
              </div>
            </div>
          </div>
        </CIPopupStyle>
      </CIPopupContent>
      <PopupCloseButton>
        <a href="/#" onClick={props.onClose}>
          닫기
        </a>
      </PopupCloseButton>
    </Popup>
  );
};
export default CIPopup;
