import React from 'react';
import { CommonContainer } from '../../../../components/Grid';
import {ContentWrapper,
        FigureIcon,
        Blockquote,
        Img,
        PostContent,
        } from '../styled';

const SectionContent = (props) => {
    return (
        <ContentWrapper>
            <CommonContainer className="container-post_content">
                <CommonContainer type="small">
                    <PostContent dangerouslySetInnerHTML={{
                            __html: props.post_content
                        }}>
                    </PostContent>
                </CommonContainer>
            </CommonContainer>
        </ContentWrapper>
    )
};
export default SectionContent;