import React, { useEffect, useRef } from "react";
import styled from "styled-components";
// import Scene from "./Scene";
import LoadInView from "../LoadInView";

const FigureCardStyle = styled.div`
  display: inline-block;
  width: 50%;
  padding: 35px;
  position: relative;
  overflow: hidden;
  transition: 0.5s;
  &:nth-child(4),
  &:nth-child(3) {
    transform: translateX(60px);
  }
  &:nth-child(4) {
    transform: translate(60px, 80px);
  }

  &:nth-child(2),
  &:nth-child(6) {
    transform: translateY(80px);
  }

  & > span {
    display: block;
    font-size: 14px;
    width: 120px;
    color: #888;
    margin-top: 20px;
    text-align: center;
    transform: rotate(-90deg);
    position: absolute;
    left: -40px;
    top: 75px;
  }

  @media ${(props) => props.theme.laptop} {
    width: 50%;
    &:nth-child(4),
    &:nth-child(3) {
      transform: translateX(30px);
    }
    &:nth-child(4) {
      transform: translate(30px, 40px);
    }

    &:nth-child(2),
    &:nth-child(6) {
      transform: translateY(40px);
    }
  }
  @media ${(props) => props.theme.tablet} {
    width: 33.3%;
    &:nth-child(4),
    &:nth-child(3) {
      /* transform: translateX(30px); */
      transform: none;
    }
    &:nth-child(4) {
      /* transform: translate(30px, 40px); */
      transform: none;
    }

    &:nth-child(2),
    &:nth-child(5) {
      transform: translateY(60px);
    }
  }
  @media ${(props) => props.theme.mobile} {
    width: 240px;
    padding: 10px 0px;
    padding-right: 20px;

    &:nth-child(4),
    &:nth-child(3) {
      transform: none;
    }
    &:nth-child(4) {
      transform: none;
    }

    &:nth-child(2),
    &:nth-child(5),
    &:nth-child(6) {
      transform: none;
    }
    & > span {
      display: none;
    }
  }
  .tile {
    width: 100%;
    padding-bottom: 130%;
    margin-bottom: 0px;
    position: relative;
    /* border: 1px solid #eee; */
    border-radius: 0px 30px;
    /* transition: .5s; */
    -webkit-transition: background 1s, border-radius 1s;
    transition: background 1s, border-radius 1s;
    overflow: hidden;
    :hover {
      border-radius: 0px 30px;
    }
    /* overflow:hidden; */
  }
  .tile.slide--current {
    --x: 0;
    --y: 0;
    --d: 50;

    transition: transform 1000ms cubic-bezier(0.25, 1, 0.35, 1);
    opacity: 1;
    pointer-events: auto;
    user-select: auto;
    overflow: hidden;
    @media (hover: hover) {
      &:hover .slide__image-wrapper {
        transform: scale(1.1)
          translate(
            calc(var(--x) / var(--d) * 1px),
            calc(var(--y) / var(--d) * 1px)
          );
      }
      &:hover .tile__image {
        -webkit-filter: none !important;
        filter: none !important;
      }
    }
    .slide__image-wrapper:after {
      content: "";
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      transition: 0.5s;
      background: rgba(0, 0, 0, 0);
      /* border-radius: 0px 30px; */

      z-index: 1;
      position: absolute;
      width: 0;
      height: 100%;
      top: 0;
      transform: skewX(-45deg);
      left: -65%;
      transition: all 0.45s cubic-bezier(0.42, 0, 0.58, 1);
    }
    /* &:hover .slide__image-wrapper:after {
      width: 245%;
      left: -65%;
      background-color: #60584c;
      opacity: 0.8;
    } */
    /* &:hover .tile__content {
      opacity: 1;
    } */
    &:hover .slide__image-wrapper {
      filter: blur(5px);
      -webkit-filter: blur(5px);
    }

    .slide__image-wrapper:after {
      width: 245%;
      left: -65%;
      background-color: #60584c;
      opacity: 0.8;
    }
    .tile__content {
      opacity: 1;
    }
  }

  .slide__image-wrapper {
    /* border-radius: 0px 30px; */
    background-color: var(--color-accent);
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    transition: transform calc(800ms / 4) cubic-bezier(0.25, 1, 0.35, 1);
    transition: 0.25s;
  }

  .tile__image {
    --d: 20;
    border-radius: 0px 30px;
    max-width: 100%;
    pointer-events: none;
    opacity: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0px;
    left: 0px;

    -webkit-filter: grayscale(100%);
    filter: gray;
    transition: opacity 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.84),
      transform 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.84);
    user-select: none;
    @media (hover: hover) {
      .slide--current:hover & {
        transform: translate(
          calc(var(--x) / var(--d) * 1px),
          calc(var(--y) / var(--d) * 1px)
        );
      }
    }
    @media ${(props) => props.theme.mobile} {
      border: 2px solid #eee;
    }
  }

  .tile__content {
    opacity: 0;
    max-width: 100%;
    z-index: 9;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    color: #fff;
    padding: 29px;
    box-sizing: border-box;
    transition: 0.6s;
    h4 {
      margin-bottom: 15px;
      display: block;
      font-size: 20px;
      line-height: 36px;
    }
    span {
      display: block;
      margin-bottom: 15px;
      font-size: 14px;
    }
    p {
      font-size: 14px;
      line-height: 30px;
    }
  }
  @media ${(props) => props.theme.laptop} {
    .tile__content {
      padding: 30px;
      h4 {
        margin-bottom: 15px;
        display: block;
        font-size: 18px;
        line-height: 30px;
      }
      span {
        display: block;
        margin-bottom: 15px;
        font-size: 11px;
      }
      p {
        font-size: 11px;
        line-height: 25px;
      }
    }
  }
  @media ${(props) => props.theme.tablet} {
    .tile__content {
      padding: 30px;
      h4 {
        margin-bottom: 15px;
        display: block;
        font-size: 18px;
        line-height: 30px;
      }
      span {
        display: block;
        margin-bottom: 25px;
        font-size: 11px;
      }
      p {
        font-size: 12px;
        line-height: 25px;
      }
    }
  }
  @media ${(props) => props.theme.mobile} {
    .tile {
      border-radius: 0px !important;
    }
    .tile__image {
      border-radius: 0px;
    }
    .tile__content {
      border-radius: 0px !important;
      padding: 30px;
      z-index: 2;
      h4 {
        margin-bottom: 15px;
        display: block;
        font-size: 17px;
        line-height: 23px;
      }
      span {
        display: block;
        margin-bottom: 15px;
        font-size: 13px;
      }
      p {
        word-break: keep-all;
        font-size: 12px;
        line-height: 26px;
      }
    }
  }
`;

const FigureCard = (props) => {
  var scene = 0;
  const slide = useRef(null);
  useEffect(() => {
    // scene = new Scene(props.member.member_type+'_'+props.member.id);
  });

  const handleMouseMove = (event) => {
    const el = slide.current;
    const r = el.getBoundingClientRect();

    el.style.setProperty(
      "--x",
      event.clientX - (r.left + Math.floor(r.width / 2))
    );
    el.style.setProperty(
      "--y",
      event.clientY - (r.top + Math.floor(r.height / 2))
    );
  };

  const handleMouseLeave = (event) => {
    slide.current.style.setProperty("--x", 0);
    slide.current.style.setProperty("--y", 0);
  };

  const imageLoaded = (event) => {
    event.target.style.opacity = 1;
  };

  return (
    <FigureCardStyle>
      <LoadInView>
        <article
          className="tile slide--current"
          id={props.member.member_type + "_" + props.member.id}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          ref={slide}
        >
          <figure className="tile__figure slide__image-wrapper">
            <img
              src={props.member.image}
              className="tile__image"
              width="300"
              onLoad={imageLoaded}
            />
          </figure>
          <div className="tile__content">
            <h4>{props.member.title}</h4>
            <span>{props.member.name}</span>
            <p>{props.member.content}</p>
          </div>
        </article>
      </LoadInView>
      <span>{props.member.title}</span>
    </FigureCardStyle>
  );
};

export default FigureCard;
