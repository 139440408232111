import styled from 'styled-components';

/*
Board
*/
export const BoardTitle = styled.h2`
    margin-top: 100px;
    margin-bottom: 50px;
    text-align: center;
`;

export const BoardTableWrapper = styled.div`
    box-sizing: border-box;
    border-collapse: collapse;
    font-family: 돋움, Dotum, Tahoma, Geneva, sans-serif;
    display: inline-table;
    width: 100%;    
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;

    thead > tr > th{
        border-top: 3px solid #000;
        border-bottom: 1px solid #e0e0e0;
        padding: 18px 0;
        font-size: 14px;
        line-height: 13px;
        font-weight: 500;
        color: #424242;
    }

    th:nth-of-type(2n-1){
        width: 100px;
    }
    th:nth-of-type(2) > label{
        box-sizing: border-box;
        display: block;
        border-left: 1px solid #e0e0e0;
        border-right: 1px solid #e0e0e0;
    }
    tbody{
        td:nth-of-type(2){
            text-align: left;
            padding-left: 5px;
        }
    }
    tbody > tr {
        height: 50px;
        transition: .3s;
        :hover {
            background:#f8f8f8;
        }
    }
    tbody > tr > td {
        padding: 18px 0px;
        border-bottom: 1px solid #e0e0e0;
        font-size: 14px;
        font-weight: 500;
        color: #888;
    }
    tbody > tr > td > a {
        color:#111;
        transition: .2s;
        &:hover {
            opacity: .5;
        }
    }
    tbody > tr > td span {
        color: #30D6C2;
    }
`;

export const ButtonWrapper = styled.div`
    text-align: right;
    margin-bottom: 20px;
`;


export const BoardContainer = styled.div`
    max-width: 860px;
    margin: 0 auto;
    margin-bottom: 50px;
    width: 100%;
    input,textarea {
        margin-bottom:20px;
    }
`;
export const SingleSubject = styled.h3`
    padding: 18px 0;
    border-bottom: 1px solid #eee;
`;
export const SingleMeta = styled.div`
    padding-top:15px;
    font-size:14px;
    color: #666;
    span:last-child {
        float:right;
    }
`;
export const SingleContent = styled.div`
    padding: 30px 0;
    border-bottom: 1px solid #eee;
    .wp-block-file {
        padding:15px;
        background:#f7f7f7;
        margin-bottom:15px;
        a {
            color:#666;
            font-size:15px;
            line-height:40px;
            text-decoration: none;
            transition: .3s;
            :hover {
                opacity: .5;
            }
        }
        .wp-block-file__button {
            float:right;
            line-height:normal;
            padding:10px;
            background:#fff;
            color:#000;
        }
        :after{
            content:'';
            display:block;
            clear:both;
        }
    }
    p {
        margin-bottom: 15px;
    }
`;
export const SingleButtons = styled.div`
    a {
        margin:12px 0px;
        margin-left:12px;
        float:right;
    }
    a:first-child {
        float:none;
        margin-left:0px;
    }
`;

/*
Comments
*/
export const CommentsWrapper = styled.div`
    border-bottom: 1px solid #eee;
    padding-top: 20px;
`;
export const CommentForm = styled.div`
    position: relative;
    & > a {
        position: absolute;
        top:20px;
        right:20px;
    }
`;

export const CommentLoop = styled.div`
    border-top: 1px solid #eee;
    padding: 20px 0px;
`;
export const CommentLoopWrapper = styled.div`
    .empty {
        margin-top:15px;
        font-size:13px;
        color:#888;
    }
`;
export const CommentLable = styled.div`
    font-size:13px;
    border-bottom: 1px solid #eee;
    padding-bottom:20px;
`;
export const CommentItem = styled.div`
    border-bottom: 1px solid #eee;
    padding: 20px 0px;
    position: relative;
`;
export const CommentItemMeta = styled.div`
    margin-bottom:15px;
    h5 {
        font-size:14px;
    }
    span {
        position: absolute;
        top: 20px;
        right:0px;
        color: #888;
        font-size: 12px;
    }
`;
export const CommentItemContent = styled.div`
    font-size:14px;
`;