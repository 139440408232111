import React from 'react';
import { CommonContainer } from '../../../components/Grid';
import {AboutContent,
} from '../styled';

const AboutContnet = (props) => {
    return (
        <CommonContainer>
            <AboutContent>
                <h2>소개</h2>
                <>
                    <p>
                        {props.user.description}
                    </p>
                </>
            </AboutContent>
            {/* <AboutContentBadge>
                <Badge type="round">헤비메탈</Badge>
                <Badge type="round">영화</Badge>
                <Badge type="round">소설</Badge>
            </AboutContentBadge> */}
        </CommonContainer>
    )
};

export default AboutContnet;