import React from "react";
import Button from "../../../components/Button";
import { Br } from "../../../components/Grid";
import LoadInView from "../../../components/LoadInView";
import {
  DigitalCard,
  MainContentCardWrapper,
  MainContentCard,
  MainContentCardContainer,
} from "../styled";

const SectionDigitalCrad = (props) => {
  return (
    <DigitalCard>
      <MainContentCardWrapper>
        <MainContentCardContainer data-id="1" onClick={props.show_popup}>
          <LoadInView>
            <MainContentCard>
              <strong>
                그룹 Cloud 의 통합 운영으로
                <Br />
                DT 지원의 효율화 추구
              </strong>
              <p>
                KB금융그룹의 Cloud 자원, 거버넌스 등을 하나로 통합 운영,
                효율적인 One-stop 서비스 제공
              </p>
              <Button type="primary" icon="plus" iconSize="13px 13px">
                자세히보기
              </Button>
            </MainContentCard>
          </LoadInView>
        </MainContentCardContainer>
        <MainContentCardContainer data-id="2" onClick={props.show_popup}>
          <LoadInView>
            <MainContentCard>
              <strong>AI기술 컨설팅부터 사업화까지 그룹의 AI MSP 지향</strong>
              <p>
                생성형AI를 비롯한 AI 기술 연구, PoC 진행, 비즈니스 구현,
                운영까지 모든 영역 지원하는 역할 지향
              </p>
              <Button type="primary" icon="plus" iconSize="13px 13px">
                자세히보기
              </Button>
            </MainContentCard>
          </LoadInView>
        </MainContentCardContainer>
        <MainContentCardContainer data-id="3" onClick={props.show_popup}>
          <LoadInView>
            <MainContentCard>
              <strong>
                자체 기술력으로 제품개발,
                <Br />
                역량 기반 디지털 사업 확대
              </strong>
              <p>
                자체 개발한 디지털자산을 기반으로 SaaS 방식을 포함한 다양한
                방식으로 사업 확대
              </p>
              <Button type="primary" icon="plus" iconSize="13px 13px">
                자세히보기
              </Button>
            </MainContentCard>
          </LoadInView>
        </MainContentCardContainer>
      </MainContentCardWrapper>
    </DigitalCard>
  );
};

export default SectionDigitalCrad;
