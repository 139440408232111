import React from 'react';
import MainContainer from '../../../components/MainContainer';
import SectionMain from './components/SectionMain';
import SectionContent from './components/SectionContent';
import LoadInView from '../../../components/LoadInView';
import {CustomerWrapper} from './styled';


const CustomerPolicy = () => {
    return (
        <MainContainer>
            <CustomerWrapper>
                <SectionMain/>
                <LoadInView>
                    <SectionContent/>
                </LoadInView>
            </CustomerWrapper>
        </MainContainer>
    );
};  

export default CustomerPolicy;