import styled from "styled-components";
import { ColWrap, RowWrap } from "../../components/Grid/styled";

export const PeopleWrapper = styled.div`
  margin: 0 auto;
  overflow: hidden;
  @media ${(props) => props.theme.mobile} {
  }
`;

export const Top = styled.div`
  margin: 0 auto 150px auto;
  h3 {
    font-family: "KBFG Display";
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #ffbc00;
    text-align: center;
    margin-bottom: 22px;
  }
  div {
    font-family: "KBFG Display";
    font-weight: 600;
    font-size: 42px;
    line-height: 55px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #000000;
    margin-bottom: 30px;
    white-space: keep-all;
  }
  p {
    position: relative;
    z-index: 1;
    font-family: "KBFG Text";
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.03em;
    color: #838383;
    word-break: keep-all;
  }
  .main-bg {
    position: relative;
    margin-top: 100px;
  }
  .main-bg.bg-01 {
    position: relative;
    margin-top: 100px;
    /* top: -45px; */
    :after {
      position: absolute;
      display: block;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        360deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 80%
      );
    }
  }
  .main-bg-skton {
    position: relative;
    top: 145px;
    height: 557px;
    background-image: url("/video-thumbnail.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
  }
  .content {
    position: absolute;
    z-index: 1;
    width: max-content;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
  }

  .main-bg-video {
    position: relative;
    width: 100%;
    height: 871px;
    z-index: -1;
    object-fit: cover;
  }

  @media ${(props) => props.theme.mobile} {
    .mobile-mt0 {
      margin-top: 0 !important;
    }
    /* margin-top: 60px; */
    margin-bottom: 60px;
    h3 {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 10px;
    }
    div {
      font-size: 22px;
      line-height: 160%;
      margin-bottom: 14px;
    }
    p {
      max-width: 400px;
      margin: 0 auto;
      font-weight: 500;
      font-size: 12px;
      line-height: 160%;
      br {
        display: none;
      }
    }
    .content {
      width: 100%;
      top: 60px;
      padding: 0 10px;
    }
    .main-bg-video {
      height: 500px;
      /* padding-bottom: 55%; */
      top: 0;
    }
    .main-bg-skton {
      top: 0;
      height: 500px;
      background: #fff;
    }
    .main-bg {
      :after {
        background: linear-gradient(
          360deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 63%
        );
      }
    }
  }
`;

export const Culture = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1292px;
  width: 100%;
  margin: 0 auto 120px auto;
  padding: 0 20px;
  .left {
    width: 49.7%;
    padding-right: 20px;
    h4 {
      font-family: "KBFG Display";
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
      letter-spacing: 0.01em;
      color: #ffbc00;
      margin-bottom: 39px;
    }
    div {
      font-family: "KBFG Display";
      font-style: normal;
      font-weight: 600;
      font-size: 42px;
      line-height: 55px;
      letter-spacing: 0.01em;
      color: #000000;
      margin-bottom: 25px;
      white-space: nowrap;
    }
    .desc {
      font-family: "KBFG Text";
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 27px;
      letter-spacing: 0.01em;
      color: #878787;
      margin-bottom: 43px;
      flex-wrap: wrap;
      white-space: break-spaces;
    }
  }
  .right {
    flex: 0 1 611px;
    .youtube {
      opacity: 0;
      position: absolute;
    }
    .tb {
      position: relative;
      /* width: 611px; */
      padding-bottom: 68.57%;
      /* background-image: url("/people-culture.png"); */
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      overflow: hidden;
      cursor: pointer;
      :before {
        display: block;
        position: absolute;
        content: "";
        top: 50%;
        left: 50%;
        width: 140px;
        height: 140px;
        transform: translateX(-50%) translateY(-50%);
        background-image: url("https://wp.daitso.kds.co.kr/wp-content/plugins/ep-manager/assets/images/common/icon-play.svg");
        background-size: cover;
        z-index: 3;
        pointer-events: none;
        transition: all 0.3s ease 0s;
      }
      :hover {
        :after {
          transform: scale(1.06);
        }
      }
      :after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: inherit;
        background-size: cover;
        transform-origin: center;
        transition: transform 0.4s ease-in-out;
      }
    }
  }
  @media ${(props) => props.theme.mobile} {
    display: block;
    margin-bottom: 112px;
    .left {
      width: 100%;
      h4 {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 10px;
      }
      div {
        font-size: 22px;
        line-height: 35px;
      }
    }
    .right {
      a {
        margin-top: 20px;
        float: right;
      }
      .tb {
        :before {
          width: 62px;
          height: 62px;
        }
      }
    }
  }
`;

export const KBDSLive = styled.div`
  margin: 0 auto 0 auto;
  padding: 120px 0 120px 0;
  background-color: #f7f7f7;
  .container {
    max-width: 1292px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      position: relative;
      top: 9px;
    }
  }
  h4 {
    font-family: "KBFG Display";
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #ffbc00;
    margin-bottom: 25px;
  }
  .desc {
    font-family: "KBFG Display";
    font-weight: 600;
    font-size: 36px;
    line-height: 160%;
    color: #000000;
    margin-bottom: 25px;
  }
  p {
    font-family: "KBFG Text";
    font-weight: 500;
    font-size: 17px;
    line-height: 27px;
    color: #878787;
    margin-bottom: 51px;
  }
  .box {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    .card {
      position: relative;
      flex: calc(50% - 15px);
      height: 404px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 0 30px 0 30px;
      overflow: hidden;
      padding: 51px 0 0 41px;
      cursor: pointer;
      :after {
        position: absolute;
        display: block;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.26);
      }
      :hover {
        :before {
          transform: scale(1.06);
        }
      }
      :before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: inherit;
        background-size: cover;
        transform-origin: center;
        transition: transform 0.4s ease-in-out;
      }
    }

    /* .card:nth-child(1) {
      background-image: url("/people-live-01.png");
    }
    .card:nth-child(2) {
      background-image: url("/people-live-03.png");
    }
    .card:nth-child(3) {
      background-image: url("/people-live-04.png");
    }
    .card:nth-child(4) {
      background-image: url("/people-live-02.png");
    } */

    .desc {
      position: relative;
      font-family: "KBFG Display";
      font-weight: 600;
      font-size: 30px;
      line-height: 45px;
      letter-spacing: 0.01em;
      color: #ffffff;
      z-index: 1;
      margin-bottom: 10px;
      word-break: keep-all;
    }
    .post-date {
      position: relative;
      font-family: "KBFG Display";
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 160%;
      letter-spacing: 0.01em;
      color: #ffffff;
    }
    .btn {
      position: relative;
      z-index: 1;
      width: 43px;
      height: 43px;
      background-image: url("/play-btn-trans.png");
      background-size: contain;
    }
  }
  @media ${(props) => props.theme.mobile} {
    padding: 60px 0 60px 0;
    margin: 0 auto 60px auto;

    h4 {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 10px;
    }
    .flex {
      .desc {
        font-size: 22px;
        line-height: 35px;
        margin-bottom: 10px;
      }
    }
    p {
      font-size: 12px;
      line-height: 19px;
      margin-bottom: 34px;
    }
    .box {
      gap: 16px;
      .card {
        flex: 100%;
        height: 205px;
        padding: 25px 0 0 25px;
      }
      .desc {
        font-size: 18px;
        line-height: 25px;
      }
      .btn {
        width: 32px;
        height: 32px;
      }
    }
    .container {
      a {
        margin-top: 20px;
        float: right;
      }
    }
  }
`;

export const KBDSYoutube = styled.div`
  /* border: 10px solid skyblue; */
  margin: 150px auto 0 auto;
  padding: 0 0 0 0;
  /* background-color: #f7f7f7; */
  .container {
    max-width: 1292px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      position: relative;
      top: 9px;
    }
  }
  h4 {
    font-family: "KBFG Display";
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #ffbc00;
    margin-bottom: 25px;
  }
  .desc {
    font-family: "KBFG Display";
    font-weight: 600;
    font-size: 36px;
    line-height: 160%;
    color: #000000;
    margin-bottom: 25px;
  }
  p {
    margin-bottom: 40px;
    span {
      display: inline-block;
      padding: 13px 24px;
      background: #f0f0f0;
      border-radius: 27.8294px;
      font-family: "KBFG Display";
      font-weight: 500;
      font-size: 18.263px;
      line-height: 160%;
      color: #000000;
      margin-right: 14px;
      cursor: pointer;
      transition: 0.3s;
      :hover {
        color: #f0f0f0;
        background-color: #000;
      }
    }
  }
  .youtube-category-active {
    color: #f0f0f0;
    background-color: #000;
  }
  @media ${(props) => props.theme.mobile} {
    margin-top: 100px;
    margin-bottom: 92px;
    h4 {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 10px;
    }
    .flex {
      div {
        font-size: 22px;
        line-height: 35px;
      }
    }
    .desc {
      margin-bottom: 14px;
    }
    p {
      span {
        font-size: 12px;
        line-height: 19px;
        padding: 7px 13px 6px 13px;
        margin-right: 8px;
      }
    }
    .container > a {
      float: right;
      margin-top: 0;
    }
  }
`;

export const SlickWrapper = styled.div`
  /* border: 2px solid green; */
  width: calc(50vw + 50%);
  height: 1000px;
  background-color: tomato;

  .slick-item {
    background-color: gray;
    /* width: 100% !important; */
    height: 300px;
    padding: 50px;
    /* border: 10px solid pink; */
    /* margin-right: 100px; */
  }
  .slick-dots li {
    width: 76px;
  }
  .slick-dots li button {
    width: 76px;
    height: 42px;
  }
  .slick-slide {
    /* max-width: 1252px; */

    /* float: left; */
    height: 300px;
    /* min-height: 1px; */
    margin: 0px 10px;
  }
  .slick-list {
    margin: 0px -10px;
  }
  /* .slick-slide {
    margin: 0 30px;
  }
  .slick-list {
    margin: 0 -30px;
  } */
  .slick-list {
    /* width: 100vw; */
  }
  .slick-dots li button:before {
    display: block;
    width: 76px;
    height: 2px;
    font-size: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #000;
  }
`;

export const KBDSStory = styled.div`
  margin: 0 auto 0 auto;
  /* padding: 120px 0 40px 0; */
  padding-top: 120px;
  background-color: #fff;
  .container {
    max-width: 1292px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      position: relative;
      top: 9px;
    }
  }
  h4 {
    font-family: "KBFG Display";
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #ffbc00;
    margin-bottom: 25px;
  }
  .desc {
    font-family: "KBFG Display";
    font-weight: 600;
    font-size: 36px;
    line-height: 160%;
    color: #000000;
  }
  p {
    font-family: "KBFG Text";
    font-weight: 500;
    font-size: 17px;
    line-height: 27px;
    color: #878787;
    margin-bottom: 51px;
  }
  @media ${(props) => props.theme.mobile} {
    padding: 60px 0 40px 0;
    margin: 0 auto 0 auto;
    h4 {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 10px;
    }
    .desc {
      font-size: 22px;
      line-height: 35px;
    }
    .flex {
      display: block;
    }
    > a {
      float: right;
      position: relative;
      top: -40px;
      right: 20px;
      z-index: 1;
    }
  }
`;

export const YoutubeSliderWrap = styled.div`
  /* height: 868px; */
  overflow: hidden;
  z-index: 1;
  position: relative;
  width: 100vw;

  /* @media ${(props) => props.theme.mobile} {
    background: #f8f8f8;
    ${(props) => (props.type ? `padding-top:90px;` : ``)}
  } */
`;

export const MainSliderWrapper = styled.div`
  position: relative;
  height: 837px;

  .mt0 {
    margin-top: 0 !important;
  }

  :after {
    content: "";
    display: block;
    clear: both;
  }

  .mainSlider1 {
    position: relative;
  }
  .mainSlider2 {
    margin-bottom: 0 !important;
  }
  div:has(.mainSlider2) {
    margin-bottom: 0 !important;
  }
  .video-area {
    position: relative;
    width: 100%;
    height: 871px;
    z-index: -1;
    object-fit: cover;
  }
  .video-area-height {
    position: relative;
    width: 100%;
    height: 971px;
    z-index: -1;
    object-fit: cover;
  }
  @media ${(props) => props.theme.mobile} {
    height: 500px;
    .video-area {
      width: 100%;
      height: 500px;
    }
    .video-area-height {
      width: 100%;
      height: 500px;
    }
  }
`;
export const RecruitmentWrapper = styled.div`
  margin-top: 120px;
  .people-recruitment-container > div > div {
    height: 650px;
  }
  /* padding-top: 150px; */
  @media ${(props) => props.theme.laptop} {
    /* padding-top: 140px; */
  }
  @media ${(props) => props.theme.tablet} {
    /* padding-top: 100px; */
  }
  @media ${(props) => props.theme.mobile} {
    margin-top: 50px;
    padding-top: 0px;
    position: relative;
    .people-recruitment-container > div > div {
      height: 230px;
    }
  }
`;

export const RecruitVideoWrapper = styled.div`
  position: relative;
  top: 25%;
  height: 0;
  padding-bottom: 56.25%;
  z-index: 10;
  div {
    width: 100%;
    padding-bottom: 56.25%;
    background-image: url(${(props) => props.theme.image_url}/common/${(
      props
    ) => props.bg});
    background-size: cover;
    background-position: center center;
  }
  iframe {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
  :after {
    display: block;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0, 0.3);
    cursor: pointer;
  }
  :before {
    display: block;
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    transform: translateX(-50%) translateY(-50%);
    /* background-image: url(${(props) =>
      props.theme.image_url}/common/icon-play.svg); */
    background-size: cover;
    z-index: 3;
    cursor: pointer;
  }
`;

export const RecruitmentImg = styled.div`
  position: relative;
  background-image: url("/people-recruit-img-reverse.jpg");
  /* background-image: url(${(props) =>
    props.theme.image_url}/common/Recruite_bg.png); */
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  :after {
    display: block;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0, 0.4);
    z-index: 1;
  }

  @media ${(props) => props.theme.laptop} {
    background-attachment: scroll;
    background-size: auto 150%;
    background-repeat: no-repeat;
    background-position: 40% 100%;
  }

  @media ${(props) => props.theme.tablet} {
    ${RowWrap} {
      display: block;
    }
    ${ColWrap}:last-child {
      padding-bottom: 100px;
    }
  }

  @media ${(props) => props.theme.mobile} {
    background-size: auto 230%;
    background-repeat: no-repeat;
    background-position: 40% 100%;
  }
  @media (max-width: 360px) {
    background-size: auto 120%;
    background-repeat: no-repeat;
    background-position: 40% 100%;
  }
`;

export const RecruitContainer = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 20px;
  ${RowWrap} {
    @media ${(props) => props.theme.tablet} {
      display: block;
    }
  }
  ${ColWrap} {
    @media ${(props) => props.theme.tablet} {
      display: block;
    }
  }
  @media ${(props) => props.theme.tablet} {
    padding: 0 50px;
  }
  @media ${(props) => props.theme.mobile} {
    padding: 0 20px;
  }
`;

export const Recruitment = styled.div`
  position: relative;
  font-style: normal;
  padding-top: 250px;
  padding-bottom: 300px;
  /* padding-right: 40px; */
  text-align: left;
  z-index: 5;
  h4 {
    font-family: "KBFG Display";
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: #ffcc00;
    text-shadow: 0px 2px 20px rgba(0, 0, 0, 0.25);
    margin-bottom: 10px;
  }
  p {
    font-family: "KBFG Display";
    font-weight: 700;
    font-size: 38px;
    line-height: 160%;
    color: #ffffff;
    text-shadow: 0px 2px 20px rgba(0, 0, 0, 0.25);
    margin-bottom: 30px;
  }
  a {
    font-family: "KBFG Text";
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
    margin-left: 20px;
    i::before {
      height: 32px;
      width: 32px;
    }
    i::after {
      top: -2px;
      right: -1px;
    }
  }
  /* p:nth-of-type(1){
        padding-right: 280px;
        padding-left: 280px;
    } */
  @media ${(props) => props.theme.laptop} {
    p {
      font-size: 30px;
    }
  }
  @media ${(props) => props.theme.tablet} {
    margin-top: 0px;
    padding-top: 110px;
    padding-bottom: 40px;
    padding-right: 0;
    h4 {
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    }
    p {
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      margin-bottom: 40px;
      font-size: 36px;
    }
    a {
      padding-right: 10px;
      margin-bottom: 60px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    /* p:nth-of-type(1){
            padding-right: 20px;
            padding-left: 20px;
        }   */
    padding-top: 60px;
    padding-bottom: 0px;
    h4 {
      font-size: 16px;
      line-height: 20px;
    }
    p {
      font-size: 18px;
      line-height: 160%;
      text-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
      margin-bottom: 27px;
      span {
        display: block;
      }
    }
    a {
      padding-right: 0px;
      font-size: 14px;
      line-height: 23px;
      margin-left: 65%;
      margin-bottom: 37px;
    }
  }
`;

//Recruitment end

export const OverviewFigureTop = styled.div`
  position: absolute;
  top: -200px;
  right: -168px;

  z-index: -1;
  /* transition: 3s; */
  div {
    position: relative;
    width: 482px;
    height: 482px;
    border: 1px dashed #ffbc00;
    box-sizing: border-box;
    border-radius: 50%;
    /* transition: 3s; */
  }
  @media ${(props) => props.theme.mobile} {
    top: -102px !important;
    right: -99px !important;
    div {
      height: 200px;
      width: 200px;
    }
  }
`;
export const OverviewFigureBottom = styled.div`
  position: absolute;
  bottom: 227px;
  left: -187px;

  z-index: -1;
  /* transition: 3s; */
  div {
    position: relative;
    width: 482px;
    height: 482px;
    border: 1px dashed #ffbc00;
    box-sizing: border-box;
    border-radius: 50%;
    /* transition: 3s; */
  }
  @media ${(props) => props.theme.mobile} {
    bottom: 100px !important;
    left: -100px !important;
    div {
      height: 200px;
      width: 200px;
    }
  }
`;
export const RecruitButton = styled.a`
  display: inline-block;
  width: auto;
  position: relative;
  transition: 0.2s;
  font-family: "KBFG Text";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  color: #000;
  text-decoration: none;
  background: none;
  cursor: pointer;
  ${(props) => {
    var out = ``;
    switch (props.type) {
      case "primary": // primary 버튼일때
        out += `
                    border: none;
                    line-height:35px;
                    height:35px;
                    span {
                        position:relative;
                        z-index:2;
                    }
                    i {
                        display:inline-block;
                        vertical-align:middle;
                        margin-left: 8px;
                        border-radius: 50%;
                        width: 35px;
                        height: 35px;
                        trasition: .3s;
                        :after {
                            display: inline-block;
                            content: "";
                            position: absolute;
                            right: 0px;
                            top: 0px;
                            background-image: url(${
                              props.theme.image_url
                            }/common/icon-${props.icon}.svg);
                            background-size: ${
                              props.iconSize ? props.iconSize : "18px 18px"
                            };
                            background-repeat: no-repeat;
                            background-position: center center ;
                            width: 35px;
                            height: 35px;
                        }
                        :before {
                            content: '';
                            right: 0px;
                            top: 0px;
                            display: block;
                            position: absolute;
                            width: 35px;
                            height:35px;
                            background: #f7f7f7;
                            border-radius: 50%;
                            transition:.3s;
                        }
                    }
                    .load-in-view:hover &,
                    .section:hover &,
                    div:hover > & {
                        color:#000;
                        i {
                            :before {
                                // top: -3px;
                                display: block;
                                width: 120%;
                                // height:40px;
                                background: #FFBC00;
                                border-radius: 50px;
                            }
                        }
                    }
                    .load-in-view:hover &:hover,
                    .section:hover &:hover,
                    div:hover > &:hover
                    &:hover {
                        color:#000;
                        i {
                            :before {
                                // top: -3px;
                                display: block;
                                width: 120%;
                                // height:40px;
                                background: #f7f7f7;
                                border-radius: 50px;
                            }
                        }
                    }

                    @media ${(props) => props.theme.tablet} {
                      position:absolute;
                      right: 0;
                        font-size: 15px;
                    }
                `;
        if (props.color === "white")
          out += `
                    color: #fff; 
                    i{
                        :before {
                            background: #fff;
                        }
                    }
                 `;
        if (props.color === "bg-white")
          out += `
                    color: #000;
                    i{
                        :before {
                            background: #fff;
                        }
                    }
                    .section:hover &,
                    div:hover > &,
                    &:hover {
                        color:#000;
                        i {
                            :before {
                                background: #fff;
                            }
                        }
                    }
                 `;
        break;
      default:
    }
    return out;
  }}
`;
