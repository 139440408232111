import styled from "styled-components";

export const FooterWrapper = styled.div`
  box-sizing: border-box;
  padding-bottom: 35px;
  border-top: 1px solid #eeeeee;
`;

export const FooterContainer = styled.div`
  margin: 0 auto;
  max-width: 1252px;
  @media ${(props) => props.theme.laptop} {
    padding-right: 70px;
    padding-left: 70px;
  }
  @media ${(props) => props.theme.tablet} {
    padding-right: 50px;
    padding-left: 50px;
  }
  @media ${(props) => props.theme.mobile} {
    padding-right: 15px;
    padding-left: 15px;
  }
`;

export const FooterMenu = styled.div`
  margin-top: 65px;
  margin-bottom: 0px;
  & > ul {
    display: inline-block;
  }
  & > ul li {
    float: left;
    margin-right: 41px;
  }
  & > ul a {
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    color: #bfbfbf;
    text-decoration: none;

    :focus {
      outline: none;
    }
  }
  .privacy {
    a {
      color: #034ea2;
      font-weight: 600;
    }
  }
  :after {
    content: "";
    display: block;
    clear: both;
  }
  @media ${(props) => props.theme.tablet} {
  }
  @media ${(props) => props.theme.mobile} {
    margin-top: 40px;
    & > ul li {
      padding-bottom: 30px;
      margin-right: 19px;
    }
    & > ul li:nth-of-type(3) {
      margin-right: 0px;
    }
    & > ul a {
      font-size: 14px;
      line-height: 22px;
    }
  }
`;

export const NetworkSite = styled.button`
  position: relative;
  background: #fff;
  border: 1px solid #eee;
  font-family: "KBFG Display";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  color: #bababa;
  float: right;
  padding: 14px 30px 13px 30px;
  img {
    margin-left: 9px;
    padding-bottom: 8px;
    position: relative;
    top: 3px;
  }
  ul {
    ${(props) => (props.on ? "display:block;" : "display:none;")}
    position: absolute;
    bottom: 46px;
    z-index: 9;
    left: 0;
    width: 100%;
    height: 243px;
    overflow-y: scroll;
    overflow-x: hidden;
    border: 1px solid #eee;
    li {
      float: none;
      margin-right: 0px;
      text-align: left;
      a {
        display: block;
        height: 49px;
        border-bottom: 1px solid #eee;
        line-height: 49px;
        background: #fff;
        color: #666;
        font-size: 13px;
        text-decoration: none;
        transition: 0.4s;
        padding: 0px 30px;
        :hover {
          background: #f7f7f7;
        }
      }
      &:last-child a {
        border-bottom: none;
      }
    }
  }

  @media ${(props) => props.theme.tablet} {
    margin-right: 50px;
  }
  @media ${(props) => props.theme.mobile} {
    float: left;
    margin-bottom: 27px;
  }
`;

export const Copyright = styled.div`
  font-family: "KBFG Display";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 19px;
  color: #bfbfbf;
  @media ${(props) => props.theme.tablet} {
  }
  @media ${(props) => props.theme.mobile} {
    font-size: 12px;
    line-height: 20px;
    color: #888888;
  }
`;
