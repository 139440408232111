import React from "react";
import { Br, Col, CommonContainer, Row } from "../../../components/Grid";
import LoadInView from "../../../components/LoadInView";
import {
  MissionBackGround,
  MissionBox,
  MissionInner,
  MissionItem,
  MissonWrapper,
  SubTitle,
} from "../styled";

const SectionMission = () => {
  return (
    <MissonWrapper>
      <CommonContainer>
        {/* <SectionTitle>
                    <small>미션 & 비전</small>
                    우리의 목표와 미래
                </SectionTitle> */}
      </CommonContainer>
      <CommonContainer type="wide">
        <MissionBackGround>
          <MissionBox>
            <MissionInner>
              <div>
                <LoadInView>
                  <h4>
                    <small>MISSON</small>
                    <span>
                      <strong>세상을 바꾸는 금융</strong> 고객의 행복과 <Br />더
                      나은 세상을 만들어 갑니다
                    </span>
                  </h4>
                </LoadInView>
              </div>
              <div>
                <LoadInView>
                  <h4>
                    <small>VISION</small>
                    <span>
                      최고의 인재와 담대한 혁신으로 가장 <Br />
                      신뢰받는 <strong>금융IT 파트너</strong>
                    </span>
                  </h4>
                </LoadInView>
              </div>
            </MissionInner>
          </MissionBox>
        </MissionBackGround>
        <CommonContainer>
          <SubTitle>핵심가치</SubTitle>
        </CommonContainer>
      </CommonContainer>
      <CommonContainer>
        <MissionItem>
          <Row>
            <Col flex="1.5">
              <LoadInView>
                <strong>
                  <span>01</span>고객중심
                </strong>
                <p>
                  고객의 입장과 이익을 최우선으로
                  <Br /> 고려하여판단하고 의사결정을 함
                </p>
              </LoadInView>
            </Col>
            <Col flex="1.5">
              <LoadInView>
                <strong>
                  <span>02</span>전문성
                </strong>
                <p>
                  업계 최고 수준의 역량을 갖춘 금융인을
                  <Br /> 목표로 끊임없이 노력함
                </p>
              </LoadInView>
            </Col>
            <Col flex="1">
              <LoadInView>
                <strong>
                  <span>03</span>혁신주도
                </strong>
                <p>
                  미래금융의 ‘First Mover’로서 창의적인 사고와 과감한 도전을
                  통해 변화와 혁신을 주도함
                </p>
              </LoadInView>
            </Col>
          </Row>
          <Row>
            <Col flex="1.5">
              <LoadInView>
                <strong>
                  <span>04</span>신뢰정직
                </strong>
                <p>
                  윤리의식을 바탕으로 정직과
                  <Br /> 신의성실의 자세를 항상 견지함
                </p>
              </LoadInView>
            </Col>
            <Col flex="1.5">
              <LoadInView>
                <strong>
                  <span>05</span>동반성장
                </strong>
                <p>
                  개인과 조직의 발전을 넘어, 국민과
                  <Br /> 함께 성장하고 사회발전에 기여함
                </p>
              </LoadInView>
            </Col>
            <Col></Col>
          </Row>
        </MissionItem>
      </CommonContainer>
    </MissonWrapper>
  );
};

export default SectionMission;
