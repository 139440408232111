import styled from "styled-components";
import { ColWrap, RowWrap } from "../../components/Grid/styled";

export const TitleWrapper = styled.div`
  margin-top: 105px;
  @media ${(props) => props.theme.mobile} {
    margin-top: 45px;
  }
`;

export const Path = styled.div`
  font-family: "KBFG Text";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  color: #ffac00;
  margin-bottom: 32px;
  span {
    font-family: "KBFG Text";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #999999;
    :after {
      content: ">";
      margin: 0 14px;
    }
  }
  @media ${(props) => props.theme.laptop} {
    padding-left: 20px;
  }
  @media ${(props) => props.theme.tablet} {
    padding-left: 0px;
    font-size: 15px;
    line-height: 19px;
    margin-bottom: 25px;
    span {
      font-size: 15px;
      line-height: 19px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    font-size: 13px;
    line-height: 19px;
    margin-bottom: 15px;
    span {
      font-size: 13px;
      line-height: 19px;
    }
  }
`;

export const MainTitle = styled.div`
  margin-bottom: 40px;
  h2 {
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 62px;
    color: #1a1a1a;
  }
  @media ${(props) => props.theme.laptop} {
    padding-left: 20px;
  }
  @media ${(props) => props.theme.tablet} {
    padding-left: 0px;
    h2 {
      margin-bottom: 50px;
      font-size: 50px;
      line-height: 72px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    margin-bottom: 20px;
    h2 {
      margin-bottom: 25px;
      font-size: 34px;
      line-height: 35px;
    }
  }
`;

export const SectionTitle = styled.h3`
  margin-bottom: 20px;
  display: block;
  font-family: "KBFG Display";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 160%;
  color: #000000;
  small {
    display: block;
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 25px;
    color: #ffbc00;
  }
  @media ${(props) => props.theme.tablet} {
  }
  @media ${(props) => props.theme.mobile} {
    font-size: 22px;
    line-height: 140%;
    small {
      line-height: 26px;
    }
  }
`;

export const MainTitleImg = styled.div`
  height: 420px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
  :after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background: rgba(0, 0, 0, 0.1);
  }
  @media ${(props) => props.theme.laptop} {
    background-attachment: scroll;
  }
  @media ${(props) => props.theme.tablet} {
    height: 480px;
    background-position: center center;
  }
  @media ${(props) => props.theme.mobile} {
    height: 230px;
    border-bottom: 15px solid #eee;
    background-position: center center;
  }
`;

//Title end

export const DxWrapper = styled.div`
  padding-top: 150px;

  @media ${(props) => props.theme.laptop} {
    padding-top: 140px;

    ${SectionTitle} {
      padding-left: 20px;
    }
  }
  @media ${(props) => props.theme.tablet} {
    ${SectionTitle} {
      padding-left: 0px;
    }
    padding-top: 100px;
  }
  @media ${(props) => props.theme.mobile} {
    padding-top: 90px;
  }
`;

export const DxFrame = styled.div`
  margin-top: 40px;
  @media ${(props) => props.theme.laptop} {
  }
  @media ${(props) => props.theme.tablet} {
    padding: 0 50px;
  }
  @media ${(props) => props.theme.mobile} {
    margin-top: 30px;
    padding: 0 0;
  }
`;

export const DXVideoWrapper = styled.div`
  height: 580px;
  position: relative;
  overflow: hidden;
  div {
    width: 100%;
    height: 300px;
    background-image: url(${(props) => props.theme.image_url}/common/${(
      props
    ) => props.bg});
    background-size: cover;
    background-position: center center;
  }
  video {
    width: 100%;
    height: 320%;
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
    display: block;
  }
  iframe {
    width: 100%;
    height: 320%;
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
    display: block;
  }
  &:after {
    display: block;
    content: "";
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
  }
  :before {
    display: block;
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    transform: translateX(-50%) translateY(-50%);
    background-image: url(${(props) =>
      props.theme.image_url}/common/icon-play.svg);
    background-size: cover;
    z-index: 3;
    pointer-events: none;
  }
  @media ${(props) => props.theme.laptop} {
    video[poster] {
      height: 100%;
      width: 100%;
      object-fit: fill;
    }
    height: 520px;
  }
  @media ${(props) => props.theme.tablet} {
    height: 380px;
  }
  @media ${(props) => props.theme.mobile} {
    height: 300px;
    video[poster] {
      height: 100%;
      width: 100%;
      object-fit: fill;
    }
  }
`;

export const DxContent = styled.div`
  background: #60584c;
  width: 100%;
  height: auto;
  padding-top: 85px;
  padding-bottom: 100px;
  color: #ffffff;
  font-style: normal;
  strong {
    display: block;
    font-family: "KBFG Display";
    font-weight: 600;
    font-size: 27px;
    line-height: 160%;
    margin-bottom: 25px;
  }
  span {
    font-family: "KBFG Text";
    font-weight: 300;
    font-size: 17px;
    line-height: 34px;
  }
  p {
    font-family: "KBFG Text";
    font-weight: 300;
    font-size: 17px;
    line-height: 36px;
  }
  @media ${(props) => props.theme.laptop} {
    strong {
      font-size: 25px;
    }
  }
  @media ${(props) => props.theme.tablet} {
    padding-top: 85px;
    padding-bottom: 100px;
    strong {
      font-size: 20px;
      padding-right: 30px;
      margin-bottom: 20px;
      span {
        display: none;
      }
    }
    span {
      display: block;
      font-size: 16px;
      line-height: 20px;
    }
    p {
      font-size: 16px;
      line-height: 28px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    padding: 50px 0px;
    ${ColWrap}:nth-of-type(1) {
      padding-right: 0px;
    }
    strong {
      font-size: 19px;
      padding-right: 0;
      line-height: 160%;
      margin-bottom: 10px;
      span {
        display: none;
      }
    }
    span {
      display: block;
      margin-bottom: 20px;
      font-size: 14px;
    }
    p {
      line-height: 26px;
      font-size: 14px;
    }
  }
`;

//Dx end

export const VisionWrapper = styled.div`
  @media ${(props) => props.theme.laptop} {
    ${SectionTitle} {
      padding-left: 20px;
    }
  }
  @media ${(props) => props.theme.tablet} {
    ${SectionTitle} {
      padding-left: 0px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    padding-top: 0px;
    & > div > div:nth-of-type(3) {
      border-bottom: 15px solid #eee;
    }
  }
`;

export const VisionBackground = styled.div`
  width: 100%;
  ${(props) =>
    props.is_white ? "background-color: #fff;" : "background-color: #f7f7f7;"}
  .border-card {
    border: 1px solid #eee;
  }
  padding-top: 90px;
  @media ${(props) => props.theme.laptop} {
    padding-top: 80px;
  }
  @media ${(props) => props.theme.tablet} {
    padding-top: 40px;
  }
  @media ${(props) => props.theme.mobile} {
    padding-top: 60px;
    padding-bottom: 10px;
  }
`;

//Vision end

export const MainContent = styled.div`
  h4 {
    font-family: "KBFG Display";
    font-style: normal;
    font-size: 26px;
    line-height: 32px;
    color: #000000;
  }
  p {
    font-family: "KBFG Text";
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 36px;
    color: #666666;
  }
  @media ${(props) => props.theme.tablet} {
    h4 {
      font-size: 26px;
      line-height: 32px;
    }
    p {
      font-size: 17px;
      line-height: 36px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    h4 {
      font-size: 24px;
      line-height: 140%;
    }
    p {
      margin-top: 10px;
      font-size: 16px;
      line-height: 26px;
    }
    margin-bottom: 50px;
  }
`;

export const MainContentBadge = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
  label {
    height: 34px;
    line-height: 34px;
    padding: 0px 13px;
    margin-right: 10px;
  }
  @media ${(props) => props.theme.tablet} {
    margin-bottom: 30px;
    label {
      font-size: 15px;
      line-height: 34px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    label {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
`;

export const MainVideoWrapper = styled.div`
  position: relative;
  cursor: pointer;
  height: auto;
  div {
    width: 100%;
    /* height: 188px; */
    position: relative;
    height: 0px;
    padding-bottom: 56.25%;
    background-image: url(${(props) => props.theme.image_url}/common/${(
      props
    ) => props.bg});
    background-size: cover;
    background-position: center center;
  }
  :before {
    display: block;
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    transform: translateX(-50%) translateY(-50%);
    background-image: url(${(props) =>
      props.theme.image_url}/common/icon-play.svg);
    background-size: cover;
    z-index: 3;
    pointer-events: none;
    transition: 0.3s;
  }
  &:hover:before {
    opacity: 0.8;
  }
  :after {
    display: block;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }
  video {
    display: block;
  }
  iframe {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }

  @media ${(props) => props.theme.tablet} {
  }
  @media ${(props) => props.theme.mobile} {
    div {
    }
    video[poster] {
      height: 100%;
      width: 100%;
      object-fit: fill;
    }
  }
`;

// DigitalCard

export const DigitalCard = styled.div`
  /*  */
  & > div > div:nth-of-type(3) {
    p {
      :after {
        display: block;
        content: "block";
        font-size: 0;
      }
    }
  }
  @media ${(props) => props.theme.laptop} {
    & > div > div:nth-of-type(1) {
      p {
        :after {
          display: none;
        }
      }
    }
  }
  @media ${(props) => props.theme.tablet} {
    & > div > div:nth-of-type(1) {
      p {
        :after {
          display: block;
          content: "block";
          font-size: 0;
        }
      }
    }
  }
  @media ${(props) => props.theme.mobile} {
    & > div > div:nth-of-type(1) {
      p {
        :after {
          display: block;
          content: "block";
          font-size: 0;
        }
      }
    }
  }
`;

export const Development = styled.div``;

// MainContent styled End

export const CardContainer = styled.div`
  @media ${(props) => props.theme.tablet} {
    overflow-x: scroll;
    overflow-y: hidden;
  }
  @media ${(props) => props.theme.mobile} {
    position: relative;
  }
`;

export const MainContentCardWrapper = styled.div`
  margin: 40px -20px 80px;
  @media ${(props) => props.theme.tablet} {
    width: 140%;
    max-width: 140%;
    margin: 40px -20px 40px;
  }
  @media ${(props) => props.theme.mobile} {
    width: 280%;
    max-width: 280%;
    margin: 40px -12px 0px;
  }
`;

export const MainContentCardContainer = styled.div`
  display: inline-block;
  width: 33.3%;
  padding: 0 20px;
  vertical-align: top;
  @media ${(props) => props.theme.mobile} {
    position: relative;
    /* width: 100%; */
    padding: 0 12px;
  }
`;

export const MainContentCard = styled.div`
  background: #fff;
  font-style: normal;
  padding: 36px 36px;
  text-align: center;
  strong {
    font-family: "KBFG Display";
    padding-bottom: 20px;
    font-size: 18px;
    line-height: 32px;
    color: #000000;
    display: block;
  }
  p {
    word-break: keep-all;
    font-family: "KBFG Text";
    font-weight: 300;
    font-size: 14px;
    font-weight: normal;
    line-height: 32px;
    color: #666666;
  }
  a {
    font-family: "KBFG Text";
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
    color: #1a1a1a;
    margin-top: 27px;
  }
  @media ${(props) => props.theme.laptop} {
    padding: 30px;
    strong {
      font-size: 15px;
      line-height: 30px;
    }
    p {
      font-size: 13px;
    }
  }
  @media ${(props) => props.theme.tablet} {
    strong {
      font-size: 17px;
      line-height: 28px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    strong {
      padding-bottom: 10px;
    }
    p {
      line-height: 26px;
    }
    a {
      font-size: 14px;
      line-height: 23px;
      font-weight: 600;
      margin-top: 15px;
    }
  }
`;

//MainContentCard stlyed end

export const WorkWrapper = styled.div`
  padding-top: 120px;
  @media ${(props) => props.theme.laptop} {
    padding-top: 120px;
    ${SectionTitle} {
      padding-left: 20px;
    }
  }
  @media ${(props) => props.theme.tablet} {
    padding-top: 100px;
    ${SectionTitle} {
      padding-left: 0px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    border-bottom: 15px solid #eee;
    padding-top: 60px;
    padding-bottom: 30px;
    & > div:last-child {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
`;

export const WorkContent = styled.div`
  margin: 0px -15px;
  @media ${(props) => props.theme.laptop} {
    margin: 0px 15px;
  }
  @media ${(props) => props.theme.mobile} {
    margin: 40px 0px 30px 5px;
    overflow-x: scroll;
    overflow-y: hidden;
  }
`;
export const WorkContentWrapper = styled.div`
  @media ${(props) => props.theme.mobile} {
    width: 2600px;
    max-width: 2030px;
    margin: 0 -15px;
  }
`;

export const WorkVideo = styled.div`
  ${(props) => (props.wide ? `width:100%;` : `width:50%;`)}
  font-style: normal;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 30px;
  padding: 0px 15px;
  height: auto;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  :before {
    display: block;
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    transform: translateX(-50%) translateY(-65%);
    background-image: url(${(props) =>
      props.theme.image_url}/common/icon-play.svg);
    background-size: cover;
    z-index: 3;
    pointer-events: none;
    cursor: pointer;
  }
  p {
    position: absolute;
    bottom: 40px;
    left: 0px;
    width: 100%;
    padding: 0px 60px;
    pointer-events: none;
  }
  strong,
  small {
    display: block;
    font-style: normal;
    color: #ffffff;
    text-shadow: 0px 2px 14px rgba(0, 0, 0, 0.3);
    z-index: 20;
  }
  strong {
    font-family: "KBFG Display";
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
  }
  small {
    position: relative;
    margin-top: 10px;
    font-family: "KBFG Text";
    font-weight: 300;
    font-size: 17px;
    line-height: 32px;
  }
  div {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
    z-index: 0;
    border-radius: 0px 30px;

    cursor: pointer;
    div {
      cursor: pointer;
      background-image: url(${(props) => props.theme.image_url}/common/${(
        props
      ) => props.url});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      height: 100%;
      -webkit-transition: transform 0.8s ease-out;
      -moz-transition: transform 0.8s ease-out;
      -ms-transition: transform 0.8s ease-out;
      -o-transition: transform 0.8s ease-out;
      transition: transform 0.8s ease-out;
      :hover {
        transform: scale(1.1);
      }
      :after {
        display: block;
        position: absolute;
        content: "";
        background: rgba(0, 0, 0, 0.4);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        border-radius: 0px 30px;
      }
    }
  }
  @media ${(props) => props.theme.laptop} {
    padding: 0px 15px;
    strong {
      font-size: 25px;
    }
    small {
      line-height: 30px;
    }
  }
  @media ${(props) => props.theme.tablet} {
    padding: 0px 15px;
    & > div {
      height: 360px;
    }
    strong {
      font-size: 20px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    ${(props) => (props.wide ? `width:100%;` : `width:100%;`)}
    position: relative;
    display: inline-block;
    width: 290px;
    padding: 0px 15px;
    & > div {
      height: 280px;
    }
    p {
      bottom: 20px;
      padding: 0px 40px;
    }
    strong {
      font-size: 16px;
      line-height: 26px;
      color: #ffffff;
      text-shadow: 0px 1px 14px rgba(0, 0, 0, 0.25);
    }
  }
`;

//Work end

export const TalentWrapper = styled.div`
  padding-top: 90px;
  ${SectionTitle} {
    margin-bottom: 100px;
  }
  @media ${(props) => props.theme.laptop} {
    padding-top: 140px;
  }
  @media ${(props) => props.theme.tablet} {
    padding-top: 100px;
  }
  @media ${(props) => props.theme.mobile} {
    /* border-bottom: 15px solid #eee; */
    padding-top: 90px;
    /* padding-bottom: 90px; */
    ${SectionTitle} {
      margin-bottom: 30px;
    }
  }
`;

export const TalentContainer = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  @media ${(props) => props.theme.laptop} {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media ${(props) => props.theme.tablet} {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media ${(props) => props.theme.mobile} {
    padding-left: 20px;
    padding-right: 20px;
    overflow-x: hidden;
    overflow-y: visible;
  }
`;

export const TalentContent = styled.div`
  width: 100%;
  height: 100%;
  @media ${(props) => props.theme.tablet} {
  }
  @media ${(props) => props.theme.mobile} {
  }
`;

export const TalentCard = styled.div`
  display: inline-block;
  position: relative;
  width: 20%;
  height: 450px;
  font-family: "KBFG Text";
  font-style: normal;
  font-weight: 500;
  text-align: center;
  color: #fff;
  div {
    background-image: url(${(props) => props.theme.image_url}/common/${(
      props
    ) => props.bg});
    background-repeat: no-repaet;
    background-size: cover;
    background-position: center center;
    height: 100%;
    transition: 0.3s;
    :after {
      display: block;
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(96, 88, 76, 0.3);
      transition: 0.3s;
    }
    :hover {
      transform: scale(1.2);
      z-index: 50;
      :after {
        background: rgba(96, 88, 76, 0.9);
      }
    }
  }

  p:nth-of-type(1) {
    font-size: 24px;
    line-height: 36px;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 3;
    transition: 0.3s;
    strong {
      font-size: 40px;
    }
  }
  p:nth-of-type(2) {
    font-size: 14px;
    line-height: 28px;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    width: 100%;
    padding: 0 20px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 2;
    color: #fff;
    transition: 0.3s;
  }

  :hover {
    z-index: 10;
    p:nth-of-type(1) {
      top: 40%;
    }
    p:nth-of-type(2) {
      visibility: visible;
      opacity: 1;
      top: 60%;
    }
  }
  @media ${(props) => props.theme.laptop} {
  }
  @media ${(props) => props.theme.tablet} {
    width: 100%;
    height: 240px;
    p:nth-of-type(2) {
      padding: 0 50px;
    }
    div {
      :hover {
        transform: scale(1);
      }
    }
  }
  @media ${(props) => props.theme.mobile} {
    height: 200px;
    p:nth-of-type(1) {
      font-size: 24px;
      line-height: 36px;
      strong {
        font-size: 34px;
      }
    }
    p:nth-of-type(2) {
      padding: 29px 50px;
      font-size: 12px;
    }

    :hover {
      z-index: 10;
      p:nth-of-type(1) {
        top: 29%;
      }
      p:nth-of-type(2) {
        visibility: visible;
        opacity: 1;
        top: 65%;
      }
    }
    div {
      overflow-x: hidden;
      :hover {
        transform: scale(1.2);
      }
    }
  }
`;

export const SlickTalentCard = styled.div`
  display: inline-block;
  position: relative;
  width: 20%;
  height: 450px;
  font-family: "KBFG Text";
  font-style: normal;
  font-weight: 500;
  text-align: center;
  color: #fff;
  background-color: white;
  div {
    background-image: url(${(props) => props.theme.image_url}/common/${(
      props
    ) => props.bg});
    background-repeat: no-repaet;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    margin: 0 12px !important;
    transition: 0.3s;
    :after {
      display: block;
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      margin: 0 12px !important;
      background: rgba(96, 88, 76, 0.3);
      transition: 0.3s;
    }
  }

  p:nth-of-type(1) {
    font-size: 24px;
    line-height: 36px;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 3;
    transition: 0.3s;
    strong {
      font-size: 40px;
    }
  }
  p:nth-of-type(2) {
    font-size: 14px;
    line-height: 28px;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    width: 100%;
    padding: 0 20px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 2;
    color: #fff;
    transition: 0.3s;
  }

  @media ${(props) => props.theme.mobile} {
    height: 200px;
    width: 100%;
    p:nth-of-type(1) {
      font-size: 24px;
      line-height: 36px;
      strong {
        font-size: 34px;
      }
    }
    p:nth-of-type(2) {
      visibility: visible;
      font-size: 12px;
    }

    p:nth-of-type(1) {
      top: 29%;
    }
    p:nth-of-type(2) {
      opacity: 1;
      color: #fefefe;
      font-weight: 600;
      top: 65%;
    }
  }
`;

export const RecruitmentWrapper = styled.div`
  padding-top: 150px;
  @media ${(props) => props.theme.laptop} {
    padding-top: 140px;
  }
  @media ${(props) => props.theme.tablet} {
    padding-top: 100px;
  }
  @media ${(props) => props.theme.mobile} {
    border-bottom: 15px solid #eee;
    padding-top: 0px;
    position: relative;
  }
`;

export const RecruitVideoWrapper = styled.div`
  position: relative;
  top: 25%;
  height: 0;
  padding-bottom: 56.25%;
  z-index: 10;
  div {
    width: 100%;
    padding-bottom: 56.25%;
    background-image: url(${(props) => props.theme.image_url}/common/${(
      props
    ) => props.bg});
    background-size: cover;
    background-position: center center;
  }
  iframe {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
  :after {
    display: block;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0, 0.3);
    cursor: pointer;
  }
  :before {
    display: block;
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    transform: translateX(-50%) translateY(-50%);
    background-image: url(${(props) =>
      props.theme.image_url}/common/icon-play.svg);
    background-size: cover;
    z-index: 3;
    cursor: pointer;
  }
`;

export const RecruitmentImg = styled.div`
  position: relative;
  background-image: url(${(props) =>
    props.theme.image_url}/common/Recruite_bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  :after {
    display: block;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0, 0.4);
    z-index: 1;
  }

  @media ${(props) => props.theme.laptop} {
    background-attachment: scroll;
  }

  @media ${(props) => props.theme.tablet} {
    ${RowWrap} {
      display: block;
    }
  }

  @media ${(props) => props.theme.tablet} {
    ${ColWrap}:last-child {
      padding-bottom: 100px;
    }
  }

  @media ${(props) => props.theme.mobile} {
  }
`;

export const RecruitContainer = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 20px;
  ${RowWrap} {
    @media ${(props) => props.theme.tablet} {
      display: block;
    }
  }
  ${ColWrap} {
    @media ${(props) => props.theme.tablet} {
      display: block;
    }
  }
  @media ${(props) => props.theme.tablet} {
    padding: 0 50px;
  }
  @media ${(props) => props.theme.mobile} {
    padding: 0 20px;
  }
`;

export const Recruitment = styled.div`
  position: relative;
  font-style: normal;
  padding-top: 250px;
  padding-bottom: 300px;
  padding-right: 40px;
  text-align: left;
  z-index: 5;
  h4 {
    font-family: "KBFG Display";
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: #ffcc00;
    text-shadow: 0px 2px 20px rgba(0, 0, 0, 0.25);
    margin-bottom: 10px;
  }
  p {
    font-family: "KBFG Display";
    font-weight: 600;
    font-size: 33px;
    line-height: 160%;
    color: #ffffff;
    text-shadow: 0px 2px 20px rgba(0, 0, 0, 0.25);
    margin-bottom: 60px;
  }
  a {
    font-family: "KBFG Text";
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
    margin-left: 20px;
  }

  /* p:nth-of-type(1){
        padding-right: 280px;
        padding-left: 280px;
    } */
  @media ${(props) => props.theme.laptop} {
    p {
      font-size: 30px;
    }
  }
  @media ${(props) => props.theme.tablet} {
    margin-top: 0px;
    padding-top: 110px;
    padding-bottom: 40px;
    padding-right: 0;
    h4 {
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    }
    p {
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      margin-bottom: 40px;
      font-size: 36px;
    }
    a {
      padding-right: 10px;
      margin-bottom: 60px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    text-align: center;
    /* p:nth-of-type(1){
            padding-right: 20px;
            padding-left: 20px;
        }   */
    padding-top: 80px;
    padding-bottom: 0px;
    h4 {
    }
    p {
      font-size: 28px;
      line-height: 40px;
      text-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
    }
    a {
      padding-right: 0px;
      font-size: 18px;
      line-height: 23px;
    }
  }
`;

//Recruitment end

export const StorySliderSection = styled.div`
  background: #f8f8f8;
  overflow: hidden;
  z-index: 1;
  position: relative;
  padding-top: 135px;
  @media ${(props) => props.theme.mobile} {
    background: #f8f8f8;
  }
`;

export const VisionStoryStyle = styled.div`
  margin-top: 100px;
  & > div > div {
  }
  @media ${(props) => props.theme.laptop} {
    & > div > div {
    }
  }
  @media ${(props) => props.theme.tablet} {
    & > div > div {
    }
  }
  @media ${(props) => props.theme.mobile} {
    & > div > div {
      /* margin-top: 0px; */
    }
  }
`;
