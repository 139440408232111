import React from 'react';
import Popup from '../../../components/Popup';
import styled from 'styled-components';

const VisionVideoPopupStyle = styled.div``;
const VisionVideoPopup7 = (props) => {

    return (
        <VisionVideoPopupStyle>
            <Popup type="video" youtube_id="SypqKYgxmlM" show={props.show} onClose={props.onClose}></Popup>
        </VisionVideoPopupStyle>
    );
};

export default VisionVideoPopup7;