import styled from "styled-components";

//BusinessCard//

export const CardWrapper = styled.div`
  width: 33.33%;
  height: 100%;
  display: inline-block;
  padding: 0 15px;
`;

export const CardStyle = styled.div`
  box-sizing: border-box;
  height: 100%;
  background: rgba(255, 255, 255, 0.96);
  padding-top: 43px;
  padding-left: 49px;

  h4 {
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 162.5%;
    color: #000000;
    margin-bottom: 10px;
  }
  h5 {
    font-family: "KBFG Text";
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 32px;
    color: #666666;
    white-space: pre;
  }
`;

//BusinessCard//

//

export const StorySliderWraaper = styled.div`
  ${(props) => (props.type ? ` margin-bottom: 170px;` : ``)}
  margin-bottom: 220px;

  .slick-slider {
    padding: 0px 80px;
    @media (max-width: 1500px) {
      padding: 0px 80px;
    }
    @media ${(props) => props.theme.laptop} {
      padding: 0px 0px;
    }
  }
  @media (max-width: 1500px) {
  }
  @media ${(props) => props.theme.laptop} {
    padding: 0px 0px;
  }
  @media ${(props) => props.theme.mobile} {
    margin-bottom: 60px;
  }
`;

export const StorySliderStyle = styled.div`
  margin-top: 24px;
  text-align: center;
  @media ${(props) => props.theme.mobile} {
    margin-top: 0px;
    & > div {
      overflow-y: hidden;
      overflow-x: scroll;
    }
    & > div > div {
      width: 3920px;
      text-align: left;
    }
    & > div > div > div {
      width: 325px;
    }
    & section > div {
      width: 300px;
      height: 300px;
      padding-bottom: 0px;
    }
  }
`;

export const StoryCardWrapper = styled.div`
  padding: 36px 14px;
  padding-bottom: 0px;
`;

export const Storytext = styled.div`
  position: relative;
  span {
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 37px;
    color: #515151;
  }
  span:last-child {
    right: 0;
    position: absolute;
  }
`;

export const Storyimg = styled.div`
  width: 388px;
  height: 388px;
  background-image: url("../Group102.png");
  background-size: cover;
  background-position: center center;
  @media ${(props) => props.theme.laptop} {
    height: 0;
    weight: 100%;
    padding: 50px;

    position: relative;
  }
  @media ${(props) => props.theme.tablet} {
  }
  @media ${(props) => props.theme.mobile} {
    width: 100%;
  }
`;

//

//slick//

export const settings = {
  infinite: true,
  autoplay: 0,
  speed: 500,
  arrows: 1,
  slidesToShow: 4,
  rows: 1,
  slidesToScroll: 1,
  autoplaySpeed: 3000, // 자동 스크롤 시 다음으로 넘어가는데 걸리는 시간 (ms)
  pauseOnHover: true,
  centerPadding: "0px",
  responsive: [
    {
      breakpoint: 1800,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1480,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 770,
      settings: "unslick",
    },
  ],
};

export const SlickWrap = styled.div`
  .slick-arrow {
    z-index: 3;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-list {
    overflow: visible;
    padding: 0px !important;
  }
  .slick-slide:not(.slick-active) {
  }
  .slick-arrow {
  }
  .slick-center {
    position: relative;
    /* div{
            :after{
                display: block;
                position: absolute;
                content: "";
                background: rgba(255, 25, 255, 0);
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: 0.4s; 
            }
        } */
  }
  .slick-prev,
  .slick-next {
    font-size: 0;
    position: absolute;
    bottom: 20px;
    color: red;
    border: 0;
    background: none;
    z-index: 1;
    cursor: pointer;
    background: #fff;
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  .slick-prev {
    left: 50%;
    transform: translateX(-150%);
    top: 506px;
    @media ${(props) => props.theme.tablet} {
      top: 400px;
    }
  }
  .slick-prev:before {
    content: url("/arrow-before.svg");
  }
  .slick-next {
    right: 50%;
    transform: translateX(150%);
    top: 506px;
    @media ${(props) => props.theme.tablet} {
      top: 400px;
    }
  }
  .slick-next:before {
    content: url("/arrow-next.svg");
  }
  .slick-prev:hover:after,
  .slick-next:hover:after {
    color: red;
  }
`;

//slick//

//ESGslick//

export const ESGsettings = {
  infinite: true,
  autoplay: 1,
  speed: 500,
  arrows: 0,
  slidesToShow: 3,
  rows: 1,
  slidesToScroll: 1,
  autoplaySpeed: 3000, // 자동 스크롤 시 다음으로 넘어가는데 걸리는 시간 (ms)
  pauseOnHover: true,

  centerPadding: "-20px",
  responsive: [
    {
      breakpoint: 1740,
      settings: {
        slidesToShow: 2,
        centerPadding: "50px;",
      },
    },
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 1,
        centerPadding: "200px;",
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1,
        centerPadding: "300px;",
      },
    },
  ],
};

//ESGslick//
