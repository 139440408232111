import React, { useEffect, useState } from "react";
import { StyledButton, WR } from "./styled";

const Button = (props) => {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const targetElement = document.getElementById("targett");
    if (isHovered) {
      targetElement.classList.add("hovered");
    } else {
      targetElement.classList.remove("hovered");
    }

    // Cleanup 함수로 컴포넌트 언마운트 시 클래스를 제거
    return () => {
      targetElement.classList.remove("hovered");
    };
  }, [isHovered]);

  return (
    <WR onMouseLeave={() => setIsHovered(false)}>
      <StyledButton
        href={props.to}
        target={props.target}
        disable={props.disable}
        onClick={props.onClick}
        type={props.type}
        color={props.color}
        icon={props.icon ? props.icon : "arrow"}
        size={props.size}
        iconSize={props.iconSize}
        onMouseLeave={() => setIsHovered(false)}
      >
        <span>{props.children}</span>
        {/* {isHovered ? "isHovered" : 0} */}
        {props.type == "primary" || "unique" ? <i></i> : ""}
        {props.type == "header" ? (
          <>
            <i
              className="e"
              // onMouseEnter={() => setIsHovered(true)}
              // onMouseLeave={() => setIsHovered(false)}
            >
              <svg
                width="23"
                height="23"
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="ar_"
              >
                <path
                  d="M6.01172 6.98877H15.9863V16.9634"
                  stroke="black"
                  stroke-width="1.60714"
                />
                <path
                  d="M15.9883 6.98825L5.18245 17.7941"
                  stroke="black"
                  stroke-width="1.60714"
                />
              </svg>
            </i>
          </>
        ) : (
          ""
        )}
      </StyledButton>
      {props.type == "header" ? (
        <>
          <div className="tn" onMouseEnter={() => setIsHovered(true)}></div>
          {isHovered ? null : (
            <div onMouseLeave={() => setIsHovered(false)} className="tdd"></div>
          )}
        </>
      ) : null}
    </WR>
  );
};

export default Button;
