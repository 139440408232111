const size = {
  mobile: "770px",
  tablet: "1024px",
  laptop: "1380px",
  desktop: "1700px",
};

const theme = {
  gdaColor: "",
  subColor: "",
  plusColor: "",
  minus: "",
  image_url:
    "https://wp.daitso.kds.co.kr/wp-content/plugins/ep-manager/assets/images",
  NoChangeColor: "",
  backgroundColor: "",
  mobile: `(max-width: ${size.mobile})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  desktop: `(max-width: ${size.desktop})`,
};

export default theme;
