import React, { useEffect, useState } from "react";
import { CommonContainer } from "../../../../components/Grid";
import { useParams } from "react-router";
import LoadInView from "../../../../components/LoadInView";
import { ContentContainer, PageContent, Select } from "../styled";
import { GetPost, GetPrivacy, GetPrivacyList } from "../../../../api/page";

const SectionContent = (props) => {
  const [page_id, setPostId] = useState(111656);
  const [errorMsg, SeterrorMsg] = useState("");
  const [versions, update_versions] = useState(0);
  const [is_load_versions, set_is_load_versions] = useState(0);
  const [post_data, set_post_data] = useState({
    post_id: 0,
    post_title: "",
    post_content: "",
  });
  useEffect(() => {
    GetPost({ post_id: page_id })
      .then((response) => {
        console.log(response);
        if (response.data.code == "200") {
          const post_data_ = response.data.body;
          set_post_data({
            post_id: post_data_.ID,
            post_title: post_data_.post_title,
            post_content: post_data_.post_content,
            post_date: post_data_.post_date,
          });
        } else {
          SeterrorMsg("게시물을 찾을 수 없습니다.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // GetPrivacy()
    //   .then((response) => {
    //     console.log(response);
    //     if (response.data.code == "200") {
    //       const post_data_ = response.data.body;
    //       set_post_data({
    //         post_id: post_data_.ID,
    //         post_title: post_data_.post_title,
    //         post_content: post_data_.post_content,
    //         post_date: post_data_.post_date,
    //       });
    //     } else {
    //       SeterrorMsg("게시물을 찾을 수 없습니다.");
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    if (!is_load_versions) {
      GetPrivacyList()
        .then((response) => {
          if (response.data.code == "200") {
            update_versions(response.data.body);
          }
        })
        .catch((err) => console.log(err));
      set_is_load_versions(1);
    }
  }, [page_id]);

  const ChangeVersion = (e) => {
    setPostId(e.target.value);
  };
  return (
    <>
      <CommonContainer>
        <ContentContainer>
          <LoadInView>
            {versions && (
              <Select onChange={ChangeVersion}>
                {versions.map((version, key) => (
                  <option
                    value={version.ID}
                    selected={page_id == version.ID ? "selected" : ""}
                  >
                    {version.post_title}
                  </option>
                ))}
              </Select>
            )}
            <PageContent
              dangerouslySetInnerHTML={{
                __html: post_data.post_content,
              }}
            ></PageContent>
          </LoadInView>
        </ContentContainer>
      </CommonContainer>
    </>
  );
};

export default SectionContent;
