import React, { useRef, useState } from "react";
import Popup from "../../../components/Popup";
import Badge from "../../../components/Badge";
import SectionDigitalCard from "./SectionDigitalCard";
import {
  Row,
  Col,
  mobileQuery,
  MediaQuery,
  Br,
} from "../../../components/Grid";
import YouTube from "react-youtube";
import {
  MainContent,
  MainContentBadge,
  MainVideoWrapper,
  CardContainer,
} from "../styled";
import { motion } from "framer-motion";

const SectionDigital = (props) => {
  const video_el = useRef(null);
  const isMobile = MediaQuery(mobileQuery);
  const [VideoPopup_isshow, show_VideoPopup] = useState(0);
  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: 1,
      mute: 1,
      loop: 1,
      playlist: "tobojqwKvkM",
      controls: 0,
    },
  };
  const showVideoPopup = (e) => {
    e.preventDefault();
    if (VideoPopup_isshow) {
      show_VideoPopup(0);
    } else {
      show_VideoPopup(1);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 140 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1, ease: "easeOut", staggerChildren: 0.5 }}
    >
      <MainContent>
        <Row>
          <Col flex="2">
            <h4>디지털</h4>
          </Col>
          <Col flex="8">
            <p>
              KB데이타시스템은 KB 금융그룹 內 신기술 기반의 핵심 IT/디지털 사업
              수행을 위해 지속 가능한 자체 기술 경쟁력을 확보하고 있으며, 새로운
              디지털 사업모델 발굴부터 구축/이전/전환/운영/관리 등 디지털 통합
              서비스를 제공하고자 합니다.
            </p>
            <MainContentBadge>
              <Badge type="second" size="medium">
                #DevOps
              </Badge>
              <Badge type="second" size="medium">
                #Agile
              </Badge>
              <Badge type="second" size="medium">
                #OpenAPI
              </Badge>
            </MainContentBadge>
            <MainVideoWrapper bg="Visionitem7.png" onClick={showVideoPopup}>
              {!isMobile ? (
                <YouTube videoId={"tobojqwKvkM"} opts={opts} />
              ) : (
                // <video autoPlay={true} muted loop ref={video_el}>
                //     <source src="https://kds.easypress.me/wp-content/plugins/ep-manager/assets/images/common/vision_digital.mp4" type="video/mp4"/>
                // </video>
                <div></div>
              )}
            </MainVideoWrapper>
            <CardContainer>
              <SectionDigitalCard show_popup={props.show_popup} />
            </CardContainer>
          </Col>
        </Row>
        <Popup
          type="video"
          youtube_id="tobojqwKvkM"
          show={VideoPopup_isshow}
          onClose={showVideoPopup}
        />
        {/* <Popup type="video" src="https://kds.easypress.me/wp-content/plugins/ep-manager/assets/images/common/vision_digital.mp4" show={VideoPopup_isshow} onClose={showVideoPopup}/> */}
      </MainContent>
    </motion.div>
  );
};

export default SectionDigital;
