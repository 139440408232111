import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Header from '../../components/Header';
import UserTitle from './components/UserTitle';
import Footer from '../../components/Footer';
import About from './components/About';
import Loop from './components/Loop';
import {UserWrapper,
        UserTab,
        } from './styled';
import {GetUser} from '../../api/user';

const User = (props) => {

    const params = useParams();
    const [active_tab, set_active_tab] = useState(2);
    const [user_data, set_user_data] = useState({ID: 0});

    const ChangeTab = (e) => {
        e.preventDefault();
        set_active_tab(e.target.dataset.id)
    }
    useEffect(() => {

        if(user_data.ID != params.id) {
            GetUser({
                user_id: params.id
            })
            .then((response) => {
                if(response.data.code == '200') {
                    set_user_data(response.data.body);
                    console.log(response.data.body);
                }
            })
            .catch((response) => {
                console.log(response);
            })
        }
    });
    return (
        <>
        <Header title="" />
            {user_data ? (
            <UserWrapper>
                <UserTitle user={user_data}/>
                <UserTab>
                    {/* <li className={(active_tab == 1) ? 'active' : ''}><a href="#" data-id="1" onClick={ChangeTab}>소개</a></li> */}
                    <li className={(active_tab == 2) ? 'active' : ''}><a href="/#" data-id="2" onClick={ChangeTab}>스토리 </a> <span>32</span></li>
                    
                </UserTab>
                {(active_tab == 1) ? (<About user={user_data}/>) : ''}
                {(active_tab == 2) ? (<Loop user_id={params.id}/>) : ''}
            </UserWrapper>
            ) : ''}
        <Footer />
        </>
    )
}

export default User;