import React from 'react';
import StorySlider from '../components/StorySlider';
import StoryCardSlider from './StoryCardSlider';
import {StoryWrapper,
        StoryPost,
        StorySliderSection
} from '../styled'

const SectionStory = () => {
    return (
        <StoryWrapper>
            <StoryPost> 
                <StoryCardSlider cate_slug={'kbds'}/>
            </StoryPost>
            <StorySliderSection>
                <StorySlider/>
            </StorySliderSection>
        </StoryWrapper>
    );
};

export default SectionStory;