import styled from 'styled-components';

export const StyledTable = styled.table`
        width: 100%; 
        box-sizing: border-box;
        border: 1px solid #F1F1F1;
        border-collapse: collapse;
        ${props => `width:`+props.width+`;`}
        font-size: 16px;
        font-weight: 300;
        letter-spacing: -0.4px;
        color: #222222;
        th {
                padding: 11px ;
                border-bottom: 1px solid #F1F1F1;
                background-color: #F1F1F1;
                border: 1px solid #F1F1F1;
        }
        td {
                padding: 11px ;
                text-align: center;  
                border: 1px solid #F1F1F1;
                vertical-align: middle;
        }
`;