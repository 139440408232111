import gsap from "gsap";
import React, { useEffect, useRef } from "react";
import ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import LoadInView from "../../components/LoadInView";
import MainContainer from "../../components/MainContainer";
import SectionMission from "../About/components/SectionMission";
import SectionDx from "./components/SectionDx";
import SectionMenu from "./components/SectionMenu";
import SectionStory from "./components/SectionStory";
import SectionTitle from "./components/SectionTitle";
import SectionVision from "./components/SectionVision";

ScrollMagicPluginGsap(ScrollMagic, gsap);

const Vision = () => {
  let topBoxEl = useRef(null);
  let sliderTitleEl = useRef(null);
  useEffect(() => {
    var controller = new ScrollMagic.Controller();
    var dx = new ScrollMagic.Scene({ triggerElement: "#dx", duration: "100%" })
      .setClassToggle("#dx_nav", "active")
      .addTo(controller);
    var vision = new ScrollMagic.Scene({
      triggerElement: "#vision",
      duration: "100%",
    })
      .setClassToggle("#vision_nav", "active")
      .addTo(controller);
    var wayworking = new ScrollMagic.Scene({
      triggerElement: "#wayworking",
      duration: "100%",
    })
      .setClassToggle("#wayworking_nav", "active")
      .addTo(controller);
    var recruit = new ScrollMagic.Scene({
      triggerElement: "#recruit",
      duration: "100%",
    })
      .setClassToggle("#recruit_nav", "active")
      .addTo(controller);
    var talent = new ScrollMagic.Scene({
      triggerElement: "#talent",
      duration: "100%",
    })
      .setClassToggle("#talent_nav", "active")
      .addTo(controller);
  });
  return (
    <>
      <MainContainer>
        <SectionTitle />
        <LoadInView id="dx">
          <SectionDx />
        </LoadInView>
        <LoadInView id="mission">
          <SectionMission />
        </LoadInView>
        <LoadInView id="vision">
          <SectionVision />
        </LoadInView>
        <LoadInView>
          <SectionStory />
        </LoadInView>
        <SectionMenu />
      </MainContainer>
    </>
  );
};

export default Vision;
