import React, { useEffect, useState } from "react";
import {
  SectionTitle,
  StorySliderSection,
  StoryWrapper,
  TextArea,
} from "../../Home/styled";
import StorySlider from "../components/StorySlider";
import { AboutStoryStyle } from "../styled";
import { GetBlogPosts } from "../../../api/blog";

const SectionStory = () => {
  const [items, setItems] = useState([]);

  // useEffect(() => {
  //   GetBlogPosts({
  //     paged: 1,
  //     postsPerPage: 12,
  //     series: [4950, 4951],
  //   })
  //     .then((res) => {
  //       console.log(res);
  //       if (res.data.code == 200) {
  //         setItems(res.data.body.items);
  //       } else {
  //         setItems(null);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  return (
    <AboutStoryStyle>
      <StoryWrapper type="1">
        <StorySliderSection type="1">
          <TextArea>
            <SectionTitle>
              <small>스토리</small>
              지속 가능 경영 활동
            </SectionTitle>
          </TextArea>
          <StorySlider />
        </StorySliderSection>
      </StoryWrapper>
    </AboutStoryStyle>
  );
};

export default SectionStory;
