import React from "react";
import Button from "../../../components/Button";
import { Br, CommonContainer } from "../../../components/Grid";
import {
  SectionSummary,
  SectionTitle,
  SeemoreContainer,
  TextArea,
  WayCardArea,
  WayCardAreaItem,
  WayWorkingWrapper,
} from "../styled";

const SectionWayWorking = () => {
  return (
    <WayWorkingWrapper>
      <CommonContainer>
        <TextArea>
          <SectionTitle>
            <small>일하는 방식</small>
            한사람 한사람의 일하는 방식이 모여 <Br />
            일하는 문화를 만듭니다.
          </SectionTitle>
          <SectionSummary>
            <span>
              한사람 한사람의 일하는 방식이 모여 우리가 일하는 문화를 만듭니다.{" "}
            </span>
            KB데이타시스템의 구성원들은 다음과 같이 일합니다.
          </SectionSummary>
          <Button type="primary" to={"/vision/#wayworking"}>
            자세히보기
          </Button>
        </TextArea>
        <WayCardArea>
          <WayCardAreaItem wide="true">
            <div>
              <div />
            </div>
            <p>
              <strong>기본에 충실하자</strong>
              {/* <small>전문가라면 보이지 않는 코드 한 줄에도 정성을 쏟는 디테일이 필요합니다. 작은 것까지 섬세하게 마무리하는 것이 진정한 전문가의 자세입니다.</small> */}
            </p>
          </WayCardAreaItem>
          <WayCardAreaItem>
            <div>
              <div />
            </div>
            <p>
              <strong>우리의 1시간은 고객의 1년입니다.</strong>
              {/* <small>우리가 고객사에서 1년간 구축한 시스템은 향후 10년 동안 고객이 사용하게 될 업무환경이라는 책임감과 자부심으로 프로젝트에 임합니다.</small> */}
            </p>
          </WayCardAreaItem>
          <WayCardAreaItem>
            <div>
              <div />
            </div>
            <p>
              <strong>매뉴얼보다 더 좋은 방법이 있다면 그것이 매뉴얼</strong>
              {/* <small>효율적이고 효과적인 방식이 있다면 이것이 표준! 일이 중심이고 그에 따라 형식과 절차는 개선합니다.</small> */}
            </p>
          </WayCardAreaItem>
        </WayCardArea>
        <SeemoreContainer>
          <Button type="primary" to={"/people"}>
            PEOPLE
          </Button>
        </SeemoreContainer>
      </CommonContainer>
    </WayWorkingWrapper>
  );
};

export default SectionWayWorking;
