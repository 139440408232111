import React from 'react';
import Badge from '../../../components/Badge';
import { CommonContainer, Row, Col } from '../../../components/Grid';
import {LoopRelevant,
        RelevantImg,
        RelevantBadge,
        } from '../styled';
import LoadInView from '../../../components/LoadInView';

const LoopCard = (props) => {
    return (
    <div className="RelevantBox" onClick={()=>{window.location.href=`/story/${props.post.ID}`}}>
        <LoadInView>
            <Row>
                {props.post.post_image ? (
                    <Col flex="0">
                        <RelevantImg url={props.post.post_image} />
                    </Col>
                ) : '' }
                
                <Col>
                    <strong>{props.post.cate_name}</strong>
                    <h3 dangerouslySetInnerHTML={{
                            __html: props.post.post_title
                        }}></h3>
                    <RelevantBadge>
                {props.post.post_tags ? props.post.post_tags.map((tag,key) => (<Badge type="default" size="small">#{tag.name}</Badge>)) : ''}
                        
                    </RelevantBadge>
                    <span>{props.post.post_date}</span>
                </Col>    
            </Row>
        </LoadInView>
    </div>);
}
const LoopContent = (props) => {
    return (
        <CommonContainer>
            <LoopRelevant>
                {props.post_list ? props.post_list.map((post, key) => <LoopCard post={post}/> ) : ''}
            </LoopRelevant>
        </CommonContainer> 
    )
};

export default LoopContent;