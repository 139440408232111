import { motion } from "framer-motion";
import React, { useState } from "react";
import { Br, CommonContainer } from "../../../components/Grid";
import { SectionTitle, VisionBackground, VisionWrapper } from "../styled";
import SectionDevelopment from "./SectionDevelopment";
import SectionDigital from "./SectionDigital";
import SectionOperation from "./SectionOperation";
import VisionPopup from "./VisionPopup";

const SectionVision = () => {
  const [active_popup, set_active_popup] = useState(null);
  const show_popup = (e) => {
    const item = e.currentTarget.dataset.id;
    e.preventDefault();
    set_active_popup(item);
  };
  const close_popup = (e) => {
    e.preventDefault();
    set_active_popup(null);
  };
  return (
    <VisionWrapper id="section_vision">
      <CommonContainer>
        <motion.div
          initial={{ opacity: 0, y: 140 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: "easeOut", staggerChildren: 0.5 }}
        >
          <SectionTitle id="vision">
            <small>영역별 비전</small>
            Continuous Innovation, <Br />
            사업영역별 비전을 소개합니다.
          </SectionTitle>
        </motion.div>
      </CommonContainer>
      <CommonContainer type="wide">
        <VisionBackground>
          <CommonContainer>
            <SectionDigital show_popup={show_popup} />
          </CommonContainer>
        </VisionBackground>

        <VisionBackground is_white="1">
          <CommonContainer>
            <SectionDevelopment show_popup={show_popup} />
          </CommonContainer>
        </VisionBackground>

        <VisionBackground>
          <CommonContainer>
            <SectionOperation show_popup={show_popup} />
          </CommonContainer>
        </VisionBackground>
      </CommonContainer>
      <VisionPopup active={active_popup} onClose={close_popup} />
    </VisionWrapper>
  );
};

export default SectionVision;
