import { motion } from "framer-motion";
import React, { useRef, useState } from "react";
import {
  Br,
  Col,
  CommonContainer,
  MediaQuery,
  mobileQuery,
  Row,
} from "../../../components/Grid";
import Popup from "../../../components/Popup";
import {
  RecruitButton,
  Recruitment,
  RecruitmentImg,
  RecruitmentWrapper,
} from "../styled";

const SectionRecruitment = () => {
  const video_el = useRef(null);
  const isMobile = MediaQuery(mobileQuery);
  const [VideoPopup_isshow, show_VideoPopup] = useState(0);
  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: 1,
      mute: 1,
      loop: 1,
      playlist: "pa_9umxYB78",
      controls: 0,
    },
  };
  const showVideoPopup = (e) => {
    e.preventDefault();
    if (VideoPopup_isshow) {
      show_VideoPopup(0);
    } else {
      show_VideoPopup(1);
    }
  };

  return (
    <RecruitmentWrapper>
      <CommonContainer type="full" className={"people-recruitment-container"}>
        <motion.div
          initial={{ opacity: 0, y: 140 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: "easeOut", staggerChildren: 0.5 }}
        >
          <RecruitmentImg>
            <CommonContainer style={{ paddingX: "30px" }}>
              <Row>
                <Col flex="1">
                  <Recruitment>
                    <h4>채용정보</h4>
                    <p>
                      변화를 선도하며 고객만족을 실현시키는 <Br />
                      분야별 전문가
                    </p>

                    <RecruitButton
                      onClick={() =>
                        (window.location.href =
                          "https://kbds.career.greetinghr.com/")
                      }
                      type={"primary"}
                      color={"white"}
                      icon={"goodbye"}
                    >
                      <span>채용사이트</span>
                      <i />
                    </RecruitButton>
                  </Recruitment>
                </Col>
                <Col flex="1"></Col>
              </Row>
            </CommonContainer>
          </RecruitmentImg>
        </motion.div>
      </CommonContainer>
      <Popup
        type="video"
        youtube_id="pa_9umxYB78"
        show={VideoPopup_isshow}
        onClose={showVideoPopup}
      />
    </RecruitmentWrapper>
  );
};

export default SectionRecruitment;
