import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import LoadInView from "../../../components/LoadInView";
import MainContainer from "../../../components/MainContainer";
import SectionComment from "./components/SectionComment";
import SectionContent from "./components/SectionContent";
import SectionTitle from "./components/SectionTitle";
import SectionUser from "./components/SectionUser";
import SectionRelatedPosts from "./components/SectionRelatedPosts";
import { GetPost } from "../../../api/story";

const Single = (props) => {
  const [errorMsg, SeterrorMsg] = useState("");
  const [post_data, set_post_data] = useState({
    post_id: 0,
    post_title: "",
    post_content: "",
  });
  const params = useParams();
  useEffect(() => {
    if (post_data.post_id != params.id) {
      GetPost({ post_id: params.id })
        .then((response) => {
          console.log(response);
          if (response.data.code == "200") {
            const post_data_ = response.data.body;
            set_post_data({
              post_id: post_data_.ID,
              post_title: post_data_.post_title,
              post_content: post_data_.post_content,
              post_tags: post_data_.post_tags,
              post_image: post_data_.post_image,
              post_author: post_data_.post_author,
              post_author_name: post_data_.post_author_name,
              post_author_img: post_data_.post_author_img,
              post_date: post_data_.post_date,
            });
          } else {
            SeterrorMsg("게시물을 찾을 수 없습니다.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  });
  return (
    <MainContainer>
      <LoadInView>
        <SectionTitle
          item={{
            post_tags: post_data.post_tags,
            post_title: post_data.post_title,
            post_image: post_data.post_image,
            post_author: post_data.post_author,
            post_author_name: post_data.post_author_name,
            post_author_img: post_data.post_author_img,
            post_date: post_data.post_date,
          }}
        />
      </LoadInView>
      <LoadInView>
        <SectionContent post_content={post_data.post_content} />
      </LoadInView>
      {/* <LoadInView>
                <SectionComment />
            </LoadInView> */}
      {/* <LoadInView>
                <SectionUser item={{
                    ID: post_data.post_id,
                    post_author: post_data.post_author,
                    post_author_name: post_data.post_author_name,
                    post_author_img: post_data.post_author_img,
                }}/>
            </LoadInView>
            <LoadInView>
                <SectionRelatedPosts item={{
                    ID: post_data.post_id,
                }}/>
            </LoadInView> */}
    </MainContainer>
  );
};

export default Single;
