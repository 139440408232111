import React, {useEffect, useRef} from 'react';
import {MediaQuery, mobileQuery} from '../../../components/Grid';
import {ESGCardWrapper,
        ESGimg,
        ESGtext}
from '../styled'

const ESGCard = (props) => {
    const element = useRef();
    const itemWidth = 260;
    const viewPortMargin = 158;
    const randomizeHeight = true;
    const x = props.x;
    const y = 0;
    const topOffset = useRef(
      y + (randomizeHeight ? Math.random() * 80 : 0)
    );
    const isMobile = MediaQuery(mobileQuery);
    useEffect(() => {
        let lastRender = Date.now();
        let isRendering = true;
    
        const render = () => {
            const elapsedTime = Date.now() - lastRender;
            const baseSpeed = props.shouldAnimate ? 5 : 0.5;
            const deltaX = (baseSpeed * elapsedTime) / 1000;

            if (element.current) {
                let currentLeft = parseInt(
                    element.current.style.left.replace('px', ''),
                    10
                );
                if (currentLeft <= 0) {
                    currentLeft = props.wrapperWidth + itemWidth + viewPortMargin;
                }
                element.current.style.left = currentLeft - deltaX + 'px';
            }

            lastRender = Date.now();

            if (isRendering) {
                if(props.shouldAnimate) {
                    if(!isMobile) {
                        requestAnimationFrame(render);
                    }
                }
            }
        };
    
        if (element) {
          render();
        }
    
        return () => {
          isRendering = false;
        };
    });
    return (
        <ESGCardWrapper 
            ref={element}
            style={{
                left: itemWidth + x,
                top: topOffset.current,
            }}
            index={props.index}
            onClick={props.onClick}
        >
            <ESGimg image={props.image}/>
            <ESGtext>{props.title}</ESGtext>
        </ESGCardWrapper>
    );
};

export default ESGCard;