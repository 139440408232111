import React from "react";
import Popup from "../../components/Popup/index";
import Table from "../../components/Table";
import { FinancialPopupStyle, PopupCloseButton } from "./styled";

const FinancialPopup = (props) => {
  return (
    <Popup
      type="default"
      show={props.show}
      summary="정도경영을 바탕으로 
        지속적으로 성장하는 KB데이타시스템이 되겠습니다."
      title="재무정보"
      top="0%"
      onClose={props.onClose}
    >
      <FinancialPopupStyle>
        {/* <Financial/> */}

        <div class="cs">
          <p class="copy"></p>
          <div class="cs2">
            <h4>요약손익계산서</h4>
            <Table
              cellspacing="0"
              summary="KB데이타시스템 요약손익계산서입니다."
            >
              <caption>
                KB데이타시스템 요약손익계산서 <small>(단위 : 백만원)</small>
              </caption>
              <thead>
                <tr>
                  <th width="150px">구분</th>
                  {/* <th scope="col">2017년</th> */}
                  <th scope="col">2021년</th>
                  <th scope="col">2022년</th>
                  <th scope="col">2023년</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">매출액</th>
                  <td>
                    <span>174,819</span>
                  </td>
                  <td>
                    <span>233,320</span>
                  </td>
                  <td>
                    <span>230,825</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row">매출원가</th>
                  {/* <td><span>110,954</span></td> */}

                  <td>
                    <span>164,948</span>
                  </td>
                  <td>
                    <span>217,563</span>
                  </td>
                  <td>
                    <span>218,394</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row">매출총이익</th>
                  {/* <td><span>4,095</span></td> */}

                  <td>
                    <span>9,871</span>
                  </td>
                  <td>
                    <span>15,758</span>
                  </td>
                  <td>
                    <span>12,430</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row">판매비와일반관리비</th>
                  {/* <td><span>6,990</span></td> */}

                  <td>
                    <span>9,668</span>
                  </td>
                  <td>
                    <span>11,807</span>
                  </td>
                  <td>
                    <span>13,184</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row">영업이익</th>
                  {/* <td><span>789</span></td> */}
                  <td>
                    <span>203</span>
                  </td>
                  <td>
                    <span>3,950</span>
                  </td>
                  <td>
                    <span>-754</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row">영업외손익</th>
                  {/* <td><span>361</span></td> */}

                  <td>
                    <span>441</span>
                  </td>
                  <td>
                    <span>711</span>
                  </td>
                  <td>
                    <span>953</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row">세전이익</th>
                  {/* <td><span>1,150</span></td> */}

                  <td>
                    <span>765</span>
                  </td>
                  <td>
                    <span>4,822</span>
                  </td>
                  <td>
                    <span>200</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row">법인세비용</th>
                  {/* <td><span>205</span></td> */}

                  <td>
                    <span>302</span>
                  </td>
                  <td>
                    <span>1,705</span>
                  </td>
                  <td>
                    <span>66</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row">당기순이익</th>
                  {/* <td><span>945</span></td> */}

                  <td>
                    <span>467</span>
                  </td>
                  <td>
                    <span>3,162</span>
                  </td>
                  <td>
                    <span>134</span>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div class="cs2">
            <h4>요약재무상태표</h4>
            <Table>
              <caption>
                KB데이타시스템 요약재무상태표 <small>(단위 : 백만원)</small>
              </caption>
              <thead>
                <tr>
                  <th width="150px">구분</th>
                  {/* <th scope="col">2017년</th> */}
                  <th scope="col">2021년</th>
                  <th scope="col">2022년</th>
                  <th scope="col">2023년</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">유동자산</th>
                  {/* <td><span>36,560</span></td> */}

                  <td>
                    <span>34,797</span>
                  </td>
                  <td>
                    <span>50,929</span>
                  </td>
                  <td>
                    <span>48,753</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row">비유동자산</th>
                  {/* <td><span>5,383</span></td> */}

                  <td>
                    <span>8,908</span>
                  </td>
                  <td>
                    <span>12,715</span>
                  </td>
                  <td>
                    <span>12,756</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row">자산총계</th>
                  {/* <td><span>41,944</span></td> */}

                  <td>
                    <span>43,704</span>
                  </td>
                  <td>
                    <span>63,645</span>
                  </td>
                  <td>
                    <span>61,509</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row">유동부채</th>
                  {/* <td><span>24,343</span></td> */}

                  <td>
                    <span>23,688</span>
                  </td>
                  <td>
                    <span>38,834</span>
                  </td>
                  <td>
                    <span>38,550</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row">비유동부채</th>
                  {/* <td><span>2,896</span></td> */}

                  <td>
                    <span>1,441</span>
                  </td>
                  <td>
                    <span>1,736</span>
                  </td>
                  <td>
                    <span>2,066</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row">부채총계</th>
                  {/* <td><span>27,239</span></td> */}

                  <td>
                    <span>25,129</span>
                  </td>
                  <td>
                    <span>40,570</span>
                  </td>
                  <td>
                    <span>40,616</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row">자본금</th>

                  <td>
                    <span>8,000</span>
                  </td>
                  <td>
                    <span>8,000</span>
                  </td>
                  <td>
                    <span>8,000</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row">이익잉여금</th>
                  {/* <td><span>8,928</span></td> */}

                  <td>
                    <span>15,272</span>
                  </td>
                  <td>
                    <span>18,434</span>
                  </td>
                  <td>
                    <span>17,959</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row">자본총계</th>
                  {/* <td><span>14,704</span></td> */}

                  <td>
                    <span>18,575</span>
                  </td>
                  <td>
                    <span>23,074</span>
                  </td>
                  <td>
                    <span>20,892</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row">부채와자본총계</th>
                  {/* <td><span>41,944</span></td> */}

                  <td>
                    <span>43,704</span>
                  </td>
                  <td>
                    <span>63,645</span>
                  </td>
                  <td>
                    <span>61,509</span>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </FinancialPopupStyle>
      <PopupCloseButton>
        <a href="/#" onClick={props.onClose}>
          닫기
        </a>
      </PopupCloseButton>
    </Popup>
  );
};

export default FinancialPopup;
