import React from 'react';
import {
  PopupWrapper,
  Overlay,
  PopupInner,
  PopupContent,
  PopupHead,
  PopupBody,
} from './styled';

const DefaultPopup = props => {
  return (
    <PopupWrapper
      zIndex={props.option.zIndex}
      type={props.option.type}
      show={props.option.show}
      top={props.option.top}
    >
      {props.option.onClose ?
       <Overlay show={props.option.show} onClick={props.option.onClose} /> 
      : <Overlay show={props.option.show} />}
      <PopupInner
        type={props.option.type}
        show={props.option.show}
        width={props.option.width}
        padding={props.option.padding}
        size={props.option.size}
      >
        <PopupContent>
          <PopupHead
            Headpadding={props.option.Headpadding}
            is_padding={props.option.padding}
            size={props.option.size}
          >
            <strong>{props.option.title}</strong>
            {props.option.onClose && (
              <a onClick={props.option.onClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                >
                  <path
                    id="ic_close_24px"
                    d="M23,6.813,21.187,5,14,12.187,6.813,5,5,6.813,12.187,14,5,21.187,6.813,23,14,15.813,21.187,23,23,21.187,15.813,14Z"
                    transform="translate(-5 -5)"
                  />
                </svg>
              </a>
            )}
            <p>{props.option.summary}</p>
          </PopupHead>
          <PopupBody>{props.option.children}</PopupBody>
        </PopupContent>
      </PopupInner>
    </PopupWrapper>
  );
};

export default DefaultPopup;
