import { motion } from "framer-motion";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import {
  Br,
  CommonContainer,
  MediaQuery,
  mobileQuery,
} from "../../../components/Grid";
import {
  SectionTitle,
  SlickTalentCard,
  TalentCard,
  TalentContainer,
  TalentContent,
  TalentWrapper,
} from "../styled";

const SectionTalent = (props) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 300,

    slidesToShow: 1.2,
    slidesToScroll: 1,
  };
  const isMobile = MediaQuery(mobileQuery);
  return (
    <TalentWrapper>
      <CommonContainer>
        <motion.div
          initial={{ opacity: 0, y: 140 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: "easeOut", staggerChildren: 0.5 }}
        >
          <SectionTitle>
            <small>인재채용</small>
            우리는 당신과 같은 <Br />
            SMART 한 인재를 기다리고 있습니다.
          </SectionTitle>
        </motion.div>
      </CommonContainer>
      <TalentContainer>
        <TalentContent>
          {isMobile ? (
            <Slider {...settings}>
              <SlickTalentCard bg="tal_1.png">
                <div bg="tal_1.png">
                  <p>
                    <strong>S</strong>tart with why
                  </p>
                  <p>
                    모든 일에 있어 그 의미를 되새기고 고민하며 행동하는 진중함
                  </p>
                </div>
              </SlickTalentCard>
              <SlickTalentCard bg="tal_2.png">
                <div bg="tal_2.png">
                  <p>
                    <strong>M</strong>icro ambitious
                  </p>
                  <p>
                    큰 그림에 대한 포부와 더불어, 맡은 업무에 디테일한 부분까지
                    책임을 다하고 보다 더 나은 방법이 없는지 끊임없이 고민하는
                    섬세함
                  </p>
                </div>
              </SlickTalentCard>
              <SlickTalentCard bg="tal_3.png">
                <div bg="tal_3.png">
                  <p>
                    <strong>A</strong>fter action review
                  </p>
                  <p>
                    모든 Task에 배움이 담겨져 있음을 통감하고 모든 프로젝트를
                    뒤돌아 보며 배울점과 고칠점을 찾는 능동성과 자기 주도성
                  </p>
                </div>
              </SlickTalentCard>
              <SlickTalentCard bg="tal_4.png">
                <div bg="tal_4.png">
                  <p>
                    <strong>R</strong>e-design
                  </p>
                  <p>
                    고객의 니즈에 적극 반응하며 IT를 통한 비즈니스 혁신을 이끌어
                    내는 고객지향성
                  </p>
                </div>
              </SlickTalentCard>
              <SlickTalentCard bg="tal_5.png">
                <div bg="tal_5.png">
                  <p>
                    <strong>T</strong>echnology quotient
                  </p>
                  <p>
                    변화의 흐름을 꿰뚫고 현재에 안주하지 않으며 또다른 기회를
                    찾아 움직이는 변화 주도성
                  </p>
                </div>
              </SlickTalentCard>
            </Slider>
          ) : (
            <>
              <TalentCard bg="tal_1.png">
                <div bg="tal_1.png">
                  <p>
                    <strong>S</strong>tart with why
                  </p>
                  <p>
                    모든 일에 있어 그 의미를 되새기고 고민하며 행동하는 진중함
                  </p>
                </div>
              </TalentCard>
              <TalentCard bg="tal_2.png">
                <div bg="tal_2.png">
                  <p>
                    <strong>M</strong>icro ambitious
                  </p>
                  <p>
                    큰 그림에 대한 포부와 더불어, 맡은 업무에 디테일한 부분까지
                    책임을 다하고 보다 더 나은 방법이 없는지 끊임없이 고민하는
                    섬세함
                  </p>
                </div>
              </TalentCard>
              <TalentCard bg="tal_3.png">
                <div bg="tal_3.png">
                  <p>
                    <strong>A</strong>fter action review
                  </p>
                  <p>
                    모든 Task에 배움이 담겨져 있음을 통감하고 모든 프로젝트를
                    뒤돌아 보며 배울점과 고칠점을 찾는 능동성과 자기 주도성
                  </p>
                </div>
              </TalentCard>
              <TalentCard bg="tal_4.png">
                <div bg="tal_4.png">
                  <p>
                    <strong>R</strong>e-design
                  </p>
                  <p>
                    고객의 니즈에 적극 반응하며 IT를 통한 비즈니스 혁신을 이끌어
                    내는 고객지향성
                  </p>
                </div>
              </TalentCard>
              <TalentCard bg="tal_5.png">
                <div bg="tal_5.png">
                  <p>
                    <strong>T</strong>echnology quotient
                  </p>
                  <p>
                    변화의 흐름을 꿰뚫고 현재에 안주하지 않으며 또다른 기회를
                    찾아 움직이는 변화 주도성
                  </p>
                </div>
              </TalentCard>
            </>
          )}
        </TalentContent>
      </TalentContainer>
    </TalentWrapper>
  );
};

export default SectionTalent;
