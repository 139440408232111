import styled from 'styled-components';

export const TagWrapper = styled.div`
    margin-top: 105px;
    @media ${props => props.theme.mobile}{
        margin-top: 75px;
    }
`;

export const Path = styled.div`
    font-family: "KBFG Text";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    color: #FFAC00;
    margin-bottom: 32px;
    span{
        color: #999999;
        :after{
            content: ">";
            margin: 0 14px;
        }
    }
    @media ${props => props.theme.laptop}{
        margin-left: 20px;
    }
    @media ${props => props.theme.tablet}{
        margin-left: 0px;
        font-size: 15px;
        line-height: 19px;
        margin-bottom: 25px;
        span{
            font-size: 15px;
            line-height: 19px;
        }
    }
    @media ${props => props.theme.mobile}{
        font-size: 13px;
        line-height: 17px;
        margin-bottom: 25px;
        span{
            font-size: 13px;
            line-height: 17px;
        }
    }
`;

export const Title = styled.h3`
    text-align: center;
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 62px;
    color: #000000;
    margin-bottom: 40px;
    span{
        color: #FFBC00;
    }
    @media ${props => props.theme.laptop}{
        margin-bottom: 30px;
    }
    @media ${props => props.theme.tablet}{
        margin-bottom: 20px;
    }
    @media ${props => props.theme.mobile}{
        font-size: 34px;
        line-height: 35px;
        span{
            margin-top: 20px;
            display: block;
        }
    }
`;

export const Blank = styled.div`
    border: 1px solid #ddd;
    width: 58px;
    height: 00px;
    margin: 0 auto;
    margin-bottom: 20px;
    transform: rotate(90deg);
    margin-bottom: 50px;
    @media ${props => props.theme.laptop}{
        margin-bottom: 40px;
        width: 54px;
    }
    @media ${props => props.theme.tablet}{
        margin-bottom: 30px;
        width: 50px;
    }
    @media ${props => props.theme.mobile}{
        width: 30px;
        margin-bottom: 0px;
    }
`;

export const TagCount = styled.div`
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 300;
    font-size: 21px;
    line-height: 26px;
    text-align: center;
    color: #888888;
    margin-bottom: 70px;
    span{
        font-family: "KBFG Display";
        color: #FFBC00;
    }
    @media ${props => props.theme.laptop}{
        margin-bottom: 60px;
        font-size: 19px;
    }
    @media ${props => props.theme.tablet}{
        margin-bottom: 50px;
        font-size: 17px;
    }
    @media ${props => props.theme.mobile}{
        margin-bottom: 30px;
        font-size: 15px;
    }
`;