import React from "react";
import Popup from "../../components/Popup";
import styled from "styled-components";
import { Row, Col, Br } from "../../components/Grid";
import { ColWrap, RowWrap } from "../../components/Grid/styled";

const PopupCloseButton = styled.div`
  text-align: center;
  margin-top: 40px;
  position: relative;
  a {
    background: rgba(96, 88, 76, 0.88);
    color: #ffffff;
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    padding: 14px 85px;
    display: inline-block;
    text-decoration: none;
  }
  @media ${(props) => props.theme.mobile} {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;

const BusinessPopupStyle = styled.div`
  // 공통 Span

  span {
    word-break: keep-all;
    display: block;
    margin-top: -30px;
    margin-bottom: 20px;
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    color: #666666;
  }

  // 공통 Span End

  // 공통 popup

  .popup {
  }
  // 공통 popup End

  // SI Popup

  .sipopup {
    background: #f8f8f8;
    padding: 30px 30px;
    font-family: "KBFG Display";
    font-style: normal;
    p {
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      color: #444444;
    }
  }

  // SI Popup End

  // SM Popup

  .smpopup {
    background: #f8f8f8;
    padding: 30px 30px;
    margin-bottom: 20px;
    font-family: "KBFG Display";
    font-style: normal;
    h5 {
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      color: #000000;
      margin-bottom: 10px;
    }
    p {
      position: relative;
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      color: #444444;
    }
  }

  // SM Popup End

  // Digital Kronos

  .kronospopup {
    background: #f8f8f8;
    padding: 30px 30px;
    margin-bottom: 20px;
    font-family: "KBFG Display";
    font-style: normal;
    h5 {
      font-size: 18px;
      line-height: 24px;
      color: #000000;
    }
    ul {
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      color: #444444;
      padding: 10px 20px 0px 20px;
    }
    li {
      word-break: keep-all;
    }
  }
  b {
    display: block;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    color: #444444;
    margin-top: 12px;
  }

  // Digital Kronos End

  // Digital Open API

  .apipopup {
    ${RowWrap} {
      margin: 0 -15px;
    }
    ${ColWrap} {
      & > div {
        box-sizing: border-box;
        position: relative;
        padding: 0px 10px;
      }
    }
  }
  .api {
    min-height: 160px;
    background: #f8f8f8;
    padding: 30px 30px;
    margin-bottom: 20px;
    font-family: "KBFG Display";
    font-style: normal;
    h5 {
      font-size: 18px;
      line-height: 24px;
      color: #000000;
    }
    ul {
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      color: #444444;
      padding: 10px 20px 0px 20px;
    }
    ul > li > p {
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      color: #999999;
    }
  }

  // Digital Open API End

  // Digital R&D

  .rnd_top {
    height: auto;
    background: #f8f8f8;
    margin-bottom: 20px;
    font-family: "KBFG Display";
    font-style: normal;
    text-align: center;
    padding: 80px 4px;
    h5 {
      font-size: 16px;
      line-height: 30px;
      color: #000000;
    }
  }
  .rnd_center {
    height: auto;
    background: #f8f8f8;
    margin-bottom: 20px;
    font-family: "KBFG Display";
    font-style: normal;
    text-align: center;
    padding: 64px 27px;
    h5 {
      font-size: 16px;
      line-height: 30px;
      color: #000000;
    }
  }
  .rnd_bottom {
    height: auto;
    background: #f8f8f8;
    margin-bottom: 20px;
    font-family: "KBFG Display";
    font-style: normal;
    text-align: center;
    padding: 94px 3px;
    h5 {
      font-size: 16px;
      line-height: 30px;
      color: #000000;
    }
  }

  .rap {
    height: auto;
    background: #f8f8f8;
    box-sizing: border-box;
    margin-bottom: 20px;
    font-family: "KBFG Display";
    font-style: normal;
    padding: 30px 30px;
    ul {
      padding: 10px 20px 0px 20px;
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      color: #444444;
    }
    h5 {
      font-size: 18px;
      line-height: 24px;
      color: #000000;
    }
  }
  li.sub {
    color: #999999;
    font-weight: 300;
    font-size: 13px;
    line-height: 26px;
    @media ${(props) => props.theme.mobile} {
    }
  }
  .rndpopup {
  }
  // Digital R&D End
  .br {
    margin-right: 10px;
  }
  .li {
    margin-right: 15px;
  }
  @media ${(props) => props.theme.laptop} {
    .br {
      margin-right: 0px;
    }
    .li {
      margin-right: 0px;
    }
  }
  @media ${(props) => props.theme.tablet} {
  }
  @media ${(props) => props.theme.mobile} {
    span {
      margin-top: 0px;
    }
    .popup {
    }
    .sipopup {
      p {
        font-size: 13px;
      }
    }
    .smpopup {
      h5 {
        font-size: 16px;
        font-weight: 600;
      }
      p {
        font-size: 13px;
      }
    }
    .rnd_top {
      margin-bottom: 0px;
      padding: 20px 0px;
      h5 {
        font-size: 16px;
        line-height: 10px;
      }
    }
    .rnd_center {
      margin-bottom: 0px;
      padding: 20px 0px;
      h5 {
        font-size: 16px;
        line-height: 10px;
      }
    }
    .rnd_bottom {
      margin-bottom: 0px;
      padding: 20px 0px;
      h5 {
        font-size: 16px;
        line-height: 10px;
      }
    }
    .rap {
      ul {
        font-weight: 600;
        font-size: 13px;
      }
    }
  }
`;
const BusinessPopup = (props) => {
  // id
  const contents = [
    {
      title: "KB금융그룹의 Cloud 를 관리하는 통합 MSP",
      content: (
        <>
          <span>
            KB데이타시스템의 Cloud 전문 인력이 그룹사의 다양한 Cloud 플랫폼을
            통합된 운영 시스템으로 관리하여 비용 절감과 운영 효율화 추구합니다.
            이를 통해 KB금융그룹의 경쟁력을 강화하고 디지털 전환을 가속화를
            지원합니다.
          </span>
          <div className="popup">
            <div className="kronospopup">
              {/* <h5>
                KB데이타시스템의 Cloud 전문 인력이 그룹사의 다양한 Cloud
                플랫폼을 통합된 운영 시스템으로 관리하여 비용 절감과 운영 효율화
                추구합니다. 이를 통해 KB금융그룹의 경쟁력을 강화하고 디지털
                전환을 가속화를 지원합니다.
              </h5> */}
              <ul>
                <li type="disc">
                  AWS, GCP, AZURE 등 Public Cloud와 KB금융그룹의 Private Cloud의
                  통합 운영
                </li>
                <li type="disc">
                  Cloud 이키텍처부터 인프라 보안 관제까지 Total Managed Service{" "}
                </li>
                <li type="disc">
                  시스템 자동화 기반의 효율적인 운영 체계 구축
                </li>
                <li type="disc">
                  Cloud 사용 준비부터 운영 모니터링, 빌링까지 One-stop 서비스
                  제공
                </li>
                {/* <li type="disc">공동 Cloud 플랫폼을 통한 그룹 시너지 증대</li>
                <li type="disc">Agile/DevOps/고객 중심 민첩한 대응</li>
                <li type="disc">
                  직군별 또는 계열사간 IT협업 강화를 위한 관리 체계 정립
                </li> */}
              </ul>
            </div>
            {/* <div className="kronospopup">
              <h5>
                데이터 중심의 디지털 신기술 활용이 가능한 “Cloud Platform”
              </h5>
              <ul>
                <li type="disc">
                  신기술 개발에 특화된 클라우드 기반 개발 전용 환경 지원
                </li>
                <li type="disc">
                  지속적 Re-Skill/Up-Skill을 통한 안정적인 신규 서비스 개발
                </li>
                <li type="disc">
                  다양한 디지털 및 데이터 서비스 제공을 위한 환경 지원
                </li>
              </ul>
            </div>
            <div className="kronospopup">
              <h5>IT기술 내재화를 통한 지속적인 서비스 개선</h5>
              <ul>
                <li type="disc">
                  신사업 추진 및 서비스 개선에 필요한 빠른 개발 환경 제공(과제
                  발의 후 개발 환경 구축까지 약 2-3주 소요되는 것과 달리 개발
                  자원 선택 후 즉시 구현 가능)
                </li>
              </ul>
            </div> */}
          </div>
          <PopupCloseButton>
            <a href="/#" onClick={props.onClose}>
              닫기
            </a>
          </PopupCloseButton>
        </>
      ),
    },
    {
      title: "생성형AI를 비롯한 AI기술 기반 서비스",
      content: (
        <>
          {/* <span className="br">
            계열사별 다양한 API 형태의 서비스 개발 및 제공을 위한 KBDS의 그룹
            Open API 개발·운영·관리·총괄 역할 수행
          </span> */}
          <div className="popup">
            <div className="apipopup">
              <Row>
                <Col>
                  <div>
                    <div className="api">
                      <h5>생성형AI 기술 연구</h5>
                      <ul>
                        <li type="disc">
                          SLM 연구 및 Fine Tuning, 프롬프트 엔지니어링, 벡터DB
                          코드 자동생성 기술 연구 및 PoC 진행
                        </li>
                        <li type="disc">
                          Cloud 기반 RAG 시스템 ‘Knoid’ 구축, 데모 서비스 운영
                        </li>
                        <li type="disc">
                          CSP별 보유 SOTA 모델 연계 위한 KB One Cloud 아키텍처
                          개발
                        </li>
                        {/* <li type="disc">송금, 간편결제 (출금,이체)</li>
                        <li type="disc">통합자산관</li>
                        <li type="disc">사용내역조회, 거래 API (예정)</li> */}
                      </ul>
                    </div>
                  </div>
                </Col>
                {/* <Col>
                  <div>
                    <div className="api">
                      <h5>제휴 API</h5>
                      <ul>
                        <li type="disc">외부 API 결합 서비스</li>
                      </ul>
                    </div>
                  </div>
                </Col> */}
              </Row>
              <Row>
                <Col>
                  <div>
                    <div className="api">
                      <h5>그룹 공동 생성형AI 플랫폼 구축</h5>
                      <b>
                        KB금융 계열사가 공동으로 이용하는 생성형AI 플랫폼 구축에
                        KB데이타시스템이 생성형AI기술 역량 기반으로 참여하여
                        계열사의 생성형AI 사업의 토대를 마련하는데 함께 합니다.
                      </b>
                      <ul>
                        {/* <li type="disc">상품별/사업별 수수료</li>
                        <li type="disc">실시간자금 집계 및 거래 로그</li>
                        <li type="disc">API 장애 현황조회</li> */}
                      </ul>
                    </div>
                  </div>
                </Col>
                {/* <Col>
                  <div>
                    <div className="api">
                      <h5>통합 API 운영/관리</h5>
                      <ul>
                        <li type="disc">
                          통합 G/W 및 API 서버관리를 통한 체계적인 일괄 정책
                          수립 및 운영
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col> */}
              </Row>
              <Row>
                <Col>
                  <div>
                    <div className="api">
                      <h5>KB금융그룹의 AI MSP 역할</h5>
                      <b>
                        빠르게 발전하는 AI 기술을 KB금융그룹에 적용하기 위해 AI
                        도입부터 구축(SI, Cloud), 운영(SM), 컨설팅에 이르기까지
                        AI 비즈니스를 실현하는데 필요한 모든 영역을 지원하는
                        서비스를 제공하고자 합니다.
                      </b>
                      <ul>{/* <li type="disc">신규 수익원 검토</li> */}</ul>
                    </div>
                  </div>
                </Col>
                {/* <Col></Col> */}
              </Row>
            </div>
          </div>
          <PopupCloseButton>
            <a href="/#" onClick={props.onClose}>
              닫기
            </a>
          </PopupCloseButton>
        </>
      ),
    },
    {
      title: "KB 디지털 자산의 발굴과 확대",
      content: (
        <>
          <span>
            KB금융그룹 신기술 기반 서비스 발굴 및 융합형 기술 개발을 위한 주도형
            R&D 역량 내재화
          </span>
          <div className="popup">
            <div className="rndpopup">
              <div className="rap">
                {/* <h5>KBDS 주도형 신기술 역량 확보</h5> */}
                <b style={{ margin: 0 }}>
                  KB데이타시스템이 개발한 S/W를 시작으로, KB금융그룹의 기술
                  역량이 발현된 S/W, 플랫폼 등 디지털 자산을 발굴하고 상품
                  가치를 더하여 KB금융 계열사들과 여러 산업계에서 활용할 수
                  있도록 추진하고 있습니다. 테스트 통합관리 솔루션
                  ‘KB이슈리포터’, 화상상담 플랫폼 ‘라이브케어’ Service Request
                  솔루션 ‘Alfred’ 등 여러 제품을 Cloud 기반 SaaS 로, On
                  premise의 설치형 솔루션 등 환경에 최적화된 방식으로 제공하고
                  있습니다.
                </b>
                {/* <ul>
                  <li type="disc">선제적 R&D 협업 과제 발굴 및 사업화 기획</li>
                  <li type="disc">'일하는 방식의 변화'를 위한 선행기술 검증</li>
                  <li type="disc">다양한 근무 형태별 업무 도구 실증 및 확산</li>
                </ul> */}
              </div>
              {/* <div className="rap">
                <h5>그룹 디지털/IT 협업 강화</h5>
                <ul>
                  <li type="disc">협업의 기획 단계부터 주도적 참여 확대</li>
                  <li type="disc">산학 협력 및 핀테크 업체와의 협업 강화</li>
                </ul>
              </div>
              <div className="rap">
                <h5>디지털/IT 서비스 개발 역량 내재화</h5>
                <ul>
                  <li type="disc">신기술 및 프로그래밍 언어 CoP운영</li>
                  <li type="disc">
                    디지털/IT 컨설팅 역량 확보를 위한 외부 협업 및 전문 교육
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
          <PopupCloseButton>
            <a href="/#" onClick={props.onClose}>
              닫기
            </a>
          </PopupCloseButton>
        </>
      ),
    },
    {
      title: "계열사 신규 IT 사업 참여 및 고도화",
      content: (
        <>
          <div className="sipopup">
            <p>
              국내외 다양한 IT시스템 구축 경험과 사례를 기반으로 차세대시스템
              구축 등 계열사에서 추진하는 중요 IT프로젝트를 수행하고 있으며,
              기존 운영시스템의 고도화에도 주도적 역할을 수행하고 있습니다
            </p>
            <p>
              구체적으로 계열사의 IT 파트너로서 은행/증권/보험/카드/캐피탈 관련
              금융업무시스템과 경영관리 ERP시스템, 인프라 등의 구축 사업을
              수행하고 있으며, 최근에는 디지털 신기술을 활용하여 클라우드 환경
              구축 및 모바일 뱅킹/지급결제/자산관리 등의 다양한 디지털 사업을
              중점으로 추진하고 있습니다.
            </p>
          </div>
          <PopupCloseButton>
            <a href="/#" onClick={props.onClose}>
              닫기
            </a>
          </PopupCloseButton>
        </>
      ),
    },
    {
      title: "그룹 공동사업에 대한 역할 수행",
      content: (
        <>
          <div className="sipopup">
            <p>
              KB금융그룹 내 계열사 間 원활한 업무 수행과 공동 마케팅 등 IT시너지
              극대화를 위해 다양한 그룹 공동IT사업을 수행하고 있습니다.
              구체적으로 Open API, 그룹 바젤 시스템, 기업단일신용평가,
              통합보안관제시스템, 그룹웨어, 그룹CRM등 그룹의 다양한 공동사업을
              구축 수행해 왔으며, 변화하는 디지털 환경에 대응하기 위한 클라우드
              기반의 그룹 공동 플랫폼 등 그룹 IT시너지를 극대화 할 수 있는
              프로젝트를 지속적으로 발굴하고, 역량을 집중하여 추진하고 있습니다
            </p>
          </div>
          <PopupCloseButton>
            <a href="/#" onClick={props.onClose}>
              닫기
            </a>
          </PopupCloseButton>
        </>
      ),
    },
    {
      title: "그룹내 사업 신속 개발 대응",
      content: (
        <>
          <div className="sipopup">
            <p>
              디지털 환경변화에 따라 Agile DevOps 형태의 소규모 디지털
              IT개발업무가 증가함에 따라, 스마트금융 개발 대응반을 구성하여
              신속하고 빠른 디지털 개발 서비스를 국민은행, 국민카드에 제공하고
              있으며, 점차 다른 계열사에도 서비스 확장을 추진하고 있습니다.
              또한, 계열사 글로벌 진출 사업, 신규 계열사 편입 관련 IT지원 등
              긴급업무 Needs에 적시 대응하여 계열사 Biz수행을 적극 지원하고
              있습니다.
            </p>
          </div>
          <PopupCloseButton>
            <a href="/#" onClick={props.onClose}>
              닫기
            </a>
          </PopupCloseButton>
        </>
      ),
    },
    {
      title: "그룹 공동 시스템 운영/유지보수",
      content: (
        <>
          <div className="popup">
            <div className="smpopup">
              <h5>통합보안관제</h5>
              <p>
                KB금융그룹 계열사에서 안정적인 IT서비스를 제공하기 위해
                웹방화벽(WAF), 침입방지시스템(IPS), DDoS 차단 등 각종 보안
                솔루션을 이용하여 다양한 보안위협을 실시간으로 감시, 분석•대응
                및 예방 활동을 24x365(24시간 365일) 수행하여 KB금융그룹의
                정보자산을 보호하기 위한 업무를 진행하고 있습니다. 또한
                KB금융그룹 계열사의 홈페이지 및 모바일 앱 서비스를 제공하기 전에
                외부침입에 노출될 수 있는 취약한 부분을 점검하는 활동을 통해
                상시적인 예방 활동을 수행하고 있습니다.
              </p>
            </div>
            <div className="smpopup">
              <h5>OpenAPI</h5>
              <p>
                KB금융그룹 OpenAPI 제공을 위한 포탈 및 G/W 시스템 운영 관리를
                하고 있습니다. API 등록, 제휴사 연계, 통계 정보 제공 등
                계열사/제휴사를 위한 포탈을 운영 하고 있으며, 가입된 제휴사를
                위한 API 사용을 위한 개발 가이드 지원도 같이 진행하고 있습니다.
                API를 운영하면서 더 편하고, 빠른API 사용을 위해 포탈의 기능
                업그레이드 등 추가 개발도 진행하고 있습니다. KB금융그룹
                OpenAPI의 안정적인 운영을 수행하고 있습니다
              </p>
            </div>
            <PopupCloseButton>
              <a href="/#" onClick={props.onClose}>
                닫기
              </a>
            </PopupCloseButton>
          </div>
        </>
      ),
    },
    {
      title: "은행IT시스템 운영서비스",
      content: (
        <>
          <div className="popup">
            <div className="smpopup">
              <h5>글로벌 금융 사업운영</h5>
              <p>
                KB국민은행의 글로벌 진출을 위해 운영하는 해외영업점•법인
                IT시스템의 코어업무부터 디지털부문의 비대면 업무까지 개발 및
                운영을 담당하고 있으며, KB금융그룹의 글로벌 확대 전략에 맞춰
                인도네시아에 설립된 현지IT법인은 KB금융그룹의 글로벌 전략을
                추진하는 IT전략자산으로 글로벌 ICT시장 진출 및 디지털 금융사업
                모델을 발굴하고 있습니다.
              </p>
            </div>
            <div className="smpopup">
              <h5>금융 핵심 비즈니스 IT 서비스 제공</h5>
              <p>
                KB국민은행의 여신, 수신, 외환업무 등의 은행 IT시스템의
                핵심업무인 코어업무의 개발 및 운영 경험으로 KB국민은행의 새로운
                상품 출시 등 고객에게 보다 빠르고 안정적인 서비스를 할 수 있도록
                지원하고, 다양한 업무 수행 경험을 바탕으로 실전 노하우를 보유한
                전문IT인력을 육성하고 있습니다
              </p>
            </div>
            <div className="smpopup">
              <h5>Digital Transformation의 리딩</h5>
              <p>
                오랜 기간 축적된 전통적인 금융 비즈니스 노하우를 보유한
                전문인력을 바탕으로 빠르게 변화하는 디지털 환경에서 고객에게
                새로운 경험을 제공하기 위해 디지털 전문인력 육성, 디지털
                경쟁력을 확보하여 KB국민은행의 디지털 혁신을 더욱 속도감 있게
                추진하고 있습니다.
              </p>
            </div>
            <div className="smpopup">
              <h5>그룹사/대외기관 IT시스템 운영</h5>
              <p>
                내재화된 전문역량과 책임감으로 콜센터, 방카슈랑스, 증권대행,
                e-하나로, 한은금융망, 수납지급대행, 이미지시스템 및 ONE KB
                포탈/메일/메신저 시스템 개발 및 위수탁 운영하고 있습니다.
              </p>
            </div>
          </div>
          <PopupCloseButton>
            <a href="/#" onClick={props.onClose}>
              닫기
            </a>
          </PopupCloseButton>
        </>
      ),
    },
    {
      title: "그룹사/대외기관 IT시스템 운영",
      content: (
        <>
          <div className="smpopup">
            <p>
              KB금융지주, 증권, 손해보험, 국민카드, 캐피탈, 생명보험 등
              KB금융그룹 계열사의 시스템 운영/관리, Core 시스템부터 콜센터,
              홈페이지, 다양한 모바일 앱 등 채널 서비스까지 24*365 안정적인
              IT서비스를 제공하고 있습니다.
            </p>
            <p>
              KB금융그룹 계열사에 고품질의 IT아웃소싱 서비스를 제공하고,
              고객에게 가치를 제공하기 위한 기회 발굴과 지속적인 Up-Skill,
              Re-Skill을 통해 신기술 기반의 혁신 금융서비스를 제공하는
              KB금융그룹의 IT파트너로서 역할을 수행하고 있습니다
            </p>
          </div>
          <PopupCloseButton>
            <a href="/#" onClick={props.onClose}>
              닫기
            </a>
          </PopupCloseButton>
        </>
      ),
    },
  ];
  return (
    <BusinessPopupStyle>
      <Popup
        type="default"
        show={props.active}
        onClose={props.onClose}
        title={
          contents[props.active - 1] ? contents[props.active - 1].title : ""
        }
      >
        {contents[props.active - 1] ? contents[props.active - 1].content : ""}
      </Popup>
    </BusinessPopupStyle>
  );
};

export default BusinessPopup;
