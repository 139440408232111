import React from 'react';
import Header from './Header';
import Footer from './Footer';
import styled from 'styled-components';
import ScrollArrow from '../components/TopButton';

const MainContainerWrapper = styled.div`
    position: relative;
    & > .content {
        position: relative;
    }
`;

const MainContainer = (props) => {
    return (
        <MainContainerWrapper>
            <Header type={props.header_type}/>
            <div className="content">{props.children}</div>
            <ScrollArrow/>
            <Footer/>
        </MainContainerWrapper>
    );
};

export default MainContainer;