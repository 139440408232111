import React from 'react';
import AboutContent from './AboutContent';
import AboutSubContent from './AboutSubContent';
import {AboutWrapper,
        } from '../styled';


const About = (props) => {
    return (
        <AboutWrapper>
            <AboutContent user={props.user}/>
            <AboutSubContent user={props.user}/>
        </AboutWrapper>
    )
};

export default About;