import React from "react";
import {
  SectionTitle,
  StorySliderSection,
  StoryWrapper,
  TextArea,
} from "../../Home/styled";
import StorySlider from "../components/StorySlider";
import { VisionStoryStyle } from "../styled";

const SectionStory = () => {
  return (
    <VisionStoryStyle>
      <StoryWrapper type="1" full="1">
        <StorySliderSection type="1">
          <TextArea>
            <SectionTitle>
              <small>스토리</small>
              금융 IT 트렌드
            </SectionTitle>
          </TextArea>
          <StorySlider />
        </StorySliderSection>
      </StoryWrapper>
    </VisionStoryStyle>
  );
};

export default SectionStory;
