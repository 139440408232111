import React, { useEffect, useRef, useState } from "react";
import YouTube from "react-youtube";
import ScrollMagic from "scrollmagic";
import {
  GetPeopleCultures,
  GetPeopleLives,
  GetPeopleMains,
} from "../../api/people";
import Button from "../../components/Button";
import { MediaQuery, mobileQuery } from "../../components/Grid";
import LoadInView from "../../components/LoadInView";
import MainContainer from "../../components/MainContainer";
import Popup from "../../components/Popup";
import StorySlider from "../Home/components/StorySlider";
import { StorySliderSection } from "../Home/styled";
import SectionTalent from "../Vision/components/SectionTalent";
import SectionWayworking from "../Vision/components/SectionWayworking";
import PeopleSlider from "./components/PeopleSlider";
import SectionMenu from "./components/SectionMenu";
import SectionRecruitment from "./components/SectionRecruitment";
import {
  Culture,
  KBDSLive,
  KBDSStory,
  OverviewFigureBottom,
  OverviewFigureTop,
  PeopleWrapper,
  Top,
} from "./styled";

const YOUTUBE_CATEGORY = [
  { content: "회사홍보영상", key: "promotion" },
  { content: "채용", key: "employment" },
  { content: "기업문화", key: "group" },
];

const People = () => {
  let topBoxEl = useRef(null);
  let sliderTitleEl = useRef(null);
  useEffect(() => {
    var controller = new ScrollMagic.Controller();

    var focus = new ScrollMagic.Scene({
      triggerElement: "#focus",
      duration: "100%",
    })
      .setClassToggle("#focus_nav", "active")
      .addTo(controller);

    var live = new ScrollMagic.Scene({
      triggerElement: "#live",
      duration: "100%",
    })
      .setClassToggle("#live_nav", "active")
      .addTo(controller);

    var wayworking = new ScrollMagic.Scene({
      triggerElement: "#wayworking",
      duration: "100%",
    })
      .setClassToggle("#wayworking_nav", "active")
      .addTo(controller);

    var recruit = new ScrollMagic.Scene({
      triggerElement: "#recruit",
      duration: "100%",
    })
      .setClassToggle("#recruit_nav", "active")
      .addTo(controller);
  });
  const isMobile = MediaQuery(mobileQuery);

  const [show, setShow] = useState(false);

  const [pTitle, setpTitle] = useState("");
  const [liveItem, setLiveItem] = useState("");
  const showPopup = (args) => {
    // setpTitle(args);
    setLiveItem(args);
    setShow((p) => !p);
  };

  const [videoShow, setVideoShow] = useState(false);
  const showVideo = (args) => {
    setVideoShow((p) => !p);
    setYoutubeId(args);
  };
  const [yotubeId, setYoutubeId] = useState("");
  const [post_list, update_post_list] = useState(0);

  const [live_list, update_live_list] = useState("");

  const [youtubeCategory, setYoutubeCategory] = useState(
    YOUTUBE_CATEGORY[0].key
  );
  const [mainInfo, setMainInfo] = useState({});
  const [cultureInfo, setCultureInfo] = useState({});

  const [on, setOn] = useState(false);
  const playVideo = () => {
    setOn(true);
    setTimeout(() => {
      player.current && player.current.internalPlayer.playVideo();
    }, [100]);
  };

  const player = useRef(null);

  const opts = {
    height: "418px",
    width: "100%",
    playerVars: {
      autoplay: 0,
      mute: 0,
      controls: 1,
    },
  };

  const getCultures = () => {
    GetPeopleCultures()
      .then((response) => {
        if (response.data.body) {
          setCultureInfo(response.data.body);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getMainData = () => {
    GetPeopleMains()
      .then((response) => {
        if (response.data.body) {
          setMainInfo(response.data.body);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getLiveData = () => {
    GetPeopleLives({
      postsPerPage: 4,
      paged: 1,
    })
      .then((response) => {
        if (response.data.body.posts) {
          update_live_list(response.data.body.posts);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getLiveData();
    getMainData();
    getCultures();
  }, []);

  const movePage = (link) => window.open(`${link}`, "_blank");

  return (
    <>
      <MainContainer>
        <PeopleWrapper>
          <LoadInView id="people_main">
            <Top>
              <PeopleSlider mainInfo={mainInfo}></PeopleSlider>
            </Top>
          </LoadInView>
          <LoadInView id="focus">
            <OverviewFigureTop
              className="bg-figure"
              style={{
                top: "-200px",
                right: "-168px",
                mobileTop: "-102px",
                mobileRight: "-99px",
              }}
            >
              <div />
              {/* <Parallax y={[30, -30]} x={[-30, 0]} tagOuter="figure"></Parallax> */}
            </OverviewFigureTop>
            <OverviewFigureBottom
              style={{
                top: "378px",
                left: "-173px",
                mobileTop: "100px",
                mobileLeft: "-100px",
              }}
            >
              <div />
            </OverviewFigureBottom>
            <Culture>
              <div className="left">
                <h4>KBDS FOCUS</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: cultureInfo.postTitle || "",
                  }}
                />
                <div
                  className="desc"
                  dangerouslySetInnerHTML={{
                    __html: cultureInfo.postContent || "",
                  }}
                />
                {!isMobile && (
                  <Button
                    type="primary"
                    onClick={() => movePage(cultureInfo.link)}
                  >
                    자세히보기
                  </Button>
                )}
              </div>
              <div className="right">
                {!on && (
                  <div
                    className="tb"
                    style={
                      cultureInfo.thumbnail
                        ? { backgroundImage: `url(${cultureInfo.thumbnail})` }
                        : {}
                    }
                    onClick={playVideo}
                  ></div>
                )}
                <div className={on ? "" : "youtube"}>
                  <YouTube
                    videoId={
                      cultureInfo.youtubeLink
                        ? cultureInfo.youtubeLink
                        : "oadla2tcx4U"
                    }
                    className={"youtube_item"}
                    opts={opts}
                    ref={player}
                  />
                </div>

                {isMobile && (
                  <Button
                    type="primary"
                    onClick={() => movePage(cultureInfo.link)}
                  >
                    자세히보기
                  </Button>
                )}
              </div>
            </Culture>
          </LoadInView>
          <LoadInView id="live">
            <KBDSLive>
              <div className="container">
                <div className="flex">
                  <div>
                    <h4>KBDS Live</h4>
                    <div className="desc">KB데이타시스템 Live</div>
                  </div>
                  <Button
                    type="unique"
                    onClick={() => window.open("https://www.youtube.com/@kbds")}
                  >
                    KBDS Youtube
                  </Button>
                </div>
                <p>
                  {isMobile ? (
                    <>
                      KB데이타시스템의 Live 소식을 전합니다.
                      <br />
                      새로운 IT 트랜드 소식, KB데이타시스템 임직원의
                      <br />
                      모임 활동 Live Story를 확인해보세요.
                    </>
                  ) : (
                    <>
                      KB데이타시스템의 Live 소식을 전합니다.
                      <br />
                      새로운 IT 트랜드 소식, KB데이타시스템 임직원의 모임 활동
                      Live Story를 확인해보세요.
                    </>
                  )}
                </p>
                <div className="box">
                  {live_list ? (
                    live_list
                      .filter((item, index) => index < 4)
                      .map((post) => (
                        <div
                          key={post.liveId}
                          className="card"
                          style={{
                            backgroundImage: `url(${post.thumbnail})`,
                          }}
                          onClick={() => showPopup(post)}
                        >
                          <div
                            className="desc"
                            dangerouslySetInnerHTML={{
                              __html: post.postTitle,
                            }}
                          />

                          <div className="post-date">{post.postDate || ""}</div>
                          {/* <div className="btn" /> */}
                        </div>
                      ))
                  ) : (
                    <div
                      className="card"
                      // onClick={() => showVideo("cAK3r7kPje0")}
                    >
                      <div className="desc">
                        모든 가정에 근원적인 물음을
                        <br />
                        제기합니다.
                      </div>
                      <div className="btn" />
                    </div>
                  )}
                </div>
              </div>
            </KBDSLive>
          </LoadInView>
          <LoadInView id="wayworking">
            <SectionWayworking />
          </LoadInView>
          <LoadInView id="talent">
            <SectionTalent />
          </LoadInView>
          <LoadInView id="recruit">
            <SectionRecruitment />
          </LoadInView>
          <LoadInView id="story">
            <KBDSStory>
              <div className="container">
                <div className="flex">
                  <div>
                    <h4>KBDS Story</h4>
                    <div className="desc">조직 문화</div>
                  </div>
                  {!isMobile && (
                    <Button type="primary" to="https://blog.kbds.co.kr/">
                      자세히보기
                    </Button>
                  )}
                </div>
              </div>
              <StorySliderSection>
                <StorySlider post_list={post_list} />
              </StorySliderSection>
              {isMobile && (
                <Button
                  type="primary"
                  color="white_"
                  onClick={() => showPopup("KBDS Story")}
                >
                  자세히보기
                </Button>
              )}
            </KBDSStory>
          </LoadInView>
        </PeopleWrapper>
        <Popup
          type="liveContent"
          show={show}
          title={pTitle}
          post={liveItem}
          onClose={() => setShow((p) => !p)}
        />
        <Popup
          type="video"
          youtube_id={yotubeId}
          show={videoShow}
          onClose={() => setVideoShow((p) => !p)}
        />
        <SectionMenu />
      </MainContainer>
    </>
  );
};

export default People;
