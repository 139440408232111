import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import Card from '../../../components/Card';
import {
  settings,
  SlickWrap,
  StorySliderWraaper,
  StorySliderStyle,
} from './styled';
import { GetPosts } from '../../../api/story';
// import LoadInView from '../../../components/LoadInView';
// import {animteScrollTo} from '../../../lib/common';

const StorySlider = props => {
  const [post_list, update_post_list] = useState(0);
  const [current_paged, set_current_paged] = useState(0);
  const [total_page, set_total_page] = useState(0);
  const [list_tags, set_list_tags] = useState(0);
  const [paged, set_paged] = useState(1);
  useEffect(() => {
    GetPosts({
      order: 'DESC',
      orderby: 'ID',
      posts_per_page: '12',
      cate: '4500',
      paged: paged,
    })
      .then(response => {
        console.log(response);
        if (response.data.body.paged) {
          set_total_page(response.data.body.total);
          set_current_paged(response.data.body.paged);
          update_post_list(response.data.body.posts);
          set_list_tags(response.data.body.tags);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, [paged]);
  return (
    <StorySliderWraaper>
      <StorySliderStyle>
        {post_list ? (
          <SlickWrap>
            <Slider {...settings}>
              {post_list
                ? post_list.map((post, key) => (
                    <Card item={post} key={key} width="100%" />
                  ))
                : '로딩'}
            </Slider>
          </SlickWrap>
        ) : (
          ''
        )}
      </StorySliderStyle>
    </StorySliderWraaper>
  );
};

export default StorySlider;
