import styled from "styled-components";

export const PrivacyWrapper = styled.div`
  margin-top: 135px;
  margin-bottom: 0px;
  @media ${(props) => props.theme.tablet} {
    margin-top: 105px;
  }
  @media ${(props) => props.theme.mobile} {
    margin-top: 75px;
  }
`;

export const Path = styled.div`
  font-family: "KBFG Text";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  color: #ffac00;
  margin-bottom: 32px;
  span {
    font-family: "KBFG Text";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    color: #999999;
    :after {
      content: ">";
      margin: 0 14px;
    }
  }
  @media ${(props) => props.theme.laptop} {
    margin-left: 30px;
  }
  @media ${(props) => props.theme.tablet} {
    font-size: 15px;
    line-height: 19px;
    margin-bottom: 25px;
    margin-left: 0px;
    span {
      font-size: 15px;
      line-height: 19px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    font-size: 13px;
    line-height: 19px;
    margin-bottom: 15px;
    span {
      font-size: 13px;
      line-height: 19px;
    }
  }
`;

export const MainTitle = styled.div`
  margin-bottom: 40px;
  h2 {
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 62px;
    color: #1a1a1a;
  }
  @media ${(props) => props.theme.laptop} {
    padding-left: 20px;
  }
  @media ${(props) => props.theme.tablet} {
    margin-bottom: 50px;
    padding-left: 0px;
    h2 {
      font-size: 50px;
      line-height: 72px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    margin-bottom: 20px;
    h2 {
      margin-bottom: 25px;
      font-size: 34px;
      line-height: 35px;
    }
  }
`;

export const MainPost = styled.div`
  height: 395px;
  margin-bottom: 85px;
  background-image: url(${(props) =>
    props.theme.image_url}/common/Privacy_bg.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  @media ${(props) => props.theme.laptop} {
    background-attachment: scroll;
  }
  @media ${(props) => props.theme.tablet} {
    height: 480px;
    margin-bottom: 100px;
    background-position: center center;
  }
  @media ${(props) => props.theme.mobile} {
    height: 460px;
    margin-bottom: 90px;
    background-position: center center;
  }
`;

export const ContentContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  margin-bottom: 290px;
  p {
    font-family: "KBFG Text";
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 30px;
    color: #000000;
  }
`;
export const PageContent = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 25px;
  }
  p {
    margin-bottom: 25px;
    color: #888;
  }
  a {
    color: #111;
  }
  ul {
    list-style: square;
    margin-bottom: 25px;
    margin-top: 15px;
  }
  li {
    line-height: 1.8;
    margin-bottom: 10px;
    margin-left: 20px;
    color: #888;
  }
  .wp-block-group {
    display: none;
  }
  .togglee {
    cursor: pointer;
  }
  &.active .wp-block-group {
    display: block;
  }
  table {
    border: 1px solid #eee;
    margin-bottom: 25px;
    th {
      background: #f7f7f7;
    }
    td,
    th {
      padding: 10px;
      border: 1px solid #eee;
      vertical-align: middle;
      line-height: 1.7;
      text-align: center;
    }
    .left {
      text-align: left;
    }
  }
  @media ${(props) => props.theme.mobile} {
    h1,
    h2,
    h3,
    h4,
    h5 {
      line-height: 35px;
      font-size: 21px;
    }
    table {
      max-width: 100%;
      width: 100% !important;
      overflow-x: scroll;
      overflow-y: visible;
    }
  }
`;

export const Select = styled.select`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  /* 생략 */
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;

  color: #444;
  background-color: #fff;

  padding: 0.6em 1.4em 0.5em 0.8em;
  margin: 0;
  margin-bottom: 40px;

  border: 1px solid #aaa;
  border-radius: 0.5em;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
`;
