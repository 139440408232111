import React from "react";
import {
  CardWrapper,
  CardStyle,
  CardBadge,
  Title,
  Date,
  Tag,
  Tags,
  Author,
  Avatar,
} from "./styled";
import LoadInView from "../../components/LoadInView";
const Card = (props) => {
  let item = {};
  if (props.isBlog) {
    item = {
      post_title: props.item ? props.item.postTitle : "금융/트랜드",
      cate_name: props.item ? props.item.series : "KB데이타시스템",
      post_tags: props.item ? props.item.tags : "",
      post_date: props.item ? props.item.post_date : "2020.02.01",
      post_image: props.item ? props.item.thumbnail : "",
      post_author_name: props.item ? props.item.postAuthorName : "",
      post_author_img: props.item ? props.item.postAuthorImg : "",
    };
  } else {
    item = {
      post_title: props.item ? props.item.post_title : "금융/트랜드",
      cate_name: props.item ? props.item.cate_name : "KB데이타시스템",
      post_tags: props.item ? props.item.post_tags : "",
      post_date: props.item ? props.item.post_date : "2020.02.01",
      post_image: props.item ? props.item.post_image : "",
      post_author_name: props.item ? props.item.post_author_name : "",
      post_author_img: props.item ? props.item.post_author_img : "",
    };
  }

  const LinktoPost = () => {
    if (props.isBlog) {
      window.open(`https://blog.kbds.co.kr/blog/${props.item.ID}`);
    } else {
      window.location.href = "/story/" + props.item.ID;
    }
  };
  return (
    <CardWrapper width={props.width} onClick={LinktoPost}>
      <LoadInView>
        <CardStyle postImage={item.post_image}>
          <CardBadge>{item.cate_name}</CardBadge>
          <Title
            dangerouslySetInnerHTML={{
              __html: item.post_title,
            }}
          ></Title>
          <Date>{item.post_date}</Date>
          <Tags>
            {item.post_tags
              ? item.post_tags.map((tag, key) => <Tag>#{tag.name}</Tag>)
              : ""}
          </Tags>
          <Author>
            <span>{item.post_author_name}</span>
            <Avatar image={item.post_author_img} alt="" />
          </Author>
        </CardStyle>
      </LoadInView>
    </CardWrapper>
  );
};

export default Card;
