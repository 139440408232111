import { PostTitle } from '../views/Story/Single/styled';
import axios from './defaultClient';

const nameSpace = '/ep/v1';

export const GetPeoplePosts = async (args) => {
    return await axios.get( nameSpace + '/youtubes',{ 
        params:{
            postsPerPage: args.postsPerPage,
            paged: args.paged,
            slug: args.slug,
        }
    })
}
export const GetPeopleLives = async (args) => {
    return await axios.get( nameSpace + '/lives',{ 
        params:{
            postsPerPage: args.postsPerPage,
            paged: args.paged,
        }
    })
}
export const GetPeopleCultures = async (args) => {
    return await axios.get( nameSpace + '/cultures',{ 
        params:{
        }
    })
}
export const GetPeopleMains = async (args) => {
    return await axios.get( nameSpace + '/mains',{ 
        params:{
        }
    })
}
