import React from 'react';
import {CommonContainer, Br} from '../../../components/Grid';
import Button from '../../../components/Button';
import {VisionWrapper,
        VisionBox,
        VisionContent,
        TextArea,
        SectionTitle,
        VisionButton,
} from '../styled';

const SectionVision = () => {
    return (
        <CommonContainer type="full">
            <VisionWrapper>
                <CommonContainer>
                    <VisionBox>
                        <TextArea>
                            <SectionTitle>
                                <small>영역별 비전</small>
                                업무 중심의 역량 확대를 <Br/>추진해나가고 있습니다.
                            </SectionTitle>
                        </TextArea>
                        <VisionContent>
                            <div>
                                <figure>
                                    <h4>SI</h4>
                                    <p>그룹 IT사업 추진에서 서비스 개선 및 강화를<Br/> 위한 중추적인 개발 역할 수행</p>
                                </figure>
                                <div className="rot"/>
                            </div>
                            <div>
                                <figure>
                                <h4>Digital</h4>
                                    <p>그룹 Value 향상 및 역량 강화를 위한<Br/> 디지털 사업 집중</p>
                                </figure>
                                <div className="rot"/>
                            </div>
                            <div>
                                <figure> 
                                    <h4>SM</h4>
                                    <p>주도성, 핵심역량 기반. 지속적인 혁신 유도로<Br/> 고객사에 차별적인 IT서비스 제공</p>
                                </figure>
                                <div className="rot"/>
                            </div>
                        </VisionContent>
                        <VisionButton>
                            <Button type='primary' color='white' to={'/vision/#section_vision'}>자세히보기</Button>
                        </VisionButton>
                    </VisionBox>
                </CommonContainer>
            </VisionWrapper>
        </CommonContainer>
    );
};

export default SectionVision;