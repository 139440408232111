import React,{useEffect, useState} from 'react';
import {CommonContainer} from '../../../../components/Grid';
import { useParams } from 'react-router';
import LoadInView from '../../../../components/LoadInView';
import {ContentContainer, PageContent} from '../styled';
import {GetPost} from '../../../../api/page';

const SectionContent = (props) => {
    const page_id = 99059;
    const [errorMsg, SeterrorMsg] = useState('');
    const [post_data, set_post_data] = useState({
        post_id:0,
        post_title:'',
        post_content:''
    })
    useEffect(() => {
        if(post_data.post_id != page_id) {
            GetPost({post_id: page_id})
            .then((response) => {
                console.log(response);
                if(response.data.code == '200') {
                    const post_data_ = response.data.body;
                    set_post_data({
                        post_id: post_data_.ID,
                        post_title: post_data_.post_title,
                        post_content: post_data_.post_content,
                        post_date: post_data_.post_date,
                    });
                } else {
                    SeterrorMsg('게시물을 찾을 수 없습니다.');
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    });

    return (
        <>
            <CommonContainer>
                <ContentContainer>
                    <LoadInView>
                        <PageContent dangerouslySetInnerHTML={{
                                __html: post_data.post_content
                            }}>
                        </PageContent>
                    </LoadInView>
                </ContentContainer>
            </CommonContainer>
        </>
    );
};

export default SectionContent;