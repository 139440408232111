import React, {useState} from 'react';
import {CommonContainer, Row, Col, MediaQuery, mobileQuery} from '../../../components/Grid';
import CIPopup from '../CIPopup';
import LocationPopup from '../LocationPopup';
import FinancialPopup from '../FinancialPopup';
import EthicalPopup from '../EthicalPopup';
import LoadInView from '../../../components/LoadInView';
import {OverviewWrapper,
        OverviewFigure,
        SectionTitle,
        AboutContent,
        AboutBox,
        OverviewSummary,
        AboutBoxItem,
        AboutBottomBox,
        BottomItemStyle,
        BottomItem,
} from '../styled'
import { Parallax } from 'react-scroll-parallax';

const SectionOverview = () => {
    const isMobile = MediaQuery(mobileQuery);
    const [CIPopup_isshow,show_CIPopup] = useState(0);
    const showCIPopup = (e) => {
        e.preventDefault();
        if(CIPopup_isshow) {
            show_CIPopup(0);
        } else {
            show_CIPopup(1);
        }
    }

    const [LocationPopup_isshow,show_LocationPopup] = useState(0);
    const showLocationPopup = (e) => {
        e.preventDefault();
        if(LocationPopup_isshow) {
            show_LocationPopup(0);
        } else {
            show_LocationPopup(1);
        }
    }

    const [FinancialPopup_isshow,show_FinancialPopup] = useState(0);
    const showFinancialPopup = (e) => {
        e.preventDefault();
        if(FinancialPopup_isshow) {
            show_FinancialPopup(0);
        } else {
            show_FinancialPopup(1);
        }
    }

    const [EthicalPopup_isshow,show_EthicalPopup] = useState(0);
    const showEthicalPopup = (e) => {
        e.preventDefault();
        if(EthicalPopup_isshow) {
            show_EthicalPopup(0);
        } else {
            show_EthicalPopup(1);
        }
    }

    return (
        <OverviewWrapper>

            {!isMobile ? (
                <OverviewFigure>
                    <Parallax y={[30, -30]} x={[-30, 0]} tagOuter="figure">
                    </Parallax>
                </OverviewFigure>
            ) : ''}

            <OverviewFigure>
                <Parallax y={[20, -20]} x={[-20, 20]} tagOuter="figure">
                </Parallax>
            </OverviewFigure>
            <CommonContainer>
                <SectionTitle>
                    <LoadInView>
                        <small>회사개요</small>
                        선진화 및 고도화를 이끌어 온<br/>금융 IT기업 KB데이타시스템
                    </LoadInView>
                </SectionTitle>
                <AboutContent>
                    <OverviewSummary>
                        <LoadInView>
                        <p>
                            오직 금융만을 생각하고 연구하며 국내 최고 금융기관의 성장을 지원한 경험과 지식을 보유한 KB데이타시스템은 금융에 대한 풍부한 이해와 수백여 프로젝트
                            경험에서 축적된 노하우를 바탕으로 급변하는 금융 환경에 고객의 needs를 명확히 분석하여 컨설팅에서 시스템 구축, 운영까지 최적의 통합IT서비스를 제공합니다.
                        </p>
                        </LoadInView>
                        <LoadInView>
                        <p>
                            체계화된 조직구조와 최고의 기술력으로 전문 IT서비스를 제공하며, 업계 최고의 품질관리시스템으로 한치의 오차도 허용하지 않는 완벽한 시스템을 구현합니다.
                        </p>
                        </LoadInView>
                    </OverviewSummary>
                    <AboutBox>
                        <Row>
                            <Col>
                                <AboutBoxItem>
                                    <LoadInView>
                                        <h4>Value creation</h4>
                                        <p>전문적인 IT서비스로 그룹IT 혁신 및 가치 창출</p>
                                    </LoadInView>
                                </AboutBoxItem>
                            </Col>
                            <Col>
                                <AboutBoxItem>
                                    <LoadInView>
                                        <h4>Improving</h4>
                                        <p>IT전문인력 집중 양성으로 그룹IT 역량 강화에 기여</p>
                                    </LoadInView>
                                </AboutBoxItem>
                            </Col>
                            <Col>
                                <AboutBoxItem>
                                    <LoadInView>
                                        <h4>Partnership</h4>
                                        <p>선진 IT서비스 업체들과의 전략적 파트너십 등을 통한 핵심 기술력 확보</p>
                                    </LoadInView>
                                </AboutBoxItem>
                            </Col>
                        </Row>
                    </AboutBox>
                    <AboutBottomBox>
                            <Row>
                                <Col>
                                    <BottomItemStyle>
                                        <LoadInView>
                                            <BottomItem bgImage="/Vision_item_CI.png" onClick={showCIPopup}>
                                                <h4>CI</h4>
                                                <div/>
                                            </BottomItem>
                                        </LoadInView>
                                    </BottomItemStyle>
                                </Col>
                                <Col>
                                    <BottomItemStyle>
                                        <LoadInView>
                                            <BottomItem bgImage="/Aboutitem2.png" onClick={showLocationPopup}>
                                                <h4>회사위치</h4>
                                                <div/>
                                            </BottomItem>
                                        </LoadInView>
                                    </BottomItemStyle>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <BottomItemStyle>
                                        <LoadInView>
                                            <BottomItem bgImage="/Vision_item_Finance.png" onClick={showFinancialPopup} >
                                                <h4>재무정보</h4>
                                                <div/>
                                            </BottomItem>
                                        </LoadInView>
                                    </BottomItemStyle>
                                </Col>
                                <Col>
                                    <BottomItemStyle>
                                        <LoadInView>
                                            <BottomItem bgImage="/Aboutitem4.png" onClick={showEthicalPopup}>
                                                <h4>윤리경영</h4>
                                                <div/>
                                            </BottomItem>
                                        </LoadInView>
                                    </BottomItemStyle>
                                </Col>
                            </Row>
                    </AboutBottomBox>
                </AboutContent>
            </CommonContainer>
            <CIPopup show={CIPopup_isshow} onClose={showCIPopup}/>
            <LocationPopup show={LocationPopup_isshow} onClose={showLocationPopup}/>
            <FinancialPopup show={FinancialPopup_isshow} onClose={showFinancialPopup}/>
            <EthicalPopup show={EthicalPopup_isshow} onClose={showEthicalPopup}/>
        </OverviewWrapper>
    );
};

export default SectionOverview;