import React from 'react';
import Popup from '../../components/Popup';
import {Br} from '../../components/Grid';
import {
    LocationPopupStyle,
    PopupCloseButton,
    Location} from './styled';


const LocationPopup = (props) => {
    return (
        <>
            <Popup type="default" show={props.show} title="회사위치" onClose={props.onClose} top="0%" style={{textAlign:"center"}}> 
                <Location>
                    <p>121-815 서울시 마포구 마포대로 78 (도화동 566) 경찰공제회 자람빌딩 13/14층 <Br />
                    Tel : 02-3215-6000 / Fax : 02-3215-6100</p>
                </Location>
                <LocationPopupStyle>
                    <div/>
                </LocationPopupStyle>
                <PopupCloseButton>
                    <a href="/#" onClick={props.onClose}>닫기</a>
                </PopupCloseButton>
            </Popup>
        </>
    );
}
export default LocationPopup;