import React from "react";
import styled from "styled-components";

const SectionMenu = () => {
  return (
    <SectionMenuStyle>
      {/* <CommonContainer> */}
      <div>
        <ul>
          <li id="focus_nav" className={"active"}>
            <a href="#focus">KBDS FOCUS</a>
          </li>
          <li id="live_nav">
            <a href="#live">KBDS Live</a>
          </li>
          <li id="wayworking_nav">
            <a href="#wayworking">일하는 방식</a>
          </li>
          <li id="recruit_nav">
            <a href="#recruit">인재채용</a>
          </li>
        </ul>
      </div>
      {/* </CommonContainer> */}
    </SectionMenuStyle>
  );
};

export default SectionMenu;

const SectionMenuStyle = styled.div`
  position: absolute;
  z-index: 999;
  height: 91%;
  left: 0px;
  top: 100vh;
  /* & > div {
        position: relative;
        height: 100%;
    } */
  & > div {
    padding-top: 180px;
    position: absolute;
    height: 100%;
    width: 150px;
    left: 0px;
    top: 0px;
  }
  & > div > ul {
    position: sticky;
    top: 100px;
  }
  & > div > ul > li {
    margin-bottom: 27px;
    padding-left: 50px;
  }
  & > div > ul > li:last-child {
    margin-bottom: 0px;
  }
  li.active {
    position: relative;
    :before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      margin-top: -1px;
      left: 0px;
      width: 37px;
      height: 2px;
      background: #ffbc00;
    }
  }
  li.active > a {
    color: #ffbc00;
    font-weight: 600;
  }
  li > a {
    color: #bfbfbf;
    font-size: 16px;
    text-decoration: none;
  }
  @media ${(props) => props.theme.tablet} {
    display: none;
  }
`;
