import React, { useRef, useEffect } from "react";
import { Player } from "video-react";
import { PopupWrapper, PopupContainer, Overlay, PopupInner } from "./styled";
import YouTube from "react-youtube";

const VideoPopup = (props) => {
  const player = useRef(null);
  // console.log(player);

  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: 0,
      mute: 1,
      //   loop: 1,
      //   playlist: 'cAK3r7kPje0',
      controls: 1,
    },
  };

  useEffect(() => {
    if (props.option.show) {
      if (player.current) {
        player.current.internalPlayer.playVideo();
      }
    } else {
      if (player.current) {
        player.current.internalPlayer.pauseVideo();
      }
    }
    // if(props.option.show) {
    //     player.current.play();
    // } else {
    //     player.current.pause();
    // }
  });

  return (
    <PopupWrapper
      type={props.option.type}
      show={props.option.show}
      top={props.option.top}
    >
      <Overlay show={props.option.show} onClick={props.option.onClose} />
      <PopupContainer>
        <PopupInner
          type={props.option.type}
          show={props.option.show}
          width={props.option.width}
        >
          <a onClick={props.option.onClose}>
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path id="ic_close_24px" d="M23,6.813,21.187,5,14,12.187,6.813,5,5,6.813,12.187,14,5,21.187,6.813,23,14,15.813,21.187,23,23,21.187,15.813,14Z" transform="translate(-5 -5)"/></svg> */}
            <div></div>
            <div></div>
          </a>
          {props.option.show && (
            <YouTube
              videoId={props.option.youtube_id}
              className={"youtube_item"}
              opts={opts}
              ref={player}
            />
          )}
        </PopupInner>
      </PopupContainer>
    </PopupWrapper>
  );
};
export default VideoPopup;
