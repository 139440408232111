import React from "react";
import Popup from "../../components/Popup/index";
import {
  EthicalPopupStyle,
  EthicalContent,
  EthicalField,
  EthicalCode,
  FullText,
  EthicalStandards,
  PopupCloseButton,
  EthicalReport,
} from "./styled";

const EthicalPopup = (props) => {
  return (
    <Popup
      type="default"
      size="Full"
      show={props.show}
      title="윤리경영"
      summary=""
      top="0%"
      onClose={props.onClose}
      padding="0 0"
      Headpadding="40px 0px 0px 40px"
    >
      <EthicalPopupStyle>
        <EthicalContent>
          <EthicalField>
            <strong>윤리헌장</strong>
            <span>
              KB금융그룹은 고객에게 최상의 금융서비스를 제공하고, 주주의 권익을
              우선하며, 임직원의 성장과 삶의 질 향상을 도모한다. 아울러 건전하고
              공정한 기업문화 창달과 사회공헌 활동을 통하여 국가와 사회의 발전에
              이바지 한다. 이를 위하여 KB금융그룹의 모든 구성원은 윤리적 가치를
              최우선 기준으로 삼아 행동하고자 윤리헌장을 제정하고 적극 실천할
              것을 다짐한다.
            </span>
            <p>
              <span>우리는</span> 항상 고객의 입장에서 생각하고 행동하며, 최고의
              금융서비스를 제공함으로써 고객감동 실현을 위해 노력한다.
            </p>
            <p>
              <span>우리는</span> 투명하고 합리적인 경영을 통하여 그룹의 가치를
              극대화함으로써 주주의 권익향상을 위해 최선을 다한다.
            </p>
            <p>
              <span>우리는</span> 제반 법규를 준수하고 다양한 문화와 가치를
              존중하며, 국가경제와 사회의 발전 및 인류의 공존번영을 위해
              노력한다.
            </p>
            <p>
              <span>우리는</span> 그룹의 정책방향을 공유하고, 계열사 상호간
              신뢰를 바탕으로 그룹의 역량을 결집하여 그룹이 지속성장할 수 있도록
              노력한다.
            </p>
            <p>
              <span>우리는</span> 항상 성실하고 정직하게 행동하며, 올바른
              근무자세를 견지하여 모범적인 금융인의 표상이 된다.
            </p>
          </EthicalField>
          <EthicalCode>
            <strong>윤리강령</strong>
            <span>
              우리는 신용과 기술력을 바탕으로 국내 SI업계를 선도하는 『세계적인
              금융 전문 SI기업』 을 지향한다. 이를 위하여 공정하고 투명한
              경영으로 고객만족을 실현하고기업가치를 끊임없이 향상시켜,
              고객·주주 등과 함께 성장·발전하는회사를 만들기 위해 노력한다.이에
              우리는 "윤리강령"을 우리의 가치판단과 행동양식의 기준으로 삼아
              이를 적극 실천할 것을 다짐한다.
            </span>
            <p>
              <span>우리는</span> 고객만족을 실현하기 위하여 항상 고객의
              입장에서 생각하고 행동한다.
            </p>
            <p>
              <span>우리는</span> 합리적인 의사결정과 투명한 경영활동을 통하여
              주주가치 극대화에 최선을 다한다.
            </p>
            <p>
              <span>우리는</span> 개개인의 자율과 창의를 존중하며 삶의 질 향상을
              위해 노력한다.
            </p>
            <p>
              <span>우리는</span> 윤리적 가치관을 확립하고, 성실하고 올바른
              직무자세 및 생활태도로 직장인으로서의 신뢰와 품위를 높인다.
            </p>
            <p>
              <span>우리는</span> 기업시민으로서의 사회적 책임을 다함으로써
              국가와 사회발전에 이바지한다.
            </p>
          </EthicalCode>
          <FullText>
            <strong>윤리강령 전문</strong>
            <div>
              <strong>제1장 고객에 대한 윤리</strong>
              <span>1. 고객우선</span>
              <p>
                KB데이타시스템은 고객이 모든 행동의 최우선 기준임을 인식하고
                항상 고객의 입장에서 생각하고 행동하며, 고객의 요구와 기대에
                부응하는 최상의 금융 IT 서비스를 제공하여 고객감동을 위해
                노력한다.
              </p>
              <span>2. 고객보호</span>
              <p>
                KB데이타시스템은 고객의 재산과 안전 및 개인정보를 보호하고
                고객의 권익을 침해하는 어떠한 비도덕적, 비윤리적 행위도 하지
                않는다.
              </p>
              <span>3. 고객예절</span>
              <p>
                KB데이타시스템의 모든 임직원은 진실하고 호의적인 태도로 업무에
                임하며, 수준 높은 금융전문지식을 바탕으로 윤리적 마케팅 활동을
                수행한다.
              </p>
              <strong>제2장 주주와 투자자에 대한 윤리</strong>
              <span>1. 주주 및 투자자의 이익 극대화</span>
              <p>
                KB데이타시스템은 주주와 투자자의 권리를 보호하고, 주주와
                투자자의 정당한 요구와 제안을 존중하며, 투명하고 합리적인
                의사결정과 건전한 경영활동을 통하여 주주 및 투자자로부터 신뢰를
                확보하며, 안정적인 수익을 창출하여 기업의 시장가치를 제고한다.
              </p>
              <span>2. 주주의 권익 보호</span>
              <p>
                KB데이타시스템은 모든 주주를 공정하고 평등하게 대우하며, 전체
                주주의 이익을 고려하여 경영의사를 결정함으로써 주주의 이익이나
                권리가 부당하게 침해되지 않도록 한다.
              </p>
              <span>3. 적극적인 정보제공</span>
              <p>
                KB데이타시스템은 일반적으로 인정된 회계원칙에 따라 회계자료를
                기록·관리하고 재무상태와 경영성과를 투명하게 제공한다. 또한
                정확한 경영정보를 관련법규에 따라 완전하고, 공평하며,
                신속정확하고 이해가 가능하도록 적시에 공시하여 정보 이용자가
                합리적인 투자 판단을 할 수 있도록 한다.
              </p>
              <strong>제3장 국가와 사회에 대한 윤리</strong>
              <span>1. 국가 경제 및 사회발전에 기여</span>
              <p>
                KB데이타시스템은 국가경제에 기여하는 금융IT회사로서 고용의 창출
                및 조세의 성실한 납부를 통해 국가경제 발전에 이바지한다. 또한
                교육·문화예술 지원 및 기부·봉사활동 등 사회공헌활동을 실천하여
                기업의 사회적 책임을 다한다.
              </p>
              <span>2. 재해예방 및 환경보호</span>
              <p>
                KB데이타시스템은 안전관련 제반 법규와 국내외 환경관련 법규를
                준수하고 재해·위험의 예방관리 및 깨끗한 환경보전을 위해
                노력한다.
              </p>
              <span>3. 정치관여금지</span>
              <p>
                KB데이타시스템은 특정정당이나 후보를 지지하는 의견을 표명하거나,
                지원하는 행동으로 정치적 중립을 침해하는 행위를 하지 않으며,
                각종 정치 또는 자선단체 기부금은 반드시 관련 법령을 준수하여
                기부한다.
              </p>
              <strong>제4장 경쟁사 및 거래업체에 대한 윤리</strong>
              <span>1. 국내외 법규의 준수</span>
              <p>
                KB데이타시스템은 국가 및 지역사회의 일원으로
                부패·뇌물·자금세탁의 방지 등과 관련된 각종 법규를 준수할 뿐만
                아니라, 국제사회의 구성원 으로서 국제협약·규정 등 국제적으로
                통용되는 제반 규약과 현지국의 법규 및 문화와 관습을 존중한다.
              </p>
              <span>2. 다른 경쟁사와의 공정한 경쟁</span>
              <p>
                KB데이타시스템은 자유경쟁의 원칙에 따라 공정하고 자유로운
                시장경제 질서를 존중하고 다른 경쟁사와 정당하게 경쟁하며 공정
                거래 관련 법규를 준수한다.
              </p>
              <span>3. 거래업체와의 공정한 거래</span>
              <p>
                KB데이타시스템은 공정하고 투명한 업무수행을 통해 거래업체와
                상호신뢰 및 협력관계를 구축함으로써 공동의 발전을 추구한다. 또한
                우월적 지위를 이용하여 어떠한 형태의 부당한 요구도 하지 않으며,
                청렴한 계약을 체결하고 준수한다.
              </p>
              <strong>제5장 임직원에 대한 윤리</strong>
              <span>1. 인격존중과 공정한 대우</span>
              <p>
                KB데이타시스템은 임직원 개개인의 인격과 사생활을 존중하고,
                지연·혈연·학연·성별·종교·연령·장애·혼인 여부에 따른 부당한
                차별대우를 하지 않으며, 능력과 자질에 따라 평등한 기회를
                부여하고 공정하게 평가한 성과에 의해 합리적으로 보상한다.
              </p>
              <span>2. 인재 육성</span>
              <p>
                KB데이타시스템은 임직원 개개인의 자율과 창의를 존중하고, 능력을
                향상시킬 수 있는 직무기회를 공정하게 부여함으로써 직무만족도를
                높이고, 업무전문성 제고를 위한 다양한 교육 프로그램을 운영하여
                인재를 육성함과 동시에 임직원의 자아실현을 지원한다.
              </p>
              <span>3. 삶의 질 향상</span>
              <p>
                KB데이타시스템은 임직원을 회사의 가장 소중한 자산으로 여기고,
                일과 삶이 조화를 이룰 수 있는 여건 마련과 직무수행에 따른 건강과
                안전을 위한 쾌적한 근무환경을 조성한다. 또한 임직원의 독립적
                인격과 기본권을 존중하며, 상호신뢰를 바탕으로 자유로운 제안과
                건의를 할 수 있는 성숙한 조직문화를 만들기 위해 노력한다.
              </p>
              <strong>제6장 임직원의 근무 윤리</strong>
              <span>1. 기본윤리준수</span>
              <p>
                1.1. 임직원은 그룹의 경영이념을 공유하고 회사가 추구하는 목표와
                가치 실현을 위해 각자에게 부여된 사명을 성실히 수행한다.
              </p>
              <p>
                1.2. 임직원은 파벌문화를 배척하며, 상하 및 동료간 상호존중과
                원활한 의사소통을 바탕으로 하는 열린 조직문화를 만들어 간다.
              </p>
              <p>
                1.3. 임직원은 업무과 관련된 모든 회계처리를 일반적으로 인정되는
                회계원칙에 의거 수행하여야 하며, 대내외 법규 및 회계원칙에 의거
                완전하고 공평하며, 신속 정확하고 이해가 가능하도록 적시에
                제공토록 한다.
              </p>
              <p>
                1.4. 임직원은 업무와 관련된 제반 법규를 준수하고, 사회의
                기본가치 및 양심을 지키며 맡은 바 임무를 성실히 수행한다.
              </p>
              <span>2. 이해상충행위 금지</span>
              <p>
                2.1. 임직원 개인과 회사의 이해 또는 회사 내 부서간의 이해가
                상충될 경우에는 회사의 이익을 우선하여 행동 하여야 하며, 다른
                부서의 업무에 부당하게 개입하거나 위법·부당한 거래를 알선하는
                행위를 하여서는 아니된다.
              </p>
              <p>
                2.2. 임직원으로 활동하던 중 포착하게 된 사업기회는 회사에
                우선적으로 귀속시켜야 한다.
              </p>
              <p>
                2.3. 임직원은 업무수행에 영향을 주거나 줄 가능성이 있는 대상에
                대한 투자, 가까운 친인척 및 지인과의 거래, 외부강연 등으로 인한
                개인적인 이해관계가 회사의 이익과 상충되지 않도록 하여야 하며,
                이해관계의 상충이 예상되는 경우에는 윤리규범 담당부서 및
                상사에게 사전에 이해상충과 관련된 모든 사실을 밝혀야 한다.
              </p>
              <span>3. 내부자 거래 등 불공정거래 금지</span>
              <p>
                3.1. 임직원은 업무상 취득한 내부정보를 이용하여 유가증권의 매매,
                시세조종, 미공개정보 이용행위 등 개인적인 이익을 위한
                불공정거래를 하지 않는다.
              </p>
              <p>
                3.2. 임직원은 적법한 절차에 의하지 아니하고 주가에 영향을 미칠
                수 있는 미공개 중요정보를 제3자에게 제공하지 않는다.
              </p>
              <span>4. 회사 재산의 보호</span>
              <p>
                4.1. 임직원은 회사의 물적 재산, 지적재산권, 영업비밀 등 회사의
                모든 유·무형의 자산을 회사의 가치증대를 위한 업무목적으로만
                사용하여야 하며 개인적인 이익을 위하여 사용하지 않는다.
              </p>
              <p>
                4.2. 임직원은 회사자금 및 자산을 규정된 용도에 따라 회사가
                승인한 목적만을 위해 합리적이고 투명하게 사용하여야 한다.
              </p>
              <span>5. 정보의 보호 및 보안 유지</span>
              <p>
                5.1. 임직원은 업무수행 중 접한 공개되지 않은 정보를 적법하지
                않은 방법으로 외부에 제공하여서는 아니되며, 권한 없는 제3자가
                그러한 정보에 접근하지 못하게 적절히 통제하여야 하고, 만약,
                제3자가 접근하거나 접근을 시도할 경우에는 즉시 관련부서에
                보고하여야 한다.
              </p>
              <p>
                5.2. 신문, 방송 등 언론기관에의 정보제공은 정당한 권한을 부여
                받은 임직원에 의해서만 제공되어야 한다.
              </p>
              <span>6. 금품 및 향응수수 금지</span>
              <p>
                6.1. 임직원은 거래업체 등 이해관계자로부터 금품이나 향응을
                제공받지 않으며, 또한 회사업무와 관련하여 이해관계자에게
                위법하거나 사회통념상 인정되는 범위를 벗어나는 금품이나 향응을
                제공하지 않는다.
              </p>
              <p>6.2. 임직원 상호간에도 금품이나 향응을 수수하여서는 안된다.</p>
              <span>7. 정치관여 금지</span>
              <p>
                7.1. 임직원은 근무시간 중에 정치활동을 하지 않으며 회사의 조직,
                인력 및 재산을 정치적 목적으로 이용하지 않는다.
              </p>
              <p>
                7.2. 임직원 개개인의 참정권과 정치적 견해는 존중되어야 하나,
                각자의 정치적 견해나 정치관여가 KB데이타시스템의 입장으로 오해
                받지 않도록 해야 한다.
              </p>
              <span>8. 성희롱 등 금지</span>
              <p>
                임직원은 지위를 이용하거나, 육체적·언어적·시각적인 행위을 통한
                성희롱, 폭언, 폭행 등 일체의 무례하거나 위압적인 행위를 하지
                않는다.
              </p>
              <span>9. 윤리규범의 준수</span>
              <p>
                9.1. 임직원은 제반 윤리규범(헌장·강령·행동기준)을 성실히
                준수하여야 하며, 이를 위반한 경우에는 해당 행위에 책임을 진다.
              </p>
              <p>
                9.2. 임직원은 제반 윤리규범 및 법령에 반하는 행위를 강요 받거나
                그러한 부당한 행위를 인지하였을 경우에는 윤리규범 담당부서에
                신고하여야 한다.
              </p>
              <p>
                9.3. 임직원의 윤리규범 위반행위 발생시 철저한 원인 규명과 교육을
                통해 재발방지를 위해 노력하여야 한다.
              </p>
            </div>
          </FullText>
          <EthicalStandards>
            <strong>협력회사 윤리행동 기준</strong>
            <div>
              <strong>전문</strong>
              <p>
                1. KB금융그룹은 아시아 금융을 선도하는 글로벌 금융그룹으로 지속
                가능한 성장을 위해 노력하며, 상생의 동반자인 협력회사 (어떠한
                명칭이든 그룹사에 직•간접으로 상품 및 서비스를 제공하는 조직이나
                개인을 지칭함)가 가지는 가치를 존중합니다.
              </p>
              <p>
                2. KB금융그룹의 모든 그룹사는 사업유형 및 이행장소를 불문하고
                각자의 협력회사에 본 행동기준의 자발적 이행을 권고할 수 있으며,
                협력회사는 KB금융그룹이 사회적 책임 경영 원칙을 준수할 수 있도록
                본 「협력회사 윤리행동기준」을 성실히 이행하여 주시기 바랍니다.
              </p>
              <strong>제1장 근로기본권 존중 및 법령준수</strong>
              <p>
                1. 협력회사는 정신적•육체적 근로를 불문하고 근로자(계약직,
                임시직 기타 모든 유형의 근로자 포함)에게 관련 법령에 반하는
                근로나 고역(苦役)을 강요하여서는 안되며, 동료나 부하직원에게도
                이를 위반하는 업무처리를 하도록 요구하거나 지시하지 않는다.
              </p>
              <p>
                2. 협력회사는 근로자의 사생활을 보호하고, 성희롱, 폭언, 폭행,
                인신매매 등 인격적 존엄성을 해칠 우려가 있는 일체의 비인간적
                행위를 하지 않는다.
              </p>
              <p>
                3. 협력회사는 관련법령이 정한 바에 따라, 15세 미만인 자를
                근로자로 고용하지 않으며, 여성, 장애인 등 취약계층은 도덕상 또는
                보건상 유해하거나 위험한 업무를 수행하지 않도록 한다.
              </p>
              <p>
                4. 협력회사는 관련법령이 정한 바에 따라, 근로시간을 준수하고
                복리후생 제도를 운영하여야 하며, 최저임금 이상의 임금을 정해진
                시기에 정당한 방법으로 지급한다.
              </p>
              <p>
                5. 협력회사는 근로자의 능력과 자질에 따라 공정한 기회를
                부여하고, 개인의 역량과 성과에 따라 공정하게 평가•보상하며,
                개인의 능력을 최대한 발휘할 수 있도록 교육의 기회를 제공하고,
                국적, 인종, 종교, 출신지역, 학력, 성별, 연령 등을 이유로
                부당하게 차별하지 않는다.
              </p>
              <p>
                6. 협력회사는 근로자(계약직, 임시직 기타 모든 유형의 근로계약자
                포함)의 기본권을 존중하며, 기본권 침해를 방지하는 제도•절차를
                운영하는 등 근로자의 인권을 보장하기 위하여 적극 노력한다.
              </p>
              <strong>제2장 안전과 보건</strong>
              <p>
                1. 협력회사는 적절한 개인보호장비를 제공하고 위험통제장치를
                설치하거나 안전한 작업절차를 마련하여 근로자의 안전에 위협이 될
                수 위험요인을 제거하는데 최선을 다한다.
              </p>
              <p>
                2. 협력회사는 산업재해와 질병을 예방•관리할 수 있도록 근로자에게
                위생적인 근무환경과 휴게공간을 제공하여야 하며, 과중한 피로와
                스트레스 등으로 근로자의 건강이 손상되지 않도록 업무환경 개선에
                지속적으로 노력한다.
              </p>
              <strong>제3장 환경보호</strong>
              <p>
                1. 협력회사는 환경보호와 관련된 인허가 및 등록절차 등 국내외
                법령과 국제협약을 성실히 준수한다.
              </p>
              <p>
                2. 협력회사는 인체에 유해하거나 환경오염을 유발하는 물질을
                파악하고 안전하게 취급•관리하여야 하며, 폐기물 재활용, 저공해
                대체재 사용, 친환경 자원활용 등을 통해 환경오염물질의 배출이
                최소화될 수 있도록 최선을 다한다.
              </p>
              <strong>
                제4장 시장 경쟁질서 준수 및 자신의 협력회사와의 상생
              </strong>
              <p>
                1. 협력회사는 자신의 경쟁회사와 입찰•가격담합, 경쟁회사의 미공개
                정보 활용 등 불공정한 거래행위를 하지 않으며, 자유시장경쟁
                질서를 준수하고 정직하고 투명하게 업무를 처리한다.
              </p>
              <p>
                2. 협력회사는 자신의 협력회사에게 우월적 지위를 이용하여 부당한
                요구를 하거나 불공정한 행위를 강요하지 않으며, 상호이익과 공동의
                발전을 추구하며 동반자적 관계의 성장을 위해 노력한다.
              </p>
              <strong>제5장 협력회사의 준수 윤리</strong>
              <p>
                1. 협력회사는 임직원이 KB금융그룹 그룹사와 거래 시 공정하고
                투명하게 업무를 처리하여 시장경제질서를 훼손하는 일이 없도록
                통제하여야 하며, 또한, 이해관계자에게 금품•향응 또는 부당한 편익
                등을 직간접으로 제공하거나 제공받지 않도록 적극적으로
                관리•감독하는 등 KB금융그룹의 부정부패에 대한 무관용 원칙에 적극
                협력한다.
              </p>
              <p>
                2. 협력회사는 KB금융그룹 그룹사가 시행하는 청렴계약제도를 철저히
                준수하여야 한다.
              </p>
              <p>
                3. 협력회사는 관련 적용법규에 따라 고객을 비롯한 개인의 정보를
                보호하며, 이를 위한 기술적•물리적 조치를 취한다.
              </p>
              <p>
                4. 협력회사는 국가와 사회의 가치관을 존중하고, 관련 법령을
                준수하며, 사업활동의 기반이 되는 지역사회와 사회적 책임을
                공유하며 지역사회 발전을 위해 적극 노력한다.
              </p>
            </div>
          </EthicalStandards>
          <EthicalReport>
            <strong>윤리신고센터</strong>
            <div class="desc">
              직무와 관련하여 외부의 이해관계자로부터 금품 또는 향응을 수수하는
              행위, 직위를 이용하여 부당한 이득을 얻거나 부정한 수단이나 의도를
              가지고 사손을 끼치는 행위, 기타 외부 관계자와 관련된 비윤리적 행위
              등에 대한 제보를 받으며, 사실에 근거하지 않는 비방, 개인 사생활과
              관련된 사항은 처리되지 않습니다.
            </div>
            <div class="subtitle">신고채널</div>
            <div class="round-box">
              <div>
                <span>이메일</span>{" "}
                <a href="mailto:whistle.kdg@kbfg.com">whistle.kdg@kbfg.com</a>
              </div>
              <div>
                <span>전화번호</span> <a href="tel:02-702-6002">02-702-6002</a>
              </div>
            </div>
          </EthicalReport>
          <PopupCloseButton>
            <a href="/#" onClick={props.onClose}>
              닫기
            </a>
          </PopupCloseButton>
        </EthicalContent>
      </EthicalPopupStyle>
    </Popup>
  );
};

export default EthicalPopup;
