import React,{useEffect, useState} from 'react';
import {htmlDecode} from '../../../lib/common.jsx';
import {CommonContainer} from '../../../components/Grid';
import MainContainer from '../../../components/MainContainer';
import {BiddingGetPost} from '../../../api/board';
import { useParams } from 'react-router';
import Button from '../../../components/Button';
import { 
    BoardTitle,
    BoardContainer, 
    SingleSubject,
    SingleMeta,
    SingleContent,
    SingleButtons
} from '../styled';

const BoardView = (props) => {
    const [errorMsg, SeterrorMsg] = useState('');
    const [board_data, set_board_data] = useState({
        post_id:0,
        post_title:'',
        post_content:''
    })
    const params = useParams();
    useEffect(() => {
        if(board_data.post_id != params.id) {
            BiddingGetPost({post_id: params.id})
            .then((response) => {
                console.log(response);
                if(response.data.code == '200') {
                    const board_data_ = response.data.body;
                    set_board_data({
                        post_id: board_data_.ID,
                        post_title: board_data_.post_title,
                        post_content: board_data_.post_content,
                        post_author: board_data_.post_author,
                        post_date: board_data_.post_date
                    });
                } else {
                    SeterrorMsg('게시물을 찾을 수 없습니다.');
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    });

    if(errorMsg)
        return (
            <CommonContainer>
                <BoardTitle>
                    글쓰기
                </BoardTitle>
                <BoardContainer>
                    <h3>{errorMsg}</h3>
                    <Button type="boxed" size="small" onClick={(e) => window.history.back(1)}>뒤로가기</Button>
                </BoardContainer>
            </CommonContainer>
        );

    return (
        <>  
        <MainContainer>
            <CommonContainer>
                <BoardTitle>
                    입찰공고
                </BoardTitle>
                 <BoardContainer>
                    <SingleSubject>{board_data.post_title}</SingleSubject>
                    <SingleMeta>
                        <span>{board_data.post_author}</span>
                        <span>{board_data.post_date}</span>
                    </SingleMeta>
                    <SingleContent dangerouslySetInnerHTML={{
                            __html: board_data.post_content
                        }}></SingleContent>
                    <SingleButtons>
                        <Button type="boxed" size="small" to={'/board/bidding'}>목록보기</Button>
                    </SingleButtons>
                 </BoardContainer>
            </CommonContainer>
        </MainContainer>
        </>
    );
};

export default BoardView;