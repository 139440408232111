import gsap from "gsap";
import React, { useEffect, useRef, useState } from "react";
import ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import LoadInView from "../../components/LoadInView";
import MainContainer from "../../components/MainContainer";
import SectionBusiness from "./components/SectionBusiness";
import SectionDX from "./components/SectionDX";
import SectionESG from "./components/SectionESG";
import SectionMain from "./components/SectionMain";
import SectionMenu from "./components/SectionMenu";
import SectionOverview from "./components/SectionOverview";
import SectionRecruit from "./components/SectionRecruit";
import SectionStory from "./components/SectionStory";
import SectionVision from "./components/SectionVision";
import SectionWayWorking from "./components/SectionWayworking";
import { HomeWrapper } from "./styled";
// import { motion } from 'framer-motion';
import { GetMainSections } from "../../api/global";
import SectionYoutube from "../About/SectionYoutube";

ScrollMagicPluginGsap(ScrollMagic, gsap);

const Qrdemo = () => {
  let topBoxEl = useRef(null);
  let sliderTitleEl = useRef(null);
  const [main_sections, set_main_sections] = useState(0);
  const [is_load, set_is_load] = useState(0);

  const [active, setActive] = useState(true);

  const scrollmagic = () => {
    var controller = new ScrollMagic.Controller();
    if (main_sections) {
      main_sections.map((item, key) => {
        switch (item.slug) {
          case "overview":
            var overview = new ScrollMagic.Scene({
              triggerElement: "#overview",
              duration: "100%",
            })
              .setClassToggle("#overview_nav", "active")
              .addTo(controller);
            break;
          case "business":
            var business = new ScrollMagic.Scene({
              triggerElement: "#business",
              duration: "100%",
            })
              .setClassToggle("#business_nav", "active")
              .addTo(controller);
            break;
          case "dx":
            var dx = new ScrollMagic.Scene({
              triggerElement: "#dx",
              duration: "100%",
            })
              .setClassToggle("#dx_nav", "active")
              .addTo(controller);
            break;
          case "vision":
            var vision = new ScrollMagic.Scene({
              triggerElement: "#vision",
              duration: "100%",
            })
              .setClassToggle("#vision_nav", "active")
              .addTo(controller);
            break;
          case "wayworking":
            var wayworking = new ScrollMagic.Scene({
              triggerElement: "#wayworking",
              duration: "100%",
            })
              .setClassToggle("#wayworking_nav", "active")
              .addTo(controller);
            break;
          case "busirecruitness":
            var recruit = new ScrollMagic.Scene({
              triggerElement: "#recruit",
              duration: "100%",
            })
              .setClassToggle("#recruit_nav", "active")
              .addTo(controller);
            break;
          case "esg":
            var esg = new ScrollMagic.Scene({
              triggerElement: "#esg",
              duration: "100%",
            })
              .setClassToggle("#esg_nav", "active")
              .addTo(controller);
            break;
          case "youtube":
            var youtube = new ScrollMagic.Scene({
              triggerElement: "#youtube",
              duration: "100%",
            })
              .setClassToggle("#youtube_nav", "active")
              .addTo(controller);
            break;
          case "story":
            var story = new ScrollMagic.Scene({
              triggerElement: "#story",
              duration: "100%",
            })
              .setClassToggle("#story_nav", "active")
              .addTo(controller);
            break;
          default:
            return;
        }
      });
    }
  };
  useEffect(() => {
    if (!is_load) {
      GetMainSections()
        .then((response) => {
          if (response.data.code == "200") {
            set_main_sections(response.data.body);
            set_is_load(1);
            scrollmagic();
          }
        })
        .catch((err) => {
          console.log(err);
          set_is_load(1);
        });
    }
  });
  return (
    <MainContainer header_type="white">
      <HomeWrapper>
        <SectionMain />
        {main_sections
          ? main_sections.map((item, key) => {
              switch (item.slug) {
                case "overview":
                  return (
                    <LoadInView id="overview">
                      <SectionOverview />
                    </LoadInView>
                  );
                case "business":
                  return (
                    <LoadInView id="business">
                      <SectionBusiness />
                    </LoadInView>
                  );
                case "dx":
                  return (
                    <LoadInView id="dx">
                      <SectionDX />
                    </LoadInView>
                  );
                case "vision":
                  return (
                    <LoadInView id="vision">
                      <SectionVision />
                    </LoadInView>
                  );

                case "wayworking":
                  return (
                    <LoadInView id="wayworking">
                      <SectionWayWorking />
                    </LoadInView>
                  );
                case "recruit":
                  return (
                    <LoadInView id="recruit">
                      <SectionRecruit />
                    </LoadInView>
                  );
                case "esg":
                  return (
                    <LoadInView id="esg">
                      <SectionESG />
                    </LoadInView>
                  );
                case "youtube":
                  return (
                    <LoadInView id="youtube">
                      <SectionYoutube />
                    </LoadInView>
                  );
                case "story":
                  return (
                    <LoadInView id="story">
                      <SectionStory />
                    </LoadInView>
                  );
                default:
                  return;
              }
            })
          : ""}
        <div className={active ? "qr active" : "qr"}>
          <div
            className={active ? "overlay active" : "overlay"}
            onClick={() => setActive(false)}
          />
          <div className={active ? "inner active" : "inner"}>
            <img src={process.env.PUBLIC_URL + "/전자명함.png"} />
          </div>
        </div>
      </HomeWrapper>
      <SectionMenu items={main_sections} />
    </MainContainer>
  );
};

export default Qrdemo;
