import styled from 'styled-components';

//StoryHeader//

export const StoryHeaderWrapper = styled.div`
    box-sizing: border-box;
    height: 400px;
    background: #FFDC00;
`;

export const StoryLogo = styled.h2`
    position: absolute;
    top: 44px;
    left: 226px;
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 52px;
    color: #444444;
`;


export const StoryHeaderMenu = styled.div`
    float: right;
    margin-top: 42px;
    margin-right: 223px;
    li{
        float: left;
        margin-left: 60px;
    }
    a{
        font-family: "KBFG Display";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 40px;
        text-align: center;
        color: #444444;
        :focus{
            outline: none;
        }      
    }
    button{
        background: #FFFFFF;
        border: none;
        border-radius: 23px;   
        width: 157px;
        height: 46px;
        font-family: "KBFG Display";
        font-style: normal;
        font-weight: 500;
        font-size: 19px;
        line-height: 24px;
        text-align: center;
        color: #444444;     
    }
`;

export const StoryTitle = styled.div`
    padding-top: 220px;
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 60px;
    color: #444444;
`;

//StoryHeader//

//TagHeader//

export const TagHeaderWrapper = styled.div`
    box-sizing: border-box;
    height: 400px;
    background: #FFDC00;
`;

export const TagLogo = styled.h2`
    position: absolute;
    top: 44px;
    left: 226px;
    img{
        width: 209px;
    }
`;


export const TagHeaderMenu = styled.div`
    float: right;
    margin-top: 42px;
    margin-right: 223px;
    li{
        float: left;
        margin-left: 60px;
    }
    a{
        font-family: "KBFG Display";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 40px;
        text-align: center;
        color: #444444;
        :focus{
            outline: none;
        }      
    }
    button{
        background: #FFFFFF;
        border: none;
        border-radius: 23px;   
        width: 157px;
        height: 46px;
        font-family: "KBFG Display";
        font-style: normal;
        font-weight: 500;
        font-size: 19px;
        line-height: 24px;
        text-align: center;
        color: #444444;     
    }
`;

export const TagTitle = styled.div`
    padding-top: 220px;
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 60px;
    color: #444444;
`;

//TagHeader//

//StoryBox//

export const StoryBackground = styled.div`
    position: relative;
    height: 531px;
    width:100%;
    padding: 93px 0px;
    background-image: url(${props=>props.imageUrl ? props.imageUrl : ''}); 
    background-size: cover;
    background-position: center center; 
    background-repeat: no-repeat;
    transition: .3s;

    @media ${props => props.theme.tablet} {
        height: 500px;
    }
    @media ${props => props.theme.mobile} {
        height: 480px;
        :after {
            display: block;
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0.3);
            top: 0px;
            left: 0px;
        }
        & > div {
            z-index: 2;
        }
        & > div:first-child {
            position:relative;
        }
    }
`;
export const ProgressBar = styled.div`
    position:absolute;
    width:100%;
    height:10px;
    background:rgba(255,255,255,0.3);
    bottom:0px;
    left:0px;
    div {
        width:${props=>props.percent}%;
        height:10px;
        background:rgba(255,255,255,0.7);
        .slick-active > div & {
            animation: timeline 7s;  
            -webkit-animation-timing-function: linear; 
            animation-timing-function: linear;
            @keyframes timeline {
                0%{
                    width: 0px;
                }
                100%{
                    width: 100%;
                }
            }

        }
    }
`;
export const StoryBox = styled.div`
    height: 344px;
`;

//StoryBox//

//StoryBox//

export const StorySliderWrapper = styled.div`
    position:relative;
    overflow:hidden;
    height: 531px;
    :after {
        content:"";
        display:block;
        clear:both;
    }

`;
export const Avatar = styled.div`
    background-image:url(${props=>props.url});
    background-size: cover;
    position: absolute;
    bottom: 48px;
    right: 60px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    @media ${props => props.theme.laptop} {
        
    }
    @media ${props => props.theme.tablet} {

    }
    @media ${props => props.theme.mobile} {
        bottom: 38px;
        right: 30px;
    }
`;
export const StoryBoxWrapper = styled.div`
    width: 500px;
    position: relative;
    box-sizing: border-box;
    padding: 50px 65px;
    background: rgba(255, 255, 255, 0.96);
    cursor:pointer;
    transition: .3s;
    :hover {
        background: rgba(255, 255, 255, 0.8);
    }
    label{
        margin-right: 10px;
    }
    @media ${props => props.theme.tablet} {
        width: 480px;
        padding: 40px 50px;
        background: rgba(255, 255, 255, 0.92);
        label{
            
        }
    }
    @media ${props => props.theme.mobile} {
        width: 100%;
        padding: 40px;
        background: rgba(255, 255, 255, 0.97);
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);

    }
`;

export const BoxTitle = styled.div`
    margin-top: 24px;
    margin-bottom: 50px;
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 52px;
    color: #000000;
    @media ${props => props.theme.tablet} {
        font-size: 28px;
        line-height: 42px;
        margin-top: 10px;
        margin-bottom: 66px;
        font-weight: 600;
    }
    @media ${props => props.theme.mobile} {
        font-size: 22px;
        line-height: 30px;
        margin-top: 15px;
        margin-bottom: 60px;
    }
`;

//StoryBox//

//Tab//

export const TabStyle = styled.ul`
    display: inline-block;
    margin-bottom: 0px;
    position : relative;
    line-height: 37px;
    top: 8px;
    li{
        margin-right: 60px;
        display:inline-block;
    }
    a{  
        display:block;
        font-family: "KBFG Display";
        font-style: normal;
        font-weight: 600;
        font-size: 21px;
        line-height: 26px;
        text-align: center;
        color: #BFBFBF;
        text-decoration: none;
        :focus
            {outline: none;
        }
        
    }
    div{
        visibility: hidden;
        line-height: 10px;
        
    }
    li.active a {
        color: #FFBC00;
        position: relative;
        div{
            visibility: visible;
        }
        :after{
            display: block;
            position: absolute;
            content: "";
            top: -11px;
            left: 50%;
            transform: translateX(-50%);
            width: 6px;
            height: 6px;
            background: #FFBC00;
            border-radius: 50%;
        }
    }
    @media ${props => props.theme.tablet} {
        li{
            margin-right: 60px;
            margin-bottom: 50px;
        }
        a{
            font-size: 18px;
            line-height: 25px;
        }        
    }
    @media ${props => props.theme.mobile} {
        li{
            margin-right: 16px;
            margin-bottom: 40px;
        }
        a{
            font-size: 16px;
            line-height: 18px;
        }
        div{
            margin-bottom: -30px;
        }
    }
`;


//Tab//

export const BadgeSection = styled.div`
    max-height: 92px;
    overflow: hidden;
    label {
        margin-right: 12px;
        margin-bottom: 12px;
    }
    @media ${props => props.theme.mobile}{
        max-height: 82px;
        label{
            padding: 0px 11px;
            font-size: 13px;
            margin-right: 10px;
            margin-bottom: 10px;
        }
    }
`;

export const CardSection = styled.div`
    margin: 40px -20px 70px -20px;
    max-width: none;
    @media ${props=>props.theme.laptop} {
        
    }
    @media ${props=>props.theme.tablet} {
        margin: 10px -20px 70px -20px;
        & > div {
            
        }
    }
    @media ${props=>props.theme.mobile}{
        margin: 0px -20px 70px -20px;
    }
`;



//slick//

export const settings = {
    infinite: true,
    autoplay: true,
    speed: 1000,
    arrows: true,
    slidesToShow: 1,
    rows: 1,
    slidesToScroll: 1,
    dots : 0, 		// 스크롤바 아래 점으로 페이지네이션 여부
    autoplaySpeed : 2000, 		// 자동 스크롤 시 다음으로 넘어가는데 걸리는 시간 (ms)
    pauseOnHover : true,	
  };

  export const SlickWrap = styled.div`
    margin: 45px auto;
    border: 1px solid red;

    .slick-arrow {
        z-index:2;
    }
    .slick-initialized .slick-slide {
        display: block;
    }
    .slick-list {
        overflow:visible;
        padding: 0px !important;
    }

    .slick-prev:before {
        opacity: 1;
        color: black;
    }
    .slick-next:before {
        opacity: 1;
        color: black;
    }
    .slick-slide:not(.slick-active) {
    opacity: .4;
    }
  `;

//slick//