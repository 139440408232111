import axios from "axios";

// const jwt_token = "bbbG6Yv%|:hw3::6k>NA(U+9DD+{XMoskhJg=/-Rn9EGXT67Vpe@oK!I_p1dmaXD";

// axios.defaults.withCredentials = true;

// const localToken = localStorage.getItem('user_token');

// if(localToken) {
//     axios.defaults.headers.common = {'Authorization': `Bearer ${localToken}`}
// }

const baseURL = (() => {
  // return 'https://kds.easypress.me/wp-json/';
  return "https://wp.daitso.kbds.co.kr/wp-json/";
})();

const defaultClient = axios.create({
  baseURL,
  //   jwt_token,
  //   withCredentials: true,
});

export default defaultClient;
