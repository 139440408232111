import styled from 'styled-components';

export const UserWrapper = styled.div`
    margin-top: 83px;
`;

export const Path = styled.div`
    margin-bottom: 45px;
    font-family: "KBFG Text";
    font-style: normal;
    font-size: 13px;
    line-height: 17px;
    color: #BFBFBF;
    span{
        color: #BFBFBF;
        :after{
            content: ">";
            margin: 0 14px;
        }
    }
    @media ${props => props.theme.laptop}{
        padding-left: 20px;
    }
    @media ${props => props.theme.tablet}{
        padding-left: 0px;
    }
`;

export const UserContent = styled.div`
    font-family: "KBFG Text";
    font-style: normal;
    text-align: center;
    position: relative;
    z-index: 1;
    .userimg{
        background-image: url(${props=>props.user_image});
        width: 145px;
        height: 145px;
        margin: 0 auto;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 100px;
        position: relative;
    }
    strong{
        margin-top: 27px;
        font-size: 28px;
        line-height: 36px;
        color: #444444;
        display: block;
        small{
            margin-top: 9px;
            font-size: 18px;
            line-height: 23px;
            color: #ffac00;
            display: block;
        }
        span{
            margin-top: 5px;
            font-size: 18px;
            line-height: 23px;
            color: #6c6c6c;
        }
        img{
            width: 3px;
            height: 3px;
            margin: 0px 5px;
            margin-bottom: 5px;
        }
    }
`;

export const UserTab = styled.ul`
    margin-top: 45px;
    border-top: 1px solid #eeeeee;
    font-family: "KBFG Display";
    text-align: center;
    font-style: normal;
    position: relative;
    transition: .3s;
    li{
        display: inline-block;
        text-decoration: none;
    }
    a{
        transition: .3s;
        margin-left: 120px;
        padding-top: 20px;
        font-size: 23px;
        line-height: 34px;
        font-weight: 600;
        position: relative;
        display: inline-block;
        text-decoration: none;
        color: #bfbfbf;
        border-top: 3px solid transparent;
        letter-spacing: -0.55px;
        :focus
            {outline: none;
        }
        :hover{
            color: #000000;

        }
    }
    span{
        /* position: absolute; */
        display: inline-block;
        margin-left: 5px;
        margin-top: 24px;
        font-size: 16px;
        font-weight: 300;
        line-height: 32px;
        font-weight: normal;
        color: #9e9e9e;
        vertical-align:top;
        
    }
    li:first-child a {
        margin-left: 0px;
    }
    li:hover a,
    li.active a {
        border-top: 3px solid #ffcc00;
        color:#000;
    }
    @media ${props => props.theme.tablet} {
        a{
            font-size: 22px;
        }
    }
    @media ${props => props.theme.mobile} {
        a{
            margin-left: 80px;
        }
    }
`;

//index styled end

export const AboutWrapper = styled.div`
    margin-top: 85px;
`;

export const AboutContent = styled.div`
    font-family: "KBFG Display";
    font-style: normal;
    position: relative;
    padding-bottom:40px;
    h2{
        font-size: 32px;
        line-height: 30px;
        color: #000000;
    }
    p{
        margin-top: 36px;
        font-size: 18px;
        line-height: 31px;
        color: #888888;
    }
 
`;

export const AboutContentBadge = styled.div`
    position: relative;
    margin-top: 50px;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 83px;
    font-family: "KBFG Text";
    font-style: normal;
    font-size: 20px;
    line-height: 30px;
    label{
        margin-right: 20px;
    }
`;

export const AboutKeyWord = styled.div`
        font-family: "KBFG Display";
        font-style: normal;
        margin-top: 65px;
    h2{
        font-size: 32px;
        line-height: 30px;
        color: #000000;
    }
`;

export const TagBox = styled.div`
    margin-top: 36px;
    border: 1px solid #eeeeee;
    width: 100%;
    height: 497px;
    @media ${props => props.theme.mobile} {
        
    }
`;

export const AboutWebSite = styled.div`
    margin-top: 145px;
    margin-bottom: 380px;
    font-family: "KBFG Display";
    font-style: normal;
    position: relative;
    h2{
        font-size: 32px;
        line-height: 30px;
        color: #000000;
    }
    @media ${props => props.theme.tablet} {
        margin-bottom: 280px;
    }
    @media ${props => props.theme.mobile} {
        margin-bottom: 180px;
    }
`;
export const FigureIcon = styled.div`
    position: absolute; 
    top: 60px;
    li{
        padding-right: 70px;
        display: inline-block;
        position: relative;
        a{
            width: 52px;
            height: 52px;
            position: absolute;
            box-sizing: border-box;
            border: 1px solid #EEEEEE;
            border-radius: 50%;
            text-align: center;
            line-height: 52px;
        }
        img{
            display: inline-block;
        }
    }
`;


//about styled end


export const LoopWrapper = styled.div`
    margin-top: 43px;
`;

export const BadgeList = styled.div`
    margin-bottom: 27px;
    label{
        margin-right: 10px;
        margin-bottom: 13px;
    }
    @media ${props => props.theme.laptop}{
        padding: 0px 20px;
    }
    @media ${props => props.theme.tablet}{
        padding: 0px 0px;
    }
    @media ${props => props.theme.mobile} {
        padding: 0px 20px;
        label{
            padding: 0px 11px;
        }
    }
`;


export const LoopRelevant = styled.div`
    margin-bottom: 100px;
    font-family: "KBFG Display";
    font-style: normal;
    .RelevantBox{
        border-top: 1px solid #eeeeee;
        padding: 30px 0px;
        display: block;
        cursor:pointer;
        transition: .5s;
        &:hover {
            background: #f7f7f7;
        }
        div:last-child {
            position: relative;
        }
        a {
            text-decoration:none;
        }
    }

    strong{
        margin-top: 18px;
        margin-bottom: 15px;
        font-size: 18px;
        line-height: 26px;
        display:block;
        color: #ffac00;
    }
    h3{
        font-size: 24px;
        line-height: 1.5;
        color: #000000;
    }
    p{
        font-family: "KBFG Display";
        font-style: normal;
        margin-top: 15px;
        font-weight: normal;
        font-size: 18px;
        line-height: 30px;
        color: #7a7a7a;
        display: inline-block;
    }
    span{
        position: absolute;
        bottom: 0px;
        right: 0px;
        font-family: "KBFG Text";
        font-style: normal;
        float: right;
        font-size: 15px;
        line-height: 32px;
        color: #c1c1c1;
    }
    @media ${props => props.theme.labtop} {
        ${props => {
            if(props.type == 'small') {
                return `max-width:800px;`;
            } else {
                return `max-width:1180px;`;
            }
        }}
        .RelevantBox{
            padding-left: 20px;
            padding-right: 20px;
        }
        span{
            bottom: 15px;
        }

    }
    @media ${props => props.theme.tablet} {
        .RelevantBox{
            padding-left: 0px;
            padding-right: 0px;
        }
        h3{
            margin-top: 10px;
            margin-bottom: 5px;
            font-size: 22px;
            line-height: 1.2;
        }
        strong{
            margin-bottom: 0px;
            font-size: 16px;
            line-height: 28px;
        }
        p{
            margin-top: 5px;
        }
        span{
            bottom: 15px;
        }
    }
    @media ${props => props.theme.mobile} {
        max-width:100%;
        padding-left:20px;
        padding-right: 20px;
        h3{
            margin-top: 5px;
            margin-bottom: 5px;
            font-size: 20px;
            line-height: 32px;
        }
        strong{
            font-size: 14px;
        }
        span{
            position: relative;
            float: left;
            
        }
    }
`;
export const RelevantImg = styled.div`
    ${props => props.url ? `background-image: url("${props.url}");` : ''}
    background-repeat: no-repeat;
    background-size: cover;
    width: 345px;
    height: 230px;
    margin-right: 40px;
    display: inline-block;
    position: relative;
    @media ${props => props.theme.mobile} {
        width: 100%;
        height: 190px;
    }
`;

export const RelevantBadge = styled.div`
    margin-top: 21px;
    display: block;
    position: absolute;
    bottom: 15px;
    label{
        margin-right: 10px;
    }
    @media ${props => props.theme.tablet} {
        label{
            padding: 0px 11px;
        }
    }
    @media ${props => props.theme.mobile} {
        position: static;
        margin-top: 15px;
        margin-bottom: 25px;
        label{
            padding: 0px 11px;
        }
    }
`;

