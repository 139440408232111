import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { GetPeoplePosts } from "../../../../api/people";
import {
  Card,
  settings,
  SlickWrap,
  StorySliderStyle,
  StorySliderWraaper,
} from "./styled";
// import LoadInView from '../../../components/LoadInView';
// import {animteScrollTo} from '../../../lib/common';

const YoubtubeSlider = (props) => {
  const [post_list, update_post_list] = useState(0);
  const [current_paged, set_current_paged] = useState(0);
  const [total_page, set_total_page] = useState(0);
  const [list_tags, set_list_tags] = useState(0);
  const [paged, set_paged] = useState(1);
  useEffect(() => {
    GetPeoplePosts({
      postsPerPage: 10,
      paged,
      slug: props.youtubeCategory,
    })
      .then((response) => {
        if (response.data.body.total) {
          set_total_page(response.data.body.total);
          set_current_paged(response.data.body.paged);
          update_post_list(response.data.body.posts);
          set_list_tags(response.data.body.tags);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [paged, props.youtubeCategory]);
  return (
    <StorySliderWraaper style={props.style ? props.style : {}}>
      {/* react slick slide button, bottom 65px */}
      <StorySliderStyle>
        {post_list ? (
          <SlickWrap>
            <Slider {...settings}>
              {post_list
                ? post_list.slice(0, 3).map((post, key) => (
                    <Card
                      key={`post-${key}`}
                      onClick={() => {
                        props.onShow(post.youtubeLink);
                      }}
                    >
                      <div
                        style={{
                          backgroundImage: `url(${post.thumbnail})`,
                        }}
                      >
                        <div className="btn" />
                        <div className="content">
                          <div>{post.postTitle}</div>
                          <div className="youtube-desc">
                            <p>{post.desc}</p>
                          </div>
                        </div>
                      </div>
                    </Card>
                  ))
                : "로딩"}
            </Slider>
          </SlickWrap>
        ) : (
          <SlickWrap>
            <Slider {...settings}>
              {[1].map((post, key) => (
                <Card>
                  <div
                    style={{
                      backgroundImage: `url('/people-youtube-01.png')`,
                    }}
                  ></div>
                </Card>
              ))}
            </Slider>
          </SlickWrap>
        )}
      </StorySliderStyle>
    </StorySliderWraaper>
  );
};

export default YoubtubeSlider;
