import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import { CommonContainer } from "../../../components/Grid";
import Button from "../../../components/Button";
import { motion } from "framer-motion";
import { TopBoxWrapper, TopBox, TimeLineWrapper, TimeLine } from "../styled";
import { GetMainSlider } from "../../../api/slider";

const SectionMain = (props) => {
  const [playbutton, on_playbutton] = useState(0);
  const [slideList, SetSliderList] = useState(0);
  const [slideTotal, SetSliderTotal] = useState(0);

  const ToggleMenu = () => {
    if (!playbutton) {
      on_playbutton(1);
    } else {
      on_playbutton(0);
    }
  };

  useEffect(() => {
    if (!slideList) {
      GetMainSlider()
        .then((response) => {
          console.log(response);
          if (response.data.code == "200") {
            SetSliderList(response.data.body.posts);
            SetSliderTotal(response.data.body.total);
          }
        })
        .catch((err) => console.log(err));
    }
  });

  return (
    <CommonContainer type="wide">
      <TopBoxWrapper header_type={props.type}>
        <SlickWrap>
          <Slider {...settings}>
            {slideList ? (
              slideList.map((slide, key) => (
                <TopBox className="section" image={slide.post_image}>
                  <CommonContainer>
                    <motion.div
                      className="section-wrap"
                      initial={{ opacity: 0, y: 140 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{
                        duration: 1,
                        ease: "easeOut",
                        staggerChildren: 0.5,
                      }}
                    >
                      <TimeLineWrapper>
                        <span>
                          {key + 1}/{slideTotal}
                        </span>
                        <TimeLine onClick={ToggleMenu} isplay={playbutton} />
                      </TimeLineWrapper>
                      <h2
                        dangerouslySetInnerHTML={{
                          __html: slide.post_title,
                        }}
                      ></h2>
                      <p>{slide.slider_desc}</p>
                      <Button
                        type="primary"
                        color="white"
                        icon="arrow"
                        to={slide.slider_link}
                      >
                        자세히보기
                      </Button>
                    </motion.div>
                  </CommonContainer>
                </TopBox>
              ))
            ) : (
              <TopBox
                className="section"
                image={
                  "https://wp.daitso.kds.co.kr/wp-content/plugins/ep-manager/assets/images/common/HomeBg1.png"
                }
              >
                <CommonContainer>
                  <motion.div
                    className="section-wrap"
                    initial={{ opacity: 0, y: 140 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                      duration: 1,
                      ease: "easeOut",
                      staggerChildren: 0.5,
                    }}
                  >
                    <TimeLineWrapper>
                      <span>1/1</span>
                      <TimeLine onClick={ToggleMenu} isplay={playbutton} />
                    </TimeLineWrapper>
                    <h2>
                      Digital Transformation을
                      <br />
                      소개합니다.
                    </h2>
                    <p>KB데이타시스템이 선도합니다.</p>
                    <Button
                      type="primary"
                      color="white"
                      icon="arrow"
                      to={"/vision/#dx"}
                    >
                      자세히보기
                    </Button>
                  </motion.div>
                </CommonContainer>
              </TopBox>
            )}
          </Slider>
        </SlickWrap>
      </TopBoxWrapper>
    </CommonContainer>
  );
};

const settings = {
  infinite: true,
  autoplay: 1,
  speed: 300,
  arrows: 1,
  slidesToShow: 1,
  rows: 1,
  fade: true,
  slidesToScroll: 1,
  autoplaySpeed: 7000, // 자동 스크롤 시 다음으로 넘어가는데 걸리는 시간 (ms)
  pauseOnHover: false,
  // afterChange: () => this.setState(state => ({ updateCount: state.updateCount + 1 })),
  // beforeChange: (current, next) => this.setState({ slideIndex: next }),
  responsive: [
    {
      breakpoint: 1800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const SlickWrap = styled.div`
  .slick-arrow {
    z-index: 3;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-list {
    /* padding: 0px !important; */
    width: 100%;
  }
  .slick-slide:not(.slick-active) {
  }
  .slick-arrow {
  }
  .slick-prev,
  .slick-next {
    font-size: 0;
    position: absolute;
    bottom: auto;
    color: red;
    border: 0;
    background: none;
    z-index: 1;
    cursor: pointer;
    background: #fff;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    transition: 0.8s;
    opacity: 0;
  }
  .slick-slider:hover .slick-next,
  .slick-slider:hover .slick-prev {
    opacity: 0.8;
    :hover {
      opacity: 1;
    }
  }
  .slick-prev {
    left: 80px;
    top: 50%;
    margin-top: -30px;
  }
  .slick-prev:before {
    content: url("arrow-before.svg");
  }
  .slick-next {
    right: 80px;
    top: 50%;
    margin-top: -30px;
  }
  .slick-next:before {
    content: url("arrow-next.svg");
  }
  .slick-prev:hover:after,
  .slick-next:hover:after {
    color: red;
  }
  @media ${(props) => props.theme.laptop} {
    .slick-prev,
    .slick-next {
      border-radius: 0%;
      width: 0px;
      height: 0px;
    }
    .slick-prev:before {
      display: none;
    }
    .slick-next:before {
      display: none;
    }
  }
`;

export default SectionMain;
