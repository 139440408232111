import styled from "styled-components";

export const Wrapper = styled.div`
  border-top: 1px solid #e9e9e9;
  padding: 27px 0 50px 0;
  max-height: 818px;
  overflow: auto;
  margin-top: -10px;

  .box {
    display: flex;
    flex-wrap: wrap;
    gap: 27px;
    .card {
      position: relative;
      flex: 100%;
      height: 254px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 0 30px 0 30px;
      overflow: hidden;
      padding: 51px 0 0 43px;
      cursor: pointer;
      :after {
        position: absolute;
        display: block;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.26);
      }
      :hover {
        :before {
          transform: scale(1.06);
        }
      }
      :before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: inherit;
        background-size: cover;
        transform-origin: center;
        transition: transform 0.4s ease-in-out;
      }
    }
    .card:nth-child(1) {
      background-image: url("/people-live-01.png");
    }
    .card:nth-child(2) {
      background-image: url("/people-live-03.png");
    }
    .card:nth-child(3) {
      background-image: url("/people-live-04.png");
    }
    .card:nth-child(4) {
      background-image: url("/people-live-02.png");
    }

    .desc {
      position: relative;
      font-family: "KBFG Display";
      font-weight: 700;
      font-size: 26px;
      line-height: 41px;
      letter-spacing: 0.01em;
      color: #ffffff;
      z-index: 1;
      margin-bottom: 26px;
    }
    .btn {
      position: relative;
      z-index: 1;
      width: 43px;
      height: 43px;
      background-image: url("/play-btn-trans.png");
      background-size: contain;
    }
  }
  @media ${(props) => props.theme.mobile} {
    .box {
      gap: 18px;
      .card {
        padding: 30px 20px 20px 20px;
        height: 196px;
      }
      .desc {
        font-size: 17px;
        line-height: 24px;
      }
    }
  }
`;
export const DetailWrapper = styled.div`
  border-top: 1px solid #e9e9e9;
  padding: 27px 0 50px 0;
  max-height: 818px;
  overflow: auto;
  margin-top: -10px;

  .box {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 27px;
    :before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: inherit;
      background-size: cover;
      transform-origin: center;
      transition: transform 0.4s ease-in-out;
    }
  }
  .card:nth-child(1) {
    background-image: url("/people-live-01.png");
  }
  .card:nth-child(2) {
    background-image: url("/people-live-03.png");
  }
  .top {
    position: relative;
    height: 300px;
    .detail-video {
      width: 100%;
      height: 330px;
    }
    .detail-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .content {
    position: relative;
    padding: 48px;
    .title {
      width: 51px;
      height: 22px;
      left: 660px;
      top: 732px;

      font-family: "KBFG Display";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 160%;
      letter-spacing: 0.01em;

      color: #ffbc00;
      margin-bottom: 10px;
    }
    .main-title {
      font-family: "KBFG Display";
      font-weight: 700;
      font-style: normal;
      font-size: 22px;
      line-height: 140%;
      letter-spacing: 0.01em;
      color: #000;
      margin-bottom: 18px;
    }
    .date {
      width: 112px;
      height: 22px;
      margin-bottom: 29px;
      font-family: "KBFG Display";
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 160%;
      letter-spacing: 0.01em;

      color: #999999;
    }
    .desc {
      p {
        font-family: "KBFG Text";
        font-weight: 300;
        font-size: 16px;
        line-height: 25.6px;
        color: #222;
      }
    }
  }
  .btn {
    position: relative;
    z-index: 1;
    width: 43px;
    height: 43px;
    background-image: url("/play-btn-trans.png");
    background-size: contain;
  }

  @media ${(props) => props.theme.mobile} {
    .box {
      gap: 18px;
      .card {
        padding: 30px 20px 20px 20px;
        height: 196px;
      }
      .desc {
        font-size: 17px;
        line-height: 24px;
      }
    }
  }
`;
export const ItemTopWrapper = styled.div`
  position: relative;
  .content-area {
    margin: 0 auto;
    max-width: 900px;
    width: 100%;
    div {
      width: 100%;
      padding-bottom: 56.25%;
    }
    div iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
    img {
      position: relative;
      margin: 0 auto;
      max-width: 900px;
      width: 100%;
    }
  }
  a {
    position: absolute;
    top: 0;
    right: -67px;
    display: block;
    width: 43px;
    height: 43px;
    cursor: pointer;
    z-index: 100;
    div {
      position: absolute;
      text-align: center;
      width: 2px;
      height: 42px;
      vertical-align: top;
      margin-left: -3px;
      background: #fff;
      transition: 0.2s;
      margin-left: 19px;
    }
    div:nth-of-type(1) {
      transform: rotate(45deg);
    }
    div:nth-of-type(2) {
      transform: rotate(-45deg);
    }
    :hover {
      div:nth-of-type(1) {
        transform: rotate(90deg);
      }
      div:nth-of-type(2) {
        transform: rotate(-90deg);
      }
    }
  }
  @media ${(props) => props.theme.mobile} {
    a {
      top: -44px;
      right: 10px;
      display: block;
      width: 24px;
      height: 24px;
      div {
        height: 24px;
      }
    }
  }
`;
export const ItemWrapper = styled.div`
  .box {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 27px;
      :before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: inherit;
        background-size: cover;
        transform-origin: center;
        transition: transform 0.4s ease-in-out;
      }
    }
    .card:nth-child(1) {
      background-image: url('/people-live-01.png');
    }
    .card:nth-child(2) {
      background-image: url('/people-live-03.png');
    }
    .content{
      position: relative;
      padding: 48px;
      .title {
        width: 51px;
        height: 22px;
        left: 660px;
        top: 732px;

        font-family: 'KBFG Display';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 160%;
        letter-spacing: 0.01em;

        color: #FFBC00;
        margin-bottom: 10px;
      }
      .main-title {
        font-family: 'KBFG Display';
        font-weight: 700;
        font-style:normal;
        font-size: 22px;
        line-height: 140%;
        letter-spacing: 0.01em;
        color: #000;
        margin-bottom: 18px;
      }
      .date {
        width: 112px;
        height: 22px;
        margin-bottom: 29px;
        font-family: 'KBFG Display';
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 160%;
        letter-spacing: 0.01em;

        color: #999999;
      }
      .desc {
        p {
          font-family: 'KBFG Text';
          font-weight: 300;
          font-size: 16px;
          line-height: 25.6px;
          color: #222;
        }      
        figure{
          width:100%;
          img {
            width: 100%;
            object-fit: scale-down;
          }
        }
      }
    }
    .btn {
      position: relative;
      z-index: 1;
      width: 43px;
      height: 43px;
      background-image: url('/play-btn-trans.png');
      background-size: contain;
    }
  }
  @media ${(props) => props.theme.mobile} {
    .box {
      gap: 18px;
      .card {
        padding: 30px 20px 20px 20px;
        height: 196px;
      }
      .desc {
        font-size: 17px;
        line-height: 24px;
      }
    }
  }
`;
