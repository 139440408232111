import React, {useState} from 'react';
import ESGCard from './ESGCard';
import {ESGSliderWraaper,
        ESGSliderStyle
} from '../styled';

const ESGSlider = () => {
    const [hasMouseOver, setMouseOver] = useState(false);
    const itemWidth = 268;
    const viewPortMargin = 158;
    const imageWrapperWidth = ((itemWidth + viewPortMargin) * 10) - ( itemWidth + viewPortMargin );

    const [ESG_open, on_ESG_open] = useState(0);

    const Toggle = () => {
        if(!ESG_open) {
            on_ESG_open(1);
        } else {
            on_ESG_open(0);
        }
    }

    return (
        <ESGSliderWraaper>
            <ESGSliderStyle 
                onMouseEnter={() => {
                    setMouseOver(true);
                    // console.log('마우스오버');
                }}
                onMouseLeave={() => {
                    setMouseOver(false);
                    // console.log('마우스아웃');
                }}> 
                {/* <Slider {...ESGsettings}> */}
                    <ESGCard 
                        wrapperWidth={imageWrapperWidth} 
                        x={(itemWidth + viewPortMargin) * 0} 
                        shouldAnimate="1" 
                        image="/ESG_card5.png"
                        title="기후 변화 및 환경보호" 
                        index={0}
                        onClick={Toggle} isopen={ESG_open}
                    />
                    <ESGCard 
                        wrapperWidth={imageWrapperWidth} 
                        x={(itemWidth + viewPortMargin) * 1} 
                        shouldAnimate="1" 
                        image="/ESG_card2.png"
                        title="사회공헌 활동 확대" 
                        index={1}
                        onClick={Toggle} isopen={ESG_open}
                    />
                    <ESGCard 
                        wrapperWidth={imageWrapperWidth} 
                        x={(itemWidth + viewPortMargin) * 2} 
                        shouldAnimate="1" 
                        image="/ESG_card1.png"
                        title="동반성장 및 상생 가치실현" 
                        index={2}
                        onClick={Toggle} isopen={ESG_open}
                    />
                    <ESGCard 
                        wrapperWidth={imageWrapperWidth} 
                        x={(itemWidth + viewPortMargin) * 3} 
                        shouldAnimate="1" 
                        image="/ESG_card3.png"
                        title="인권 및 다양성 존중" 
                        index={3}
                        onClick={Toggle} isopen={ESG_open}
                    />
                    <ESGCard 
                        wrapperWidth={imageWrapperWidth} 
                        x={(itemWidth + viewPortMargin) * 4} 
                        shouldAnimate="1" 
                        image="/ESG_card4.png"
                        title="다양하고 지속적인 소통을 통한 조직문화 확립" 
                        index={4}
                        onClick={Toggle} isopen={ESG_open}
                    />
                    <ESGCard 
                        wrapperWidth={imageWrapperWidth} 
                        x={(itemWidth + viewPortMargin) * 5} 
                        shouldAnimate="1" 
                        image="/ESG_card5.png"
                        title="기후 변화 및 환경보호" 
                        index={5}
                        onClick={Toggle} isopen={ESG_open}
                    />
                    <ESGCard 
                        wrapperWidth={imageWrapperWidth} 
                        x={(itemWidth + viewPortMargin) * 6} 
                        shouldAnimate="1" 
                        image="/ESG_card2.png"
                        title="사회공헌 활동 확대" 
                        index={6}
                        onClick={Toggle} isopen={ESG_open}
                    />
                    <ESGCard 
                        wrapperWidth={imageWrapperWidth} 
                        x={(itemWidth + viewPortMargin) * 7} 
                        shouldAnimate="1" 
                        image="/ESG_card1.png"
                        title="동반성장 및 상생 가치실현" 
                        index={7}
                        onClick={Toggle} isopen={ESG_open}
                    />
                    <ESGCard 
                        wrapperWidth={imageWrapperWidth} 
                        x={(itemWidth + viewPortMargin) * 8} 
                        shouldAnimate="1" 
                        image="/ESG_card3.png"
                        title="인권 및 다양성 존중" 
                        index={8}
                        onClick={Toggle} isopen={ESG_open}
                    />
                    <ESGCard 
                        wrapperWidth={imageWrapperWidth} 
                        x={(itemWidth + viewPortMargin) * 9} 
                        shouldAnimate="1" 
                        image="/ESG_card4.png"
                        title="다양하고 지속적인 소통을 통한 조직문화 확립" 
                        index={9}
                        onClick={Toggle} isopen={ESG_open}
                    />
                {/* </Slider> */}
            </ESGSliderStyle>
        </ESGSliderWraaper>
    );
};

export default ESGSlider;