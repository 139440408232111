import React from 'react';
import PropTypes from 'prop-types';
import {StyledTable} from './styled'

const Table = (props) => {
    return (
        <StyledTable width={props.width}>
            {props.children}
        </StyledTable>
    )
}
export default Table;

Table.propTypes = {
    width: PropTypes.string,
};
  
Table.defaultProps = {
    width: '100%'
};