import React from "react";
import { useMediaQuery } from "react-responsive";
import { ColWrap, Container, ResponsiveBr, RowWrap } from "./styled";

export const MediaQuery = useMediaQuery;
export const laptopQuery = {
  query: "(max-width: 1380px)",
};

export const tabletQuery = {
  query: "(max-width: 1024px)",
};

export const mobileQuery = {
  query: "(max-width: 770px)",
};

export const Row = (props) => {
  return (
    <RowWrap type={props.type} ref={props.ref}>
      {props.children}
    </RowWrap>
  );
};
export const Col = (props) => {
  return (
    <ColWrap flex={props.flex} ref={props.ref}>
      {props.children}
    </ColWrap>
  );
};
export const Br = (props) => {
  return <ResponsiveBr dnjdlek={props.dnjdlek}></ResponsiveBr>;
};

/*
type
- default - container - 1200px;
- wide - padding
- full - max
*/
export const CommonContainer = (props) => {
  return (
    <Container
      type={props.type}
      id={props.id}
      className={props.className}
      style={props.style}
    >
      {props.children}
    </Container>
  );
};
