import React from 'react';
import { CommonContainer } from '../../../components/Grid';
import {UserWrapper,
        Path,
        UserContent,
        } from '../styled';

const UserTitle = (props) => {
    return (
        <UserWrapper>
            <CommonContainer>
                <Path>
                    <span>홈</span>
                    <span>Story</span>
                </Path>
                <UserContent user_image={props.user.user_avatar_url}>
                    <div className="userimg" />
                    <strong>{props.user.user_name}</strong>
                    {/* <small>그룹IT운영부<img src={process.env.PUBLIC_URL + '/Singitem7.svg'} alt=""/> */}
                    {/* <span>차장</span></small></strong> */}
                </UserContent>
            </CommonContainer>
        </UserWrapper>
    )
};

export default UserTitle;