import React, { useEffect, useState } from "react";
import { CommonContainer } from "../../../../components/Grid";
import LoadInView from "../../../../components/LoadInView";
import { ContentContainer, PageContent, Select } from "../styled";
import { GetPost, GetPrivacy, GetPrivacyList } from "../../../../api/page";
import { useParams } from "react-router-dom";

const SectionContent = () => {
  const params = useParams();
  const [page_id, update_page_id] = useState(
    params.version ? params.version : 0
  );
  const [versions, update_versions] = useState(0);
  const [is_load_versions, set_is_load_versions] = useState(0);
  const [errorMsg, SeterrorMsg] = useState("");
  const [post_data, set_post_data] = useState({
    post_id: null,
    post_title: "",
    post_content: "",
  });
  useEffect(() => {
    // if(params.version)
    if (post_data.post_id != page_id) {
      if (!page_id) {
        GetPrivacy()
          .then((response) => {
            console.log(response);
            if (response.data.code == "200") {
              const post_data_ = response.data.body;
              set_post_data({
                post_id: post_data_.ID,
                post_title: post_data_.post_title,
                post_content: post_data_.post_content,
                post_date: post_data_.post_date,
              });
            } else {
              SeterrorMsg("게시물을 찾을 수 없습니다.");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        GetPost({ post_id: page_id })
          .then((response) => {
            console.log(response);
            if (response.data.code == "200") {
              const post_data_ = response.data.body;
              set_post_data({
                post_id: post_data_.ID,
                post_title: post_data_.post_title,
                post_content: post_data_.post_content,
                post_date: post_data_.post_date,
              });
            } else {
              SeterrorMsg("게시물을 찾을 수 없습니다.");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    if (!is_load_versions) {
      GetPrivacyList()
        .then((response) => {
          if (response.data.code == "200") {
            update_versions(response.data.body);
          }
        })
        .catch((err) => console.log(err));
      set_is_load_versions(1);
    }
  });
  const ChangeVersion = (e) => {
    update_page_id(e.target.value);
  };

  return (
    <>
      <CommonContainer>
        <ContentContainer>
          <LoadInView>
            <PageContent>
              <div className="area">
                <strong>■ 그룹마이데이터 (KB금융그룹)</strong>
                <p>소개 [About the Company & Business]</p>
                <span className="d">
                  KB금융그룹은 KB금융지주를 주축으로 하는 대한민국의
                  종합금융그룹으로서 2022년 S&P 세계 100대 은행 순위에서 세계
                  61위, 국내 1위에 해당하는 대한민국 최대 금융그룹입니다.
                  그룹마이데이터 플랫폼은 KB금융그룹의 모든 계열사가 보유한 고객
                  데이터를 통합하여 고객에게 맞춤형 금융 서비스를 제공하는
                  시스템입니다. 이를 통해 KB금융그룹은 고객의 금융 생활을 보다
                  편리하고 효율적으로 지원하고, 금융 산업의 혁신을 선도해 나갈
                  수 있게 되었습니다.
                </span>
                <p>과제 [The Challanges]</p>
                <span>- 다양한 금융 데이터를 통합하고 관리해야 합니다.</span>
                <span>- 고객의 금융 데이터를 안전하게 보호해야 합니다.</span>
                <span>
                  - 고객의 개인정보를 보호하면서도 맞춤형 금융 서비스를 제공해야
                  합니다.
                </span>
                <p>해결 [The Solutions]</p>
                <div className="o">
                  KB데이타시스템은 AWS의 다양한 서비스를 활용하여 KB국민은행의
                  과제를 해결하였습니다.
                </div>
                <span>
                  - 다양한 금융 데이터를 통합하고 관리하기 위해 Amazon S3,
                  Amazon SageMaker, Amazon Amazon Glue, Amazon MWAA, Amazon ES
                  등을 활용했습니다. 이를 통해 KB금융그룹은 다양한 금융 데이터를
                  안전하게 통합하고 관리할 수 있었습니다.
                </span>
                <span>
                  - 고객의 금융 데이터를 안전하게 보호하기 위해 AWS의 Amazon
                  KMS를 활용했습니다. 이를 통해 KB금융그룹은 고객의 금융
                  데이터를 암호화하고, 침해로부터 수 있었습니다.
                </span>
                <p>결과[Benefits]</p>
                <div className="o">
                  KB데이타시스템은 KB금융그룹의 MSP사업자로서 필요한 지원과
                  솔루션을 제공하고, 합리적인 비용으로 다음과 같은 도움을
                  주었습니다.
                </div>
                <span>
                  - 고객의 금융 데이터를 통합하여 고객 맞춤형 금융 서비스를
                  제공할 수 있게 되었습니다.
                </span>
                <span>
                  - 고객의 금융 생활을 보다 편리하고 효율적으로 지원할 수 있게
                  되었습니다.
                </span>
                <span>
                  - 금융 산업의 혁신을 선도해 나갈 수 있게 되었습니다.
                </span>
              </div>
              <div className="area">
                <strong>■ KB윌렛 (KB국민은행)</strong>
                <p>소개 [About the Company & Business]</p>
                <span className="d">
                  KB국민은행은 KB금융그룹 계열사로, 대한민국 시중은행 중 자산
                  1위를 달성한 주요 리딩뱅크입니다 KB윌렛은 KB국민은행의 다양한
                  금융 서비스와 생활 밀착형 서비스를 하나로 통합한 서비스입니다.
                  이를 통해 KB국민은행은 고객이 금융과 일상을 한 번에 관리할 수
                  있도록 지원하고, 금융 산업의 혁신을 선도해 나갈 수 있게
                  되었습니다.
                </span>
                <p>과제 [The Challanges]</p>
                <span>
                  - 다양한 금융 서비스와 생활 밀착형 서비스를 통합해야 합니다.
                </span>
                <span>
                  - 고객의 편의성을 높이기 위한 다양한 기능을 제공해야 합니다.
                </span>
                <span>- 안전하고 안정적인 시스템을 구축해야 합니다.</span>
                <p>해결 [The Solutions]</p>
                <div className="o">
                  KB데이타시스템은 AWS의 다양한 서비스를 활용하여 KB국민은행의
                  과제를 해결하였습니다.
                </div>
                <span>
                  - 다양한 금융 서비스와 생활 밀착형 서비스를 통합하기 위해
                  AWS의 Amazon S3, Amazon ElastiCache,Amazon MSAK, Amazon MWAA
                  등을 활용했습니다. 이를 통해 KB국민은행은 다양한 금융 서비스와
                  생활 밀착형 서비스를 쉽게 통합할 수 있었습니다.
                </span>
                <span>
                  - 고객의 편의성을 높이기 위한 다양한 기능을 제공하기 위해
                  AWS의 Amazon RDS, Amazon EKS, AmazonEC2 등을 활용했습니다.
                  이를 통해 KB국민은행은 고객이 원하는 정보를 쉽게 찾고, 다양한
                  서비스를 편리하게 이용할 수 있도록 했습니다.
                </span>
                <span>
                  - 안전하고 안정적인 시스템을 구축하기 위해 AWS의 Amazon KMS,
                  Amazon GuardDuty 등을 활용했습니다.이를 통해 KB국민은행은
                  고객의 개인정보를 보호하고, 시스템을 안정적으로 운영할 수
                  있었습니다.
                </span>
                <p>결과[Benefits]</p>
                <div className="o">
                  KB데이타시스템은 KB금융그룹의 MSP사업자로서 필요한 지원과
                  솔루션을 제공하고, 합리적인 비용으로 다음과 같은 도움을
                  주었습니다.
                </div>
                <span>
                  - 다양한 금융 서비스와 생활 밀착형 서비스를 통합하여 고객에게
                  편리한 금융서비스를 제공할 수 있게 되었습니다.
                </span>
                <span>
                  - 고객의 편의성을 높이기 위한 다양한 기능을 제공하여 고객
                  만족도를 높일 수 있었습니다.
                </span>
                <span>
                  - 안전하고 안정적인 시스템을 구축하여 고객의 신뢰를 얻을 수
                  있었습니다.
                </span>
              </div>
              <div className="area">
                <strong>■ KB부동산 (KB국민은행)</strong>
                <p>소개 [About the Company & Business]</p>
                <span className="d">
                  KB국민은행은 KB금융그룹 계열사로, 대한민국 시중은행 중 자산
                  1위를 달성한 주요 리딩뱅크입니다 KB부동산은 KB국민은행의
                  부동산 금융 서비스에 AI와 데이터 분석 기술을 접목한
                  서비스입니다. 이를 통해KB국민은행은 고객에게 보다 편리하고
                  정확한 부동산 금융 서비스를 제공하고, 금융 산업의 혁신을
                  선도해나갈 수 있게 되었습니다.
                </span>
                <p>과제 [The Challanges]</p>
                <span>- 대용량 부동산 데이터를 분석해야 합니다.</span>
                <span>
                  - 고객의 요구를 빠르게 파악하고, 맞춤형 서비스를 제공해야
                  합니다.
                </span>
                <span>- 안정적인 시스템을 구축해야 합니다.</span>
                <p>해결 [The Solutions]</p>
                <div className="o">
                  KB데이타시스템은 AWS의 다양한 서비스를 활용하여 KB국민은행의
                  과제를 해결하였습니다.
                </div>
                <span>
                  - 대용량 부동산 데이터를 분석하기 위해 AWS의 Amazon S3, Amazon
                  Glue Amazon MWAA, Amazon SQS등을 활용했습니다. 이를 통해
                  KB국민은행은 대용량 부동산 데이터를 빠르고 정확하게 분석할 수
                  있었습니다.
                </span>
                <span>
                  - 고객의 요구를 빠르게 파악하고, 맞춤형 서비스를 제공하기 위해
                  AWS의 Amazon EC2, Amazon EKS,Amazon QuickSight 등을
                  활용했습니다. 이를 통해 KB국민은행은 고객의 요구를 빠르게
                  파악하고, 맞춤형 서비스를 제공할 수 있었습니다.
                </span>
                <span>
                  - 안전하고 안정적인 시스템을 구축하기 위해 AWS의 Amazon KMS,
                  Amazon GuardDuty 등을 활용했습니다.이를 통해 KB국민은행은
                  고객의 개인정보를 보호하고, 시스템을 안정적으로 운영할 수
                  있었습니다.
                </span>

                <p>결과[Benefits]</p>
                <div className="o">
                  KB데이타시스템은 KB금융그룹의 MSP사업자로서 필요한 지원과
                  솔루션을 제공하고, 합리적인 비용으로 다음과 같은 도움을
                  주었습니다.
                </div>
                <span>
                  - 대용량 부동산 데이터를 분석하여 고객에게 보다 정확한 부동산
                  정보를 제공할 수 있게 되었습니다.
                </span>
                <span>
                  - 고객의 요구를 빠르게 파악하여 맞춤형 부동산 금융 서비스를
                  제공할 수 있게 되었습니다.
                </span>
                <span>
                  - 안정적인 시스템을 구축하여 고객의 신뢰를 얻을 수 있었습니다.
                </span>
              </div>
            </PageContent>
          </LoadInView>
        </ContentContainer>
      </CommonContainer>
    </>
  );
};

export default SectionContent;
