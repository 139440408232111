import styled, { keyframes } from "styled-components";
import { ColWrap, RowWrap } from "../../components/Grid/styled";

export const AboutWrapper = styled.div`
  margin-top: 105px;
  @media ${(props) => props.theme.mobile} {
    margin-top: 45px;
  }
`;

export const Path = styled.div`
  font-family: "KBFG Text";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  color: #ffac00;
  margin-bottom: 32px;
  span {
    font-family: "KBFG Text";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    color: #999999;
    :after {
      content: ">";
      margin: 0 14px;
    }
  }
  @media ${(props) => props.theme.laptop} {
    padding-left: 20px;
  }
  @media ${(props) => props.theme.tablet} {
    font-size: 15px;
    line-height: 19px;
    margin-bottom: 25px;
    padding-left: 0px;
    span {
      font-size: 15px;
      line-height: 19px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    font-size: 13px;
    line-height: 19px;
    margin-bottom: 15px;
    span {
      font-size: 13px;
      line-height: 19px;
    }
  }
`;

export const MainTitle = styled.div`
  margin-bottom: 40px;
  h2 {
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 62px;
    color: #1a1a1a;
  }
  @media ${(props) => props.theme.laptop} {
    padding-left: 20px;
  }
  @media ${(props) => props.theme.tablet} {
    margin-bottom: 50px;
    padding-left: 0px;
    h2 {
      font-size: 50px;
      line-height: 72px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    margin-bottom: 20px;
    h2 {
      margin-bottom: 25px;
      font-size: 34px;
      line-height: 35px;
    }
  }
`;

export const MainPost = styled.div`
  height: 420px;
  background-image: url(${(props) =>
    props.theme.image_url}/common/about_bg.jpg);
  background-size: cover;
  background-position: center 0%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  @media ${(props) => props.theme.laptop} {
    background-attachment: scroll;
  }
  @media ${(props) => props.theme.tablet} {
    height: 480px;
    background-position: center center;
  }
  @media ${(props) => props.theme.mobile} {
    height: 230px;
    background-position: center center;
    border-bottom: 15px solid #eee;
  }
`;

export const SectionTitle = styled.h3`
  margin-bottom: 20px;
  display: block;
  font-family: "KBFG Display";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 160%;
  color: #000000;
  small {
    display: block;
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 25px;
    color: #ffbc00;
  }
  @media ${(props) => props.theme.tablet} {
  }
  @media ${(props) => props.theme.mobile} {
    font-size: 22px;
    line-height: 170%;
    small {
      line-height: 26px;
      font-size: 15px;
    }
  }
`;

export const OverviewFigure = styled.div`
  position: absolute;
  right: -300px;
  top: 0px;
  z-index: -1;
  transition: 3s;
  div {
    position: relative;
    width: 600px;
    height: 600px;
    border: 1px dashed #ffbc00;
    box-sizing: border-box;
    border-radius: 50%;
    transition: 3s;
  }
`;

export const OverviewWrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 150px;
  ${OverviewFigure} {
  }
  ${OverviewFigure}:nth-of-type(2) {
    top: 400px;
    left: -100px;
    width: 600px;
    height: 600px;
    div {
      width: 600px;
      height: 600px;

      &:after {
        content: "";
        position: absolute;
        background: rgba(247, 247, 247, 0.6);
        border-radius: 500px;
        width: 200px;
        height: 200px;
        top: -50px;
        left: 90px;
        z-index: 0;
      }
    }
  }
  @media ${(props) => props.theme.laptop} {
    padding-top: 140px;
    ${SectionTitle} {
      padding-left: 20px;
    }
  }
  @media ${(props) => props.theme.tablet} {
    padding-top: 100px;
    ${SectionTitle} {
      padding-left: 0px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    padding-top: 90px;
    padding-bottom: 90px;
    border-bottom: 15px solid #eee;
    ${OverviewFigure} {
      display: none;
    }
  }
`;
export const TitleFigure = styled.div`
  position: absolute;
  border: 1px dashed #ffbc00;
  box-sizing: border-box;
  border-radius: 500px;
  width: 400px;
  height: 400px;
  right: -180px;
  top: 800px;
  z-index: -1;
  @media ${(props) => props.theme.laptop} {
  }
  @media ${(props) => props.theme.tablet} {
  }
  @media ${(props) => props.theme.mobile} {
    display: none;
  }
`;

export const AboutContent = styled.div`
  font-style: normal;
  margin-left: 214px;

  @media ${(props) => props.theme.laptop} {
  }
  @media ${(props) => props.theme.tablet} {
  }
  @media ${(props) => props.theme.mobile} {
    margin-left: 0;
  }
`;

export const ContentFigure = styled.div`
  position: absolute;
  border: 1px dashed #ffbc00;
  box-sizing: border-box;
  border-radius: 500px;
  width: 400px;
  height: 400px;
  left: -160px;
  &:after {
    content: "";
    position: absolute;
    background: rgba(247, 247, 247, 0.6);
    border-radius: 500px;
    width: 200px;
    height: 200px;
    top: 350px;
    left: 90px;
    z-index: 0;
  }
  @media ${(props) => props.theme.laptop} {
  }
  @media ${(props) => props.theme.tablet} {
  }
  @media ${(props) => props.theme.mobile} {
    top: 1300px;
    left: 200px;
    &:after {
      display: none;
    }
  }
`;

export const OverviewSummary = styled.div`
  margin-top: 63px;
  p {
    font-family: "KBFG Text";
    font-style: normal;
    font-weight: 300;
    color: #666666;
    margin-bottom: 32px;
    font-size: 17px;
    line-height: 32px;
  }
  & > div:nth-of-type(2) {
    p {
      font-family: "KBFG Text";
      font-style: normal;
      font-weight: bold;
      color: #666666;
      color: gray;
      font-size: 17px;
      line-height: 32px;
    }
  }
  @media ${(props) => props.theme.laptop} {
    p {
      padding: 0px 20px;
    }
  }
  @media ${(props) => props.theme.tablet} {
    margin-top: 40px;
    p {
      padding: 0px 0px;
      font-size: 17px;
      line-height: 32px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    margin-top: 20px;
    p {
      font-size: 14px;
      line-height: 26px;
    }
    & > div:nth-of-type(2) {
      p {
        font-size: 14px;
        line-height: 26px;
      }
    }
  }
`;

export const AboutBox = styled.div`
  margin-top: 60px;
  border-bottom: 1px solid #eee;
  @media ${(props) => props.theme.laptop} {
  }
  @media ${(props) => props.theme.tablet} {
    margin-top: 30px;
    padding-bottom: 0px;
  }
  @media ${(props) => props.theme.mobile} {
    margin-top: 36px;
    padding-bottom: 0px;
  }
`;

export const AboutBoxItem = styled.div`
  height: 110px;
  margin-bottom: 40px;
  h4,
  p {
  }
  h4 {
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #ffbc00;
  }
  p {
    margin-top: 10px;
    font-family: "KBFG Text";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 32px;
    color: #777777;
  }
  @media ${(props) => props.theme.laptop} {
    padding-left: 20px;
  }
  @media ${(props) => props.theme.tablet} {
    padding-left: 0px;
  }
  @media ${(props) => props.theme.mobile} {
    padding-right: 0px;
    height: 100%;
    margin-bottom: 30px;
    h4 {
      font-size: 22px;
      line-height: 30px;
      color: #ffbc00;
    }
    p {
      font-size: 14px;
      line-height: 27px;
      color: #666666;
    }
  }
`;

export const AboutBottomBox = styled.div`
  padding-top: 45px;
  margin: 0 -15px;
  @media ${(props) => props.theme.laptop} {
    margin: 0 0px;
  }
  @media ${(props) => props.theme.tablet} {
    margin: 0 -15px;
    padding-top: 25px;
  }
  @media ${(props) => props.theme.mobile} {
    padding-top: 25px;
    margin: 0 0;
  }
`;

export const BottomItemStyle = styled.div`
  padding: 15px 15px;
  cursor: pointer;
  @media ${(props) => props.theme.laptop} {
  }
  @media ${(props) => props.theme.tablet} {
  }
  @media ${(props) => props.theme.mobile} {
    padding: 10px 0;
  }
`;

export const BottomItem = styled.div`
  border-radius: 20px;
  position: relative;
  display: inline-block;
  width: 100%;
  height: 150px;
  z-index: 0;
  transition: 1s;
  overflow: hidden;
  div {
    background-image: url(${(props) => props.theme.image_url}/common${(props) =>
      props.bgImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding-top: 62px;
    height: 100%;
    -webkit-transition: transform 0.8s ease-out;
    -moz-transition: transform 0.8s ease-out;
    -ms-transition: transform 0.8s ease-out;
    -o-transition: transform 0.8s ease-out;
    transition: transform 0.8s ease-out;
    :after {
      display: block;
      position: absolute;
      content: "";
      background: rgba(0, 0, 0, 0.3);
      border-radius: 20px;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    :hover {
      transform: scale(1.2);
    }
  }
  h4 {
    position: absolute;
    display: inline-block;
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: #ffffff;
    text-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    z-index: 2;
    cursor: pointer;
  }
  @media ${(props) => props.theme.laptop} {
  }
  @media ${(props) => props.theme.tablet} {
  }
  @media ${(props) => props.theme.mobile} {
    height: 100px;
    h4 {
      font-size: 18px;
      line-height: 32px;
    }
  }
`;

export const BusinessWrapper = styled.div`
  padding-top: 150px;
  ${SectionTitle} {
    margin-bottom: 56px;
  }
  @media ${(props) => props.theme.laptop} {
    padding-top: 140px;
    ${SectionTitle} {
      padding-left: 20px;
    }
  }
  @media ${(props) => props.theme.tablet} {
    padding-top: 100px;
    ${SectionTitle} {
      padding-left: 0px;
      margin-bottom: 40px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    padding-top: 90px;
    border-bottom: 15px solid #eee;
    ${SectionTitle} {
      margin-bottom: 40px;
    }
  }
`;
export const BusinessBoxSummaryWrap = styled.div`
  height: 100%;
  position: sticky;
  top: 80px;
  height: 780px;
  @media ${(props) => props.theme.tablet} {
    position: relative;
    height: auto;
    top: 0px;
  }
  @media ${(props) => props.theme.mobile} {
    position: relative;
    height: auto;
    top: 0px;
  }
`;
export const BusinessSummary = styled.div`
  opacity: 0.4;
  width: 100%;
  max-width: 100%;
  padding-right: 70px;
  padding-top: 30%;
  height: 80vh;
  transition: 0.3s;
  position: absolute;
  top: 0px;
  /* display:flex; */
  /* align-items: center; */
  /* justify-content: center; */
  &.active {
    opacity: 1;
  }
  h4 {
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 162.5%;
    align-items: center;
    color: #000;
    margin-bottom: 20px;
    transition: 0.5s;
    small {
      display: block;
      font-size: 17px;
      color: #ffcc00;
      font-weight: normal;
      margin-bottom: 7px;
    }
  }
  label {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  p {
    word-break: keep-all;
    transition: 0.5s;
    margin-top: 10px;
    margin-bottom: 25px;
    font-family: "KBFG Text";
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 32px;
    color: #000;
  }
  @media ${(props) => props.theme.laptop} {
    padding-left: 30px;
  }
  @media ${(props) => props.theme.tablet} {
    position: relative;
    padding-left: 5px;
    padding-right: 0px;
    padding-top: 15px;
    height: 65vh;
    p {
      margin-bottom: 18px;
      line-height: 30px;
    }
    label {
      margin-right: 5px;
      margin-bottom: 10px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    padding-top: 0%;
    height: 60vh;
    padding: 0px 0px;
    p {
      margin-top: 15px;
      line-height: 28px;
      font-size: 14px;
      margin-right: 1px;
    }
    label {
      margin-right: 10px;
    }
    h4 {
      font-size: 20px;
      small {
        font-size: 15px;
        margin-bottom: 3px;
      }
    }
    height: auto;
    position: relative;
    margin-bottom: 45px;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export const BusinessBoxWrapper = styled.div`
  /* background: #f7f7f7; */
  transition: 0.7s;
  background: #f7f7f7;
  ${BusinessSummary} {
    h4 {
      color: #000;
      small {
        color: #ffcc00;
      }
    }
    p {
      color: #888;
    }
  }
  /* .digital > div > & {
        background: #f7f7f7;
        ${BusinessSummary}{
            h4 {
                color: #000;
                small {
                    color: #FFCC00;
                }
            }
            p {
                color: #444;
            }
        }
    }
    .si > div > & {
        background: #8A7F70;
        ${BusinessSummary}{
            h4 {
                color: #fff;
                small {
                    color: #FFCC00;
                }
            }
            p {
                color: #fff;
            }
        }
    }
    .sm > div > & {
        background: #60584C;
        ${BusinessSummary}{
            h4 {
                color: #fff;
                small {
                    color: #FFCC00;
                }
            }
            p {
                color: #fff;
            }
        }
    } */
  /* border-top: 1px solid #eee; */
  ${(props) => (props.type ? `background: #fff;` : ``)}
  width: 100%;
  height: auto;
  padding-top: 130px;
  margin-bottom: 30px;

  ${BusinessSummary} {
    min-width: 330px;
  }
  @media ${(props) => props.theme.laptop} {
  }
  @media ${(props) => props.theme.tablet} {
    padding-top: 40px;
    padding-bottom: 40px;
    ${BusinessSummary} {
      min-width: 290px;
    }
    & > div > div {
      display: block;
    }
    & > div > div > div {
      width: 100%;
      display: block;
      flex: none;
    }
  }
  @media ${(props) => props.theme.mobile} {
    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 0px;
    & > div > div > div:last-child > div {
      padding-left: 0px;
    }
  }
`;

export const BusinessContainer = styled.div`
  margin-left: 265px;
`;

export const BusinessItemWrapper = styled.div``;

export const BusinessItemStyle = styled.div`
  padding: 15px 15px;
  display: inline-block;
  vertical-align: top;
  width: 50%;
  margin-bottom: 20px;
  position: relative;
  & > div {
    background-image: url(${(props) => props.theme.image_url}/common${(props) =>
      props.image});
    background-size: cover;
    background-position: center center;
    padding-bottom: 100%;
    width: 100%;
    height: 0px;
    position: relative;
    & > figure {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      padding: 45px;
      opacity: 0;
    }
  }
  @media ${(props) => props.theme.mobile} {
    width: 300px;
    height: 300px;
    margin-right: 10px;
  }
`;

export const BusinessItem = styled.div`
  height: 400px;
  background-image: url(${(props) => props.theme.image_url}/common${(props) =>
    props.bgImage});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  /* filter: drop-shadow(0px 4px 21px rgba(0, 0, 0, 0.12)); */
  @media ${(props) => props.theme.tablet} {
    height: 250px;
    width: 250px;
  }
  @media ${(props) => props.theme.mobile} {
    box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.12);
  }
`;

export const BusinessContent = styled.div``;

export const ButtonGroup = styled.div`
  margin-bottom: 30px;
  margin-top: 50px;
  @media ${(props) => props.theme.mobile} {
  }
`;
export const BusinessButton = styled.a`
  position: relative;
  font-family: "KBFG Display";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  background: transparent;
  padding: 0px 20px;
  max-width: 400px;
  border: 1px solid #ddd;
  border-radius: 0px;
  height: 50px;
  line-height: 50px;
  width: 100%;
  z-index: 5;
  text-decoration: none;
  display: block;
  color: #2f2f2f;
  margin-bottom: 20px;
  transition: 0.4s;
  span {
    display: block;
    width: 45px;
    height: 45px;
    top: 0;
    right: 0;
    border-radius: 0px;
    position: absolute;
    margin-left: 25px;
    :after {
      content: "";
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-size: 13px 13px;
      background-position: center center;
      background-image: url(${(props) =>
        props.theme.image_url}/common/icon-plus.svg);
      background-repeat: no-repeat;
    }
  }
  :hover {
    opacity: 0.7;
  }
  @media ${(props) => props.theme.tablet} {
    padding: 0px 10px;
    font-size: 13px;
  }
  @media ${(props) => props.theme.mobile} {
    padding: 12px 15px;
    padding-right: 52px;
    font-size: 13px;
    color: #000;
    font-weight: normal;
    line-height: 25px;
    margin-bottom: 15px;
    height: auto;
    border-radius: 0px;
    span {
      /* top: 50%;
            margin-top: -23px; */
      top: 0px;
      height: 100%;
      /* background: #eee; */
      border: none;
      border-radius: 0px;
      :after {
        background-size: 12px 12px;
      }
    }
  }
`;

export const BusinessBackGround = styled.div``;

export const MissonWrapper = styled.div`
  margin-bottom: 150px;
  @media ${(props) => props.theme.laptop} {
    ${SectionTitle} {
      padding-left: 20px;
    }
  }
  @media ${(props) => props.theme.tablet} {
    ${SectionTitle} {
      padding-left: 0px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    border-bottom: 15px solid #eee;
  }
`;

export const MissionBackGround = styled.div`
  margin-bottom: 80px;
  position: relative;
  width: 100%;
  height: auto;
  padding: 21px 19px;
  box-sizing: border-box;
  background-image: url(${(props) =>
    props.theme.image_url}/common/about_mission.jpg);
  background-size: cover;
  background-position: 16% 50%;
  background-repeat: no-repeat;
  :after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background: rgba(0, 0, 0, 0.4);
  }
  @media ${(props) => props.theme.laptop} {
    background-attachment: scroll;
  }
  @media ${(props) => props.theme.tablet} {
    background-size: cover;
    background-position: center center;
    padding: 10px 10px;
    margin-bottom: 95px;
  }
  @media ${(props) => props.theme.mobile} {
    padding: 21px 10px;
    margin-bottom: 60px;
  }
`;

export const MissionBox = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  @media ${(props) => props.theme.laptop} {
  }
  @media ${(props) => props.theme.tablet} {
  }
  @media ${(props) => props.theme.mobile} {
    padding: 0 0;
  }
`;

export const MissionInner = styled.div`
  & > div {
    display: inline-block;
    width: 50%;
    height: 100%;
    padding: 162px 100px;
    text-align: center;
    transition: 0.7s;
    h4 {
      span {
        display: block;
        font-family: "KBFG Display";
        font-style: normal;
        font-weight: 600;
        font-size: 35px;
        line-height: 56px;
        text-align: center;
        color: #ffffff;
        margin-top: 11px;
        strong {
          display: inline-block;
          position: relative;
          :after {
            content: "";
            height: 8px;
            width: 100%;
            bottom: 9px;
            z-index: -1;
            position: absolute;
            background: #ffcc00;
            opacity: 0.7;
            left: 0px;
          }
        }
      }
      small {
        font-family: "KBFG Display";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 37px;
        text-align: center;
        color: #ffcc00;
      }
    }
    :hover {
      background: rgba(0, 0, 0, 0.3);
    }
  }
  & > div:nth-of-type(1) {
    border-right: 1px solid rgba(255, 255, 255, 0.3);
  }
  @media ${(props) => props.theme.laptop} {
    & > div {
      padding: 160px 60px;
      h4 {
        span {
          font-size: 28px;
          line-height: 48px;
          strong {
            font-size: 28px;
            line-height: 48px;
          }
        }
      }
    }
  }
  @media ${(props) => props.theme.tablet} {
    & > div {
      padding: 80px 20px;
      vertical-align: top;
      h4 {
        span {
          font-size: 26px;
          line-height: 40px;
          div {
            display: none;
          }
        }
        small {
          font-size: 22px;
          line-height: 27px;
        }
      }
    }
  }
  @media ${(props) => props.theme.mobile} {
    & > div {
      display: block;
      width: 100%;
      height: auto;
      padding: 50px 20px;
      h4 {
        span {
          font-size: 20px;
          line-height: 32px;
          br {
            display: none;
          }
          strong {
            font-size: 20px;
            line-height: 32px;
          }
        }
        small {
          font-size: 16px;
          line-height: 27px;
        }
      }
    }
    > div:nth-of-type(1) {
      position: relative;
      border-right: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }
  }
`;

export const MissionItem = styled.div`
  ${RowWrap}:nth-of-type(1) {
    margin-bottom: 98px;
  }
  span {
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 600;
    font-size: 84px;
    line-height: 104px;
    color: #eeeeee;
    position: absolute;
    top: -65px;
    z-index: -1;
  }
  strong {
    position: relative;
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 35px;
    color: #000000;
    margin-top: 80px;
  }
  p {
    font-family: "KBFG Text";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 29px;
    color: #666666;
    margin-top: 20px;
  }
  @media ${(props) => props.theme.laptop} {
    padding-left: 50px;
    padding-right: 50px;
    ${ColWrap} {
    }
  }
  @media ${(props) => props.theme.tablet} {
    padding-left: 0px;
    padding-right: 15px;
    ${ColWrap} {
      margin-left: 38px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    padding-left: 20px;
    ${RowWrap}:nth-of-type(1) {
      margin-bottom: 0px;
    }
    ${ColWrap} {
      margin-left: 0px;
    }
    span {
      margin-top: 10px;
      line-height: 107px;
      font-size: 55px;
    }
    strong {
      font-size: 20px;
    }
    p {
      margin-bottom: 50px;
      line-height: 26px;
      margin-top: 10px;
      font-size: 14px;
    }
    p:last-child {
      margin-bottom: 70px;
    }
  }
`;

export const SubTitle = styled.div`
  font-family: "KBFG Display";
  font-weight: 600;
  font-size: 20px;
  line-height: 40px;
  text-align: left;
  color: #ffcc00;
  margin-bottom: 105px;
  @media ${(props) => props.theme.mobile} {
    font-size: 26px;
    line-height: 140%;
    margin-bottom: 60px;
  }
`;

export const SectionHistoryStyle = styled.div`
  overflow-x: hidden;
  overflow-y: visible;
  position: relative;
  padding-top: 150px;
  @media ${(props) => props.theme.laptop} {
    padding-top: 140px;
  }
  @media ${(props) => props.theme.tablet} {
    padding-top: 100px;
  }
  @media ${(props) => props.theme.mobile} {
    padding-top: 0px;
  }
`;

export const HistoryWrapper = styled.div`
  background: #f7f7f7;
  padding-top: 150px;
  padding-bottom: 150px;
  overflow-x: hidden;
  overflow-y: visible;
  @media ${(props) => props.theme.laptop} {
    padding-top: 140px;
    padding-bottom: 140px;
    ${SectionTitle} {
      padding-left: 20px;
    }
  }
  @media ${(props) => props.theme.tablet} {
    ${SectionTitle} {
      padding-left: 0px;
    }
    padding-top: 100px;
    padding-bottom: 100px;
    overflow-x: hidden;
    overflow-y: visible;
  }
  @media ${(props) => props.theme.mobile} {
    padding-top: 90px;
    padding-bottom: 90px;
    border-bottom: 15px solid #eee;
  }
`;

export const HistoryFigure = styled.div`
  position: absolute;
  right: -300px;
  top: 0px;
  z-index: 1;
  transition: 3s;
  div {
    position: relative;
    width: 600px;
    height: 600px;
    border: 1px dashed #ffbc00;
    box-sizing: border-box;
    border-radius: 50%;
    transition: 3s;
  }
  &:nth-of-type(2) {
    top: 400px;
    left: -100px;
    width: 600px;
    height: 600px;
    div {
      width: 600px;
      height: 600px;

      &:after {
        content: "";
        position: absolute;
        background: rgba(247, 247, 247, 0.6);
        border-radius: 500px;
        width: 200px;
        height: 200px;
        top: -50px;
        left: 90px;
        z-index: 0;
      }
    }
  }
`;

export const HistoryGrid = styled.div`
  position: relative;
  width: 100%;
  :after {
    display: block;
    position: absolute;
    content: "";
    top: 0;
    left: 50%;
    margin-top: 60px;
    margin-bottom: 60px;
    margin-left: -1px;
    width: 1px;
    height: calc(100% - 40px);
    background-image: url(${(props) =>
      props.theme.image_url}/common/dash_line.png);
    background-position: top;
    z-index: 1;
  }
  @media ${(props) => props.theme.laptop} {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media ${(props) => props.theme.tablet} {
    padding-left: 0px;
    padding-right: 0px;
  }
  @media ${(props) => props.theme.mobile} {
  }
`;
export const HistoryRow = styled.div`
  display: block;
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  height: auto;
  text-align: left;
  z-index: 5;
  ${(props) =>
    props.type == "right"
      ? `
        text-align: right;
    `
      : ``}
  :after {
    display: block;
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    margin-top: -9px;
    margin-left: -9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 5px solid #ffcc00;
    background: #fff;
    z-index: 5;
  }
  @media ${(props) => props.theme.laptop} {
  }
  @media ${(props) => props.theme.tablet} {
  }
  @media ${(props) => props.theme.mobile} {
    margin-top: 30px;
    margin-bottom: 43px;
    text-align: center;
    :after {
      top: calc(100% + 22px);
      border: 3px solid #ffcc00;
      margin-top: -8px;
      margin-left: -6px;
      width: 6px;
      height: 6px;
    }
  }
`;

export const HistoryCard = styled.div`
  display: inline-block;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  width: 40%;
  height: 100%;
  text-align: left;
  padding: 22px 40px;
  transition: 0.5s;
  :hover {
    opacity: 0.7;
  }
  strong {
    display: block;
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #1a1a1a;
    margin-bottom: 5px;
  }
  p {
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 25px;
    color: #555555;
  }
  @media ${(props) => props.theme.laptop} {
  }
  @media ${(props) => props.theme.tablet} {
    width: 45%;
    p {
      font-size: 18px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    width: 100%;
    text-align: center;
    padding: 15px 25px;
    strong {
      font-size: 17px;
    }
    p {
      font-size: 14px;
    }
  }
`;

export const Historybutton = styled.div`
  margin-top: 85px;
  text-align: center;
  button {
    position: relative;
    background: #fff;
    border: 2px solid #ffcc00;
    box-sizing: border-box;
    border-radius: 50px;
    padding: 13px 62px;
    font-family: "KBFG Text";
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    color: #ffcc00;
    transition: 0.3s;
    box-sizing: border-box;
    :hover {
      opacity: 0.7;
    }
    :after {
      display: inline-block;
      position: relative;
      content: "";
      bottom: 2px;
      right: 0;
      width: 16px;
      height: 8px;
      margin-left: 8px;
      background-image: url(${(props) =>
        props.theme.image_url}/common/Vector1.svg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
  }
  @media ${(props) => props.theme.mobile} {
    button {
      font-size: 14px;
      padding: 7px 40px;
      border: 1px solid #ffcc00;
      :after {
        width: 8px;
        height: 5px;
        margin-left: 5px;
      }
    }
  }
`;

export const ESGWrapper = styled.div`
  padding-top: 150px;
  @media ${(props) => props.theme.laptop} {
    padding-top: 140px;
    ${SectionTitle} {
      padding-left: 20px;
    }
  }
  @media ${(props) => props.theme.tablet} {
    ${SectionTitle} {
      padding-left: 0px;
    }
    padding-top: 100px;
  }
  @media ${(props) => props.theme.mobile} {
    border-bottom: 15px solid #eee;
    padding-top: 90px;
    ${SectionTitle} {
      :after {
        display: block;
        position: absolute;
        content: "수혜자에게 실질적인 도움을 줄 수 있는 다양한 사회공헌사업을 추진해 나가고 있습니다.";
        font-family: "KBFG Text";
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 200%;
        color: #666666;
        margin-top: 20px;
        padding-right: 20px;
      }
    }
  }
`;

export const ESGSummary = styled.div`
  margin-top: 60px;
  margin-left: 214px;
  p {
    font-family: "KBFG Text";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 39px;
    color: #666666;
  }
  @media ${(props) => props.theme.laptop} {
  }
  @media ${(props) => props.theme.tablet} {
  }
  @media ${(props) => props.theme.mobile} {
    display: none;
  }
`;

export const ESGBox = styled.div`
  width: 100%;
  @media ${(props) => props.theme.laptop} {
  }
  @media ${(props) => props.theme.tablet} {
  }
  @media ${(props) => props.theme.mobile} {
  }
`;

//ESG Slider,Card//

export const ESGSliderWraaper = styled.div`
  margin-bottom: 0px;
  position: relative;
  height: 420px;
  overflow: hidden;
  @media ${(props) => props.theme.tablet} {
  }
  @media ${(props) => props.theme.mobile} {
    overflow-x: scroll;
    overflow-y: hidden;
    height: 520px;
  }
`;

export const ESGSliderStyle = styled.div`
  margin-top: 24px;
  width: 3000px;
  position: absolute;
  margin-left: -600px;
  top: 0px;
  @media ${(props) => props.theme.mobile} {
    margin-top: 80px;
    margin-left: 0px;
    width: 3200px;
  }
`;

const floatAnimation = keyframes`
  0% {
    transform: translateY(20px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(20px);
  }
`;
export const ESGCardWrapper = styled.div`
  padding: 36px 14px;
  padding-bottom: 0px;
  width: 600px;
  display: inline-block;
  position: absolute;
  cursor: pointer;
  left: ${(props) => props.index * 334}px;
  top: 0;
  & > div:hover {
    .hover {
      color: #ffbc00;
    }
  }
  /* animation: 7s ${floatAnimation} infinite; */
  /* animation-delay: ${(props) => (props.index || 0) * 250}ms; */

  /* will-change: transform;
    animation-fill-mode: backwards;
    animation-timing-function: ease; */

  @media ${(props) => props.theme.mobile} {
    position: relative;
    display: inline-block;
    width: 320px;
    left: auto !important;
  }
`;

// visibility: visible;
// opacity: 1;

export const ESGtext = styled.div`
  position: relative;
  span {
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 37px;
    color: #515151;
    transition: 0.3s;
  }
  span:last-child {
    right: 0;
    color: #a3a3a3;
    position: absolute;
  }

  @media ${(props) => props.theme.mobile} {
    span {
      line-height: 30px;
    }
    span:last-child {
      display: block;
      position: relative;
    }
  }
`;

export const ESGcontent = styled.div``;

export const ESGimg = styled.div`
  width: 100%;
  height: 330px;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center center;
  position: relative;
  & > div {
    opacity: 0;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    padding: 50px;
    transition: 0.5s;
    strong {
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      display: block;
      margin-bottom: 22px;
    }
    & > span,
    & div > span {
      display: block;
      color: #fff;
      font-size: 14px;
      margin-bottom: 12px;
    }
    p {
      display: block;
      color: #fff;
      font-size: 14px;
      margin-bottom: 12px;
      line-height: 36px;
    }
  }
  :hover {
    opacity: 1;
    & > div {
      opacity: 1;
    }
  }
  @media ${(props) => props.theme.mobile} {
    margin-bottom: 11px;
    height: 270px;
    & > div {
      padding: 32px;
      strong {
        font-size: 17px;
        margin-bottom: 10px;
        line-height: 26px;
      }
      span {
        line-height: 24px;
        font-size: 12px;
      }
      p {
        line-height: 24px;
        font-size: 12px;
      }
    }
  }
`;

//Esg Slider,Card//

// 어바웃 비지니스 섹션 slick //
export const Businesssettings = {
  infinite: true,
  autoplay: 0,
  speed: 500,
  arrows: 1,
  slidesToShow: 2,
  rows: 2,
  slidesToScroll: 1,
  autoplaySpeed: 2000, // 자동 스크롤 시 다음으로 넘어가는데 걸리는 시간 (ms)
  responsive: [
    {
      breakpoint: 770,
      settings: "unslick",
    },
  ],
};

export const SliderWrapper = styled.div`
  margin: -15px 0;
  padding-top: 50px;
  padding-bottom: 250px;
  &:first-child {
    padding-top: 0px;
  }
  transition: 0.5s;
  opacity: 0.4;
  &.active {
    opacity: 1;
  }
  @media ${(props) => props.theme.laptop} {
  }
  @media ${(props) => props.theme.tablet} {
    padding-bottom: 70px;
  }
  @media ${(props) => props.theme.mobile} {
    margin: -15px 0px;
    padding-bottom: 70px;
  }
`;
export const BusinessSlickWrap = styled.div`
  width: 100%;
  position: relative;
  /* overflow: visible; */
  @media ${(props) => props.theme.mobile} {
    display: block;
    overflow-y: hidden;
    overflow-x: scroll;
    & > div {
      width: ${(props) => (props.count ? 240 * props.count + "px" : "100%")};
    }
  }
`;

export const CIPopupContent = styled.div`
  margin-bottom: 40px;
  @media ${(props) => props.theme.mobile} {
    margin-bottom: 10px;
  }
`;

export const CIPopupStyle = styled.div`
  & > div {
    height: auto;
    & > div:first-child {
      display: inline-block;
      width: 50%;
      height: 100%;
      vertical-align: top;
      padding-right: 10px;
      & > div {
        div {
          position: relative;
          border: 1px solid #eee;
          height: 288px;
          background-image: url(${(props) =>
            props.theme.image_url}/common/KB_SymbolMark1.png);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 110px 79px;
          :after {
            display: block;
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            // background-image: url(${(props) =>
              props.theme.image_url}/common/CI_Grid.png);//
            background-image: url(/CI_Grid.png);
            background-position: center center;
            background-size: 100%;
            z-index: -1;
          }
        }
      }
    }
    & > div:last-child {
      display: inline-block;
      width: 50%;
      height: 100%;
      padding-left: 10px;
      & > div:first-child {
        margin-bottom: 16px;
        & > div {
          position: relative;
          border: 1px solid #eee;
          padding: 0 55px;
          div {
            height: 120px;
            background-image: url(${(props) =>
              props.theme.image_url}/common/KBDataSystems_CI_kor.png);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            :after {
              display: block;
              position: absolute;
              content: "";
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              background-image: url(${(props) =>
                props.theme.image_url}/common/CI_Grid.png);
              background-position: center center;
              background-size: 100%;
              z-index: -1;
            }
          }
        }
      }
      & > div:last-child {
        vertical-align: text-bottom;
        & > div {
          position: relative;
          border: 1px solid #eee;
          padding: 0 55px;
          div {
            height: 120px;
            background-image: url(${(props) =>
              props.theme.image_url}/common/KBDataSystems_CI_eng.png);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            :after {
              display: block;
              position: absolute;
              content: "";
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              background-image: url(${(props) =>
                props.theme.image_url}/common/CI_Grid.png);
              background-position: center center;
              background-size: 100%;
              z-index: -1;
            }
          }
        }
      }
    }
  }
  p {
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #444444;
    margin-bottom: 10px;
    a {
      float: right;
      cursor: pointer;
      :focus {
        outline: none;
      }
    }
  }
  img {
    width: 12px;
    height: 12px;
    position: relative;
    top: -2px;
  }

  @media ${(props) => props.theme.mobile} {
    & > div {
      margin-bottom: 0px;
      & > div:first-child {
        display: block;
        width: 100%;
        padding-right: 0;
        & > div {
          padding: 5px 0;
          div {
            height: 80px;
            margin-bottom: 15px;
          }
        }
      }
      & > div:last-child {
        display: block;
        width: 100%;
        padding-left: 0;
        & > div:first-child {
          margin-top: -20px;
          margin-bottom: 0px;
          & > div {
            div {
              height: 80px;
            }
          }
        }
        & > div:last-child {
          & > div {
            div {
              height: 80px;
            }
          }
        }
      }
    }
    p {
      margin-top: 20px;
      margin-bottom: 5px;
      font-size: 13px;
    }
  }
`;

export const LocationPopupStyle = styled.div`
  div {
    border: 1px solid #eee;
    width: 100%;
    height: 400px;
    background-image: url(${(props) =>
      props.theme.image_url}/common/Location.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    margin-bottom: 40px;
  }
  @media ${(props) => props.theme.tablet} {
    div {
      height: 300px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    div {
      height: 250px;
      margin-bottom: 10px;
    }
  }
`;

export const FinancialPopupStyle = styled.div`
  height: auto;
  p {
  }
  table {
    margin-bottom: 40px;
    tbody th {
      max-width: 200px;
    }
    th,
    td {
      font-size: 13px;
    }
  }
  h4 {
    margin-bottom: 15px;
  }
  caption {
    font-size: 12px;
    color: #888;
    margin-bottom: 5px;
    text-align: left;

    small {
      font-size: 12px;
      float: right;
      color: #777;
    }
  }
  @media ${(props) => props.theme.mobile} {
    /* height: 300px; */
  }
`;

export const EthicalPopupStyle = styled.div`
  overflow-y: scroll;
  height: 700px;
  @media ${(props) => props.theme.mobile} {
    height: auto;
  }
`;

export const EthicalContent = styled.div`
  background: #f8f8f8;
  width: calc(100%);
  height: auto;
  padding: 0px 40px;
  padding-top: 40px;
  @media ${(props) => props.theme.mobile} {
    margin-top: 20px;
    padding: 20px 20px;
  }
`;

export const EthicalField = styled.div`
  margin-bottom: 50px;
  strong {
    display: block;
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 20px;
  }
  & > span {
    font-family: "KBFG Text";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 28px;
    color: #1a1a1a;
    display: block;
    margin-bottom: 50px;
  }
  p {
    position: relative;
    font-family: "KBFG Text";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    padding: 16px 40px;
    color: #1a1a1a;
    background: #ffffff;
    border-radius: 200px;
    margin-bottom: 10px;
    :after {
      content: "";
      position: absolute;
      display: block;
      background: #ffcc00;
      width: 10px;
      height: 10px;
      top: 23px;
      left: 23px;
      border-radius: 0 3px;
    }
    span {
      font-weight: bold;
    }
  }
  @media ${(props) => props.theme.mobile} {
    p {
      border-radius: 0px;
    }
  }
`;

export const EthicalCode = styled.div`
  margin-bottom: 50px;
  strong {
    display: block;
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 20px;
  }
  & > span {
    font-family: "KBFG Text";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 28px;
    color: #1a1a1a;
    display: block;
    margin-bottom: 50px;
  }
  p {
    position: relative;
    font-family: "KBFG Text";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    padding: 16px 40px;
    color: #1a1a1a;
    background: #ffffff;
    border-radius: 200px;
    margin-bottom: 10px;
    :after {
      content: "";
      position: absolute;
      display: block;
      background: #ffcc00;
      width: 10px;
      height: 10px;
      top: 23px;
      left: 23px;
      border-radius: 0 3px;
    }
    span {
      font-weight: bold;
    }
  }
  @media ${(props) => props.theme.mobile} {
    p {
      border-radius: 0px;
    }
  }
`;

export const FullText = styled.div`
  margin-bottom: 60px;
  & > strong {
    font-family: "KBFG Text";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 182.5%;
    color: #000000;
  }
  div {
    margin-top: 20px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 30px;
    width: 100%;
    height: 300px;
    border-radius: 10px;
    background: #fff;
    strong {
      display: block;
      font-size: 17px;
      line-height: 24px;
      color: #1a1a1a;
      font-weight: 700;
    }
  }
  span {
    display: block;
    margin-top: 5px;
    margin-bottom: 5px;
    font-family: "KBFG Text";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #1a1a1a;
  }
  p {
    font-family: "KBFG Text";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px;
    color: #1a1a1a;
    padding-left: 20px;
    padding-right: 20px;
  }
  @media ${(props) => props.theme.mobile} {
    div {
      padding: 20px 20px;
    }
    p {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
`;

export const EthicalStandards = styled.div`
  padding-bottom: 40px;
  & > strong {
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
  }
  div {
    margin-top: 20px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 30px;
    width: 100%;
    height: 300px;
    border-radius: 10px;
    background: #fff;
    strong {
      display: block;
      margin-top: 5px;
      margin-bottom: 5px;
      font-family: "KBFG Text";
      font-style: normal;
      font-weight: 700;
      font-size: 17px;
      line-height: 24px;
      color: #1a1a1a;
    }
  }
  p {
    font-family: "KBFG Text";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
    color: #1a1a1a;
  }
  @media ${(props) => props.theme.mobile} {
    div {
      padding: 20px 20px;
    }
    p {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
`;
export const EthicalReport = styled.div`
  padding-bottom: 40px;
  & > strong {
    display: block;
    font-family: "KBFG Display";
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 20px;
  }
  .desc {
    font-family: "KBFG Text";
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px;
    color: #1a1a1a;
  }
  .subtitle {
    display: block;
    font-family: "KBFG Display";
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 20px;
  }
  .round-box {
    display: flex;
    font-family: "KBFG Text";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    padding: 16px 40px;
    color: #1a1a1a;
    background: #ffffff;
    border-radius: 200px;
    margin-bottom: 10px;
    > div {
      position: relative;
      width: 50%;
      font-size: 14px;
      line-height: 24px;
      color: #000000;
      a {
        text-decoration: none;
        color: #1a1a1a;
        :focus {
          outline: none;
        }
      }
      :after {
        content: "";
        position: absolute;
        display: block;
        background: #ffcc00;
        width: 10px;
        height: 10px;
        top: 50%;
        left: -18px;
        transform: translateY(-56%);
        border-radius: 0 3px;
      }
    }
  }
  span {
    display: inline-block;
    color: #666;
    margin-right: 10px;
  }
  @media ${(props) => props.theme.mobile} {
    .round-box {
      display: block;
      border-radius: 0px;
      padding: 10px 30px 8px 30px;
      > div {
        width: 100%;
      }
    }
  }
`;

export const PopupCloseButton = styled.div`
  text-align: center;
  padding-bottom: 45px;
  position: relative;
  a {
    background: rgba(96, 88, 76, 0.88);
    color: #ffffff;
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    padding: 14px 85px;
    display: inline-block;
    text-decoration: none;
  }
  @media ${(props) => props.theme.mobile} {
    padding-top: 10px;
    padding-bottom: 20px;
  }
`;

//popup//

export const AboutStoryStyle = styled.div`
  @media ${(props) => props.theme.mobile} {
  }
`;

export const Location = styled.div`
  p {
    margin-top: -20px;
    margin-bottom: 30px;
    font-family: "KBFG Display";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    color: #666666;
  }
`;
