import React, { useEffect, useMemo, useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import { MainSliderWrapper } from "../styled";
import { CgChevronRight, CgChevronLeft } from "react-icons/cg";

export const settings = {
  infinite: true,
  //   autoplay: 1,
  speed: 300,
  arrows: 1,
  dots: true,
  slidesToShow: 1,
  rows: 1,
  slidesToScroll: 1,
  autoplaySpeed: 7000, // 자동 스크롤 시 다음으로 넘어가는데 걸리는 시간 (ms)
  pauseOnHover: false,
  //   afterChange: () => {
  //     console.log(123);
  //   },
  responsive: [
    {
      breakpoint: 1800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 770,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const SlickWrap = styled.div`
  .slick-arrow {
    z-index: 3;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-list {
    overflow: visible;
    padding: 0px !important;
  }
  .slick-slide:not(.slick-active) {
  }
  .slick-prev,
  .slick-next {
    font-size: 0;
    position: absolute;
    bottom: auto;
    color: green;
    border: 0;
    background: none;
    z-index: 1;
    cursor: pointer;
    background: #fff;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    transition: 0.8s;
    opacity: 1;
  }
  .slick-slider:hover .slick-next,
  .slick-slider:hover .slick-prev {
    opacity: 1;
    :hover {
      opacity: 1;
    }
  }
  .slick-prev {
    left: 80px;
    top: 50%;
    margin-top: -30px;

    width: 64px;
    height: 64px;

    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  }
  .slick-prev:before {
    content: url("arrow-left2.png");
  }
  .slick-next {
    right: 80px;
    top: 50%;
    margin-top: -30px;

    width: 64px;
    height: 64px;

    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  }
  .slick-next:before {
    content: url("arrow-right2.png");
  }
  .slick-prev:hover:after,
  .slick-next:hover:after {
  }

  .slick-dots {
    position: relative;
    top: -245px;
  }
  .slick-dots li {
    margin: 0 15px;
  }
  .slick-dots li button {
    position: relative;
  }
  .slick-dots li.slick-active button:before {
    background: #ffbc00;
    opacity: 1;
  }
  .slick-dots li button:before {
    position: absolute;
    width: 40px;
    /* height: 3px; */
    height: 2px;
    background: #fff;
    content: "";
    opacity: 1;
    left: 50%;
    transform: translateX(-50%);
    top: 10px;
  }

  @media ${(props) => props.theme.laptop} {
    .slick-prev,
    .slick-next {
      width: 64px;
      height: 64px;

      background: #ffffff;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    }
    /* .slick-prev:before {
      display: none;
    }
    .slick-next:before {
      display: none;
    } */
  }
  @media ${(props) => props.theme.laptop} {
    .slick-prev,
    .slick-next {
      width: 64px;
      height: 64px;

      background: #ffffff;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    }
    /* .slick-prev:before {
      display: none;
    }
    .slick-next:before {
      display: none;
    } */
  }
  @media ${(props) => props.theme.mobile} {
    .slick-arrow {
      display: none !important;
    }
    .slick-prev,
    .slick-next {
      width: 32px;
      height: 32px;

      background: #ffffff;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    }
    .slick-prev:before {
      background-size: 50%;
      /* display: none; */
    }
    .slick-next:before {
      background-size: 50%;
      /* display: none; */
    }
    .slick-dots {
      top: -130px;
    }
  }
`;

const PeopleSlider = (props) => {
  const ContentsStyles = styled.div`
    position: absolute;
    z-index: 1;
    width: max-content;
    top: 190px;
    left: 50%;
    transform: translateX(-50%);
    @media ${(props) => props.theme.mobile} {
      top: 60px;
    }
    div > h3 {
      width: 61px;
      height: 22px;
      text-align: center;
      font-family: "KBFG Display";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #ffbc00;
    }
    @media ${(props) => props.theme.mobile} {
      div > h3 {
        font-size: 12px;
      }
    }
  `;
  const TitleFontStyles = styled.div`
    p {
      max-width: 478px;
      font-family: "KBFG Display";
      font-style: normal;
      font-weight: 700;
      font-size: 42px;
      line-height: normal;
      text-align: center;
      letter-spacing: 0.01em;
      color: #000000;
    }
    @media ${(props) => props.theme.mobile} {
      p {
        max-width: 240px;
        font-size: 22px;
      }
    }
  `;
  const DescFontStyles = styled.div`
    p {
      max-width: 481px;
      font-family: "KBFG Text";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      text-align: center;
      letter-spacing: -0.03em;
      color: #838383;
    }
    @media ${(props) => props.theme.mobile} {
      p {
        max-width: 243px;
        font-size: 12px;
      }
    }
  `;

  return (
    <MainSliderWrapper className="slider-main-wrapper">
      <SlickWrap className="slider-main">
        <Slider {...settings}>
          <div
            className="mainSlider1"
            key={1}
            count={1}
            style={{ position: "relative !important" }}
          >
            <ContentsStyles>
              <h3>People</h3>
              <TitleFontStyles>
                <p
                  dangerouslySetInnerHTML={{
                    __html: props.mainInfo.postTitle3 || "",
                  }}
                />
              </TitleFontStyles>
              <DescFontStyles>
                <p
                  dangerouslySetInnerHTML={{
                    __html: props.mainInfo.desc3 || "",
                  }}
                />
              </DescFontStyles>
            </ContentsStyles>

            <section className="main-bg bg-01 mobile-mt0">
              {props.mainInfo.videoUrl3 ? (
                <video
                  // className="main-bg-video"
                  muted
                  loop
                  preload="metadata"
                  autoPlay={true}
                  playsInline
                  className="video-area"
                >
                  <source src={`${props.mainInfo.videoUrl3}#t=0.001`}></source>
                </video>
              ) : (
                <div
                //   className="main-bg"
                >
                  {/* 비디오가 재생되지 않을 경우 배경 썸네일 */}
                  <img
                    src="/video-thumbnail.png"
                    alt="background thumbnail"
                    className="video-area-height"
                  />
                </div>
              )}
            </section>
          </div>
          <div className="mainSlider2" key={2} count={2}>
            <section className="main-bg mt0">
              {props.mainInfo.videoUrl2 ? (
                <video
                  // className="main-bg-video"
                  className="video-area-height"
                  muted
                  loop
                  preload="metadata"
                  autoPlay={true}
                  playsInline
                >
                  <source src={`${props.mainInfo.videoUrl2}#t=0.001`}></source>
                </video>
              ) : (
                <div
                //   className="main-bg"
                >
                  {/* 비디오가 재생되지 않을 경우 배경 썸네일 */}
                  <img
                    src="/video-thumbnail.png"
                    alt="background thumbnail"
                    className="video-area-height"
                  />
                </div>
              )}
            </section>
          </div>

          <div
            className="mainSlider1"
            key={3}
            count={3}
            style={{ position: "relative !important" }}
          >
            <ContentsStyles>
              <h3>People</h3>
              <TitleFontStyles>
                <p
                  dangerouslySetInnerHTML={{
                    __html: props.mainInfo.postTitle || "",
                  }}
                />
              </TitleFontStyles>
              <DescFontStyles>
                <p
                  dangerouslySetInnerHTML={{
                    __html: props.mainInfo.desc || "",
                  }}
                />
              </DescFontStyles>
            </ContentsStyles>

            <section className="main-bg bg-01 mobile-mt0">
              {props.mainInfo.videoUrl ? (
                <video
                  // className="main-bg-video"
                  muted
                  loop
                  preload="metadata"
                  autoPlay={true}
                  playsInline
                  className="video-area"
                >
                  <source src={`${props.mainInfo.videoUrl}#t=0.001`}></source>
                </video>
              ) : (
                <div
                //   className="main-bg"
                >
                  {/* 비디오가 재생되지 않을 경우 배경 썸네일 */}
                  <img
                    src="/video-thumbnail.png"
                    alt="background thumbnail"
                    className="video-area-height"
                  />
                </div>
              )}
            </section>
          </div>
        </Slider>
      </SlickWrap>
    </MainSliderWrapper>
  );
};

export default PeopleSlider;
