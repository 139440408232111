import React from "react";
import Badge from "../../../../components/Badge";
import { CommonContainer } from "../../../../components/Grid";
import {
  TitleWrapper,
  PostBadge,
  PostTitle,
  PostSubTitle,
  TitleImage,
  Avatar,
} from "../styled";

const SectionTitle = (props) => {
  return (
    <TitleWrapper>
      <CommonContainer>
        <PostBadge>
          {props.item.post_tags
            ? props.item.post_tags.map((tag, key) => (
                <Badge
                  type="primary"
                  size="medium"
                  onClick={(e) => (window.location.href = "/tag/" + tag.slug)}
                >
                  #{tag.name}
                </Badge>
              ))
            : ""}
        </PostBadge>
        <PostTitle>
          <h2>{props.item.post_title}</h2>
        </PostTitle>
        {/* <PostSubTitle onClick={(e) => window.location.href=`/user/${props.item.post_author}`}> */}
        <PostSubTitle>
          <div className="name">
            <Avatar image={props.item.post_author_img} alt="" />
            <span>{props.item.post_author_name}</span>
          </div>
          <p>{props.item.post_date}</p>
        </PostSubTitle>
      </CommonContainer>
      <CommonContainer type="full">
        <TitleImage bgImage={props.item.post_image} />
      </CommonContainer>
    </TitleWrapper>
  );
};

export default SectionTitle;
