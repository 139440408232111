import axios from './defaultClient';

const nameSpace = '/ep/v1/page';

export const GetPost = async (args) => {
    return await axios.get( nameSpace + '/', { 
        params:{
            id: args.post_id,
        }
    })
}
export const GetPrivacy = async (args) => {
    return await axios.get( '/ep/v1/privacy/latest/', {})
}
export const GetPrivacyList = async (args) => {
    return await axios.get( '/ep/v1/privacy/', {})
}