import React, { useEffect, useRef, useState } from "react";
import YouTube from "react-youtube";
import {
  PopupWrapper,
  Overlay,
  PopupInner,
  PopupContent,
  PopupHead,
  PopupBody,
} from "../../../../components/Popup/components/DefaultPopup/styled";
import { ItemTopWrapper, ItemWrapper } from "./styled";
import closeButton from "../../../../components/Button";

const PeoplePopupContent = (props) => {
  const [show, setShow] = useState(false);
  const showVideo = (args) => {
    setShow((p) => !p);
    setYoutubeId(args);
  };
  const [yotubeId, setYoutubeId] = useState("");

  const player = useRef(null);

  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: 0,
      mute: 1,
      controls: 1,
    },
  };

  useEffect(() => {
    if (props.option.show && props.option.post && props.option.post.youtubeLink)
      player.current.internalPlayer.playVideo();
    else if (player.current) player.current.internalPlayer.pauseVideo();
  }, [props.option.show]);

  return (
    <PopupWrapper
      zIndex={props.option.zIndex}
      type={props.option.type}
      show={props.option.show}
      top={props.option.top}
    >
      <Overlay show={props.option.show} onClick={props.option.onClose} />
      <PopupInner
        border={"none"}
        type={props.option.type}
        show={props.option.show}
        width={props.option.width}
        padding={"none"}
        size={props.option.size}
      >
        <ItemTopWrapper>
          <a onClick={props.option.onClose}>
            <div></div>
            <div></div>
          </a>
          <div className="content-area">
            {props.option.post.youtubeLink ? (
              <YouTube
                videoId={props.option.post.youtubeLink}
                className={"youtube_item"}
                opts={opts}
                ref={player}
              />
            ) : (
              <img
                src={props.option.post.thumbnail}
                className="detail-img"
                alt="thumbnail"
              />
            )}
          </div>
        </ItemTopWrapper>
        <PopupContent>
          <PopupBody>
            <ItemWrapper>
              {/* content start */}
              <div className="content">
                <div className="date">{props.option.post.postDate || ""}</div>
                <div className="main-title">
                  {/* {props.option.post.liveId} */}
                  {props.option.post.postTitle || ""}
                </div>
                <div
                  className="desc"
                  dangerouslySetInnerHTML={{
                    __html: props.option.post.postContent || "",
                  }}
                />
              </div>
              {/* content end */}
            </ItemWrapper>
          </PopupBody>
        </PopupContent>
      </PopupInner>
    </PopupWrapper>
  );
};

export default PeoplePopupContent;
