import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Card from "../../../components/Card";
import {
  settings,
  SlickWrap,
  StorySliderWraaper,
  StorySliderStyle,
} from "../../Home/components/styled";
import { GetPosts } from "../../../api/story";
import { GetBlogPosts } from "../../../api/blog";
// import LoadInView from '../../../components/LoadInView';
// import {animteScrollTo} from '../../../lib/common';

const StorySlider = (props) => {
  const [post_list, update_post_list] = useState(0);
  const [current_paged, set_current_paged] = useState(0);
  const [total_page, set_total_page] = useState(0);
  const [list_tags, set_list_tags] = useState(0);
  const [paged, set_paged] = useState(1);
  //   useEffect(() => {
  //     if (current_paged != paged) {
  //       GetPosts({
  //         order: "DESC",
  //         orderby: "ID",
  //         posts_per_page: "12",
  //         cate: "4505",
  //         paged: paged,
  //       })
  //         .then((response) => {
  //           console.log(response);
  //           if (response.data.body.paged) {
  //             set_total_page(response.data.body.total);
  //             set_current_paged(response.data.body.paged);
  //             update_post_list(response.data.body.posts);
  //             set_list_tags(response.data.body.tags);
  //           }
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });
  //     }
  //   });

  useEffect(() => {
    GetBlogPosts({
      paged: 1,
      postsPerPage: 12,
      series: 4949,
    })
      .then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          update_post_list(res.data.body.items);
        } else {
          update_post_list(null);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <StorySliderWraaper type="1">
      <StorySliderStyle>
        {post_list ? (
          <SlickWrap>
            <Slider {...settings}>
              {post_list
                ? post_list.map((post, key) => (
                    <Card isBlog={1} item={post} width="100%" />
                  ))
                : "로딩"}
            </Slider>
          </SlickWrap>
        ) : (
          ""
        )}
      </StorySliderStyle>
    </StorySliderWraaper>
  );
};

export default StorySlider;
