import React from 'react';
import Button from '../../../components/Button';
import LoadInView from '../../../components/LoadInView';
import {Development,
        MainContentCardWrapper,
        MainContentCardContainer,
        MainContentCard,
        } from '../styled';

const SectionDevelopmentCard = (props) => {
    return (
        <Development>
            <MainContentCardWrapper>
                <MainContentCardContainer data-id="4" onClick={props.show_popup}>
                    <LoadInView>
                        <MainContentCard className="border-card" data-id="4" onClick={props.show_popup}>
                            <strong>그룹 IT 사업 수행 및 비즈니스 Value Up</strong>
                            <p>KB금융그룹 핵심 비즈니스를 주도하는 KB데이타시스템 지향</p>
                            <Button type="primary" icon="plus" iconSize="13px 13px">자세히보기</Button>
                        </MainContentCard>
                    </LoadInView>
                </MainContentCardContainer>
                <MainContentCardContainer data-id="5" onClick={props.show_popup}>
                    <LoadInView>
                        <MainContentCard className="border-card">
                            <strong>디지털 중심의 사업 참여 및 그룹 내 디지털/IT Hub 역할 수행</strong>
                            <p>KB금융그룹 디지털/IT를 선도하는 KB데이타시스템 지향</p>
                            <Button type="primary" icon="plus" iconSize="13px 13px">자세히보기</Button>
                        </MainContentCard>
                    </LoadInView>
                </MainContentCardContainer>
                <MainContentCardContainer data-id="6" onClick={props.show_popup}>
                    <LoadInView>
                        <MainContentCard className="border-card">
                            <strong>그룹 전략사업에 대한 역량 강화 및 역할 확대</strong>
                            <p>KB금융그룹 전략적 금융서비스 창출에 기여하는 KB데이타시스템 지향</p>
                            <Button type="primary" icon="plus" iconSize="13px 13px">자세히보기</Button>
                        </MainContentCard>
                    </LoadInView>
                </MainContentCardContainer>
            </MainContentCardWrapper>
        </Development>
    )
};

export default SectionDevelopmentCard;