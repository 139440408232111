import React, { useEffect, useState } from "react";
import { CommonContainer } from "../../../../components/Grid";
import LoadInView from "../../../../components/LoadInView";
import { ContentContainer, PageContent, Select } from "../styled";
import { GetPost, GetPrivacy, GetPrivacyList } from "../../../../api/page";
import { useParams } from "react-router-dom";

const SectionContent = () => {
  const params = useParams();
  const [page_id, update_page_id] = useState(
    params.version ? params.version : 0
  );
  const [versions, update_versions] = useState(0);
  const [is_load_versions, set_is_load_versions] = useState(0);
  const [errorMsg, SeterrorMsg] = useState("");
  const [test, setTest] = useState("");
  const [post_data, set_post_data] = useState({
    post_id: null,
    post_title: "",
    post_content: "",
  });
  useEffect(() => {
    // if(params.version)
    if (post_data.post_id != page_id) {
      if (!page_id) {
        GetPrivacy()
          .then((response) => {
            console.log(response);
            if (response.data.code == "200") {
              const post_data_ = response.data.body;
              set_post_data({
                post_id: post_data_.ID,
                post_title: post_data_.post_title,
                post_content: post_data_.post_content,
                post_date: post_data_.post_date,
              });
              console.log(123, response.data);
              setTimeout(() => {
                setTest(post_data_.ID);
              }, 100);
            } else {
              SeterrorMsg("게시물을 찾을 수 없습니다.");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        GetPost({ post_id: page_id })
          .then((response) => {
            console.log(response);
            if (response.data.code == "200") {
              const post_data_ = response.data.body;
              set_post_data({
                post_id: post_data_.ID,
                post_title: post_data_.post_title,
                post_content: post_data_.post_content,
                post_date: post_data_.post_date,
              });
            } else {
              SeterrorMsg("게시물을 찾을 수 없습니다.");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    if (!is_load_versions) {
      GetPrivacyList()
        .then((response) => {
          if (response.data.code == "200") {
            update_versions(response.data.body);
          }
        })
        .catch((err) => console.log(err));
      set_is_load_versions(1);
    }
  }, [page_id]);
  const ChangeVersion = (e) => {
    update_page_id(e.target.value);
  };

  const [v, setV] = useState(false);

  useEffect(() => {
    if (test) {
      // Add a click event listener to a common ancestor
      const commonAncestor = document.getElementById("an");

      const handleClick = (event) => {
        // Check if the clicked element has the desired class
        if (event.target.classList.contains("togglee")) {
          // Your logic here
          console.log('Div with class "yourClassName" clicked!');
          setV((p) => !p);
        }
      };

      // Attach the event listener
      commonAncestor.addEventListener("click", handleClick);

      // Clean up the event listener when the component unmounts
      return () => {
        commonAncestor.removeEventListener("click", handleClick);
      };
    }
  }, [test]); // Empty dependency array means this effect runs once, similar to componentDidMount

  const toggle = () => {};

  return (
    <>
      <CommonContainer id="an">
        <ContentContainer>
          <LoadInView>
            {versions && (
              <Select onChange={ChangeVersion}>
                {versions.map((version, key) => (
                  <option
                    value={version.ID}
                    selected={page_id == version.ID ? "selected" : ""}
                  >
                    {version.post_title}
                  </option>
                ))}
              </Select>
            )}
            <PageContent
              dangerouslySetInnerHTML={{
                __html: post_data.post_content,
              }}
              className={v ? "active" : ""}
            ></PageContent>
          </LoadInView>
        </ContentContainer>
      </CommonContainer>
    </>
  );
};

export default SectionContent;
