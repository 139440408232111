import React, {useEffect, useRef} from 'react';
import {CommonContainer, MediaQuery, mobileQuery} from '../../../components/Grid';
import {Player} from 'video-react';
import Button from '../../../components/Button';
import LoadInView from '../../../components/LoadInView';
import { motion } from 'framer-motion';
import {OverviewWrapper,
        OverviewFigure,
        InterviewBoxWrapper,
        InterviewBox,
        InterviewText,
        TextArea,
        SectionTitle,
        SectionSummary,
        Seemore
} from '../styled'
import { useInView } from 'react-hook-inview';
import { Parallax } from 'react-scroll-parallax';
import YouTube from 'react-youtube';

const SectionOverview = () => {
    const player = useRef(null);
    const [ref, inView] = useInView({ unobserveOnEnter: true, delay: 3000, rootMargin:'10px', threshold: 0 });
    const isMobile = MediaQuery(mobileQuery);
    const opts = {
      height: '100%',
      width: '100%',
      playerVars: {
        autoplay: 1,
        mute: 1,
        loop: 1,
        playlist: 'SiBwPgfLGNs',
        controls: 1
      },
    };
    useEffect(() => {
        player.current.internalPlayer.playVideo();
        player.current.internalPlayer.mute();
    })
    const MouseOver = () => {
        // player.current.muted = 0;
        // player.current.internalPlayer.unMute();
        // player.current.internalPlayer.playVideo();
    }
    const MouseLeave = () => {
        // player.current.internalPlayer.mute();
        // player.current.muted = "muted";
        
    }

    return (
        <OverviewWrapper ref={ref}>
            <LoadInView>
                <OverviewFigure>
                    <Parallax y={[-30, 30]} x={[30, -30]} tagOuter="figure">
                    </Parallax>
                </OverviewFigure>
                {!isMobile ? (
                <OverviewFigure>
                    <Parallax y={[20, -20]} x={[-20, 20]} tagOuter="figure">
                    </Parallax>
                </OverviewFigure>) : '' }
                
                <TextArea className="section">
                    <motion.div
                        initial={{ opacity: 0, y: 140 }} 
                        animate={{ opacity: 1, y: 0 }} 
                        transition={{ duration: 1, ease: 'easeOut', staggerChildren: 0.5 }}>    
                            <SectionTitle>
                                <small>회사개요</small>
                                KB데이타시스템은
                            </SectionTitle>
                            <SectionSummary>
                                KB금융그룹의 IT 선진화 및 혁신을 이끌기 위해 설립된 금융 IT 전문기업입니다.
                            </SectionSummary>
                    </motion.div>
                </TextArea>
            </LoadInView>
            <CommonContainer>
                <LoadInView>
                    <InterviewBoxWrapper>
                        {isMobile ? (
                        <InterviewText type='mobile'>
                            홍보영상
                        </InterviewText> ) : '' }
                        <InterviewBox onMouseEnter={MouseOver} onMouseLeave={MouseLeave}>
                            <YouTube
                                videoId={"SiBwPgfLGNs"}
                                opts={opts}
                                ref={player}
                            />
                        </InterviewBox>
                        {!isMobile ? (
                        <InterviewText>
                            홍보영상 <span>1:00</span>
                        </InterviewText> ) : '' }
                        <Seemore>
                            <Button type="primary" color="gray" icon="arrow" to={'/about/#overview'}>자세히보기</Button>
                        </Seemore>
                    </InterviewBoxWrapper>
                </LoadInView>
            </CommonContainer>
        </OverviewWrapper>
    );
};

export default SectionOverview;