import styled from 'styled-components';

export const SidemenuWrapper = styled.div``;

export const PopupHead = styled.div`
  position: relative;
  strong {
    display: block;
    font-family: 'KBFG Display';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    color: #000000;
    padding-top: 40px;
    margin-bottom: 10px;
  }
  a {
    position: absolute;
    top: 0px;
    cursor: pointer;
    right: 0px;
    line-height: auto;
  }
  p {
    font-family: 'KBFG Display';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    color: #666666;
  }
`;

export const SidemenuBar = styled.div`
    width:700px;
    height:100vh;
    transition:.4s;
    position:fixed;
    top:0px;
    padding: 40px 50px;
    padding-bottom: 0;
    z-index: 999999;
    background: #fff;
    box-sizing:border-box;
    overflow-y: auto;
    ${props => {
      if (props.isopen) {
        return `
                left: 0px;
            `;
      } else {
        return `
                left: -700px;
            `;
      }
    }}
    @media ${props => props.theme.laptop} {
        width: 600px;
        ${props => {
          if (props.isopen) {
            return `
                    left: 0px;
                `;
          } else {
            return `
                    left: -600px;
                `;
          }
        }}
    }
    @media ${props => props.theme.tablet} {
        width: 450px;
        padding: 20px 30px;
        padding-bottom: 0;
        ${props => {
          if (props.isopen) {
            return `
                    left: 0px;
                `;
          } else {
            return `
                    left: -450px;
                `;
          }
        }}
    }
    @media ${props => props.theme.mobile} {
        width: 280px;
        padding: 20px 10px;
        padding-bottom: 0;
        ${props => {
          if (props.isopen) {
            return `
                    left: 0px;
                `;
          } else {
            return `
                    left: -280px;
                `;
          }
        }}
    }
`;

export const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99999;
  top: 0px;
  left: 0px;
  ${props => {
    if (props.isopen) {
      return `
                display:block;
            `;
    } else {
      return `
                display:none;
            `;
    }
  }}
`;
