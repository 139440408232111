import React from "react";
import PeoplePopupContent from "../../views/People/components/popup/PeoplePopupContent";
import DefaultPopup from "../Popup/components/DefaultPopup";
import SidePopup from "../Popup/components/SidePopup";
import VideoPopup from "../Popup/components/VideoPopup";

const Popup = (props) => {
  switch (props.type) {
    case "default":
      return <DefaultPopup option={props} />;
    case "side":
      return <SidePopup option={props} />;
    case "video":
      return <VideoPopup option={props} />;
    case "liveContent":
      return <PeoplePopupContent option={props} />;
    default:
  }
  return <></>;
};

export default Popup;
