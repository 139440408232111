import gsap from "gsap";
import React, { useEffect, useRef } from "react";
import ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import LoadInView from "../../components/LoadInView";
import MainContainer from "../../components/MainContainer";
import SectionBusiness from "./components/SectionBusiness";
import SectionESG from "./components/SectionESG";
import SectionHistory from "./components/SectionHistory";
import SectionMain from "./components/SectionMain";
import SectionMenu from "./components/SectionMenu";
import SectionOverview from "./components/SectionOverview";
import SectionStory from "./components/SectionStory";
import SectionYoutube from "./SectionYoutube";
import { AboutWrapper } from "./styled";

ScrollMagicPluginGsap(ScrollMagic, gsap);

const About = () => {
  let topBoxEl = useRef(null);
  let sliderTitleEl = useRef(null);
  useEffect(() => {
    var controller = new ScrollMagic.Controller();
    var overview = new ScrollMagic.Scene({
      triggerElement: "#overview",
      duration: "100%",
    })
      .setClassToggle("#overview_nav", "active")
      .addTo(controller);
    var business = new ScrollMagic.Scene({
      triggerElement: "#business",
      duration: "100%",
    })
      .setClassToggle("#business_nav", "active")
      .addTo(controller);
    var dx = new ScrollMagic.Scene({
      triggerElement: "#mission",
      duration: "100%",
    })
      .setClassToggle("#mission_nav", "active")
      .addTo(controller);
    var vision = new ScrollMagic.Scene({
      triggerElement: "#history",
      duration: "100%",
    })
      .setClassToggle("#history_nav", "active")
      .addTo(controller);
    var esg = new ScrollMagic.Scene({
      triggerElement: "#esg",
      duration: "100%",
    })
      .setClassToggle("#esg_nav", "active")
      .addTo(controller);
    var youtube = new ScrollMagic.Scene({
      triggerElement: "#youtube",
      duration: "100%",
    })
      .setClassToggle("#youtube_nav", "active")
      .addTo(controller);
  });
  return (
    <MainContainer>
      <AboutWrapper>
        <SectionMain />
        <LoadInView id="overview">
          <SectionOverview />
        </LoadInView>
        <LoadInView id="business">
          <SectionBusiness />
        </LoadInView>
        <LoadInView id="esg">
          <SectionESG />
        </LoadInView>
        <LoadInView id="history">
          <SectionHistory />
        </LoadInView>
        <LoadInView id="youtube">
          <SectionYoutube />
        </LoadInView>
        <LoadInView>
          <SectionStory />
        </LoadInView>
      </AboutWrapper>
      <SectionMenu />
    </MainContainer>
  );
};

export default About;
